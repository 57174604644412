import React, { useEffect, useState } from "react"
import { useToasts } from "react-toast-notifications"
import { formatWithComma, onlyNumbers } from "components/CommonMethods"
import Progress from "./Progress"
import Timeline from "./Timeline"
import { joinWaitlist } from "../../../requesters"
import ErrorModal from "./ErrorModal"

const DEFAULT_MINIMUM_INVESTMENT = 500000

const JoinWaitlist = ({
 offering, reloadData, currentEntity
}) => {
  const [enableInvestment, setEnableInvestment] = useState(false)
  const [investmentAmount, setInvestedAmount] = useState(offering.need_fixed_investment ? offering.min_investment : 0)
  const [showModal, setShowModal] = useState(false)
  const minInvestmentLbl = offering.need_fixed_investment ? "Investment amount" : "Minimum amount"

  // edge cases where fresh entities do not have an investor cert
  let userRestriction = "500k"
  if (currentEntity.country === "SG") {
    userRestriction = (currentEntity.investor_certificate && currentEntity.investor_certificate[`invest_limit_${offering.region}`]) || "restricted"
  } else {
    userRestriction = (currentEntity.investor_certificate && currentEntity.investor_certificate[`invest_limit_${offering.region}`]) || "500k"
  }

  const { addToast } = useToasts()
  useEffect(() => {
    setEnableInvestment(
      Number.isFinite(investmentAmount)
      && investmentAmount > 0
      && investmentAmount >= offering.min_investment
      && offering.remaining_amount >= investmentAmount
    )
  }, [investmentAmount])

  const investButtonClickHandler = () => {
    if (currentEntity.background_check_status === "verified"
      && (userRestriction === "unrestricted" || (userRestriction === "500k" && investmentAmount >= DEFAULT_MINIMUM_INVESTMENT))
    ) {
      joinWaitlist(offering.id, investmentAmount, currentEntity.id).then(data => {
      const res = data.response.json
      if (res.success) {
        setShowModal(false)
        addToast("Congratulations! Your investment has been added.", { appearance: "success" })
      } else {
        addToast(res.message, { appearance: "error" })
      }
        reloadData()
      })
    } else {
      setShowModal(true)
    }
  }

  // Ideally this needs to be in a callback from closing the error modal
  // Fixes bug where dismissing the error modal wasn't resetting the showModal variable
  useEffect(() => {
    setShowModal(false)
  }, [investmentAmount])

  return (
    <div className="col-md-4 order-1 order-md-2 mb-5">
      <div className="card shadow-sm card-body sticky-top">
        <Progress offering={offering} />
        <h3 className="mb-3">Oversubscribed</h3>
        <div className="small mb-3">
          Offer currently fully allocated. To be placed on the waitlist, enter the
          amount you intend to invest. You will be placed on the waitlist based on
          the date of the entry.
        </div>
        <div className="form-group input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text border-right-0" id="basic-addon1">
              {`Amount (${offering.currency})`}
            </span>
          </div>
          <input
            aria-label="Investment amount"
            className="form-control border-left-0 text-right"
            placeholder="$0.00"
            min={0}
            onKeyDown={onlyNumbers}
            onChange={e => setInvestedAmount(parseFloat(e.target.value))}
            type="number" />
        </div>
        <div className="text-center">
          <div className="small">
            {minInvestmentLbl}
            &nbsp;
            {`${offering.currency} ${formatWithComma(offering.min_investment)}`}
          </div>
        </div>
        <button
          type="submit"
          onClick={investButtonClickHandler}
          data-target="#errorModal"
          className="btn btn-lg btn-block btn-main mt-4"
          data-toggle="modal"
          disabled={!enableInvestment}>
          Join Waitlist
        </button>
        <div className="mt-4">
          <table className="table table-borderless yt-table table-sm table-between">
            <tbody>
              <Timeline title="Investor Funding Date" date={offering.investor_funding_date} />
              <Timeline title="Issue Date" date={offering.issue_date} />
            </tbody>
          </table>
        </div>
      </div>
      {showModal && (
        <ErrorModal
          entity={currentEntity}
          userNotVerified={currentEntity.background_check_status !== "verified"}
          userRestriction={userRestriction}
          offeringCountry={offering.country} />
      )}
    </div>
  )
}

export default JoinWaitlist
