import React, { useState } from "react"

const ProceedToBronId = ({ handleBronIdRedirect }) => {
  const [submitted, setSubmitted] = useState(false)
  const [btnLabel, setBtnLabel] = useState("Proceed to ID verification")

  return (
    <div className="row justify-content-center">
      <div className="col-xl-7 col-lg-8">
        <div className="text-center">
          <h2 className="mt-5 pt-4">Identification</h2>
          <p>
            To verify your identity, your identification documents will need to
            pass a verification process.
          </p>
          <p>
            By clicking on the button below, you will be redirected to another
            website. The whole process will take about 3-5 mins to complete.
            Please have your
            <strong> passport </strong>
            or
            <strong> identification document </strong>
            ready.
          </p>
          <button
            type="submit"
            className="btn btn-main btn-lg"
            disabled={submitted}
            onClick={e => {
              setSubmitted(true)
              setBtnLabel("Redirecting...")
              handleBronIdRedirect(e)
            }}>
            {btnLabel}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ProceedToBronId
