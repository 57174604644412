import React from "react"
import Money from "components/Money"
import Progress from "./Progress"
import Timeline from "./Timeline"

const Signed = ({ offering, investment }) => (
  <div className="col-md-4 order-1 order-md-2 mb-5">
    <div className="card shadow-sm card-body sticky-top">
      <Progress offering={offering} />
      <h3 className="mb-3">Pending</h3>
      <div className="d-flex justify-content-between">
        Amount
        <strong className="med">
          <Money currency={offering.currency} amount={investment.amount} />
        </strong>
      </div>
      <div className="bg-light p-2 text-center rounded-lg mt-4 mb-2">
        <small>{`Funding Reference: ${investment.reference_no}`}</small>
      </div>
      <div className="mt-4 investor-cta-state">
        <table className="table table-borderless yt-table table-sm table-between">
          <tbody>
            <Timeline title="Documentation" date={offering.closing_date} status="green" />
            <Timeline title="Funding Date" date={offering.investor_funding_date} status="empty" />
            <Timeline title="Issue Date" date={offering.issue_date} status="empty" />
          </tbody>
        </table>
      </div>
    </div>
  </div>

  )

export default Signed
