import React, { useState } from "react"
import { useSelector } from "react-redux"
import { useToasts } from "react-toast-notifications"
import axios from "axios"

import { getCurrentUser } from "../../reducers"

export default function PasswordResetForm() {
  const [email, setEmail] = useState("")
  const [error, setError] = useState(null)

  const currentUser = useSelector(getCurrentUser)

  const { addToast } = useToasts()

  async function handleSubmit() {
    const path = "/users/password"
    const params = {
      authenticity_token: $('meta[name="csrf-token"]').prop("content"),
      user: { email },
      commit: "Send me reset password instructions"
    }

    axios.post(path, params)
      .then(response => {
        if (_.get(response, "status") === 200) {
          addToast(_.get(response, "data.message"), { appearance: "success" })
          setError(null)
        } else setError(_.get(response, "data.message"))
      })
      .catch(error => setError(_.get(error, "response.data.message")))
  }

  function handleCancel() {
    window.location.href = "/"
  }

  if (currentUser) handleCancel()

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-6 col-md-8">
          <h1 className="text-center">
            Reset Password
          </h1>
          <div className="text-center">
            Enter your email address you use for Aura Group Marketplace and we&#39;ll send you instructions to reset your password.
          </div>
          <br />
          <br />
          <form>
            <div className="form-group">
              <input
                type="email"
                autoComplete="email"
                className="form-control"
                onChange={e => setEmail(e.currentTarget.value)}
                name="email" />
            </div>
            <div className="text-center my-5">
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-main btn-lg"
                  onClick={handleCancel}>
                  Cancel
                </button>
                &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-main btn-lg"
                  disabled={!email}
                  onClick={handleSubmit}>
                  Send Email
                </button>
              </div>
              {error && <div className="err-msg text-danger">{error}</div>}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
