import React from "react"
import { formatWithComma } from "components/CommonMethods"
import Timeline from "./Timeline"

const Deposited = ({ offering, investment }) => (
  <div className="col-md-4 order-1 order-md-2 mb-5">
    <div className="card shadow-sm card-body sticky-top">
      <div className="funding-progress mb-4 pb-3">
        <div className="py-3">
          <div className="h2 mb-0">Deposited</div>
          <small className="text-muted">Recently Funded</small>
        </div>
        <div className="progress">
          <div aria-valuemax={100} aria-valuemin={0} aria-valuenow={100} className="progress-bar" role="progressbar" style={{ width: "100%" }} />
        </div>
      </div>
      <div className="d-flex justify-content-between">
        {` Amount (${offering.currency})`}
        <strong className="med">
          {`$${formatWithComma(investment.amount)}`}
        </strong>
      </div>
      <div className="bg-light p-2 text-center rounded-lg mt-4 mb-2">
        <small>{`Funding Reference: ${investment.reference_no}`}</small>
      </div>
      <hr className="mb-0" />
      <div className="mt-4">
        <table className="table table-borderless yt-table table-sm table-between">
          <tbody>
            <Timeline title="Funding Due" date={offering.investor_funding_date} />
            <Timeline title="Issue Date" date={offering.issue_date} />
          </tbody>
        </table>
      </div>
    </div>
  </div>
)

export default Deposited
