import React, { useState, useEffect } from "react"
import { useToasts } from "react-toast-notifications"
import dayjs from "dayjs"
import MultilineText from "components/MultilineText"
import Money from "components/Money"
import { getOfferingDetail, updateOffering, updateOfferingStatus } from "../../requesters"
import CsvUploadFileModel from "./CsvUploadFileModel"
import DocAndMedia from "./DocAndMedia"
import Investment from "./Investment"
import Batches from "./Batches"
import Banking from "./Banking"

const OfferingDetail = ({ selectedOffering, setOfferingFields, offeringFriendlyID }) => {
  const [offering, setOffering] = useState(undefined)
  const [offeringBatches, setOfferingBatches] = useState({})
  const [launchDate, setLaunchDate] = useState(undefined)
  const [sortingIndex, setSortingIndex] = useState(10)
  const { addToast } = useToasts()

  const reloadOffering = () => {
    getOfferingDetail(selectedOffering).then(data => {
      const offering = data.response.json.data
      setOffering(offering)
      setLaunchDate(offering.launch_date)
      setOfferingBatches(offering.yield_batches)
      setSortingIndex(offering.sorting_index)
    })
  }

  useEffect(() => {
    reloadOffering()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateOfferingDetail = data => {
    updateOffering(offering.id, data).then(data => {
      const res = data.response.json
      if (res.success) {
        addToast("Offering updated successfully.", { appearance: "success" })
        reloadOffering()
      } else {
        addToast(res.message, { appearance: "error" })
      }
    })
  }

  const launchDateSaveHandler = () => {
    const today = dayjs().format("YYYY-MM-DD")
    if (today < launchDate && offering.status !== "Upcoming") {
      updateOfferingStatus(offering.id, "draft").then(data => {
        const res = data.response.json
        if (res.success) {
          // addToast("Offering transitioned to Upcoming successfully.", { appearance: "success" })
          reloadOffering()
        } else {
          addToast(res.message, { appearance: "error" })
        }
      })
    }
    updateOfferingDetail({ launch_date: launchDate })
  }

  const launchEoiHandler = e => {
    e.preventDefault()
    updateOfferingStatus(offering.id, "upcoming").then(data => {
      const res = data.response.json
      if (res.success) {
        addToast("Offering transitioned to Upcoming successfully.", { appearance: "success" })
        reloadOffering()
      } else {
        addToast(res.message, { appearance: "error" })
      }
    })
  }

  const unpublishEoiHandler = e => {
    e.preventDefault()
    updateOfferingStatus(offering.id, "draft").then(data => {
      const res = data.response.json
      if (res.success) {
        addToast("Offering transitioned to Draft successfully.", { appearance: "success" })
        reloadOffering()
      } else {
        addToast(res.message, { appearance: "error" })
      }
    })
  }

  const archiveHandler = e => {
    e.preventDefault()
    updateOfferingDetail({ is_archive: true })
  }

  const onBreadcrumbClickHandler = e => {
    e.preventDefault()
    setOfferingFields(undefined, undefined)
  }

  const onReorder = e => {
    const { value } = e.target
    updateOfferingDetail({ sorting_index: value })
  }

  // Sets launch date today to change Offer status to "Live"
  const launchOfferingHandler = () => {
    if (offering.banking.id) {
      // Quick way to get today's date in YYYY-MM-DD format
      const today = new Date()
      const todayFormatted = today.toISOString().split("T")[0]
      updateOfferingDetail({ launch_date: todayFormatted })
      updateOfferingStatus(offering.id, "live")
    } else {
      addToast("Please add a Bank Account for this Offering first", { appearance: "error" })
    }
  }

  const toggleChecked = (e, region) => {
    updateOfferingDetail({ [region]: e.target.checked }).then(data => {
      const res = data.response.json
      if (res.success) {
        addToast("Offering visibility updated", { appearance: "success" })
        reloadOffering()
      } else {
        addToast(res.message, { appearance: "error" })
      }
    })
  }

  const statusClass = status => {
    let className = ""
    switch (status) {
      case "Draft":
        className = "text-warning"
        break
      case "Live":
      case "Upcoming":
      case "Issued":
        className = "text-main"
        break
      case "Archived":
      case "Closed":
        className =  "text-danger"
        break
      default:
        return ""
    }
    return className
  }

  return (
    <div className="container pt-4">
      <CsvUploadFileModel id={selectedOffering} reloadData={reloadOffering} />
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/" onClick={onBreadcrumbClickHandler}>Marketplace</a>
          </li>
          <li aria-current="page" className="breadcrumb-item active">{offeringFriendlyID}</li>
        </ol>
        <div className="mb-3" />
      </nav>
      <div className="card">
        <div className="card-header">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div className="text-nowrap mr-2"><strong>Schedule Launch: </strong></div>
              <input className="form-control mr-2 w-auto" type="date" defaultValue={offering?.launch_date} onChange={e => setLaunchDate(e.currentTarget.value)} />
              <button type="submit" className="btn btn-sm btn-main" disabled={!launchDate} onClick={launchDateSaveHandler}>Save</button>
            </div>
            <div className="actions d-flex align-items-center justify-content-end">
              <a className="btn btn-primary ml-2" rel="noreferrer" target="_blank" href={`/marketplace/${offering?.id}`}>Preview</a>
              <a className="btn btn-outline-primary ml-2" data-target="#newOfferingModal" data-toggle="modal" href="/">Upload CSV</a>
              <div className="dropdown ml-2">
                <button aria-expanded="false" aria-haspopup="true" className="btn btn-outlined-main dropdown-toggle" data-toggle="dropdown" id="dropdownMenuButton" type="button" />
                <div aria-labelledby="dropdownMenuButton" className="dropdown-menu dropdown-menu-right">
                  {["Draft", "Upcoming"].includes(offering?.status) && (
                    <>
                      <button className="dropdown-item" type="button" onClick={launchOfferingHandler}> Launch Now</button>
                      <div className="dropdown-divider" />
                    </>
                  )}
                  {offering?.csv_file && <a className="dropdown-item" href={offering?.csv_file}> Download CSV</a> }
                  <div className="dropdown-divider" />
                  <a className="dropdown-item" href="/" onClick={archiveHandler}> Archive</a>
                  {offering?.status === "Draft" && (
                    <>
                      <div className="dropdown-divider" />
                      <button type="submit" className="dropdown-item" onClick={launchEoiHandler}>Publish EOI</button>
                    </>
                  )}
                  {offering?.status === "Upcoming" && (
                    <>
                      <div className="dropdown-divider" />
                      <button type="submit" className="dropdown-item" onClick={unpublishEoiHandler}>Unpublish EOI</button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {offering
          && (
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-8">
                  <h2>
                    <strong className={statusClass(offering.status)}>
                      {offering.status}
                    </strong>
                  </h2>
                  <strong>
                    {offering.deal_id}
                  </strong>
                  <h2>{offering.name}</h2>
                  <div className="mb-3">
                    {offering.country}
                    &nbsp;·&nbsp;
                    {offering.asset_type}
                    <div className="text-muted">
                      Last updated on&nbsp;
                      {offering.last_updated_on}
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="funding-progress mb-4 pb-3">
                    <div className="bg-light-main text-center p-3">
                      <div className="h2 text-main mb-0">
                        {`${offering.progress.in_percentage}%`}
                      </div>
                      <small className="text-muted">
                        <Money currency={offering.currency} amount={offering.progress.in_amount} />
                        &nbsp;of&nbsp;
                        {offering.total_deal_size}
                        &nbsp;Funded
                      </small>
                      <div className="custom-control custom-checkbox mb-2">
                        <input
                          className="custom-control-input"
                          id="customCheck1"
                          type="checkbox"
                          defaultChecked={offering.hidden_to_au || false}
                          onChange={e => toggleChecked(e, "hidden_to_au")} />
                        <label className="custom-control-label" htmlFor="customCheck1">Hide AU</label>
                      </div>
                      <div className="custom-control custom-checkbox mb-2">
                        <input
                          className="custom-control-input"
                          id="customCheck2"
                          type="checkbox"
                          defaultChecked={offering.hidden_to_sg || false}
                          onChange={e => toggleChecked(e, "hidden_to_sg")} />
                        <label className="custom-control-label" htmlFor="customCheck2">Hide SG</label>
                      </div>
                      <div className="custom-control custom-checkbox mb-2">
                        <input
                          className="custom-control-input"
                          id="customCheck3"
                          type="checkbox"
                          defaultChecked={offering.hidden_to_rotw || false}
                          onChange={e => toggleChecked(e, "hidden_to_rotw")} />
                        <label className="custom-control-label" htmlFor="customCheck3">Hide ROTW</label>
                      </div>
                      <hr />
                      <div className="custom-control custom-checkbox mb-2">
                        <label className="custom-label">Offering Sorting Priority:</label>
                        <select className="form-control" value={sortingIndex} onChange={onReorder}>
                          <option disabled value="">Select</option>
                          <option value={0}>Featured</option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                          <option value={6}>6</option>
                          <option value={7}>7</option>
                          <option value={8}>8</option>
                          <option value={9}>9</option>
                          <option value={10}>10</option>
                        </select>
                      </div>
                    </div>
                    <div className="progress">
                      <div aria-valuemax={100} aria-valuemin={0} aria-valuenow={0} className="progress-bar" role="progressbar" style={{ width: `${offering.progress.in_percentage}%` }} />
                    </div>
                    <div className="custom-control custom-switch custom-switch-md text-right mt-3">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="nda"
                        defaultChecked={offering.nda_required || false}
                        onChange={e => toggleChecked(e, "nda_required")} />
                      <label className="custom-control-label" htmlFor="nda">NDA Required</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </div>
      {offering
        && (
          <div>
            <ul className="nav nav-pills mb-5 mt-4 flex-nowrap dashboard-tabs" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <a aria-controls="pills-investment" aria-selected="true" className="nav-link active" data-toggle="pill" href="#pills-investment" id="pills-investment-tab" role="tab">
                  Investments
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a aria-controls="pills-yield-returns" aria-selected="false" className="nav-link" data-toggle="pill" href="#pills-yield-returns" id="pills-yield-returns-tab" role="tab">
                  Yield Returns
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a aria-controls="pills-banking" aria-selected="false" className="nav-link" data-toggle="pill" href="#pills-banking" id="pills-banking-tab" role="tab">
                  Banking
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a aria-controls="pills-description" aria-selected="false" className="nav-link" data-toggle="pill" href="#pills-description" id="pills-description-tab" role="tab">
                  Deal Info
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a aria-controls="pills-documents" aria-selected="false" className="nav-link" data-toggle="pill" href="#pills-documents" id="pills-documents-tab" role="tab">
                  Docs &amp; Media
                </a>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div aria-labelledby="pills-investment-tab" className="tab-pane fade show active" id="pills-investment" role="tabpanel">
                <Investment offering={offering} reloadOffering={reloadOffering} />
              </div>
              <div aria-labelledby="pills-yield-returns-tab" className="tab-pane fade" id="pills-yield-returns" role="tabpanel">
                <div className="card my-3">
                  <div className="card-header">Yield Returns</div>
                  <div className="card-body">
                    <table className="table table-link-row yt-table">
                      <thead>
                        <tr>
                          <th>Payment Date</th>
                          <th>Batch ID</th>
                          <th>Status</th>
                          <th>Admin User</th>
                          <th className="text-right">Interest Returned</th>
                          <th className="text-right">Principal Repaid</th>
                          <th className="text-right">Other Repaid</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {offeringBatches.length
                            ? offeringBatches.map(batch => (
                              <Batches
                                batch={batch}
                                currency={offering.currency}
                                key={`transaction-${batch.id}`} />
                              ))
                            : <td><div>No Yield Returns</div></td>
                          }
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div aria-labelledby="pills-banking-tab" className="tab-pane fade" id="pills-banking" role="tabpanel">
                <Banking offering={offering} reloadOffering={reloadOffering} />
              </div>
              <div aria-labelledby="pills-description-tab" className="tab-pane fade" id="pills-description" role="tabpanel">
                <div className="row mb-5">
                  <div className="col-4">
                    <div className="p-4 mb-4 bg-light h-100">
                      <h3 className="medium">Investment Terms</h3>
                      <table className="table table-sm table-borderless table-between">
                        <tbody>
                          <tr>
                            <td>Currency</td>
                            <td>{offering.currency}</td>
                          </tr>
                          <tr>
                            <td>Management Fee</td>
                            <td>
                              {offering?.management_fee}
                            </td>
                          </tr>
                          <tr>
                            <td>Performance Fee</td>
                            <td>
                              {offering.performance_fee}
                            </td>
                          </tr>
                          <tr>
                            <td>{offering.free_text_heading_1}</td>
                            <td>
                              {offering.free_text_value_1}
                            </td>
                          </tr>
                          <tr>
                            <td><strong>{offering.free_text_heading_2}</strong></td>
                            <td>
                              <strong>
                                {offering.free_text_value_2}
                              </strong>
                            </td>
                          </tr>
                          <tr>
                            <td>Redemption</td>
                            <td>
                              {offering.redemption}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-4 mb-4 bg-light h-100">
                      <h3 className="medium">Schedule</h3>
                      <table className="table table-sm table-borderless table-between">
                        <tbody>
                          <tr>
                            <td>Interest Schedule</td>
                            <td>{offering.interest_schedule}</td>
                          </tr>
                          <tr>
                            <td>Principal Schedule</td>
                            <td>{offering.principal_schedule}</td>
                          </tr>
                          <tr>
                            <td>Prefunded</td>
                            <td>{offering.prefunded_deal}</td>
                          </tr>
                          <tr>
                            <td>Documentation Date</td>
                            <td>{offering.documentation_date}</td>
                          </tr>
                          <tr>
                            <td>Launch Date</td>
                            <td>{offering.launch_date_formatted}</td>
                          </tr>
                          <tr>
                            <td>Closing Date</td>
                            <td>{offering.closing_date}</td>
                          </tr>
                          <tr>
                            <td>Investor Funding Date</td>
                            <td>{offering.investor_funding_date}</td>
                          </tr>
                          <tr>
                            <td>Issue Date</td>
                            <td>{offering.issue_date}</td>
                          </tr>
                          <tr>
                            <td>Target Maturity</td>
                            <td>{offering.target_maturity_date}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-4 mb-4 bg-light h-100">
                      <h3 className="medium">Structure</h3>
                      <table className="table table-sm table-borderless table-between">
                        <tbody>
                          <tr>
                            <td>Deal Type</td>
                            <td>{offering.deal_type}</td>
                          </tr>
                          <tr>
                            <td>Asset Type</td>
                            <td>{offering.asset_type}</td>
                          </tr>
                          <tr>
                            <td>Sub Asset Type</td>
                            <td>{offering.sub_asset_type}</td>
                          </tr>
                          <tr>
                            <td>Asset Value</td>
                            <td>{offering.asset_value}</td>
                          </tr>
                          {/* Commenting for now out as per ticket: 415 */}
                          {/* <tr>
                            <td>Loan Type</td>
                            <td>{offering.loan_type}</td>
                          </tr> */}
                          <tr>
                            <td>Investor Structure</td>
                            <td>{offering.investor_structure}</td>
                          </tr>
                          <tr>
                            <td>Co-investment?</td>
                            <td>{offering.coinvestment_deal}</td>
                          </tr>
                          <tr>
                            <td>Originator</td>
                            <td>{offering.originator}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="pb-4">
                  <h3>Overview</h3>
                  <MultilineText text={offering.overview} />
                </div>
                <div className="py-4">
                  <h3>{offering.free_text_subheading_3}</h3>
                  <MultilineText text={offering.free_text_value_3} />
                </div>
                <div className="py-4">
                  <h3>{offering.free_text_subheading_4}</h3>
                  <MultilineText text={offering.free_text_value_4} />
                </div>
                <div className="py-4">
                  <h3>{offering.free_text_subheading_5}</h3>
                  <MultilineText text={offering.free_text_value_5} />
                </div>
                <div className="py-4">
                  <h3>{offering.free_text_subheading_6}</h3>
                  <MultilineText text={offering.free_text_value_6} />
                </div>
                <div className="py-4">
                  <h3>{offering.free_text_subheading_7}</h3>
                  <MultilineText text={offering.free_text_value_7} />
                </div>
                <div className="py-4">
                  <h3>{offering.free_text_subheading_8}</h3>
                  <MultilineText text={offering.free_text_value_8} />
                </div>
              </div>
              <DocAndMedia offering={offering} reloadData={reloadOffering} />
            </div>
          </div>
        )
      }
    </div>
  )
}

export default OfferingDetail
