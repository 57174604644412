/**
 * 126 - Aus Residents only need Passport or Driver Licence (only one ID); code kept incase they want to revert to original
 * https://trello.com/c/vlqiRuMn/126-identification-australia-residents
 */
/* eslint-disable prefer-destructuring */
import React, { Component } from "react"
import { connect } from "react-redux"
import dayjs from "dayjs"
import { jsonToFormData } from "components/CommonMethods"
import featureFlags from "components/utils/FeatureFlags"
import ProfileForm from "./ProfileForm"
import IdentityForm from "./IdentityForm"
import InvestorForm from "./InvestorForm"
import { createEntity, setupUserProfile } from "../../../requesters/UsersRequester"
import { getBaseUrl } from "../../../reducers"
import BronId from "./BronId"

class AccountCreation extends Component {
  constructor(props) {
    super(props)

    const {
      currentUser: {
        firstname, surname,
        middle_name, activeForm,
        country, email, from_rotw,
        gender,
        dob, mapped_dob, income_source,
        fund_source,
        accredited_investor,
        wholesale_investor,
        wealth_description,
        addressDetails,
        wholesaleCertificate,
        background_check
      }
    } = this.props

    let defaultDocumentTypes = []
    if (country === "SG") {
      defaultDocumentTypes = [{
        value: "nric",
        text: "NRIC"
      },
      {
        value: "passport",
        text: "Passport"
      },
      {
        value: "utilityBill",
        text: "Utility Bill"
      },
      {
        value: "bankStatement",
        text: "Bank Statement"
      },
      {
        value: "governmentLetter",
        text: "Government Letter"
      }
    ]
    } else {
      defaultDocumentTypes = [{
        value: "passport",
        text: "Passport"
      },
      {
        value: "licence",
        text: "Driver Licence"
      },
      // {
      //   value: "medicare",
      //   text: "Medicare"
      // }
      ]
    }

    this.state = {
      activeForm: activeForm || "profile",
      isClicked: false,
      profileIsValid: false,
      defaultDocumentTypes,
      idDocument1: country !== "SG" ? defaultDocumentTypes
        : [{
          value: "nric",
          text: "NRIC"
        },
        {
          value: "passport",
          text: "Passport"
        }],
      idDocument2: country !== "SG"
        ? defaultDocumentTypes
        : [
          {
            value: "utilityBill",
            text: "Utility Bill"
          },
          {
            value: "bankStatement",
            text: "Bank Statement"
          },
          {
            value: "governmentLetter",
            text: "Government Letter"
          },
        ],
      formData: {
        email,
        country,
        from_rotw,
        firstname,
        middle_name,
        surname,
        gender,
        dob,
        mapped_dob,
        income_source,
        fund_source,
        wholesale_investor,
        accredited_investor,
        wealth_description,
        addressDetails,
        background_check
      },
      address: addressDetails || {
        unit_number: undefined,
        street_number: undefined,
        street_name: undefined,
        suburb: undefined,
        postcode: undefined,
        state: undefined,
        country: undefined,
        full_address: undefined
      },
      idOne: {
        id: undefined,
        activeType: undefined,
        passport: {
          id: undefined,
          country: undefined,
          number: undefined,
          expiry: undefined,
          image: undefined,
          date: {
            day: undefined,
            month: undefined,
            year: undefined
          }
        },
        licence: {
          id: undefined,
          country: undefined,
          state: undefined,
          number: undefined,
          front_image: undefined,
          back_image: undefined,
        },
        medicare: {
          id: undefined,
          number: undefined,
          colour: undefined,
          individual_number: undefined,
          name: undefined,
          expiry: undefined,
          image: undefined,
          date: {
            day: undefined,
            month: undefined,
            year: undefined
          }
        },
        nric: {
          id: undefined,
          number: undefined,
          front_image: undefined,
          back_image: undefined
        },
      },
      idTwo: {
        id: undefined,
        activeType: undefined,
        // passport: {
        //   id: undefined,
        //   country: undefined,
        //   number: undefined,
        //   expiry: undefined,
        //   image: undefined,
        //   date: {
        //     day: undefined,
        //     month: undefined,
        //     year: undefined
        //   }
        // },
        // licence: {
        //   id: undefined,
        //   country: undefined,
        //   state: undefined,
        //   number: undefined,
        //   front_image: undefined,
        //   back_image: undefined,
        // },
        // medicare: {
        //   id: undefined,
        //   number: undefined,
        //   colour: undefined,
        //   individual_number: undefined,
        //   name: undefined,
        //   expiry: undefined,
        //   image: undefined,
        //   date: {
        //     day: undefined,
        //     month: undefined,
        //     year: undefined
        //   }
        // },
        // nric: {
        //   id: undefined,
        //   number: undefined,
        //   front_image: undefined,
        //   back_image: undefined
        // },
        utilityBill: {
          id: undefined,
          file: undefined
        },
        bankStatement: {
          id: undefined,
          file: undefined
        },
        governmentLetter: {
          id: undefined,
          file: undefined
        }
      },
      dob: {
        day: null,
        month: null,
        year: null
      },
      wholesaleCertificate: wholesaleCertificate || {
        id: undefined,
        hasWholesaleCertifcate: undefined,
        hasInvestorDocs: undefined,
        issue_date: undefined,
        pdf: undefined,
        invest_limit_rotw: undefined,
        invest_limit_sg: undefined,
        invest_limit_aus: undefined,
        doc_file: undefined,
        sup_doc_1: undefined,
        sup_doc_2: undefined,
        sup_doc_3: undefined,
        sgd_value: undefined,
        doc_issue_date: {
          day: undefined,
          month: undefined,
          year: undefined
        },
        date: {
          day: undefined,
          month: undefined,
          year: undefined
        },
        mapped_doc_issue_date: {
          day: undefined,
          month: undefined,
          year: undefined
        }
      }
    }
  }

  componentDidMount = () => {
    const { currentUser: { identificationDocuments, proofOfResidence, country } } = this.props
    const { idOne, idTwo, defaultDocumentTypes } = this.state
    const firstId = identificationDocuments[0]

    const docs = defaultDocumentTypes

    this.setState({ idOne: { ...idOne, ...firstId } }, () => {
      this.setSaveAndExitDataFromState()
    })
    this.setState({ idTwo: { ...idTwo, ...proofOfResidence } }, () => {
      this.setSaveAndExitDataFromState()
    })

    if (country !== "SG" && firstId.activeType) {
      if (defaultDocumentTypes.map(k => k.value).indexOf(firstId.activeType) >= 0) {
        this.setState({ idDocument2: docs.filter(item => item.value !== firstId.activeType) })
      }
    }
  }


  handleAddressChange = addressObj => {
    const component = addressObj.address_components
    const { address } = this.state

    const address_details = {
      id: address?.id,
      unit_number: this.extractFromAddress(component, "subpremise"),
      street_number: this.extractFromAddress(component, "street_number"),
      street_name: this.extractFromAddress(component, "route"),
      suburb: this.extractFromAddress(component, "locality"),
      postcode: this.extractFromAddress(component, "postal_code"),
      state: this.extractFromAddress(component, "administrative_area_level_1"),
      country: this.extractFromAddress(component, "country"),
      full_address: addressObj.formatted_address
    }

    this.setState({ address: address_details }, () => {
      this.setSaveAndExitDataFromState()
    })
  }

  /**
   * Get the value for a given key in address_components
   *
   * @param {Array} components address_components returned from Google maps autocomplete
   * @param type key for desired address component
   * @returns {String} value, if found, for given type (key)
   */
  extractFromAddress = (components, type) => (
    components.filter(component => component.types.indexOf(type) === 0).map(item => item.long_name).pop() || null
  )

  handleAttributeChange = event => {
    const { formData } = this.state
    const {
      name,
      value
    } = event

    formData[name] = value

    this.setState(
      { formData }, () => {
        this.setSaveAndExitDataFromState()
      }
    )
  }

  setSaveAndExitDataFromState = () => {
    const { setSaveAndExitData } = this.props
    const userInfo = this.mapStateToNestedAttribute("partial_submission")
    const userInfoFormData = jsonToFormData(userInfo.user, "user")
    setSaveAndExitData(userInfoFormData)
  }

  handleBronIdRedirection = () => {
    this.handleCreateAccount("waiting_background_check")
  }

  brondIDStatus = () => {
    const { currentUser: { background_check } } = this.props
    if (featureFlags("STAGING")) {
      // eslint-disable-next-line no-console
      console.log("BACKGROUND_CHECK>>", background_check, ((new URLSearchParams(window.location.search)).get("bronIdRedirection") === "true" ? "waiting" : ""))
    }
    return (background_check || ((new URLSearchParams(window.location.search)).get("bronIdRedirection") === "true" ? "waiting" : ""))
  }

  handleCreateAccount = userStatus => {
    const userInfo = this.mapStateToNestedAttribute(userStatus)
    const userInfoFormData = jsonToFormData(userInfo.user, "user")
    const { currentUser, baseUrl } = this.props

    setupUserProfile(baseUrl, currentUser.id, userInfoFormData).then(data => {
      const res = data.response.json
      if (res.success) {
        if (res.data.redirect_url) {
          window.location.href = res.data.redirect_url
        } else {
          window.location.reload()
        }
      } else {
        // eslint-disable-next-line no-alert
        alert("Oops! Something went wrong!") // TODO replace this with UI
      }
    })
  }

  handleCreateEntity = userStatus => {
    const userInfo = this.mapStateToNestedAttribute(userStatus)
    const userInfoFormData = jsonToFormData(userInfo.user, "user")
    const { currentUser, baseUrl } = this.props

    setupUserProfile(baseUrl, currentUser.id, userInfoFormData).then(data => {
      const res = data.response.json
      if (res.success) {
        const entityDetails = {
          firstname: currentUser.firstname,
          middle_name: currentUser.middle_name,
          surname: currentUser.surname,
          country: currentUser.country,
          dob: currentUser.dob,
          gender: currentUser.gender,
          income_source: currentUser.income_source,
          investor_type: currentUser.investor_type,
        }
        createEntity(baseUrl, currentUser.id, entityDetails).then(r => {
          if (r.response.json.success) {
            window.location.href = "/marketplace"
          }
        })
      } else {
        // eslint-disable-next-line no-alert
        alert("Oops! Something went wrong!") // TODO replace this with UI
      }
    })
  }

  mapStateToNestedAttribute = status => {
    const {
      activeForm, formData, idOne, idTwo, wholesaleCertificate
    } = this.state

    const { address } = this.state
    const { currentUser } = this.props

    const params =  {
      user: {
        id: currentUser.id,
        country: formData.country,
        active_registration_form: activeForm,
        registration_status: status,
        dob: formData.dob,
        firstname: formData.firstname,
        surname: formData.surname,
        middle_name: formData.middle_name,
        gender: formData.gender,
        income_source: formData.income_source,
        fund_source: formData.fund_source,
        address_attributes: {
          id: address.id,
          unit_number: address.unit_number,
          street_number: address.street_number,
          street_name: address.street_name,
          suburb: address.suburb,
          postcode: address.postcode,
          state: address.state,
          country: address.country,
          full_address: address.full_address
        },
        identification_documents_attributes: [
          this.mapIdentityDocs(idOne, 1),
        ],
        proof_of_residence_attributes: this.mapIdentityDocs(idTwo),
        investor_certificate_attributes: this.investorDocAttr(wholesaleCertificate)
      }
    }
    return params
  }

  mapIdentityDocs = (doc, rank) => {
    const {
      licence,
      // medicare,
      passport,
      nric,
      utilityBill,
      bankStatement,
      governmentLetter
    } = doc

    switch (doc.activeType) {
      case "licence":
        return {
          id: doc.id,
          rank,
          document_type: "DriverLicence",
          driver_licence_attributes: this.driverLicenceAttr(licence)
        }
      // case "medicare":
      //   return {
      //     id: doc.id,
      //     rank,
      //     document_type: "Medicare",
      //     medicare_attributes: this.medicareAttr(medicare)
      //   }
      case "passport":
        return {
          id: doc.id,
          rank,
          document_type: "Passport",
          passport_attributes: this.passportAttr(passport)
        }
      case "nric":
        return {
          id: doc.id,
          rank,
          document_type: "NriCard",
          nri_card_attributes: this.nriCardAttr(nric)
        }
      case "utilityBill":
        return {
          id: doc.id,
          type: "UtilityBill",
          file: this.proofOfResidenceAttr(utilityBill)
        }
      case "bankStatement":
        return {
          id: doc.id,
          type: "BankStatement",
          file: this.proofOfResidenceAttr(bankStatement)
        }
      case "governmentLetter":
        return {
          id: doc.id,
          type: "GovernmentLetter",
          file: this.proofOfResidenceAttr(governmentLetter)
        }
      default:
        return {}
    }
  }

  driverLicenceAttr = licence => {
    const attr = {
      id: licence.id,
      country: licence.country,
      state: licence.state,
      number: licence.number,
      card_number: licence.card_number,
    }

    if (licence.front_image && licence.front_image[0] instanceof File) {
      attr.front_image = licence.front_image[0]
    }
    if (licence.back_image && licence.back_image[0] instanceof File) {
      attr.back_image = licence.back_image[0]
    }

    return attr
  }

  nriCardAttr = nric => {
    const attr = {
      id: nric.id,
      number: nric.number
    }

    if (nric.front_image && nric.front_image[0] instanceof File) {
      attr.front_image = nric.front_image[0]
    }
    if (nric.back_image && nric.back_image[0] instanceof File) {
      attr.back_image = nric.back_image[0]
    }

    return attr
  }

  proofOfResidenceAttr = uploaded => {
    let attr = {}
    if (uploaded.file && uploaded.file[0] instanceof File) {
      attr = uploaded.file[0]
    }
    return attr
  }

  passportAttr = passport => {
    const attr = {
      id: passport.id,
      country: passport.country,
      expiry_date: passport.expiry,
      number: passport.number
    }

    if (passport.image && passport.image[0] instanceof File) {
      attr.image = passport.image[0]
    }
    return attr
  }

  // medicareAttr = medicare => {
  //   const attr = {
  //     id: medicare.id,
  //     color: medicare.colour,
  //     expiry_date: medicare.expiry,
  //     name_on_card: medicare.name,
  //     number: medicare.number,
  //     reference_number: medicare.individual_number
  //   }
  //   if (medicare.image && medicare.image[0] instanceof File) {
  //     attr.image = medicare.image[0]
  //   }

  //   return attr
  // }

  investorDocAttr = doc => {
    const attr = {
      id: doc.id,
      has_investor_doc: doc.hasInvestorDocs,
      has_wholesale_cert: doc.hasWholesaleCertifcate,
      issue_date: doc.issue_date,
      // Doc issue date is Today
      doc_issue_date: dayjs().format("YYYY-MM-DD"),
      // doc_issue_date: doc.doc_issue_date,
      sgd_value: doc.sgd_value
    }

    if (doc.sup_doc_1 && doc.sup_doc_1[0] instanceof File) {
      attr.sup_doc_1 = doc.sup_doc_1[0]
    }

    if (doc.sup_doc_2 && doc.sup_doc_2[0] instanceof File) {
      attr.sup_doc_2 = doc.sup_doc_2[0]
    }

    if (doc.sup_doc_3 && doc.sup_doc_3[0] instanceof File) {
      attr.sup_doc_3 = doc.sup_doc_3[0]
    }

    if (doc.pdf && doc.pdf[0] instanceof File) {
      attr.file = doc.pdf[0]
    }

    if (doc.doc_file && doc.doc_file[0] instanceof File) {
      attr.doc_file = doc.doc_file[0]
    }

    return attr
  }

  handleDateSaveChange = event => {
    const { dob } = this.state
    const {
      name,
      value
    } = event

    dob[name] = value

    this.setState({ dob }, () => {
      this.setSaveAndExitDataFromState()
    })
  }

  handleIdOneChange = event => {
    const {
      idOne,
      formData,
      idOne: {
        activeType
      },
      defaultDocumentTypes
    } = this.state
    const {
      name,
      value
    } = event

    if (name === "activeType") {
      idOne[name] = value
    } else {
      idOne[activeType][name] = value
    }
    const docs = defaultDocumentTypes

    if (formData.country !== "SG") {
      if (defaultDocumentTypes.map(k => k.value).indexOf(event.value) >= 0) {
        this.setState({ idDocument2: docs.filter(item => item.value !== event.value) })
      }
    }
    this.setState({ idOne }, () => {
      this.setSaveAndExitDataFromState()
    })
  }

  handleIdTwoChange = event => {
    const {
      idTwo,
      formData,
      idTwo: {
        activeType
      },
      defaultDocumentTypes
    } = this.state
    const {
      name,
      value
    } = event

    if (name === "activeType") {
      idTwo[name] = value
    } else {
      idTwo[activeType][name] = value
    }

    const docs = defaultDocumentTypes

    if (formData.country !== "SG") {
      if (defaultDocumentTypes.map(k => k.value).indexOf(event.value) >= 0) {
        this.setState({ idDocument1: docs.filter(item => item.value !== event.value) })
      }
    }

    this.setState({ idTwo }, () => {
      this.setSaveAndExitDataFromState()
    })
  }

  handleWholesaleChange = event => {
    const { wholesaleCertificate } = this.state
    const {
      name,
      value
    } = event

    wholesaleCertificate[name] = value

    this.setState({ wholesaleCertificate }, () => {
      this.setSaveAndExitDataFromState()
    })
  }

  setIsValid = value => {
    this.setState({ profileIsValid: value })
  }

  handleClickOnNext = () => {
    this.setState({ isClicked: true })
  }

  handleProfileIsValid = isValid => {
    this.setState({ profileIsValid: isValid || false })
  }

  activeComponent = () => {
    const {
      activeForm,
      formData, address, mapped_dob, idOne,
      isClicked,
      idTwo,
      profileIsValid,
      idDocument1,
      idDocument2,
      wholesaleCertificate
    } = this.state

    const {
      currentUser,
      currentUser: {
        country
      }
    } = this.props
     switch (activeForm) {
      case "profile":
        return (
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-8">
              <ProfileForm
                handleAttributeChange={this.handleAttributeChange}
                handleDateSaveChange={this.handleDateSaveChange}
                handleAddressSelection={this.handleAddressChange}
                handleManualAddressInput={input => this.setState({ address: input }, () => { this.setSaveAndExitDataFromState() })}
                setIsValid={this.setIsValid}
                setSaveAndExitDataFromState={this.setSaveAndExitDataFromState}
                dob={mapped_dob}
                currentUser={currentUser}
                isClicked={isClicked}
                formData={formData}
                address={address} />
              <div className="d-flex justify-content-end form-nav">
                <button
                  type="submit"
                  className="btn btn-main btn-lg"
                  disabled={Object.values(formData).filter(i => !!i).length <= 5}
                  onClick={() => {
                    this.handleClickOnNext()
                  if (profileIsValid || profileIsValid !== false) {
                      this.handleFormChange("identity")
                    }
                  }}>
                  Next
                </button>
              </div>
            </div>
          </div>
        )
      case "identity":
        if (formData.from_rotw) {
          return (
            <div className="mt-5">
              <BronId
                status={this.brondIDStatus()}
                handleBronIdRedirect={this.handleBronIdRedirection}
                setSaveAndExitDataFromState={this.setSaveAndExitDataFromState}
                handleFormChange={this.handleFormChange} />
            </div>
          )
        // eslint-disable-next-line no-else-return
        } else {
          return (
            <IdentityForm
              handleFormChange={this.handleFormChange}
              handleIdOneChange={this.handleIdOneChange}
              handleIdTwoChange={this.handleIdTwoChange}
              handleCreateEntity={this.handleCreateEntity}
              handleProfileIsValid={this.handleProfileIsValid}
              setSaveAndExitDataFromState={this.setSaveAndExitDataFromState}
              idOne={idOne}
              idTwo={idTwo}
              country={country}
              idDocument1={idDocument1}
              idDocument2={idDocument2} />
          )
        }

      case "investor":
        if (["pending_approval"].includes(currentUser?.registration_status) && currentUser.current_entity) {
          window.location.href = "/marketplace"
        }
        return (
          <InvestorForm
            currentUser={currentUser}
            handleFormChange={this.handleFormChange}
            handleAttributeChange={this.handleAttributeChange}
            handleWholesaleChange={this.handleWholesaleChange}
            setSaveAndExitDataFromState={this.setSaveAndExitDataFromState}
            formData={formData}
            handleSubmit={this.handleCreateAccount}
            handleCreateEntity={this.handleCreateEntity}
            wholesaleCertificate={wholesaleCertificate} />
        )
      default: return null
    }
  }

  activeListItem = listItem => {
    const {
      activeForm
    } = this.state

    if (listItem === activeForm) {
      return "active"
    } if (activeForm === "profile" || listItem === "investor") {
      return null
    }
    return "done"
  }

  handleFormChange = endForm => {
    this.setState({ activeForm: endForm })
  }

  render() {
    return (
      <div className="container pb-5">
        <div className="row justify-content-center sticky-top bg-white">
          <div className="col-xl-8 col-lg-10">
            <ul className="nav nav-pills nav-fill nav-stepper">
              <li className="nav-item">
                <div className={`nav-link ${ this.activeListItem("profile") }`}> Profile</div>
              </li>
              <li className="nav-item">
                <div className={`nav-link ${ this.activeListItem("identity") }`}> Identity</div>
              </li>
              {/* <li className="nav-item">
                <div className={`nav-link ${ this.activeListItem("investor") }`}> Investor</div>
              </li> */}
            </ul>
          </div>
        </div>
        { this.activeComponent() }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  baseUrl: getBaseUrl(state)
})

export default connect(mapStateToProps, {})(AccountCreation)
