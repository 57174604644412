import React, { useState } from "react"
import Spinner from "components/Spinner"
import { verifyCurrentEntity } from "../../requesters/UserRequester"
import AddEntityForm from "./AddEntity/AddEntityForm"

const AddEntity = ({
  baseUrl, currentUser
}) => {
  const [submitted, setSubmitted] = useState(false)
  const [redirecting, setRedirecting] = useState(false)

  const handleAddAccount = () => {
    verifyCurrentEntity(baseUrl).then(
      data => {
        const res = data.response.json
        if (res.success) {
          // window.location.href = res.data.redirect_url
          window.open(res.data.redirect_url)
        } else {
          // eslint-disable-next-line no-alert
          alert("Oops! Something went wrong!") // TODO replace this with UI
        }
      }
    )
  }

  return (
    <>
      <div aria-labelledby="pendingCancelledPopup" className="modal fade" id="addAccountModal" role="dialog" tabIndex={-1} style={{ display: "none" }} aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0">
              <button aria-label="Close" className="close" data-dismiss="modal" type="button">
                <span aria-hidden="true"> ×</span>
              </button>
            </div>
            <div className="modal-body pt-0 px-lg-5 pb-5">
              {!submitted ? (<AddEntityForm submitted={submitted} setSubmitted={setSubmitted} baseUrl={baseUrl} currentUser={currentUser} />) : (
                <div className="text-center">
                  <h2>Identification</h2>
                  <p>
                    To verify your identity, your identification documents will need to
                    pass a verification process.
                  </p>
                  <p>
                    By clicking on the button below, you will be redirected to another
                    website. The whole process will take about 3-5 mins to complete.
                    Please have your
                    <strong> passport </strong>
                    or
                    <strong> identification document </strong>
                    ready.
                  </p>
                  {
                    redirecting ? (
                      <div className="btn btn-main btn-lg mt-4">
                        <Spinner />
                      </div>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-main btn-lg mt-4"
                        onClick={() => {
                          setRedirecting(true)
                          handleAddAccount()
                        }}>
                        Proceed to ID verification
                      </button>
                    )
                  }
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddEntity
