import React from "react"
import Timeline from "./Timeline"

// Investment component where Offering closes and Entity has not invested
const Closed = ({ offering }) => (
  <div className="col-md-4 order-1 order-md-2 mb-5">
    <div className="card shadow-sm card-body sticky-top">
      <div className="funding-progress mb-4 pb-3">
        <div className="py-3">
          <div className="h2 mb-0">{offering.status}</div>
          <small className="text-muted">Recently Funded</small>
        </div>
        <div className="progress">
          <div aria-valuemax={100} aria-valuemin={0} aria-valuenow={100} className="progress-bar" role="progressbar" style={{ width: "100%" }} />
        </div>
      </div>
      <hr className="mb-0" />
      <div className="mt-4">
        <table className="table table-borderless yt-table table-sm table-between">
          <tbody>
            {offering.status === "Closed" && <Timeline title="Closing Date" date={offering.closing_date} />}
            <Timeline title="Issue Date" date={offering.issue_date} />
            <Timeline title="Target Maturity Date" date={offering.target_maturity_date} />
          </tbody>
        </table>
      </div>
    </div>
  </div>
  )

export default Closed
