import React, { useState, useEffect } from "react"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css"
import filterFactory, { selectFilter, textFilter } from "react-bootstrap-table2-filter"
// eslint-disable-next-line import/extensions
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css"
import paginationFactory from "react-bootstrap-table2-paginator"
import BootstrapTable from "react-bootstrap-table-next"
import { bronIdCountryList } from "components/countries"
import FileUploadModel from "./FileUploadModel"
import { getOfferingList } from "../../requesters"

const OfferingTable = ({ setOfferingFields }) => {
  const [offerings, setOfferings] = useState([])
  const [assetTypes, setAssetTypes] = useState(undefined)
  const [columns, setColumns] = useState(undefined)
  const unique = (value, index, self) => self.indexOf(value) === index
  const arrayToJson = arr => {
    const obj = {}
    arr.forEach(e => {
      obj[e] = e
    })
    return obj
  }
  const statuses = [
    "Draft",
    "Upcoming",
    "Live",
    "Issued",
    "Closed",
    "Completed",
    "Archived"
  ]

  const countries = {}
  bronIdCountryList.forEach(c => {
    countries[c.value] = c.label
  })

  const onClickHandler = (e, row) => {
    e.preventDefault()
    setOfferingFields(row.id, row.deal_id)
  }

  // https://github.com/facebook/react/issues/10135
  const setNativeValue = (element, value) => {
    const valueSetter = Object.getOwnPropertyDescriptor(element, "value").set
    const prototype = Object.getPrototypeOf(element)
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, "value").set
    if (valueSetter && valueSetter !== prototypeValueSetter) {
      prototypeValueSetter.call(element, value)
    } else {
      valueSetter.call(element, value)
    }
  }

  const reloadData = () => {
    getOfferingList().then(data => {
      const offerings = data.response.json.data
      const categories = offerings.map(elem => elem.asset_type).filter(unique)

      setOfferings(offerings)
      setAssetTypes(categories)

      setColumns([{
        dataField: "country",
        text: "",
        filter: selectFilter({
          options: countries
        }),
        headerStyle: () => ({ width: "50px" }),
        formatter: cell => <img alt={cell} className="country-flag" src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${cell}.svg`} />
      }, {
        dataField: "deal_id",
        text: "Deal ID",
        sort: true
      }, {
        dataField: "name",
        text: "Deal Name",
        sort: true,
        headerStyle: () => ({ width: "400px" }),
        filter: textFilter({ delay: 0 }),
        formatter: (cell, row) => <a onClick={e => onClickHandler(e, row)} href={`/backend/offering/${cell}`}>{cell}</a>
      }, {
        dataField: "sorting_index",
        formatter: cell => {
          if (cell === 0) {
            return <span className="text-main"><strong>Featured</strong></span>
          }
          return cell
        },
        sort: true,
        text: "Sorting Priority",
      }, {
        dataField: "asset_type",
        text: "Asset Type",
        sort: true,
        filter: selectFilter({ options: arrayToJson(categories) })
      }, {
        dataField: "total_deal_size",
        text: "Deal Size",
        sort: true
      },
        // NOTE: Removed gross_yield as part of ticket 415
        // dataField: "gross_yield",
        // text: "Gross Yield",
        // sort: true,
        // formatter: cell => `${cell}%`
       {
        dataField: "status",
        text: "Status",
        filter: selectFilter({ options: arrayToJson(statuses) }),
        sort: true,
        formatter: cell => {
          if (cell === "Live") {
            return <span className="text-main"><strong>{cell}</strong></span>
          }
          return cell
        }
      }, {
        dataField: "allocation",
        text: "Allocation",
          sort: true,
          formatter: cell => `${cell}%`
        }, {
        dataField: "issue_date",
        text: "Issue Date",
        sort: true
      }, {
        dataField: "target_maturity_date",
        text: "Target Maturity",
        sort: true
      }])
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { reloadData() }, [])

  return (
    <>
      { columns && assetTypes && (
        <>
          <FileUploadModel reloadData={reloadData} />
          <div className="d-flex mt-4 mb-3">
            <input
              className="form-control mr-2"
              placeholder="Search Offering"
              type="text"
              onChange={e => {
                const elem = document.getElementById("text-filter-column-name")
                setNativeValue(elem, e.currentTarget.value)
                elem.dispatchEvent(new Event("change", { bubbles: true }))
              }} />
            <select
              className="form-control custom-select mr-2"
              onChange={e => {
                document.getElementById("select-filter-column-status").value = e.currentTarget.value
                document.getElementById("select-filter-column-status").dispatchEvent(new Event("change", { bubbles: true }))
              }}>
              <option value="">All Status</option>
              {statuses.map(e => <option key={e} value={e}>{e}</option>)}
            </select>
            <select
              className="form-control custom-select mr-2"
              onChange={e => {
                document.getElementById("select-filter-column-country").value = e.currentTarget.value
                document.getElementById("select-filter-column-country").dispatchEvent(new Event("change", { bubbles: true }))
              }}>
              <option value="">All Countries</option>
              {bronIdCountryList.map(c => <option key={c.value} value={c.value}>{c.label}</option>)}
            </select>
            <select
              className="form-control custom-select mr-2"
              onChange={e => {
                document.getElementById("select-filter-column-asset_type").value = e.currentTarget.value
                document.getElementById("select-filter-column-asset_type").dispatchEvent(new Event("change", { bubbles: true }))
              }}>
              <option value="">All Categories</option>
              {assetTypes.map(e => <option key={e} value={e}>{e}</option>)}
            </select>
          </div>
          <div className="offering-table">
            <BootstrapTable
              keyField="deal_id"
              data={offerings}
              columns={columns}
              filter={filterFactory()}
              wrapperClasses="table-responsive"
              pagination={paginationFactory()} />
          </div>
        </>
      )}
    </>
  )
}

export default OfferingTable
