import React, { useState } from "react"
import { connect } from "react-redux"
import MainNavbar from "./components/MainNavbar"
import ExtendedNavbar from "./components/ExtendedNavbar"
import Main from "./components/Main"
import Footer from "./components/Footer"
import { setupUserProfile } from "./requesters/UsersRequester"
import { getCurrentUser, getBaseUrl } from "./reducers"

function Content({ currentUser, baseUrl }) {
  const [formData, setFormData] = useState(undefined)

  const saveAndExit = () => {
    setupUserProfile(baseUrl, currentUser.id, formData).then(data => {
      const res = data.response.json
      if (res.success) {
        window.location.href = res.data.redirect_url || "/complete-account"
      } else {
        // eslint-disable-next-line no-alert
        alert("Oops! Something went wrong!") // TODO replace this with UI
      }
    })
  }

  const needExtendedHeader = window.location.pathname.includes("complete-account")
  return (
    <>
      { needExtendedHeader ? <ExtendedNavbar handleSaveAndExit={saveAndExit} currentUser={currentUser} /> : <MainNavbar handleSaveAndExit={saveAndExit} currentUser={currentUser} />}
      <div className="content">
        <Main setSaveAndExitData={setFormData} />
      </div>
      <Footer />
    </>
  )
}

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
  baseUrl: getBaseUrl(state)
})

export default connect(mapStateToProps)(Content)