import React from "react"

const ReturningFluidUser = ({ fluidLoginUrl }) => (
  <>
    <h1 className="mt-0 pt-5">Welcome back to your digital asset portal!</h1>
    <p>
      Aura Group has partnered with Leonie Hill to bring you some of the top crypto assets available, with deep liquidity, secure private order flow, and
      institutional grade custody.
    </p>
    <p>Log back into your account now to trade! You&apos;ll need to re-input your Aura Group Marketplace email and password for security.</p>
    <a
      className="btn btn-main btn-lg mt-2"
      href={fluidLoginUrl}
      disabled={!fluidLoginUrl}>
      Crypto Log In
    </a>
  </>
)

export default ReturningFluidUser
