import React, { useState } from "react"
import { useToasts } from "react-toast-notifications"
import { jsonToFormData } from "components/CommonMethods"
import { submitImportFile } from "../../requesters"

const FileUploadModel = ({ reloadData }) => {
  const [file, setFile] = useState(undefined)
  const { addToast } = useToasts()

  const submitFile = () => {
    const formData = jsonToFormData({ file }, "offering")
    formData.append("file", file)
    submitImportFile(formData).then(data => {
      const res = data.response.json
      if (res.success) {
        addToast("File Imported successfully", { appearance: "success" })
        setFile(undefined)
        document.getElementById("btnCloseModal").click()
        document.getElementById("customFile").value = ""
        reloadData()
      } else {
        addToast(res.message, { appearance: "error" })
      }
    })
  }

  return (
    <div aria-labelledby="newOfferingModalLabel" className="modal fade" id="newOfferingModal" role="dialog" tabIndex={-1} style={{ display: "none" }} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header border-0">
            <button aria-label="Close" className="close" data-dismiss="modal" type="button">
              <span aria-hidden="true"> ×</span>
            </button>
          </div>
          <div className="modal-body pt-0">
            <h3 className="modal-title mb-2">Upload CSV</h3>
            <p className="modal-title mb-3 small text-muted">* CSV should be in UTF-8 encoding</p>
            <div className="custom-file">
              <input
                className="custom-file-input"
                id="customFile"
                type="file"
                onChange={e => setFile(e.currentTarget.files[0])}
                accept="text/csv" />
              <label className="custom-file-label" htmlFor="customFile">
                { file?.name || "Choose file"}
              </label>
            </div>
          </div>
          <div className="modal-footer border-0">
            <button className="btn btn-secondary" id="btnCloseModal" data-dismiss="modal" type="button">
              Close
            </button>
            <button className="btn btn-main" type="button" disabled={!file} onClick={submitFile}>Upload</button>
          </div>
        </div>
      </div>
    </div>

  )
}

export default FileUploadModel
