import React, { useState, useEffect } from "react"
import { allCountryList } from "components/countries"

function CountrySelector({ onChange, name, defaultValue }) {
  const [value, setValue] = useState()

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const changeHandler = e => {
    setValue(e.target.value)
    onChange()
  }

  return (
    <select
      className="form-control country-select custom-select"
      value={value || ""}
      name={name}
      autoComplete="disableAll"
      onChange={e => changeHandler(e)}>
      <option value="" disabled>Select</option>
      {allCountryList.map(c => <option key={c.value} value={c.value}>{c.label}</option>)}

    </select>
  )
}

export default CountrySelector
