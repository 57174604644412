import React, { useState } from "react"
// import { bronIdCountryList } from "components/countries"
import { createNewEntity } from "../../../requesters/UserRequester"

const bronIdCountries = [
  {
    value: "AU",
    label: "Australia"
  },
  // {
  //   value: "SG",
  //   label: "Singapore"
  // },
  {
    value: "AT",
    label: "Austria"
  },
  {
    value: "BE",
    label: "Belgium"
  },
  {
    value: "BR",
    label: "Brazil"
  },
  {
    value: "CA",
    label: "Canada"
  },
  {
    value: "CN",
    label: "China"
  },
  {
    value: "DK",
    label: "Denmark"
  },
  {
    value: "FR",
    label: "France"
  },
  {
    value: "DE",
    label: "Germany"
  },
  {
    value: "IT",
    label: "Italy"
  },
  {
    value: "MX",
    label: "Mexico"
  },
  {
    value: "NL",
    label: "Netherlands"
  },
  {
    value: "NZ",
    label: "New Zealand"
  },
  {
    value: "NO",
    label: "Norway"
  },
  {
    value: "ES",
    label: "Spain"
  },
  {
    value: "SE",
    label: "Sweden"
  },
  {
    value: "CH",
    label: "Switzerland"
  },
  {
    value: "GB",
    label: "United Kingdom"
  }
]

const AddEntityForm = ({
  baseUrl, submitted, setSubmitted
}) => {
  const [name, setName] = useState()
  const [entityType, setEntityType] = useState("")
  const [country, setCountry] = useState("")

  const handleAddAccount = () => {
    createNewEntity(baseUrl, { entity_type: entityType, entity_name: name, country }).then(
      data => {
        const res = data.response.json
        if (res.success) {
          setSubmitted(true)
        } else {
          // eslint-disable-next-line no-alert
          alert("Oops! Something went wrong!") // TODO replace this with UI
        }
      }
    )
  }

  return (
    <>
      <h2>Setup a new account</h2>
      <div className="mt-5">
        <div className="form-group row mb-2">
          <div className="col-sm-6 col-form-label">Name of your account</div>
          <div className="col-sm-6">
            <input
              className="form-control"
              type="text"
              placeholder="Account name"
              autoComplete="disableAll"
              name="name"
              onChange={e => setName(e.target.value)} />
          </div>
          <div className="col-sm-6">
            <small>
              <p>
                This will be what appears on your Aura Group investments
              </p>
            </small>
          </div>
        </div>
        <div className="form-group row mb-2">
          <div className="col-sm-6 col-form-label">Country</div>
          <div
            className="col-sm-6">
            <select
              autoComplete="disableAll"
              className="form-control custom-select"
              name="country"
              value={country}
              onChange={e => {
                setCountry(e.target.value)
              }}>
              <option value="" disabled>Select</option>
              {bronIdCountries.map(c => <option key={c.value} value={c.value}>{c.label}</option>)}
            </select>
          </div>
        </div>
        <div className="form-group row mb-2">
          <div className="col-sm-6 col-form-label">Entity Type</div>
          <div
            className="col-sm-6">
            <select
              autoComplete="disableAll"
              className="form-control custom-select"
              name="entity_type"
              value={entityType}
              onChange={e => {
                setEntityType(e.target.value)
              }}>
              <option value="" disabled>Select</option>
              <option value="privateCompany">Private Company</option>
              <option value="soleTrader">Sole Trader</option>
              <option value="partnership">Partnership</option>
              <option value="publicCompany">Public Company</option>
              {/* <option value="governmentBody">Government Body</option> */}
              <option value="association">Association</option>
              {country === "AU" && (
                <>
                  <option value="regulatedTrust">Regulated Trust</option>
                  <option value="unRegulatedTrust">Unregulated Trust</option>
                  <option value="smsf">SMSF</option>
                </>
              )}
            </select>
          </div>
        </div>
        <div className="text-right mt-4">
          <button
            type="submit"
            className="btn btn-main btn-lg"
            disabled={submitted || !name || entityType === "" || country === ""}
            onClick={
              handleAddAccount
            }>
            Next
          </button>
        </div>
      </div>
    </>
  )
}

export default AddEntityForm
