/* eslint-disable import/extensions */
import React, { useState, useEffect } from "react"
import docToBeSigned from "images/Accredited_Investor_Notification_form.pdf"
import Dropzone from "../Dropzone"
import ErrorMessage from "./ErrorMessage"

export default function AccreditedForm({
  setValidAccrediatedForm,
  handleWholesaleChange,
  wholesaleCertificate: {
    doc_file,
    sup_doc_1,
    sup_doc_2,
    sup_doc_3,
    sgd_value
  }
}) {
  const hasExistingPdf = !!doc_file?.name
  const [validCertificate, setValidCertificate] = useState(!!doc_file?.name)
  const isMobileDevice = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf("IEMobile") !== -1)

  useEffect(() => {
    setValidAccrediatedForm(validCertificate)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validCertificate])

  const handleDropZoneBlur = e => {
    const doc_file = e.currentTarget.querySelector('[type="file"]').value
    setValidCertificate(hasExistingPdf || !!doc_file)
  }

  return (
    <>
      <div className="form-group mb-2">
        <label>Select one of the below</label>
        <select
          defaultValue={sgd_value || ""}
          autoComplete="disableAll"
          className="form-control custom-select"
          name="sgd_value"
          onChange={e => {
            handleWholesaleChange(e.target)
          }}>
          <option value="">Select</option>
          <option value="SGD300k">I had a gross income of at least SGD300k in the previous 12 months</option>
          <option value="SGD1m">I have net financial assets in excess of SGD1m </option>
          <option value="SGD2m">I have net assets of at least SGD2m (where my primary residence accounts for no more than SGD1m)</option>
        </select>
      </div>
      <div className="mt-5"></div>
      <strong className="d-block mb-3">Sign Document</strong>
      <div className="row">
        <div className="col-md-6">
          <label>Please download PDF, sign page 3, and upload it. </label>
          <a
            className="btn btn-lg btn-outlined-main mb-3"
            href={docToBeSigned}
            download={!isMobileDevice}
            target="_blank"
            rel="noopener noreferrer">
            Download PDF
          </a>
        </div>
        <div className="col-md-6" onBlur={handleDropZoneBlur}>
          <Dropzone
            wrapperClass="drag-drop"
            inactiveText={doc_file?.name || "Drag and drop the file or click to Browse Files"}
            activeText="Drop your PDF here"
            multiple={false}
            defaultValue={doc_file}
            name="doc_file"
            fileTypes="image/jpeg, image/png, application/pdf"
            fileTypeNames=".jpeg .png .pdf"
            handleFileUpload={e => {
            handleWholesaleChange(e)
            setValidCertificate(hasExistingPdf || !!e.value)
          }} />
        </div>
      </div>
      {validCertificate === false && <ErrorMessage message="Please upload your current Accredited Investor documentation" />}
      <div className="mt-5"></div>
      <strong className="d-block mb-3">Supporting Documents</strong>
      <div className="form-group" onBlur={handleDropZoneBlur}>
        <label>Please upload documentation to support your Accredited Investor status; for example your latest bank or brokerage statements, your latest tax return or annual salary slip, or confirmation letter from your bank or accountant.</label>
        <div className="row">
          <div className="col-md-4">
            <Dropzone
              wrapperClass="drag-drop"
              inactiveText={sup_doc_1?.name || "Drag and drop the file or click to Browse Files"}
              activeText="Drop your PDF here"
              multiple={false}
              defaultValue={sup_doc_1}
              name="sup_doc_1"
              fileTypes="image/jpeg, image/png, application/pdf"
              fileTypeNames=".jpeg .png .pdf"
              handleFileUpload={e => {
            handleWholesaleChange(e)
          }} />
          </div>
          <div className="col-md-4">
            <Dropzone
              wrapperClass="drag-drop"
              inactiveText={sup_doc_2?.name || "Drag and drop the file or click to Browse Files"}
              activeText="Drop your PDF here"
              multiple={false}
              defaultValue={sup_doc_2}
              name="sup_doc_2"
              fileTypes="image/jpeg, image/png, application/pdf"
              fileTypeNames=".jpeg .png .pdf"
              handleFileUpload={e => {
            handleWholesaleChange(e)
          }} />
          </div>
          <div className="col-md-4">
            <Dropzone
              wrapperClass="drag-drop"
              inactiveText={sup_doc_3?.name || "Drag and drop the file or click to Browse Files"}
              activeText="Drop your PDF here"
              multiple={false}
              defaultValue={sup_doc_3}
              name="sup_doc_3"
              fileTypes="image/jpeg, image/png, application/pdf"
              fileTypeNames=".jpeg .png .pdf"
              handleFileUpload={e => {
            handleWholesaleChange(e)
          }} />
          </div>
        </div>
      </div>
    </>
  )
}
