import React from "react"

import { yieldTreeLogoInverse } from "components/Icons"

export default function ExtendedNavbar({ currentUser }) {
  const redirectUser = () => {
    window.location.href = "/complete-account"
  }
  const redirectUserMarketplace = () => {
    window.location.href = "/marketplace"
  }

  return (
    <nav className="navbar navbar-expand-md navbar-dark bg-dark main-nav">
      <div className="container">
        <a className="navbar-brand" onClick={redirectUser} href="/complete-account">
          <img src={yieldTreeLogoInverse} />
        </a>
        <button className="navbar-toggler" aria-controls="navbarSupportedContent" aria-expanded="false" data-target="#navbarSupportedContent" data-toggle="collapse" type="button">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav main-nav-tabs">
            <li className="nav-item text-right text-lg-left disable"><span className="nav-link">Dashboard</span></li>
            <a href="/marketplace" onClick={redirectUserMarketplace} className="nav-link">Marketplace</a>
          </ul>
          <div className="navbar-nav ml-md-auto d-flex align-items-center justify-content-end">
            <div className="user-initial">
              {currentUser.firstname[0] + currentUser.surname[0]}
            </div>
            <a className="nav-link active" href="/logout">Logout</a>
          </div>
        </div>
      </div>
    </nav>
  )
}
