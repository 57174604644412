/* eslint-disable no-unused-expressions */
import axios from "axios"

export const addInvestment = (id, investmentAmount, currentEntityId) => {
  const path = `/api/offerings/${id}/add_investment`
  return axios.post(path, { entity_id: currentEntityId, amount: investmentAmount, status: "pending" })
    .then(({ data, errors, status }) => ({ error: _.get(errors, "response.data.0", errors), response: data, status }))
}

export const joinWaitlist = (id, investmentAmount, currentEntityId) => {
  const path = `/api/offerings/${id}/add_investment`
  return axios.post(path, { entity_id: currentEntityId, amount: investmentAmount, status: "waiting" })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const getInvestmentDetail = (offeringId, currentEntityId) => {
  const path = `/api/offerings/${offeringId}/current_investment`
  return axios.post(path, { entity_id: currentEntityId })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const updateInvestment = (id, formData) => {
  const path = `/api/investments/${id}`
  return axios.put(path, formData, { header: { "Content-Type": "multipart/form-data" } })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const sendHelloSignRequest = id => {
  const path = `/api/investments/${id}/send_hello_sign_request`
  return axios.post(path, {}, { header: { "Content-Type": "multipart/form-data" } })
    .then(({ data, errors, status }) => ({ error: _.get(errors, "response.data.0", errors), response: data, status }))
}

export const cancelInvestment = id => {
  const path = `/api/investments/${id}/cancel`
  return axios.put(path)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}