import React, { useState, useEffect } from "react"
import { getUnregisteredUserDetails } from "../../requesters"
import UserDetail from "./UserDetail"
import UserList from "./UserList"


const Users = () => {
  const [activePage, setActivePage] = useState("list")
  const [selectedUser, setSelectedUser] = useState()
  const [user, setUser] = useState(undefined)

  const loadUserDetail = () => {
    getUnregisteredUserDetails("/api", selectedUser).then(data => {
      setUser(data.response.json.data)
      setActivePage("detail")
    })
  }

  useEffect(() => {
    if (selectedUser) {
      loadUserDetail()
    } else {
      setActivePage("list")
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser])

  switch (activePage) {
    case "list":
      return <UserList setUser={setSelectedUser} />
    case "detail":
      return (
        <UserDetail
          user={user}
          setUser={setSelectedUser}
          refetchUserDetail={loadUserDetail} />
      )
    default:
      return <UserList setUser={setSelectedUser} />
  }
}

export default Users
