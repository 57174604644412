/* eslint-disable react/no-array-index-key */
import React from "react"

const MultilineText = ({ text }) => (
  <div>
    {text?.split("\\n").map((str, index) => <p key={index} className="whitespace-prewrap">{str}</p>)}
  </div>
)

export default MultilineText
