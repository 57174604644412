import React, { useState, useEffect } from "react"
import dayjs from "dayjs"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css"
import filterFactory, { selectFilter, customFilter } from "react-bootstrap-table2-filter"
// eslint-disable-next-line import/extensions
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css"
import paginationFactory from "react-bootstrap-table2-paginator"
import BootstrapTable from "react-bootstrap-table-next"
import { bronIdCountryList } from "components/countries"
import { getYieldBatch } from "../../requesters"

const YieldBatchTable = ({ setSelectedReturn }) => {
  const [returns, setReturns] = useState([])
  const [currentReturns, setCurrentReturns] = useState([])
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()

  const arrayToJson = arr => {
    const obj = {}
    arr.forEach(e => {
      obj[e] = e
    })
    return obj
  }

  const statuses = [
    "Draft",
    "Exported"
  ]

  const countries = {}
  bronIdCountryList.forEach(c => {
    countries[c.value] = c.label
  })

  const onClickHandler = (e, id) => {
    const batch = returns.find(element => element.id === id)
    e.preventDefault()
    setSelectedReturn(batch)
  }

  let onNameFilter = null

  const reloadData = () => {
    getYieldBatch().then(data => {
      const returns = data.response.json.data
      setReturns(returns)
      setCurrentReturns(returns)
    })
  }

  const columns = [{
    dataField: "date",
    text: "Payment Date",
    sort: true,
  }, {
    dataField: "batch_id",
    text: "Batch ID",
    sort: true,
    formatter: (cell, row) => (
      <a
        onClick={e => onClickHandler(e, row.id)}
        className=""
        href={`/backend/returns/${cell}`}>
        {cell}
      </a>
    )
  }, {
    dataField: "status",
    text: "Status",
    sort: true,
    filter: selectFilter({ options: arrayToJson(statuses) }),
  }, {
    dataField: "country",
    text: "",
    filter: selectFilter({
      options: countries
    }),
    headerStyle: () => ({ width: "50px" }),
    formatter: cell => (
      <img
        alt={cell}
        className="country-flag"
        src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${cell}.svg`} />
    )
  }, {
    dataField: "deal_id",
    text: "Deal ID",
    sort: true
  }, {
    dataField: "offering_name",
    text: "Offering Name",
    sort: true,
    filter: customFilter(),
    filterRenderer: onFilter => {
      onNameFilter = onFilter
      return null
    },
  }, {
    dataField: "total_interest",
    text: "Interest Return",
    sort: true,
  }, {
    dataField: "total_principal",
    text: "Principal Return",
    sort: true,
  }, {
    dataField: "total_other",
    text: "Other Return",
    sort: true,
  }, {
    dataField: "admin_user",
    text: "Admin User",
    sort: true
  }]

  useEffect(() => {
    reloadData()
    setReturns(returns)
    setCurrentReturns(returns)
  }, [])

  useEffect(() => {
    if (startDate && endDate && endDate > startDate) {
      const currentView = []
      returns.forEach(r => {
        if (r.date && dayjs(startDate) < dayjs(r.date) && dayjs(r.date) < dayjs(endDate)) {
          currentView.push(r)
        }
      })
      setCurrentReturns(currentView)
    }
  }, [startDate, endDate])

  const onEntityChange = event => {
    onNameFilter(event.currentTarget.value)
  }

  return (
    <>
      <div className="d-flex mt-4 mb-3">
        <input
          className="form-control mr-2"
          placeholder="Search Return"
          type="search"
          onChange={onEntityChange} />
        <select
          className="form-control custom-select mr-2"
          onChange={e => {
              document.getElementById("select-filter-column-status").value = e.currentTarget.value
              document.getElementById("select-filter-column-status").dispatchEvent(new Event("change", { bubbles: true }))
            }}>
          <option value="">All Status</option>
          {statuses.map(e => <option key={e} value={e}>{e}</option>)}
        </select>
        <input
          className="form-control mr-2 w-auto"
          type="date"
          onChange={e => setStartDate(e.currentTarget.value)} />
        <input
          className="form-control mr-2 w-auto"
          type="date"
          onChange={e => setEndDate(e.currentTarget.value)} />
      </div>
      <div className="offering-table">
        <BootstrapTable
          keyField="id"
          data={currentReturns}
          columns={columns}
          filter={filterFactory()}
          wrapperClasses="table-responsive"
          pagination={paginationFactory()} />
      </div>
    </>
  )
}

export default YieldBatchTable
