import React, { useState } from "react"
import OfferingTable from "./OfferingTable"
import OfferingDetail from "./OfferingDetail"

const Offerings = () => {
  const [selectedOffering, setSelectedOffering] = useState()
  const [offeringFriendlyID, setOfferingFriendlyId] = useState()

  const setOfferingFields = (id, friendlyId) => {
    setSelectedOffering(id)
    setOfferingFriendlyId(friendlyId)
  }

  return (
    selectedOffering
      ? (
        <OfferingDetail
          selectedOffering={selectedOffering}
          setOfferingFields={setOfferingFields}
          offeringFriendlyID={offeringFriendlyID} />
        )
      : (
        <div className="container-fluid pt-4">
          <div className="d-flex justify-content-between align-items-center">
            <h3>Marketplace</h3>
            <div className="btn btn-main" data-target="#newOfferingModal" data-toggle="modal" type="button">
              Add New
            </div>
          </div>
          <OfferingTable setOfferingFields={setOfferingFields} />
        </div>
      )
  )
}

export default Offerings
