import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { bankingCountryList } from "components/countries"
import SidebarNav from "./SidebarNav"
import { getEntityDetails } from "../../requesters/UserRequester"
import RemoveBankingModal from "./RemoveBankingModal"

const getCurrency = country => {
  const foo = bankingCountryList.find(element => element.value === country)
  return foo.currency
}

const Banking = ({ baseUrl, currentEntity }) => {
  const [bankDetails, setBankDetails] = useState([])
  const [bankIdToBeDeleted, setBankIdToBeDeleted] = useState()

  useEffect(() => {
    getEntityDetails(baseUrl, currentEntity.id).then(data => {
      setBankDetails(data?.response.json.data.bank_details)
    })
  }, [])

  return (
    <div className="content">
      <div className="container">
        <div className="row no-gutters">
          <SidebarNav />
          <div className="col-lg-9">
            <h2 className="mb-1 mt-5">Banking</h2>
            <p>Interest and principal payments from investments will be paid to your bank account</p>
            {!bankDetails.length && <p>You currently have no Bank Details, please click add account.</p> }
            {!!bankDetails.length && (
              <div className="table-responsive">
                <table className="table yt-table yt-lined">
                  <thead>
                    <tr>
                      <th />
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {bankDetails?.map(bankDetail => (
                      <tr key={bankDetail.id}>
                        <td>
                          <img className="country-flag" src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${bankDetail.country}.svg`} style={{ height: "20px", paddingRight: "1rem" }} />
                          {`Account ${bankDetail.account_number} ${getCurrency(bankDetail.country)}`}
                        </td>
                        <td className="text-right">
                          <button className="btn btn-plain" type="button" data-target="#removeBankingModal" data-toggle="modal" onClick={() => setBankIdToBeDeleted(bankDetail.id)}>Remove</button>
                        </td>
                      </tr>
                  ))}
                  </tbody>
                </table>
              </div>
            )
            }
            <Link className="btn btn-outlined-main btn-lg mt-5" to="/account/banking/add">Add Account</Link>
          </div>
        </div>
      </div>
      { bankIdToBeDeleted && <RemoveBankingModal baseUrl={baseUrl} currentEntity={currentEntity} bankIdToBeDeleted={bankIdToBeDeleted} setBankIdToBeDeleted={setBankIdToBeDeleted} />}
    </div>
  )
}

export default Banking