/* eslint-disable import/extensions */
import React, { useState } from "react"
import { connect } from "react-redux"
import { useToasts } from "react-toast-notifications"
import dayjs from "dayjs"
import Dropzone from "components/Dropzone"
import { jsonToFormData } from "components/CommonMethods"
import ErrorMessage from "components/VerificationForms/ErrorMessage"
import docToBeSigned from "images/Accredited_Investor_Notification_form.pdf"
import { updateEntityInvestorCert } from "../../requesters/UserRequester"
import { getBaseUrl } from "../../reducers"

const AddInvestorCertForm = ({ currentEntity, baseUrl }) => {
  const [file, setFile] = useState(undefined)
  const [selectedSupFile1, setSelectedSupFile1] = useState()
  const [selectedSupFile2, setSelectedSupFile2] = useState()
  const [selectedSupFile3, setSelectedSupFile3] = useState()
  const [selectedSgdValue, setSelectedSgdValue] = useState()
  const isMobileDevice = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf("IEMobile") !== -1)
  const { addToast } = useToasts()

  const fileChangeHandler = e => {
    const file = e.value[0]
    setFile(file)
  }

  const makeSubmitParams = () => {
    const data = {
      sgd_value: selectedSgdValue,
      doc_issue_date: dayjs().format("YYYY-MM-DD"),
      ai_doc_verified: false
    }
    if (file) {
      data.doc_file = file
    }
    data.id = currentEntity.investor_certificate?.id
    if (selectedSupFile1) {
      data.sup_doc_1 = selectedSupFile1
    }
    if (selectedSupFile2) {
      data.sup_doc_2 = selectedSupFile2
    }
    if (selectedSupFile3) {
      data.sup_doc_3 = selectedSupFile3
    }
    return data
  }

  const updateUserDetails = data => {
    updateEntityInvestorCert(baseUrl, currentEntity.id, data).then(data => {
      const res = data?.response.json
      if (res?.success) {
        window.location.reload()
        addToast("User detail updated successfully.", { appearance: "success" })
      } else {
        addToast(res.message, { appearance: "error" })
      }
    })
  }

  const handleSave = () => {
    const data = makeSubmitParams()
    updateUserDetails(jsonToFormData(data, "user"))
  }

  return (
    <>
      <div className="mt-4">
        <div className="form-group mb-2">
          <label>Select one of the below</label>
          <select
            defaultValue={selectedSgdValue || ""}
            autoComplete="disableAll"
            className="form-control custom-select"
            name="sgd_value"
            onChange={e => setSelectedSgdValue(e.target.value)}>
            <option value="">Select</option>
            <option value="SGD300k">SGD300k - I had a gross income of at least SGD300k in the previous 12 months</option>
            <option value="SGD1m">SGD1m - I have net financial assets in excess of SGD1m </option>
            <option value="SGD2m">SGD2m - I have net assets of at least SGD2m (where my primary residence accounts for no more than SGD1m)</option>
          </select>
        </div>
      </div>
      <div className="mt-5"></div>
      <strong className="mb-3 d-block">Sign Document</strong>
      <div className="row">
        <div className="col-md-6">
          <label>Please download PDF, sign page 3, and upload it. </label>
          <a
            className="btn btn-lg btn-outlined-main mb-3"
            href={docToBeSigned}
            download={!isMobileDevice}
            target="_blank"
            rel="noopener noreferrer">
            Download PDF
          </a>
        </div>
        <div className="col-md-6">
          <Dropzone
            wrapperClass="drag-drop"
            inactiveText={file?.name || "Drag and drop the file or click to Browse Files"}
            activeText="Drop your PDF here"
            multiple={false}
            name="doc_file"
            fileTypes="image/jpeg, image/png, application/pdf"
            fileTypeNames=".jpeg .png .pdf"
            handleFileUpload={fileChangeHandler} />
        </div>
        <div className="col">
          {!file && <ErrorMessage message="Please upload your current Accredited Investor documentation" />}
        </div>
      </div>
      <div className="mt-5"></div>
      <strong className="mb-3 d-block">Supporting Documents</strong>
      <div className="form-group">
        <label>Please upload documentation to support your Accredited Investor status; for example your latest bank or brokerage statements, your latest tax return or annual salary slip, or confirmation letter from your bank or accountant.</label>
        <div className="row">
          <div className="col-md-4">
            <Dropzone
              wrapperClass="drag-drop"
              inactiveText={selectedSupFile1?.name || "Drag and drop the file or click to Browse Files"}
              activeText="Drop your PDF here"
              multiple={false}
              name="sup_doc_1"
              fileTypes="image/jpeg, image/png, application/pdf"
              fileTypeNames=".jpeg .png .pdf"
              handleFileUpload={e => {
                setSelectedSupFile1(e.value[0])
              }} />
          </div>
          <div className="col-md-4">
            <Dropzone
              wrapperClass="drag-drop"
              inactiveText={selectedSupFile2?.name || "Drag and drop the file or click to Browse Files"}
              activeText="Drop your PDF here"
              multiple={false}
              name="sup_doc_2"
              fileTypes="image/jpeg, image/png, application/pdf"
              fileTypeNames=".jpeg .png .pdf"
              handleFileUpload={e => {
                setSelectedSupFile2(e.value[0])
              }} />
          </div>
          <div className="col-md-4">
            <Dropzone
              wrapperClass="drag-drop"
              inactiveText={selectedSupFile3?.name || "Drag and drop the file or click to Browse Files"}
              activeText="Drop your PDF here"
              multiple={false}
              name="sup_doc_3"
              fileTypes="image/jpeg, image/png, application/pdf"
              fileTypeNames=".jpeg .png .pdf"
              handleFileUpload={e => {
                setSelectedSupFile3(e.value[0])
              }} />
          </div>
        </div>
      </div>
      <div className="modal-footer border-0 px-0 py-4">
        <button
          className="btn btn-main"
          id="btnCloseModal"
          data-dismiss="modal"
          type="button"
          disabled={!file || !selectedSgdValue}
          onClick={handleSave}>
          Confirm
        </button>
        <button
          className="btn btn-outlined-main"
          id="btnCloseModal"
          data-dismiss="modal"
          type="button">
          Cancel
        </button>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  baseUrl: getBaseUrl(state)
})

export default connect(mapStateToProps)(AddInvestorCertForm)
