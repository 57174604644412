import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { getBaseUrl } from "../../reducers"
import { verifyPin, resendConfirmationCode } from "../../requesters/UserRequester"

export default function VerifyPhone({ currentUser }) {
  const [code, setCode] = useState("")
  const [codeVerfied, setCodeVerified] = useState(false)
  const [showError, setShowError] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(true)
  const baseUrl = useSelector(getBaseUrl)

  const validateCode = value => {
    const validCode = /^[0-9]{6}$/
    return validCode.test(String(value))
  }

  useEffect(() => {
    setDisableSubmit(!validateCode(code))
  }, [code])

  const onSubmit = async e => {
    e.preventDefault()
    setShowMessage(false)
    await verifyPin(baseUrl, currentUser.id, code).then(data => {
      const res = data.response.json
      if (res.success) {
        setCodeVerified(true)
        setShowError(false)
      } else {
        setShowError(true)
      }
    })
  }

  const resendCode = async () => {
    await resendConfirmationCode(baseUrl, currentUser.id).then(data => {
      const res = data.response.json
      if (res.success) {
        setShowMessage(true)
        setShowError(false)
        setCode("")
      }
    })
    return false
  }

  if (codeVerfied) {
    window.location.reload()
  }
  return (
    <div className="container text-center">
      <div className="row justify-content-center">
        <div className="col-lg-11">
          <h2 className="mt-5 mb-4">Enter Verification Code</h2>
          <p>
            A secure code has been sent to your phone number. Please enter the code
            received in the field below and click “Verify” to confirm.
          </p>
          <div className="form-group my-4">
            <input
              className="form-control w-50 mx-auto"
              placeholder="Code"
              onChange={e => setCode(e.target.value)}
              type="text"
              value={code} />
            { showMessage && <div className="mt-2"> A new confirmation code has been sent to your phone number.</div>}
            { showError && <div className="err-msg text-danger mt-2"> Invalid Code</div> }
          </div>
          <input
            type="submit"
            value="Verify"
            onClick={onSubmit}
            className="btn btn-main py-3 px-5"
            disabled={disableSubmit} />
          <div className="mt-5">
            Didn&apos;t receive a code?
            <button
              type="button"
              className="btn btn-link mx-1"
              onClick={e => resendCode(e)}>
              Resend Code
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
