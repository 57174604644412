import React, { useState } from "react"
import YieldBatchDetail from "./YieldBatchDetail"
import YieldBatchTable from "./YieldBatchTable"
import CreateYieldBatch from "./CreateYieldBatch"
import { getIssuedOfferings } from "../../requesters"
import NewReturnModal from "./NewReturnModal"

const Returns = () => {
  const [selectedReturn, setSelectedReturn] = useState()
  const [issuedOfferings, setIssuedOfferings] = useState([])
  const [selectedOffering, setSelectedOffering] = useState()

  const handleIssuedOfferings = () => {
    getIssuedOfferings().then(data => {
      const offerings = data.response.json.data
      setIssuedOfferings(offerings)
    })
  }

  let pageElement = null
  switch (selectedReturn) {
    case undefined:
      pageElement = (
        <div className="container-fluid pt-4">
          <div className="d-flex justify-content-between align-items-center">
            <h3>Marketplace</h3>
            <div
              className="btn btn-main"
              data-target="#newYieldReturnsModal"
              data-toggle="modal"
              type="button"
              onClick={handleIssuedOfferings}>
              Add New
            </div>
          </div>
          <YieldBatchTable setSelectedReturn={setSelectedReturn} />
        </div>
      )
      break
    case "create":
      pageElement = (
        <CreateYieldBatch
          offeringDetail={selectedOffering} />
      )
      break
    default:
      pageElement = (
        <YieldBatchDetail
          selectedReturn={selectedReturn} />
      )
      break
  }
  return (
    <>
      {
        pageElement
      }
      {!!issuedOfferings && (
        <NewReturnModal
          setSelectedOffering={setSelectedOffering}
          setSelectedReturn={setSelectedReturn}
          issuedOfferings={issuedOfferings} />
      )}
    </>
  )
}

export default Returns