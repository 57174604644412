/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react"

const WholesaleDocEdit = ({
  user, title, issueDate, verified, fileUrl, fileName, saveHandler, cancelHandler
}) => {
  const [dateOfIssue, setDateOfIssue] = useState(issueDate)
  const [file, setFile] = useState(undefined)
  const [fileVerified, setFileVerified] = useState(verified)
  const [selectedFileName, setSelectedFileName] = useState(fileName)
  const [selectedFileUrl, setSelectedFileUrl] = useState(fileUrl)

  const onUploadClickHandler = e => {
    e.preventDefault()
    document.getElementById("wholesaleDoc").click()
  }

  const onCancelHandler = e => {
    e.preventDefault()
    cancelHandler()
  }

  const fileChangeHandler = e => {
    const file = e.target.files[0]
    setFile(file)
    setSelectedFileName(file.name)
    setSelectedFileUrl(URL.createObjectURL(file))
  }
  const makeSubmitParams = () => {
    const data = {
      issue_date: dateOfIssue,
      certificate_verified: fileVerified
    }
    if (file) {
      data.file = file
    }
    data.id = user.investor_certificate?.id

    return {
      id: user.id,
      investor_certificate_attributes: data
    }
  }

  const onSaveHandler = e => {
    e.preventDefault()
    saveHandler(makeSubmitParams())
  }

  return (
    <>
      <tr>
        <td><strong>{title}</strong></td>
      </tr>
      <tr>
        <td className="p-0" colSpan={4}>
          <table className="table table-sm table-borderless backend-info">
            <tbody>
              <tr>
                <td>Date of Issue</td>
                <td className="p-0">
                  <input className="form-control" type="date" defaultValue={issueDate} onChange={e => setDateOfIssue(e.currentTarget.value)} />
                </td>
              </tr>
              <tr>
                <td>File</td>
                <td className="px-0">
                  <div className="d-flex align-items-center justify-content-between">
                    <a rel="noreferrer" target="_blank" href={selectedFileUrl}>{ selectedFileName}</a>
                    <div className="hide">
                      <input type="file" id="wholesaleDoc" name="investor_doc" accept="image/jpeg, image/png, application/pdf" onChange={fileChangeHandler} />
                    </div>

                    <a className="btn btn-outlined-main btn-sm" href="#" onClick={onUploadClickHandler}>Upload New</a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Document Verification</td>
                <td>
                  <div className="custom-control custom-checkbox">
                    <input className="custom-control-input" id="customCheck1" type="checkbox" defaultChecked={verified} onChange={e => setFileVerified(e.currentTarget.checked)} />
                    <label className="custom-control-label" htmlFor="customCheck1">Verified</label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                </td>
                <td>
                  <a className="btn btn-main btn-sm mr-2" href="#" onClick={onSaveHandler}>Save</a>
                  <a className="btn btn-secondary btn-sm mr-2" href="#" onClick={onCancelHandler}>Cancel</a>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </>
  )
}

export default WholesaleDocEdit