import React, { useState, useEffect } from "react"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import { useToasts } from "react-toast-notifications"
import { onlyNumbers } from "components/CommonMethods"
import { bankingCountryList } from "components/countries"
import SidebarNav from "./SidebarNav"
import { addBankAccount, getEntityDetails } from "../../requesters/UserRequester"
import { getBaseUrl } from "../../reducers"

const COUNTRIES_WITHOUT_SWIFT = ["AU"]

const AddBanking = ({ currentEntity, baseUrl }) => {
  const [form, setForm] = useState({ country: "" })
  const [countryArray, setCountryArray] = useState([])
  const [isCodeValid, setIsCodeValid] = useState(false)
  const { addToast } = useToasts()

  useEffect(() => {
    getEntityDetails(baseUrl, currentEntity.id).then(data => {
      const bankDetails = data?.response.json.data.bank_details
      const countries = bankDetails.map(b => b.country)
      setCountryArray(countries)
    })
  }, [])

  const storeAndValidateField = e => {
    const {
      value, name
    } = e.target
    setForm({ ...form, [name]: value })
  }

  const onSubmit = () => {
    addBankAccount(baseUrl, currentEntity.id, { form }).then(data => {
      const res = data.response.json
      if (res.success) {
        addToast("Bank Account created successfully", { appearance: "success" })
        window.location.href = "/account/banking"
      } else {
        addToast("Something went wrong", { appearance: "error" })
      }
    })
  }

  const validateBsb = () => {
    setIsCodeValid(form.bsb_number?.length === 6)
  }

  const validateSwift = () => {
    setIsCodeValid(!!form.swift_code)
  }

  useEffect(() => {
    if (form.country !== "AU") {
    validateSwift()
    } else {
      validateBsb()
    }
  }, [form])

  return (
    <div className="content">
      <div className="container">
        <div className="row no-gutters">
          <SidebarNav />
          <div className="col-lg-9">
            <div className="col-lg-8">
              <h2 className="mb-1 mt-5">Add Account</h2>
              <p>Enter your bank account details</p>
              <div className="my-5">
                <div className="form-group row mb-2">
                  <div className="col-sm-6 col-form-label">Country</div>
                  <div className="col-sm-6">
                    <select
                      className="form-control custom-select mr-2"
                      name="country"
                      onChange={e => {
                        storeAndValidateField(e)
                      }}>
                      {bankingCountryList.map(c => (
                        <option
                          key={c.value}
                          value={c.value}
                          disabled={countryArray.includes(c.value)}>
                          {c.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="form-group row mb-2">
                  <div className="col-sm-6 col-form-label">Financial Institution</div>
                  <div className="col-sm-6">
                    <input
                      className="form-control"
                      type="text"
                      autoComplete="disableAll"
                      onChange={storeAndValidateField}
                      name="financial_institution" />
                  </div>
                </div>
                {!COUNTRIES_WITHOUT_SWIFT.includes(form?.country) && (
                  <div className="form-group row mb-2">
                    <div className="col-sm-6 col-form-label">SWIFT Code</div>
                    <div className="col-sm-6">
                      <input
                        className="form-control"
                        type="text"
                        autoComplete="disableAll"
                        onChange={storeAndValidateField}
                        name="swift_code" />
                    </div>
                  </div>
                )}
                {form?.country === "AU" && (
                  <>
                    <div className="form-group row mb-2">
                      <div className="col-sm-6 col-form-label">BSB</div>
                      <div className="col-sm-6">
                        <input
                          className="form-control"
                          type="number"
                          onKeyDown={onlyNumbers}
                          autoComplete="disableAll"
                          onChange={storeAndValidateField}
                          name="bsb_number" />
                      </div>
                    </div>
                    {!isCodeValid && !!form.bsb_number && <small className="err-msg text-danger">Please input a valid BSB (6 digits)</small>}
                  </>
                )}
                <div className="form-group row mb-2">
                  <div className="col-sm-6 col-form-label">Account Name</div>
                  <div className="col-sm-6">
                    <input
                      className="form-control"
                      type="text"
                      autoComplete="disableAll"
                      onChange={storeAndValidateField}
                      name="account_name" />
                  </div>
                </div>
                <div className="form-group row mb-2">
                  <div className="col-sm-6 col-form-label">Account No.</div>
                  <div className="col-sm-6">
                    <input
                      className="form-control"
                      type="number"
                      autoComplete="disableAll"
                      onKeyDown={onlyNumbers}
                      onChange={storeAndValidateField}
                      name="account_number" />
                  </div>
                </div>
                <p className="mt-5 small w-75">Triple check these details are right. If money is credited to the wrong account we can’t guarantee its recovery or accept liability for any loss.</p>
                <button
                  className="btn btn-main btn-lg mt-5"
                  type="submit"
                  style={{ marginRight: "1rem" }}
                  disabled={!form.account_number || !form.account_name || !form.country || !form.financial_institution || !isCodeValid}
                  onClick={onSubmit}>
                  Add Account
                </button>
                <button type="button" onClick={() => { window.location.href = "/account/banking" }} className="btn btn-outlined-main btn-lg mt-5">Back</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  baseUrl: getBaseUrl(state)
})

export default withRouter(connect(mapStateToProps)(AddBanking))