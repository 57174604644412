import React from "react"
import PersonalCommentEdit from "../common/PersonalCommentEdit"
import PersonalDetailEdit from "./PersonalDetailEdit"

const VIEW_DETAILS = 0
const EDIT_DETAILS = 1
const EDIT_COMMENTS = 2

const PersonalDetail = ({
  user,
  editMode,
  setEditMode,
  saveCommentHandler,
  saveDetailsHandler,
  onResetPasswordHandler,
}) => {
  const showDetails = !!user.account_manager || !!user.referral || !!user.general_notes

  let footerEl = (
    <>
      <button className="btn btn-secondary btn-sm mr-2" onClick={() => setEditMode(EDIT_DETAILS)} type="button">Edit Details</button>
      <button className="btn btn-secondary btn-sm mr-2" onClick={() => setEditMode(EDIT_COMMENTS)} type="button">Edit Comments</button>
      <div className="btn btn-secondary btn-sm" onClick={onResetPasswordHandler}>Send Reset Password</div>
    </>
  )
  if (editMode === EDIT_DETAILS) {
    footerEl = (
      <>
        <button type="button" className="btn btn-main btn-sm mr-2" onClick={saveDetailsHandler}>Save</button>
        <button className="btn btn-secondary btn-sm mr-2" onClick={() => setEditMode(VIEW_DETAILS)} type="button">
          Cancel
        </button>
      </>
    )
  } else if (editMode === EDIT_COMMENTS) {
    footerEl = (
      <>
        {/* TODO move button to here */}
        {/* <button type="button" className="btn btn-main btn-sm mr-2" onClick={saveCommentHandler}>Save</button> */}
        <button className="btn btn-secondary btn-sm mr-2" onClick={() => setEditMode(VIEW_DETAILS)} type="button">
          Cancel
        </button>
      </>
    )
  }

  return (
    <>
      <div className="card-body">
        <strong>Individual</strong>
        <table className="table table-sm table-borderless backend-info">
          <tbody>
            {
              editMode === VIEW_DETAILS && (
                <>
                  <tr>
                    <td>First Name</td>
                    <td>{user.firstname || user.name}</td>
                  </tr>
                  <tr>
                    <td>Middle Name</td>
                    <td>{user.middle_name}</td>
                  </tr>
                  <tr>
                    <td>Last Name</td>
                    <td>{user.surname}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>{user.email}</td>
                  </tr>
                  <tr>
                    <td>Mobile</td>
                    <td>{user.phone_number?.phone}</td>
                  </tr>
                  <hr />
                  {
                    showDetails && (
                      <>
                        <tr>
                          <td>Referral</td>
                          <td>{user.referral}</td>
                        </tr>
                        <tr>
                          <td>Account Manager</td>
                          <td>{user.account_manager}</td>
                        </tr>
                        <tr>
                          <td>General Notes</td>
                          <td>{user.general_notes}</td>
                        </tr>
                      </>
                    )
                  }
                </>
              )}
            {
              editMode === EDIT_DETAILS && (<PersonalDetailEdit user={user} />)
            }
            {
              editMode === EDIT_COMMENTS && (<PersonalCommentEdit user={user} saveHandler={saveCommentHandler} />)
            }
          </tbody>
        </table>
      </div>
      <div className="card-footer">
        {footerEl}
      </div>
    </>
  )
}

export default PersonalDetail
