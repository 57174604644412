import React from "react"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import BannerIdWarning from "components/Banners/BannerIdWarning"
import { yieldTreeLogoInverse } from "components/Icons"
import { getCurrentUser, getCurrentEntity } from "../reducers"

function Header({ currentUser, currentEntity }) {
  const redirectUser = () => {
    if (["phone_verified", "partial_submission", "waiting_background_check"].includes(currentUser.registration_status)) {
      window.location.href = "/complete-account"
    } else {
      window.location.href = "/"
    }
  }

  const entityInitials = currentEntity.firstname ? currentEntity.firstname[0] + currentEntity.surname[0] : currentEntity.name[0]

  return (
    <>
      <nav className="navbar navbar-expand-md navbar-dark bg-dark main-nav">
        <div className="container">
          <a className="navbar-brand" onClick={redirectUser} href="/">
            <img src={yieldTreeLogoInverse} />
          </a>
          <button
            className="navbar-toggler"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            data-target="#navbarSupportedContent"
            data-toggle="collapse"
            type="button">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav main-nav-tabs">
              <li className="nav-item text-right text-lg-left">
                <Link
                  className="nav-link"
                  to="/dashboard"
                  onClick={() => { window.location.href = "/dashboard" }}>
                  Dashboard
                </Link>
              </li>
              <li className="nav-item text-right text-lg-left">
                <Link
                  className="nav-link"
                  to="/marketplace"
                  onClick={() => { window.location.href = "/marketplace" }}>
                  Marketplace
                </Link>
              </li>
              {currentEntity.country === "AU" && (
                <li className="nav-item text-right text-lg-left">
                  <Link
                    className="nav-link"
                    to="/crypto"
                    onClick={() => { window.location.href = "/crypto" }}>
                    Trade Crypto
                  </Link>
                </li>
              )}
            </ul>
            <div className="navbar-nav ml-md-auto">
              <li className="nav-item dropdown">
                <div
                  aria-expanded="false"
                  aria-haspopup="false"
                  className="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                  id="navbarDropdown"
                  type="button">
                  <div className="d-flex align-items-center justify-content-end">
                    <div className="user-initial">
                      {entityInitials}
                    </div>
                    <span className="text-white">{currentEntity.firstname || currentEntity.name}</span>
                  </div>
                </div>
                <div aria-labelledby="navbarDropdown" className="dropdown-menu dropdown-menu-right">
                  <Link className="dropdown-item" to="/account">Switch Account</Link>
                  <div className="dropdown-divider"></div>
                  <Link className="dropdown-item" to="/account">Account Settings</Link>
                  <Link className="dropdown-item" to="/account/past-investments">Past Investments</Link>
                  <Link className="dropdown-item" to="/account/banking">Banking</Link>
                  <Link className="dropdown-item" to="/account/transactions">Transactions</Link>
                  <Link className="dropdown-item" to="/account/settings">Settings</Link>
                  <div className="dropdown-divider"></div>
                  <Link
                    className="dropdown-item"
                    to="/logout"
                    onClick={() => { window.location.href = "/logout" }}>
                    {" "}
                    Logout of all accounts
                  </Link>
                </div>
              </li>
            </div>
          </div>
        </div>
      </nav>
      <BannerIdWarning currentEntity={currentEntity} />
    </>
  )
}

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
  currentEntity: getCurrentEntity(state),
})

export default withRouter(connect(mapStateToProps)(Header))
