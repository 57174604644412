/* eslint-disable no-unused-expressions */
import axios from "axios"

export const submitImportFile = formData => axios.post("/api/backend/offerings/import", formData, { header: { "Content-Type": "multipart/form-data" } })
    .then(({ data, status }) => ({ response: data, status }))
  .catch(error => { error })

export const uploadUpdatedFile = (id, formData) => axios.put(`/api/offerings/${id}/import_updated_file`, formData, { header: { "Content-Type": "multipart/form-data" } })
  .then(({ data, status }) => ({ response: data, status }))
  .catch(error => { error })


export const getOfferingList = () => axios.get("/api/offerings/index")
      .then(({ data, status }) => ({ response: data, status }))
      .catch(error => { error })

export const getOfferingDetail = id => axios.get(`/api/offerings/${id}/details`)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })


export const getInvestments = id => axios.get(`/api/backend/offerings/${id}/investments`)
.then(({ data, status }) => ({ response: data, status }))
.catch(error => { error })

export const addBankDetails = (baseUrl, identifier, form) => {
  const path = `${baseUrl}/backend/offerings/${identifier}/add_bank_details`
  return axios.post(path, form)
  .then(({ data, status }) => ({ response: data, status }))
  .catch(error => { error })
}

export const removeBankDetails = (baseUrl, identifier, bankDetailId) => {
  const path = `${baseUrl}/backend/offerings/${identifier}/remove_bank_details`
  return axios.patch(path, { bank_detail_id: bankDetailId })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const updateOffering = (id, offeringDetail) => axios.put(`/api/backend/offerings/${id}/update`, { offering: offeringDetail })
.then(({ data, status }) => ({ response: data, status }))
.catch(error => { error })


export const uploadFiles = (id, formData) => axios.put(`/api/backend/offerings/${id}/update`, formData, { header: { "Content-Type": "multipart/form-data" } })
.then(({ data, status }) => ({ response: data, status }))
.catch(error => { error })

// Requester to update Offering Status via state machine
export const updateOfferingStatus = (id, status) => axios.put(`/api/backend/offerings/${id}/update_status`, { status })
.then(({ data, status }) => ({ response: data, status }))
.catch(error => { error })

export const updateOfferingNdaSigned = id => axios.put(`/api/offerings/${id}/update_nda_signed`)
.then(({ data, status }) => ({ response: data, status }))
.catch(error => { error })

export const createEmbeddedWithTemplate = (id, name, email) => axios.post(
  `/api/offerings/${id}/create_embedded_signature_request_with_template`,
  { name, email }
)
.then(({ data, status }) => ({ response: data, status }))
.catch(error => { error })

export const requestOfferingSignature = (id, signature_id) => axios.post(`/api/offerings/${id}/signature_request`, { signature_id })
.then(({ data, status }) => ({ response: data, status }))
.catch(error => { error })

export const getSignatureClientId = id => axios.get(`/api/offerings/${id}/get_signature_client_id`)
.then(({ data, status }) => ({ response: data, status }))
.catch(error => { error })