/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"

const InvestorSupportingDocEdit = ({
  selectedFile, setSelectedFile, index
}) => {
  const onUploadClickHandler = e => {
    e.preventDefault()
    document.getElementById(`sup_doc_${index}`).click()
  }

  const fileChangeHandler = e => {
    const file = e.target.files[0]
    setSelectedFile({ name: file.name, url: URL.createObjectURL(file), file })
  }

  return (
    <>
      <div className="hide">
        <input type="file" id={`sup_doc_${index}`} name="investor_doc" accept="image/jpeg, image/png, application/pdf" onChange={fileChangeHandler} />
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <a rel="noreferrer" target="_blank" href={selectedFile?.url}>{selectedFile?.name}</a>
      </div>
      <a className="btn btn-outlined-main btn-sm" href="#" onClick={onUploadClickHandler}>Upload New</a>
    </>
  )
}

export default InvestorSupportingDocEdit
