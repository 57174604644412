import _ from "lodash"
import React, { useState } from "react"
import Money from "components/Money"
import YieldReturnRow from "./YieldReturnRow"

const YieldBatchCalculation = ({
  isBatchExported, yieldBatchObj, setYieldBatchObj, offeringDetail, interestArray, setInterestArray, principalArray, setPrincipalArray, otherArray, setOtherArray
}) => {
  const offeringInvestments = yieldBatchObj.investment_details ? yieldBatchObj.investment_details : offeringDetail.investment_details
  const currency = yieldBatchObj.currency ? yieldBatchObj.currency : offeringDetail.currency
  const country = yieldBatchObj.country ? yieldBatchObj.country : offeringDetail.country
  const total_investments = yieldBatchObj.total_investment_internal ? yieldBatchObj.total_investment_internal : offeringDetail.total_investment_internal
  const [totalInterest, setTotalInterest] = useState(0)
  const [totalPrincipal, setTotalPrincipal] = useState(0)
  const [totalOther, setTotalOther] = useState(0)

  const handleChange = e => {
    const updated = { ...yieldBatchObj, [e.target.name]: e.target.value }
    setYieldBatchObj(updated)
  }

  const refreshInterestValues = newTotal => {
    setTotalInterest(newTotal)
    const multiplier = newTotal / total_investments
    offeringInvestments.forEach((inv, index) => {
      interestArray[index] = Math.round(multiplier * inv.amount)
    })
  }

  const refreshPrincipalValues = newTotal => {
    setTotalPrincipal(newTotal)
    const multiplier = newTotal / total_investments
    offeringInvestments.forEach((inv, index) => {
      principalArray[index] = Math.round(multiplier * inv.amount)
    })
  }

  const refreshOtherValues = newTotal => {
    setTotalOther(newTotal)
    const multiplier = newTotal / total_investments
    offeringInvestments.forEach((inv, index) => {
      otherArray[index] = Math.round(multiplier * inv.amount)
    })
  }

  const setValue = (obj, index, value) => {
    if (obj === "interest") {
      const updatedValues = [...interestArray]
      updatedValues[index] = value
      setInterestArray(updatedValues)
    } else if (obj === "principal") {
      const updatedValues = [...principalArray]
      updatedValues[index] = value
      setPrincipalArray(updatedValues)
    } else {
      const updatedValues = [...otherArray]
      updatedValues[index] = value
      setOtherArray(updatedValues)
    }
  }

  return (
    <>
      <div className="card bg-light border-0 p-4">
        <strong>Total amount available to be returned </strong>
        <div className="small mb-2">This payment description will display in the users transactions list</div>
        <table className="table table-sm table-borderless backend-info">
          <tbody>
            <tr>
              <td className="text-nowrap">Total Interest</td>
              <td>
                <div className="d-flex">
                  <div className="input-group mr-2">
                    <div className="input-group-prepend"><span className="input-group-text">{currency}</span></div>
                    <input
                      className="text-right form-control"
                      disabled={isBatchExported}
                      placeholder="0"
                      type="text"
                      value={totalInterest}
                      onChange={e => {
                        refreshInterestValues(e.target.value)
                      }} />
                  </div>
                </div>
              </td>
              <td className="w-50">
                <input
                  className="form-control"
                  disabled={isBatchExported}
                  placeholder="Description"
                  defaultValue={yieldBatchObj?.interest_description || ""}
                  name="interest_description"
                  type="text"
                  onChange={handleChange} />
              </td>
            </tr>
            <tr>
              <td className="text-nowrap">Total Principal</td>
              <td>
                <div className="d-flex">
                  <div className="input-group mr-2">
                    <div className="input-group-prepend"><span className="input-group-text">{currency}</span></div>
                    <input
                      className="text-right form-control"
                      disabled={isBatchExported}
                      placeholder="0"
                      type="text"
                      value={totalPrincipal}
                      onChange={e => {
                        refreshPrincipalValues(e.target.value)
                      }} />
                  </div>
                </div>
              </td>
              <td className="w-50">
                <input
                  className="form-control"
                  disabled={isBatchExported}
                  placeholder="Description"
                  defaultValue={yieldBatchObj?.principal_description || ""}
                  name="principal_description"
                  type="text"
                  onChange={handleChange} />
              </td>
            </tr>
            <tr>
              <td className="text-nowrap">Other</td>
              <td>
                <div className="d-flex">
                  <div className="input-group mr-2">
                    <div className="input-group-prepend"><span className="input-group-text">{currency}</span></div>
                    <input
                      className="text-right form-control"
                      disabled={isBatchExported}
                      placeholder="0"
                      type="text"
                      value={totalOther}
                      onChange={e => {
                        refreshOtherValues(e.target.value)
                      }} />
                  </div>
                </div>
              </td>
              <td className="w-50">
                <input
                  className="form-control"
                  disabled={isBatchExported}
                  placeholder="Description"
                  defaultValue={yieldBatchObj?.other_description || ""}
                  name="other_description"
                  type="text"
                  onChange={handleChange} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="mt-5"></div>
      <div className="table-responsive">
        <table className="table table-link-row yt-table">
          <thead>
            <tr>
              <th className="bg-light">Payee</th>
              <th>Payee Reference</th>
              <th colSpan="2">Bank Account</th>
              <th className="bg-light">Investment</th>
              <th className="text-right">Interest Yield</th>
              <th className="text-right">Principal to Repay</th>
              <th className="text-right">Other</th>
              <th className="text-right">Total Payment</th>
            </tr>
          </thead>
          <tbody>
            {offeringInvestments.map((yieldReturn, index) => (
              <YieldReturnRow
                isBatchExported={isBatchExported}
                yieldReturn={yieldReturn}
                key={`offering-investment-${yieldReturn.id}`}
                offeringCurrency={currency}
                offeringCountry={country}
                interest={interestArray[index]}
                other={otherArray[index]}
                principal={principalArray[index]}
                setValue={(type, val) => setValue(type, index, val)} />
            ))}
          </tbody>
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td className="text-right"><strong><Money currency={currency} amount={_.sum(interestArray)} /></strong></td>
              <td className="text-right"><strong><Money currency={currency} amount={_.sum(principalArray)} /></strong></td>
              <td className="text-right"><strong><Money currency={currency} amount={_.sum(otherArray)} /></strong></td>
              <td className="text-right"><strong><Money currency={currency} amount={_.sum([...interestArray, ...principalArray, ...otherArray]) || 0} /></strong></td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default YieldBatchCalculation
