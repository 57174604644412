import React from "react"
import { Link } from "react-router-dom"

const TaxDetailsPrompt = () => (
  <>
    <h3>Your Tax Details</h3>
    <p>
      You currently do not have tax details lodged in the system. Please click below to enter your details before you are able to invest.
    </p>
    <div className="mt-2 pt-2" />
    <Link
      className="btn btn-outlined-main"
      to="/account"
      target="_blank"
      rel="noopener noreferrer">
      Update Tax Details
    </Link>
  </>
  )

export default TaxDetailsPrompt
