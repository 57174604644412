import React from "react"
import { bankingCountryList } from "components/countries"
import { formatWithComma } from "components/CommonMethods"

const CountryCard = ({ interest, principal, currency }) => {
  const getCurrency = currency => {
    const foundCountry = bankingCountryList.find(element => element.currency === currency)
    return foundCountry.value
  }

  return (
    <td>
      <div className="card card-body bg-light card-totals">
        <div className="text-left mb-3">
          <img
            className="country-flag"
            src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${getCurrency(currency)}.svg`}
            style={{ height: "20px", paddingRight: "1rem" }} />
        </div>
        <div className="small mb-1">Total Principal Outstanding</div>
        <h3 className="medium mb-3">
          {currency}
          {" "}
          $
          {formatWithComma(principal) || 0}
        </h3>
        <div className="small mb-1">Total Interest Received</div>
        <h3 className="medium mb-3">
          {currency}
          {" "}
          $
          {formatWithComma(interest) || 0}
        </h3>
      </div>
    </td>
  )
}

export default CountryCard
