/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react"
import { connect } from "react-redux"
import { useToasts } from "react-toast-notifications"
import { jsonToFormData } from "components/CommonMethods"
import { updateUserProfile } from "../../requesters"
import { getBaseUrl } from "../../reducers"
import InvestorDocRow from "./InvestorDocRow"
import InvestorDocEdit from "./InvestorDocEdit"
import WholesaleDocEdit from "./WholesaleDocEdit"

const InvestorDocDetail = ({ user, editSaveHandler, baseUrl }) => {
  const [certEditable, setCertEditable] = useState(false)
  const [docEditable, setDocEditable] = useState(false)
  const { addToast } = useToasts()

  const handleCertEditableChange = () => {
    setCertEditable(true)
  }

  const handleDocEditableChange = () => {
    setDocEditable(true)
  }

  const handleEditCancel = () => {
    setCertEditable(false)
    setDocEditable(false)
  }

  const handleInvestorSave = details => {
    updateUserProfile(baseUrl, user.id, jsonToFormData(details, "user")).then(data => {
      const response = data.response.json
      if (response.success) {
        editSaveHandler()
        handleEditCancel()
        addToast("User detail updated successfully.", { appearance: "success" })
      } else {
        addToast(response.message, { appearance: "error" })
      }
    })
  }

  return (
    <div className="card my-3">
      <div className="card-header">Investor Profile</div>
      <div className="card-body">
        <table className="table table-sm table-borderless yt-table">
          <thead>
            <tr>
              <th className="pl-0">Documents</th>
              <th>Date</th>
              <th className="pl-0">Status</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {
              certEditable
                ? (
                  <WholesaleDocEdit
                    title="s708 Wholesale Certificate"
                    issueDate={user.investor_certificate?.issue_date?.original}
                    verified={user.investor_certificate?.certificate_verified}
                    fileUrl={user.investor_certificate?.file?.url}
                    fileName={user.investor_certificate?.cert_file_name}
                    saveHandler={handleInvestorSave}
                    user={user}
                    cancelHandler={handleEditCancel} />
                  )
                : (
                  <InvestorDocRow
                    title="s708 Wholesale Certificate"
                    issueDate={user.investor_certificate?.issue_date?.formatted}
                    verified={user.investor_certificate?.certificate_verified}
                    fileUrl={user.investor_certificate?.file?.url}
                    editHandler={handleCertEditableChange} />
                )
            }
            {
              docEditable
                ? (
                  <InvestorDocEdit
                    title="AI Documentation"
                    verified={user.investor_certificate?.ai_doc_verified}
                    fileUrl={user.investor_certificate?.doc_file?.url}
                    supFileUrl1={user.investor_certificate?.sup_doc_1?.url}
                    supFileUrl2={user.investor_certificate?.sup_doc_2?.url}
                    supFileUrl3={user.investor_certificate?.sup_doc_3?.url}
                    supFileName1={user.investor_certificate?.sup_doc_file_name_1}
                    supFileName2={user.investor_certificate?.sup_doc_file_name_2}
                    supFileName3={user.investor_certificate?.sup_doc_file_name_3}
                    sgdValue={user.investor_certificate?.sgd_value}
                    fileName={user.investor_certificate?.doc_file_name}
                    saveHandler={handleInvestorSave}
                    user={user}
                    cancelHandler={handleEditCancel} />
                )
                : (
                  <InvestorDocRow
                    title="AI Documentation"
                    issueDate={user.investor_certificate?.doc_issue_date?.formatted}
                    verified={user.investor_certificate?.ai_doc_verified}
                    fileUrl={user.investor_certificate?.doc_file?.url}
                    editHandler={handleDocEditableChange} />
                )
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  baseUrl: getBaseUrl(state)
})

export default connect(mapStateToProps)(InvestorDocDetail)