import React, { useState } from "react"
import axios from "axios"

const TwoFactorModal = ({
  email,
  password,
}) => {
  const [error, setError] = useState("")
  const [twoFactor, setTwoFactor] = useState()

  const handleSubmit = event => {
    event.preventDefault()
    const path = "/users/sign_in"
    const params = {
      user: {
        email,
        password,
        two_factor: twoFactor
      }
    }
    const headers = {
      "Content-Type": "application/json",
      "X-CSRF-Token": document.querySelector("[name=csrf-token]").content
    }

    axios.post(path, params, headers)
      .then(response => {
        if (_.get(response, "status") === 200) {
          window.location.href = "/marketplace"
        } else setError(_.get(response, "data.error"))
      })
      .catch(error => setError(_.get(error, "response.data.error")))
  }

  const handleRetry = () => {
    setError("")
    window.location = "/login"
  }

  return (
    <div className="container text-center onboarding">
      <div className="text-center mt-5 pb-2 " style={{ margin: "auto", width: "345px" }}>
        <h3 className="mb-3">A verification code has been sent to your email. Please enter the code below</h3>
        <form onSubmit={e => handleSubmit(e, twoFactor)}>
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              onChange={e => setTwoFactor(e.currentTarget.value)} />
          </div>
        </form>
        {error && <div className="err-msg text-danger">{error}</div>}
        <div className="text-center mt-5 pb-2">
          {error ? (
            <button className="btn btn-main btn-lg" type="button" onClick={handleRetry}>
              Retry
            </button>
          ) : (
            <button disabled={!twoFactor} className="btn btn-main btn-lg" type="button" onClick={handleSubmit}>
              Submit
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default TwoFactorModal
