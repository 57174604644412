import React from "react"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css"
import filterFactory, { textFilter } from "react-bootstrap-table2-filter"
// eslint-disable-next-line import/extensions
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css"
import paginationFactory from "react-bootstrap-table2-paginator"
import BootstrapTable from "react-bootstrap-table-next"

const AuditLogsTable = ({ auditLogs }) => {
  if (!auditLogs.length) return <div>no logs found</div>
  const columns = [
    {
      dataField: "created_at",
      text: "Timestamp",
      headerStyle: () => ({ width: "50px" }),
      filter: textFilter({ delay: 0 }),
    }, {
      dataField: "associated_type",
      text: "User/ Entity/ Offering/ Batch",
      headerStyle: () => ({ width: "50px" }),
      filter: textFilter({ delay: 0 })
    }, {
      dataField: "associated_id",
      text: "User/ Entity/ Offering/ Batch ID",
      headerStyle: () => ({ width: "50px" }),
      filter: textFilter({ delay: 0 }),
    }, {
      dataField: "auditable_type",
      text: "Item Type",
      headerStyle: () => ({ width: "50px" }),
      filter: textFilter({ delay: 0 }),
    }, {
      dataField: "auditable_id",
      text: "Item ID",
      sort: true,
      filter: textFilter({ delay: 0 }),
    }, {
      dataField: "action",
      text: "Event Type",
      sort: true,
      headerStyle: () => ({ width: "50px" }),
      filter: textFilter({ delay: 0 }),
    }, {
      dataField: "username",
      text: "Actioned by",
      sort: true,
      filter: textFilter({ delay: 0 }),
    }, {
      dataField: "audited_changes",
      text: "Item Changed to",
      sort: true,
      headerStyle: () => ({ width: "500px" }),
      filter: textFilter({ delay: 0 }),
      filterValue: cell => JSON.stringify(cell),
      formatter: cell => {
        if (_.isObject(cell)) {
          return <span className="text-danger">{JSON.stringify(cell)}</span>
        }
        return ""
      },
    }, {
      dataField: "request_uuid",
      text: "Request UUID",
      sort: true,
      headerStyle: () => ({ width: "500px" }),
      filter: textFilter({ delay: 0 }),
  }]

  return (
    <>
      { columns && !!auditLogs.length && (
        <div className="offering-table">
          <BootstrapTable
            keyField="id"
            data={auditLogs}
            columns={columns}
            filter={filterFactory()}
            wrapperClasses="table-responsive"
            pagination={paginationFactory()} />
        </div>
      )}
    </>
  )
}

export default AuditLogsTable
