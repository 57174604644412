import React, { useState, useEffect } from "react"
import { useToasts } from "react-toast-notifications"
import { formatWithComma, onlyNumbers } from "components/CommonMethods"
import { convertWaitlistToPending } from "../../../requesters"

const ConvertToSubscriptionModal = ({
 investment, offering, reloadData, reloadOffering
}) => {
  const [investmentAmount, setInvestedAmount] = useState(0)
  const { addToast } = useToasts()

  const convertToSubsHandler = () => {
    convertWaitlistToPending(investment.id, investmentAmount).then(data => {
      const res = data.response.json
      if (res.success) {
        addToast("Status updated successfully.", { appearance: "success" })
        reloadData()
        reloadOffering()
      } else {
        addToast(res.message, { appearance: "error" })
        setInvestedAmount()
      }
    })
  }

  const error = !investmentAmount || investmentAmount > offering.remaining_investment

  useEffect(() => {
    setInvestedAmount(investment?.amount)
  }, [investment])

  return (
    <div aria-labelledby="pendingCancelledPopup" className="modal fade" id="convertToSubModal" role="dialog" tabIndex={-1} style={{ display: "none" }} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header border-0">
            <button aria-label="Close" className="close" data-dismiss="modal" type="button">
              <span aria-hidden="true"> ×</span>
            </button>
          </div>
          <div className="modal-body pt-0">
            <h3 className="modal-title mb-3">Convert to Subscribe?</h3>
            <p className="text-muted">Investor will be converted from waitlist to be a subscribed investor for this deal. An email with funding reference will be sent to them.</p>
            <p>
              {`Minimum investment: ${offering.currency} $${formatWithComma(offering.min_investment)}`}
            </p>
            <p>
              {` Remaining allocation: ${offering.currency} $${formatWithComma(offering.remaining_investment)}`}
            </p>
            <div className="strong mb-2">Confirm Amount</div>
            <div className="input-group mr-2 w-75">
              <div className="input-group-prepend"><span className="input-group-text">{offering.currency}</span></div>
              <input
                className="form-control"
                onKeyDown={onlyNumbers}
                type="number"
                value={investmentAmount || 0}
                autoComplete="disableAll"
                min={0}
                onChange={e => setInvestedAmount(parseFloat(e.target.value))} />
            </div>
            {error && <div className="err-msg text-danger">Please invest a valid amount</div>}
          </div>
          <div className="modal-footer border-0">
            <button
              className="btn btn-main"
              id="btnCloseModal"
              data-dismiss="modal"
              type="button"
              disabled={error}
              onClick={() => convertToSubsHandler()}>
              Confirm
            </button>
            <button className="btn btn-secondary" id="btnCloseModal" data-dismiss="modal" type="button">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ConvertToSubscriptionModal