import React from "react"
import { defaultBackground } from "components/Icons"
import { Link } from "react-router-dom"

const ClosedOffering = ({ offering }) => {
  const offeringImage = offering.main_image || defaultBackground

  return (
    <Link
      className="card card-past-offering border-0 p-3 mb-4"
      to={`/marketplace/${offering.id}`}
      onClick={() => { window.location.href = `/marketplace/${offering.id}` }}
      style={{ textDecoration: "none" }}>
      <div className="d-flex">
        <div className="offering-image mr-4">
          <img className="img-fluid image-cover" src={offeringImage} />
        </div>
        <div className="card-content">
          <strong>{offering.name}</strong>
          <p>
            {" "}
            {offering.asset_type}
            {" "}
            {offering.total_deal_size}
          </p>
          <div className="description text-muted">
            {offering.overview}
          </div>
          <div className="green-bar my-3"></div>
          <ul className="list-inline mb-0">
            <div className="row">
              {offering.free_text_value_1 && (
                <div className="col-4 col-lg-5 col-xl-4">
                  <div className="small text-muted">{offering.free_text_heading_1}</div>
                  <h3>
                    {offering.free_text_value_1}
                  </h3>
                </div>
              )}
              {offering.free_text_value_2 && (
                <div className="col-4 col-lg-5 col-xl-4">
                  <div className="small text-muted">{offering.free_text_heading_2}</div>
                  <h3>
                    {offering.free_text_value_2}
                  </h3>
                </div>
              )}
            </div>
          </ul>
        </div>
      </div>
    </Link>
)
}

export default ClosedOffering
