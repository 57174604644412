import React from "react"

const Breadcrumb = ({ name, setEntity }) => {
  const clickHandler = e => {
    e.preventDefault()
    setEntity(undefined)
  }
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><a href="backend-entity" onClick={clickHandler}>Onboarding Users</a></li>
        <li aria-current="page" className="breadcrumb-item active">{ name }</li>
      </ol>
    </nav>
  )
}

export default Breadcrumb
