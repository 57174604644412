import React from "react"

const UserAccounts = ({ currentEntity }) => {
  const verified = currentEntity.background_check_status === "verified"
    ? <span className="text-main">Verified</span>
    : <span className="text-danger">Not Yet Verified</span>
  return (
    <div className="mt-5">
      <div className="row mb-2">
        <div className="col-5">Account Type</div>
        <div className="col-7 text-muted">{currentEntity?.entity_type_formatted || "Individual"}</div>
      </div>
      <div className="row mb-2">
        <div className="col-5">Account Name</div>
        <div className="col-7 text-muted">{currentEntity.name}</div>
      </div>
      <div className="row mb-2">
        <div className="col-5">Status</div>
        <div className="col-7 text-muted">{verified}</div>
      </div>
    </div>
  )
}
export default UserAccounts
