/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from "react"
import MultilineText from "components/MultilineText"


const TruncatedText = ({ text, length }) => {
  const needTruncation = text.length > length
  const [trunctatedText, setTruncatedText] = useState(`${text.slice(0, length)}...`)
  const [showLess, setShowLess] = useState(needTruncation)

  useEffect(() => {
    const displayText = showLess ? `${text.slice(0, length)}...` : text
    setTruncatedText(displayText)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLess])

  return (
    <div>
      <div className="text-content">
        <MultilineText text={trunctatedText} />
      </div>
      {needTruncation && (
        <button
          type="submit"
          className="btn btn-link"
          onClick={() => setShowLess(!showLess)}>
          Read&nbsp;
          {showLess ? "More" : "Less"}
        </button>
      )}
    </div>
  )
}

export default TruncatedText
