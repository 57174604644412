import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter, Switch, Route } from "react-router-dom"
import { ToastProvider } from "react-toast-notifications"
import ProtectedRoute from "components/utils/ProtectedRoute"
import Offerings from "./Offerings/index"
import * as reducers from "../reducers"
import Details from "./Offerings/Details"
import Dashboard from "./Dashboard/Dashboard"
import CryptoPage from "./Crypto"
import FluidWelcomePage from "./Crypto/FluidWelcomePage"

class Main extends Component {
  render() {
    const {
      currentUser,
    } = this.props

    // If User hasn't completed onboarding, redirect them to Home
    if (!currentUser?.current_entity && currentUser?.role === "admin") {
      window.location.href = "/backend"
    } else if (!currentUser?.current_entity) {
      window.location.href = "/"
    }
    return (
      <ToastProvider autoDismiss>
        <Switch>
          <ProtectedRoute
            exact
            currentUser={currentUser}
            path="/marketplace"
            redirectedRoute="/"
            component={Offerings} />
          <Route exact path="/marketplace/:offeringId" render={props => <Details {...this.props} {...props} />} />
          <Route exact path="/marketplace/:offeringId/:method" render={props => <Details {...this.props} {...props} />} />
          <Route exact path="/dashboard" render={props => <Dashboard {...this.props} {...props} />} />
          <Route exact path="/crypto" render={props => <CryptoPage {...this.props} {...props} />} />
          <Route exact path="/cryptoenabled" render={props => <FluidWelcomePage {...this.props} {...props} />} />
        </Switch>
      </ToastProvider>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: reducers.getCurrentUser(state),
  currentEntity: reducers.getCurrentEntity(state)
})

export default withRouter(connect(mapStateToProps)(Main))
