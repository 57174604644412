import React from "react"
import { Link } from "react-router-dom"
import { bankingCountryList } from "components/countries"

const getCurrency = country => {
  const foo = bankingCountryList.find(element => element.value === country)
  return foo.currency
}

const PayoutPrompt = ({ bankDetails }) => (
  <>
    <h3>Your Bank Details</h3>
    {bankDetails ? (
      <>
        <p>
          You will receive payout for this investment from this account
        </p>
        <div key={bankDetails.id}>
          <div>
            <img
              className="country-flag"
              src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${bankDetails.country}.svg`}
              style={{ height: "20px", paddingRight: "1rem" }} />
            {`Account ${bankDetails.account_number} ${getCurrency(bankDetails.country)}`}
          </div>
        </div>
      </>
    ) : (
      <>
        <p>
          You currently do not have a bank account set to receive distributions. Please click below to enter your details. The account must be in the same name as your Aura account.
        </p>
      </>
    )}
    <div className="mt-2 pt-2" />
    <Link
      className="btn btn-outlined-main"
      to="/account/banking"
      target="_blank"
      rel="noopener noreferrer">
      Update Banking
    </Link>
  </>
)

export default PayoutPrompt
