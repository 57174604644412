import React, { useState } from "react"
import Spinner from "components/Spinner"
import styled from "styled-components"
import { useSelector } from "react-redux"
import { postEnableFluidUser, postEnableFluidEntity } from "../../requesters"
import { TERMS_ARRAY, TICKBOXES_ARRAY } from "./constants"
import { getBaseUrl, getCurrentEntity, getCurrentUser } from "../../reducers"

const EXTRA_SHRINKAGE = 350

const TermsContainer = styled.div`
  padding: 1rem;
  max-height: calc(100vh - 96px - ${EXTRA_SHRINKAGE}px);
  overflow-y: auto;
`

const SecondTimeFluidSignup = () => {
  const baseUrl = useSelector(getBaseUrl)
  const currentUser = useSelector(getCurrentUser)
  const currentEntity = useSelector(getCurrentEntity)
  const [inflight, setInflight] = useState(false)
  const [error, setError] = useState("")
  const [isFirstChecked, setIsFirstChecked] = useState(false)
  const [isSecondChecked, setIsSecondChecked] = useState(false)
  const [isThirdChecked, setIsThirdChecked] = useState(false)

  const hasAgreedToAllTerms = isFirstChecked && isSecondChecked && isThirdChecked

  const handleCheck = e => {
    const { name, checked } = e.target
    switch (name) {
      case "isFirstChecked":
        setIsFirstChecked(checked)
        break
      case "isSecondChecked":
        setIsSecondChecked(checked)
        break
      case "isThirdChecked":
        setIsThirdChecked(checked)
        break
      default:
        break
    }
  }

  const handleSubmit = () => {
    setInflight(true)
    postEnableFluidEntity(baseUrl, currentEntity.id)
      .then(response => {
        if (_.get(response, "response.json.success")) {
          postEnableFluidUser(baseUrl, currentUser.id)
            .then(response => {
              if (_.get(response, "response.json.success")) {
                // window.location.reload()
                window.location.href = "/cryptoenabled"
                setError(_.get(response, "response.json.message"))
                setInflight(false)
              } else {
                setError(_.get(response, "response.json.message"))
                setInflight(false)
              }
            })
        } else {
          setError(_.get(response, "response.json.message", "Something went wrong"))
          setInflight(false)
        }
      })
  }

  return (
    <>
      <h1 className="mt-0 pt-5">Ready to trade digital assets?</h1>
      <p>
        To add
        {" "}
        <b>{currentEntity.name}</b>
        {" "}
        to your digital asset dashboard, please follow the steps below. If you are trying to log into an existing account, please ensure that the correct entity is selected above.
      </p>

      <ol>
        <li>Click &apos;&apos;Enable Entity&apos;&apos;</li>
        <li>Agree to the T&Cs</li>
        <li>
          Re-enter Aura Group Marketplace email address and password
        </li>
      </ol>

      <button
        className="btn btn-main btn-lg mb-3"
        data-target="#enableFluidModal"
        data-toggle="modal"
        // disabled={currentUser?.registration_status !== "complete"}
        type="button">
        Enable Entity
      </button>

      <p>You&apos;ll then be taken to the Leonie Hill platform, from where you can deposit funds to start trading.</p>
      <p>
        {`If you encounter any difficulties, please email `}
        {" "}
        <a href="mailto: invest@aura.co" className="">invest@aura.co</a>
        {" "}
        {`for assistance`}
      </p>

      <div
        aria-labelledby="enableFluidPopup"
        className="modal fade"
        id="enableFluidModal"
        role="dialog"
        tabIndex={-1}
        style={{ display: "none" }}
        aria-hidden="true">
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document">
          <div className="modal-content">
            <div className="modal-header border-0 pb-0">
              <button aria-label="Close" className="close" data-dismiss="modal" type="button">
                <span aria-hidden="true"> ×</span>
              </button>
            </div>
            <div className="modal-body pt-0 px-lg-5">
              <h2 className="text-center">Digital Asset T&Cs</h2>
              <TermsContainer>
                <>
                  {TERMS_ARRAY.map(sentence => (
                    <p key={sentence.length}>{sentence}</p>
                    ))}
                </>
              </TermsContainer>
              <div className="mt-3">
                {TICKBOXES_ARRAY.map((content, index) => (
                  <div className="custom-control custom-checkbox mt-2" key={content.length}>
                    <input
                      className="custom-control-input"
                      id={`customCheck${index}`}
                      name={content.name}
                      onChange={handleCheck}
                      type="checkbox" />
                    <label className="custom-control-label" htmlFor={`customCheck${index}`}>
                      {content.label}
                    </label>
                  </div>
              ))}
              </div>
            </div>
            <div className="modal-footer d-flex flex-column justify-content-center border-0 pt-0">
              {
                inflight ? (
                  <div className="btn btn-main btn-lg mt-2">
                    <Spinner />
                  </div>
                ) : (
                  <button
                    className="btn btn-main btn-lg mt-2"
                    disabled={!hasAgreedToAllTerms}
                    onClick={handleSubmit}
                    type="button">
                    Enable Entity
                  </button>
                )
              }
              {!!error && <div className="err-msg text-danger">{error}</div>}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SecondTimeFluidSignup
