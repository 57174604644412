import React from "react"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { yieldTreeLogoInverse } from "components/Icons"
import BannerIdWarning from "components/Banners/BannerIdWarning"
import { getCurrentUser, getCurrentEntity } from "../reducers"

function Header({ currentUser, currentEntity }) {
  const isOnDashboard = window.location.pathname === "/dashboard"
  const isOnCrypto = window.location.pathname === "/crypto"
  const isOnCryptoenabled = window.location.pathname === "/cryptoenabled"
  const pathname = window.location?.pathname
  const isOnReviewPage = pathname.includes("marketplace") && pathname.includes("review")
  const entityInitials = currentEntity?.firstname ? currentEntity?.firstname[0] + currentEntity?.surname[0] : currentEntity?.name[0]

  const redirectUser = () => {
    if (["phone_verified", "partial_submission", "waiting_background_check"].includes(currentUser.registration_status)) {
      window.location.href = "/complete-account"
    } else {
      window.location.href = "/"
    }
  }

  return (
    <>
      {!isOnReviewPage && (
        <nav className="navbar navbar-expand-md navbar-dark bg-dark main-nav">
          <div className="container">
            <a className="navbar-brand" onClick={redirectUser} href="/">
              <img src={yieldTreeLogoInverse} />
            </a>
            <button className="navbar-toggler" aria-controls="navbarSupportedContent" aria-expanded="false" data-target="#navbarSupportedContent" data-toggle="collapse" type="button">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              {currentEntity && (
              <ul className="navbar-nav main-nav-tabs">
                <li className={`${isOnDashboard && "active"} nav-item text-right text-lg-left`}>
                  <Link
                    className="nav-link"
                    to="/dashboard">
                    Dashboard
                  </Link>
                </li>
                <li className={`${!isOnDashboard && !isOnCrypto && !isOnCryptoenabled && "active"} nav-item text-right text-lg-left`}>
                  <Link
                    className="nav-link"
                    to="/marketplace">
                    Marketplace
                  </Link>
                </li>
                {currentEntity.country === "AU" && (
                  <li className={`${(isOnCrypto || isOnCryptoenabled) && "active"} nav-item text-right text-lg-left`}>
                    <Link
                      className="nav-link"
                      to="/crypto"
                      onClick={() => { window.location.href = "/crypto" }}>
                      Trade Crypto
                    </Link>
                  </li>
                )}
              </ul>
              )
            }
              {!currentEntity && (
              <ul className="navbar-nav main-nav-tabs">
                <li className="nav-item text-right text-lg-left disable"><span className="nav-link">Dashboard</span></li>
                <li className="nav-item text-right text-lg-left disable"><span className="nav-link">Marketplace</span></li>
              </ul>
            )
            }
              {currentEntity && (
              <div className="navbar-nav ml-md-auto">
                <li className="nav-item dropdown">
                  <div aria-expanded="false" aria-haspopup="false" className="btn-link nav-link dropdown-toggle" data-toggle="dropdown" id="navbarDropdown">
                    <div className="d-flex align-items-center justify-content-end">
                      <div className="user-initial">
                        {entityInitials}
                      </div>
                      <span className="text-white">{currentEntity?.firstname || currentEntity?.name}</span>
                    </div>
                  </div>
                  <div aria-labelledby="navbarDropdown" className="dropdown-menu dropdown-menu-right">
                    <Link className="dropdown-item" to="/account" onClick={() => { window.location.href = "/account" }}>Switch Account</Link>
                    <div className="dropdown-divider"></div>
                    <Link className="dropdown-item" to="/account" onClick={() => { window.location.href = "/account" }}>Account Settings</Link>
                    <Link className="dropdown-item" to="/account/past-investments" onClick={() => { window.location.href = "/account/past-investments" }}>Past Investments</Link>
                    <Link className="dropdown-item" to="/account/banking" onClick={() => { window.location.href = "/account/banking" }}>Banking</Link>
                    <Link className="dropdown-item" to="/account/transactions" onClick={() => { window.location.href = "/account/transactions" }}>Transactions</Link>
                    <Link className="dropdown-item" to="/account/settings" onClick={() => { window.location.href = "/account/settings" }}>Settings</Link>
                    <div className="dropdown-divider"></div>
                    <Link className="dropdown-item" to="/logout" onClick={() => { window.location.href = "/logout" }}> Logout of all accounts</Link>
                  </div>
                </li>
              </div>
              )
              }
              {!currentEntity && (
                <div className="navbar-nav ml-md-auto d-flex align-items-center justify-content-end">
                  <div className="user-initial">
                    {currentUser.firstname[0] + currentUser.surname[0]}
                  </div>
                  <a className="nav-link active" href="/logout">Logout</a>
                </div>
              )}
            </div>
          </div>
        </nav>
      )
    }
      <BannerIdWarning currentEntity={currentEntity} />
    </>
  )
}

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
  currentEntity: getCurrentEntity(state),
})

export default withRouter(connect(mapStateToProps)(Header))