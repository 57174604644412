import React, { useState } from "react"
import { bankingCountryList } from "components/countries"
import RemoveBanking from "./RemoveBanking"
import AddBanking from "./AddBanking"

const getCurrency = country => {
  const foo = bankingCountryList.find(element => element.value === country)
  return foo.currency
}

const Banking = ({ offering, reloadOffering }) => {
  const [bankIdToBeDeleted, setBankIdToBeDeleted] = useState()

  return (
    <div>
      <div className="card my-3">
        <div className="card-header">
          <div className="d-flex align-items-center justify-content-between">
            Banking
          </div>
        </div>
        <div className="card-body">
          <p>Investors will transfer funds to this Bank Account</p>
          {offering.banking.id ? (
            <div className="table-responsive">
              <table className="table yt-table yt-lined">
                <thead>
                  <tr>
                    <th />
                    <th />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <img
                        className="country-flag"
                        src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${offering.banking.country}.svg`}
                        style={{ height: "20px", paddingRight: "1rem" }} />
                      {`Account ${offering.banking.summary} - ${getCurrency(offering.banking.country)}`}
                    </td>
                    <td className="text-right">
                      <button
                        className="btn btn-plain"
                        type="button"
                        data-target="#removeBanking"
                        data-toggle="modal"
                        onClick={() => setBankIdToBeDeleted(offering.banking.id)}>
                        Remove
                      </button>
                    </td>
                  </tr>
                  {/* LEFT incase Offerings need to have multiple Bank Accounts */}
                  {/* {bankDetails.length && bankDetails?.map(bankDetail => (
                      <tr key={bankDetail.id}>
                        <td>
                          <img
                            className="country-flag"
                            src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${bankDetail.country}.svg`}
                            style={{ height: "20px", paddingRight: "1rem" }} />
                          {`Account ${bankDetail.account_number} ${getCurrency(bankDetail.country)}`}
                        </td>
                        <td className="text-right">
                          <button
                            className="btn btn-plain"
                            type="button"
                            data-target="#removeBanking"
                            data-toggle="modal"
                            onClick={() => setBankIdToBeDeleted(bankDetail.id)}>
                            Remove
                          </button>
                        </td>
                      </tr>
                    ))} */}
                </tbody>
              </table>
            </div>
            ) : (
              <>
                <p>Offering currently has no Bank Details, please click add account.</p>
                <button
                  className="btn btn-plain"
                  type="button"
                  data-target="#addBanking"
                  data-toggle="modal">
                  Add Account
                </button>
              </>
            ) }
        </div>
      </div>
      {bankIdToBeDeleted && (
        <RemoveBanking
          reloadOffering={reloadOffering}
          offeringId={offering.id}
          bankIdToBeDeleted={bankIdToBeDeleted}
          setBankIdToBeDeleted={setBankIdToBeDeleted} />
      )}
      <AddBanking
        reloadOffering={reloadOffering}
        offeringId={offering.id} />
    </div>
  )
}

export default Banking