import React from "react"

import { yieldTreeLogo } from "components/Icons"

export default function MainNavbar({ handleSaveAndExit, currentUser }) {
  const isUserRegistered = [
    // "pending_approval",
    "partial_submission",
    "waiting_background_check",
    "complete"
  ].includes(currentUser?.registration_status)
  const redirectUser = () => {
    window.location.href = isUserRegistered ? "/marketplace" : "/"
  }
  const logoutUser = () => {
    window.location.href = "/logout"
  }

  return (
    <div className="navbar navbar-light bg-light onboarding-nav">
      <div className="container justify-content-center">
        <button
          type="button"
          className="btn text-center"
          onClick={redirectUser}>
          <img src={yieldTreeLogo} />
        </button>
        {["phone_verified", "partial_submission", "waiting_background_check"].includes(currentUser?.registration_status)
          && (
            <div className="save-exit">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <button type="button" className="nav-link btn btn-no-border" onClick={handleSaveAndExit}>Save and Exit</button>
                </li>
              </ul>
            </div>
          )
        }
        {["partial", "phone_pending", "email_verified"].includes(currentUser?.registration_status)
          && (
            <div className="save-exit">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <button type="button" className="nav-link btn btn-no-border" onClick={logoutUser}>Logout</button>
                </li>
              </ul>
            </div>
          )
        }
      </div>
    </div>
  )
}
