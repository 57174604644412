import React from "react"
import { Provider } from "react-redux"
import ReactDOM from "react-dom"
import { ToastProvider } from "react-toast-notifications"

import { ConnectedRouter }  from "connected-react-router"
import { ThemeProvider }    from "styled-components"

import { mainTheme }        from "themes"
import { CookiesProvider } from "react-cookie"

import LastLocationCookie from "./components/LastLocationCookie"
import configureStore, { history } from "./store"

import Content from "./Content"

const startRegisterApp = rootElem => {
  const store = configureStore()

  ReactDOM.render(
    <CookiesProvider>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ThemeProvider theme={mainTheme}>
            <ToastProvider autoDismiss>
              <Content />
              <LastLocationCookie />
            </ToastProvider>
          </ThemeProvider>
        </ConnectedRouter>
      </Provider>
    </CookiesProvider>,
    rootElem
  )
}

export default startRegisterApp
