import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import PhoneInput, { formatPhoneNumberIntl } from "react-phone-number-input"
import { getBaseUrl } from "../../reducers"
import { addUserPhone } from "../../requesters"
import VerifyPhone from "./VerifyPhone"
import "react-phone-number-input/style.css"

export default function AddPhone({ currentUser }) {
  const defaultCountryCode = {
    AU: "AU",
    SG: "SG",
    ROTW: "GB"
  }[currentUser.country]

  const baseUrl = useSelector(getBaseUrl)

  const [formSubmitted, setFormSubmitted] = useState(false)
  const [phone, setPhone] = useState(undefined)
  const [phoneWithoutCountryCode, setPhoneWithoutCountryCode] = useState(undefined)
  const [validPhone, setValidPhone] = useState(undefined)
  const [showError, setShowError] = useState(undefined)

  const validatePhone = value => {
    const validPhone = /^[0-9]{4,15}$/
    return validPhone.test(String(value))
  }

  useEffect(() => {
    if (phone !== undefined) {
      setShowError(true)
      const intlPhone = formatPhoneNumberIntl(phone)
      const phoneNumber = phone.replace(intlPhone.split(" ")[0], "")
      setPhoneWithoutCountryCode(phoneNumber)
    }
  }, [phone])

  useEffect(() => {
    setValidPhone(validatePhone(phoneWithoutCountryCode))
  }, [phoneWithoutCountryCode])

  const onSubmit = async e => {
    e.preventDefault()
    const phoneWithCountryCode = phone
    await addUserPhone(baseUrl, currentUser.id, phoneWithCountryCode).then(data => {
      const res = data.response.json
      if (res.success) { setFormSubmitted(true) }
    })
  }

  if (formSubmitted) {
    return <VerifyPhone currentUser={currentUser} />
  }
  return (
    <form onSubmit={onSubmit}>
      <div className="container text-center">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8">
            <h2 className="mt-5 mb-4 pt-5">Verify Your Phone Number</h2>
            <p>
              In order to protect the security of your account, please verify your
              phone number to continue
            </p>
            <div className="form-group my-4">
              <div className="input-group">

                <PhoneInput
                  international
                  autoComplete="off"
                  placeholder="Enter phone number"
                  name="phone"
                  countryCallingCodeEditable={false}
                  defaultCountry={defaultCountryCode}
                  value={phone}
                  onChange={setPhone} />

                { (showError && validPhone === false) && <div className="err-msg text-danger"> Invalid Phone. Phone must be between 4 and 15 characters.</div> }
              </div>
            </div>
            <input
              type="submit"
              value="Next"
              className="btn btn-main py-3 px-5"
              disabled={!validPhone} />
          </div>
        </div>
      </div>
    </form>
  )
}
