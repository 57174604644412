import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { sortTransactionsDate } from "components/CommonMethods"
import SidebarNav from "./SidebarNav"
import TransactionRow from "./TransactionRow"
import { getBaseUrl } from "../../reducers"
import { getTransactions } from "../../requesters/UserRequester"

const transactionTypeColumns = [
  "Funding",
  "Return",
  "Principal",
  "Closed"
]

const Transactions = ({ currentEntity, baseUrl }) => {
  const [transactions, setTransactions] = useState([])
  const [currentTransactions, setCurrentTransactions] = useState([])
  const [dateColumns, setDateColumns] = useState([])
  const [offeringColumns, setOfferingColumns] = useState([])
  const [currencyColumns, setCurrencyColumns] = useState([])
  const [currentFilter, setCurrentFilter] = useState({})

  useEffect(() => {
    getTransactions(baseUrl, currentEntity.id)
      .then(r => {
        const transactions = r.response.json.data
        setTransactions(transactions)
        setCurrentTransactions(transactions)

        const years = transactions.map(y => y.year)
        setDateColumns(_.uniq(years, true))

        const currencies = transactions.map(y => y.currency)
        setCurrencyColumns(_.uniq(currencies, true))

        const offerings = transactions.map(y => y.offering_name)
        setOfferingColumns(_.uniq(offerings, true))
    })
  }, [])

  useEffect(() => {
    if (Object.keys(currentFilter).length) {
      const nextView = transactions.filter(
        e => {
          if (currentFilter.type && currentFilter.type !== e.type) {
            return false
          }
          if (currentFilter.year && currentFilter.year !== e.year) {
            return false
          }
          if (currentFilter.currency && currentFilter.currency !== e.currency) {
            return false
          }
          if (currentFilter.offering_name && currentFilter.offering_name !== e.offering_name) {
            return false
          }
          return true
        }
      )
      setCurrentTransactions(nextView)
    }
  }, [currentFilter])

  sortTransactionsDate(transactions)

  return (
    <div className="content">
      <div className="container">
        <div className="row no-gutters">
          <SidebarNav />
          <div className="col-lg-9">
            <h2 className="mb-1 mt-5">Transactions</h2>
            {
              transactions.length ? (
                <>
                  <div className="container-fluid pt-4">
                    <div className="row no-gutters filters mt-5 mb-3">
                      <div className="col-6 col-lg-3 filter-item">
                        <select
                          className="form-control custom-select"
                          onChange={e => {
                          const currentFil = { ...currentFilter }
                          currentFil.type = e.target.value
                          setCurrentFilter(currentFil)
                        }}>
                          <option value="">All Transactions</option>
                          {transactionTypeColumns.map(i => <option key={`type-filter-col-${i}`} value={i}>{i}</option>)}
                        </select>
                      </div>
                      <div className="col-6 col-lg-3 filter-item">
                        <select
                          className="form-control custom-select"
                          onChange={e => {
                          const currentFil = { ...currentFilter }
                          currentFil.currency = e.target.value
                          setCurrentFilter(currentFil)
                        }}>
                          <option value="">All Currencies</option>
                          {currencyColumns.map(i => <option key={`currency-filter-col-${i}`} value={i}>{i}</option>)}
                        </select>
                      </div>
                      <div className="col-6 col-lg-3 filter-item">
                        <select
                          className="form-control custom-select"
                          onChange={e => {
                          const currentFil = { ...currentFilter }
                          currentFil.offering_name = e.target.value
                          setCurrentFilter(currentFil)
                        }}>
                          <option value="">All Offerings</option>
                          {offeringColumns.map(i => <option key={`offering-filter-col-${i}`} value={i}>{i}</option>)}
                        </select>
                      </div>
                      <div className="col-6 col-lg-3 filter-item">
                        <select
                          className="form-control custom-select"
                          onChange={e => {
                          const currentFil = { ...currentFilter }
                          currentFil.year = e.target.value
                          setCurrentFilter(currentFil)
                        }}>
                          <option value="">Year</option>
                          {dateColumns.map(i => <option key={`date-filter-col-${i}`} value={i}>{i}</option>)}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="d-table w-100 yt-table table">
                    <div className="d-md-table-row d-none">
                      <div className="yt-th d-md-table-cell">Date</div>
                      <div className="yt-th d-md-table-cell d-none">Transaction</div>
                      <div className="yt-th d-md-table-cell text-right">Amount</div>
                    </div>
                    {currentTransactions.map(element => (
                      <TransactionRow transaction={element} key={`yield-row-${element.type}-${element?.id}`} />
                    ))}
                  </div>
                </>
              ) : (
                <div>You do not have any Transactions at this time.</div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  baseUrl: getBaseUrl(state)
})

export default connect(mapStateToProps)(Transactions)