/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"
import { useToasts } from "react-toast-notifications"
import { formatWithComma } from "components/CommonMethods"
import Progress from "./Progress"
import CancelInvestment from "./CancelInvestment"
import Timeline from "./Timeline"
import { cancelInvestment, sendHelloSignRequest } from "../../../requesters"

const Pending = ({
  offering, investment, reloadData
}) => {
  const { addToast } = useToasts()

  const handleCancelInvestment = e => {
    e.preventDefault()
    cancelInvestment(investment.id).then(data => {
      const res = data.response.json
      if (res.success) {
        addToast("Your investment has been cancelled!", { appearance: "success" })
        reloadData()
      } else {
        addToast(res.message, { appearance: "error" })
      }
    })
  }

  if (offering.is_offering_eoi) {
    return (
      <div className="col-md-4 order-1 order-md-2 mb-5">
        <div className="card shadow-sm card-body sticky-top">
          <Progress offering={offering} />
          <h3 className="mb-3">Expression of Interest</h3>
          <div className="small mb-3">
            Offer currently not live. You have been placed on the waitlist.
          </div>
          <div className="d-flex justify-content-between">
            {` Amount (${offering.currency})`}
            <strong className="med">
              {`$${formatWithComma(investment.amount)}`}
            </strong>
          </div>
          <CancelInvestment text="Cancel Waitlist" linkClass="btn btn-lg btn-block btn-outlined-main mt-4" investment={investment} reloadData={reloadData} />
        </div>
      </div>
    )
  }

  const handleResendHelloSign = () => {
    sendHelloSignRequest(investment.id).then(data => {
      const res = data.response.json
      if (res.success) {
        addToast("HelloSign Document sent: Please check your email address", { appearance: "success" })
        setTimeout(window.location.href = `/marketplace/${offering.id}`, 1000)
      } else {
        addToast(res.message, { appearance: "error" })
      }
    })
  }

  return (
    <div className="col-md-4 order-1 order-md-2 mb-5">
      <div className="card shadow-sm card-body sticky-top">
        <Progress offering={offering} />
        <h3 className="mb-3">Pending</h3>
        <div className="d-flex justify-content-between">
          {` Amount (${offering.currency})`}
          <strong className="med">
            {`$${formatWithComma(investment.amount)}`}
          </strong>
        </div>
        <button type="button" className="btn btn-lg btn-block btn-main mt-4 mb-2" onClick={handleResendHelloSign}>Resend HelloSign Request</button>
        <button type="button" className="btn btn-lg btn-block btn-outlined-main" onClick={handleCancelInvestment}>Cancel Investment</button>
        <div className="mt-4 investor-cta-state">
          <table className="table table-borderless yt-table table-sm table-between">
            <tbody>
              <Timeline title="Documentation" date={offering.closing_date} status="empty" />
              <Timeline title="Funding Date" date={offering.investor_funding_date} status="empty" />
              <Timeline title="Issue Date" date={offering.issue_date} status="empty" />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Pending
