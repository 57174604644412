/* eslint-disable no-unused-expressions */
import axios from "axios"

export const getUserDetails = (baseUrl, identifier) => {
  const path = `${baseUrl}/users/${identifier}/details`
  return axios.get(path)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const getEntityDetails = (baseUrl, identifier) => {
  const path = `${baseUrl}/entities/${identifier}/details`
  return axios.get(path)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const getInvestments = (baseUrl, identifier) => {
  const path = `${baseUrl}/entities/${identifier}/dashboard_investments`
  return axios.get(path)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const getFundedInvestments = (baseUrl, identifier) => {
  const path = `${baseUrl}/entities/${identifier}/funded_investments`
  return axios.get(path)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const getTransactions = (baseUrl, identifier) => {
  const path = `${baseUrl}/entities/${identifier}/transactions`
  return axios.get(path)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

/**
 * LEONIE HILL FLUID INTEGRATION - REQUESTERS
 */

export const getFluidUserEnabled = (baseUrl, identifier) => {
  const path = `${baseUrl}/users/${identifier}/fluid_enabled`
  return axios.get(path)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const getFluidLoginUrl = baseUrl => {
  const path = `${baseUrl}/users/fluid_url`
  return axios.get(path)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const getFluidEntityEnabled = (baseUrl, identifier) => {
  const path = `${baseUrl}/entities/${identifier}/fluid_enabled`
  return axios.get(path)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const postEnableFluidUser = (baseUrl, identifier) => {
      const path = `${baseUrl}/users/${identifier}/fluid_enable_user`
      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("[name=csrf-token]").content
      }
      return axios.post(path, {}, headers)
        .then(({ data, status }) => ({ response: data, status }))
        .catch(error => { error })
}

export const postEnableFluidEntity = (baseUrl, identifier) => {
      const path = `${baseUrl}/entities/${identifier}/fluid_enable_entity`
      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("[name=csrf-token]").content
      }
      return axios.post(path, {}, headers)
        .then(({ data, status }) => ({ response: data, status }))
        .catch(error => { error })
}