import React from "react"

import { yieldTreeLogo } from "components/Icons"
import { Link } from "react-router-dom"

export default function Header() {
  return (
    <div className="navbar navbar-light bg-light onboarding-nav">
      <div className="container">
        <Link
          className="navbar-brand mx-auto"
          to="/backend">
          <img src={yieldTreeLogo} />
        </Link>
      </div>
    </div>
  )
}
