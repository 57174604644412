import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { formatWithComma, tidyUpString } from "components/CommonMethods"
import SidebarNav from "./SidebarNav"
import { getPastInvestments } from "../../requesters/UserRequester"
import { getBaseUrl } from "../../reducers"

const PastInvestmentList = ({ currentEntity, baseUrl }) => {
  const [investments, setInvestment] = useState([])

  useEffect(() => {
    getPastInvestments(baseUrl, currentEntity.id).then(r => {
      const { past_investments, yield_returns } = r.response.json.data
      const output = past_investments.map(investment => {
        let runningTotalReturn = 0
        let runningTotalPrincipal = 0
        yield_returns.forEach(yr => {
          if (yr.offering_id === investment.offering_id) {
            if (yr.return_type === "Return") {
              runningTotalReturn += yr.amount
            } else {
              runningTotalPrincipal += yr.amount
            }
          }
        })

        return {
          totalReturn: runningTotalReturn,
          totalPrincipal: runningTotalPrincipal,
          ...investment
         }
      })
      setInvestment(output)
    })
  }, [])

  const returnText = investment => (!investment.totalReturn
    ? "-"
    : `+ $${formatWithComma(investment.totalReturn)} ${investment.currency}`)

  return (
    <div className="content">
      <div className="container">
        <div className="row no-gutters">
          <SidebarNav />
          <div className="col-lg-9">
            <h2 className="mb-1 mt-5">Past Investments</h2>
            {!investments.length && <p>You currently have no Past Investments</p> }
            {!!investments.length && (
              <div className="d-lg-table w-100 yt-table table">
                <div className="yt-th d-lg-table-cell">Offering</div>
                <div className="yt-th d-lg-table-cell text-center">Interest Rate</div>
                <div className="yt-th d-lg-table-cell text-right">Investment</div>
                <div className="yt-th d-lg-table-cell text-right">Status</div>
                <div className="yt-th d-lg-table-cell text-right">Interest Received</div>
                {investments.map(investment => (
                  <div className="d-lg-content table-link-wrapper" key={`invest-row-${investment.id}`}>
                    <div className="d-lg-table-row table-item border-bottom past-investment-item">
                      <div className="d-flex justify-content-between d-lg-content">
                        <div className="yt-td d-lg-table-cell border-bottom">
                          {investment.offering_name}
                          <div className="text-muted">
                            {investment.start_date}
                            &nbsp;
                            -
                            &nbsp;
                            {investment.end_date}
                          </div>
                        </div>
                        <div className="yt-td d-none d-lg-table-cell text-center border-bottom">
                          {investment.interest_rate}
                          %
                        </div>
                        <div className="d-flex justify-content-between d-lg-content flex-column">
                          <div className="yt-td d-lg-table-cell text-right border-bottom">
                            $&nbsp;
                            {formatWithComma(investment.amount)}
                          </div>
                          <div className="yt-td d-lg-table-cell text-right border-bottom">
                            {tidyUpString(investment?.status)}
                          </div>
                          <div className="yt-td d-lg-table-cell text-right text-sub text-main border-bottom">
                            {returnText(investment)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  baseUrl: getBaseUrl(state)
})

export default connect(mapStateToProps)(PastInvestmentList)
