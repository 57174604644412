import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter, Switch } from "react-router-dom"
import { ToastProvider } from "react-toast-notifications"
import ProtectedRoute from "components/utils/ProtectedRoute"
import Entities from "./Entities/index"
import Users from "./Users/index"
import Offerings from "./Offerings/index"
import Returns from "./Returns/index"
import * as reducers from "../reducers"
import Logs from "./Logs"

class Main extends Component {
  render() {
    const {
      currentUser
    } = this.props

    return (
      <ToastProvider autoDismiss>
        <Switch>
          <ProtectedRoute
            exact
            currentUser={currentUser}
            path="/backend/entities"
            redirectedRoute="/"
            component={Entities} />
          <ProtectedRoute
            exact
            currentUser={currentUser}
            path="/backend/users"
            redirectedRoute="/"
            component={Users} />
          <ProtectedRoute
            exact
            currentUser={currentUser}
            path="/backend/offerings"
            redirectedRoute="/"
            component={Offerings} />
          <ProtectedRoute
            exact
            currentUser={currentUser}
            path="/backend/returns"
            redirectedRoute="/"
            component={Returns} />
          <ProtectedRoute
            exact
            currentUser={currentUser}
            path="/backend/logs"
            redirectedRoute="/"
            component={Logs} />
        </Switch>
      </ToastProvider>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: reducers.getCurrentUser(state)
})

export default withRouter(connect(mapStateToProps)(Main))
