import React, { useState, useEffect } from "react"

import { onlyNumbers } from "components/CommonMethods"
import Dropzone from "../Dropzone"
import ErrorMessage from "./ErrorMessage"

export default function WholesaleForm({
  setValidWholeSaleCertificate,
  handleWholesaleChange,
  setDate,
  wholesaleCertificate: {
    pdf,
    date,
    date: {
      day, month, year
    }
  }
}) {
  const [validIssueDate, setValidIssueDate] = useState(undefined)
  const hasExistingPdf = !!pdf?.name
  const [validCertificate, setValidCertificate] = useState(!!pdf?.name)

  const [issueDate, setIssueDate] = useState({
    day: undefined,
    month: undefined,
    year: undefined
  })

  const validateIssueDate = () => {
    let monthStr = issueDate.month
    let dayStr = issueDate.day
    // 333: issue with parsing single digit day/months on mobile Safari
    if (issueDate.month < 10 && issueDate.month[0] !== "0") {
      monthStr = `0${issueDate.month}`
    }
    if (issueDate.day < 10 && issueDate.day[0] !== "0") {
      dayStr = `0${issueDate.day}`
    }
    const date = new Date(`${issueDate.year}-${monthStr}-${dayStr}`)
    const today = new Date()
    const validDate = new Date()
    validDate.setMonth(validDate.getMonth() - 6) // Certificate needs to be issued in the last 6 months
    setValidIssueDate(date >= validDate && date < today)
  }

  useEffect(() => {
    if (!(issueDate.day === undefined && issueDate.month === undefined && issueDate.year === undefined)) { // Do not call at the beginning
      validateIssueDate(issueDate)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issueDate])

  useEffect(() => {
    setIssueDate(date)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    setValidWholeSaleCertificate(validIssueDate && validCertificate)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validIssueDate, validCertificate])

  const handleDropZoneBlur = e => {
    const pdf = e.currentTarget.querySelector('[type="file"]').value
    setValidCertificate(hasExistingPdf || !!pdf)
  }

  return (
    <>
      <div className="form-group row mb-2">
        <div className="col-sm-6 col-form-label">Issue Date</div>
        <div className="col-sm-6">
          <div className="form-row">
            <div className="col-4">
              <input
                className="form-control"
                placeholder="DD"
                type="number"
                min={1}
                max={31}
                defaultValue={day}
                onKeyDown={onlyNumbers}
                autoComplete="disableAll"
                name="day"
                onBlur={validateIssueDate}
                onChange={e => {
                  setDate(handleWholesaleChange, "issue_date", issueDate, { name: e.currentTarget.name, value: e.currentTarget.value })
                  setIssueDate({ ...issueDate, day: e.currentTarget.value })
                }} />
            </div>
            <div className="col-4">
              <input
                className="form-control"
                placeholder="MM"
                type="number"
                onKeyDown={onlyNumbers}
                min={1}
                max={12}
                defaultValue={month}
                autoComplete="disableAll"
                name="month"
                onBlur={validateIssueDate}
                onChange={e => {
                  setDate(handleWholesaleChange, "issue_date", issueDate, { name: e.currentTarget.name, value: e.currentTarget.value })
                  setIssueDate({ ...issueDate, month: e.currentTarget.value })
                }} />
            </div>
            <div className="col-4">
              <input
                className="form-control"
                placeholder="YYYY"
                type="number"
                onKeyDown={onlyNumbers}
                defaultValue={year}
                autoComplete="disableAll"
                min={(new Date()).getUTCFullYear() - 1}
                max={(new Date()).getUTCFullYear()}
                name="year"
                onBlur={validateIssueDate}
                onChange={e => {
                  setDate(handleWholesaleChange, "issue_date", issueDate, { name: e.currentTarget.name, value: e.currentTarget.value })
                  setIssueDate({ ...issueDate, year: e.currentTarget.value })
                }} />
            </div>
            {!validIssueDate && <ErrorMessage message="Certificate needs to be issued in the last 6 months" />}
          </div>
          <div className="invalid-feedback">Please provide a valid city.</div>
        </div>
      </div>
      <div className="form-group mt-4" onBlur={handleDropZoneBlur}>
        <label>Please upload your current s708 certification so that we can review and confirm your investor status as soon as possible.</label>
        <Dropzone
          wrapperClass="drag-drop"
          inactiveText={pdf?.name || "Drag and drop the file or click to Browse Files"}
          activeText="Drop your PDF here"
          multiple={false}
          defaultValue={pdf}
          name="pdf"
          fileTypes="image/jpeg, image/png, application/pdf"
          fileTypeNames=".jpeg .png .pdf"
          handleFileUpload={e => {
            handleWholesaleChange(e)
            setValidCertificate(hasExistingPdf || !!e.value)
          }} />
      </div>
      {!validCertificate && <ErrorMessage message="Please upload your current s708 certification" />}
    </>
  )
}
