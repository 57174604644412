import React from "react"
import { useToasts } from "react-toast-notifications"
import { jsonToFormData } from "components/CommonMethods"
import { deleteIcon } from "components/Icons"
import { uploadFiles } from "../../requesters"

const DocAndMedia = ({ offering, reloadData }) => {
  const { addToast } = useToasts()

  const updateOfferingFiles = formData => {
    uploadFiles(offering.id, formData).then(data => {
      const res = data.response.json
      if (res.success) {
        addToast("Offering updated successfully", { appearance: "success" })
        reloadData()
      } else {
        addToast("Something went wrong", { appearance: "error" })
      }
    })
  }

  const fileChangeHandler = e => {
    const file = e.target.files[0]
    if (file) {
      const field = e.target.name
      const data = { [field]: file }
      updateOfferingFiles(jsonToFormData(data, "offering"))
    }
  }

  const galleryImageChangeHandler = e => {
    const file = e.target.files[0]
    if (file) {
      const data = {
        id: offering.id,
        offering_images_attributes: [{ file }]
      }
      updateOfferingFiles(jsonToFormData(data, "offering"))
    }
  }

  const imFileDeleteHandler = () => {
    updateOfferingFiles(jsonToFormData({ im_file: null }, "offering"))
  }

  const ipFileDeleteHandler = () => {
    updateOfferingFiles(jsonToFormData({ ip_file: null }, "offering"))
  }

  const ndaFileDeleteHandler = () => {
    updateOfferingFiles(jsonToFormData({ nda_file: null }, "offering"))
  }

  const subscriptionDocDeleteHandler = () => {
    updateOfferingFiles(jsonToFormData({ subscription_doc: null }, "offering"))
  }

  const mainImageDeleteHandler = () => {
    updateOfferingFiles(jsonToFormData({ main_image: null }, "offering"))
  }

  const imFileChangeBtnHandler = () => {
    const elem = document.getElementById("imFileInput")
    elem.value = ""
    elem.click()
  }

  const ndaFileChangeBtnHandler = () => {
    const elem = document.getElementById("ndaFileInput")
    elem.value = ""
    elem.click()
  }

  const ipFileChangeBtnHandler = () => {
    const elem = document.getElementById("ipFileInput")
    elem.value = ""
    elem.click()
  }

  const subsDocChangeBtnHandler = () => {
    const elem = document.getElementById("subscriptionDoc")
    elem.value = ""
    elem.click()
  }

  const mainImageBtnHandler = () => {
    const elem = document.getElementById("mainImage")
    elem.value = ""
    elem.click()
  }

  const galaryAddBtnClickHandler = () => {
    const elem = document.getElementById("galaryImage")
    elem.value = ""
    elem.click()
  }
  const galleryImageRemover = e => {
    const imageId = e.currentTarget.getAttribute("data-id")
    const data = {
      id: offering.id,
      offering_images_attributes: [{ id: imageId, _destroy: true }]
    }
    updateOfferingFiles(jsonToFormData(data))
  }

  return (
    <div aria-labelledby="pills-documents-tab" className="tab-pane fade" id="pills-documents" role="tabpanel">
      <table className="table table-sm table-borderless backend-info">
        <tbody>
          <tr>
            <td>Upload Offering Docs</td>
            <td>
              {offering.im_file_name ? <a rel="noreferrer" target="_blank" href={offering.im_file}>{offering.im_file_name}</a> : "No IM file provided."}
            </td>
            <td className="text-right">
              {offering.im_file_name
                && (
                  <button type="submit" className="btn btn-outlined-red btn-sm mr-2" onClick={imFileDeleteHandler}>
                    <img width={16} src={deleteIcon} />
                  </button>
                  )
              }
              <input className="hide" type="file" id="imFileInput" name="im_file" accept="application/pdf" onChange={fileChangeHandler} />
              <button type="submit" className="btn btn-outlined-main btn-sm" onClick={imFileChangeBtnHandler}>Upload New</button>
            </td>
          </tr>
        </tbody>
      </table>
      <hr />
      <table className="table table-sm table-borderless backend-info">
        <tbody>
          <tr>
            <td>Load NDA Template</td>
            <td>
              {offering.nda_file_name ? <a rel="noreferrer" target="_blank" href={offering.nda_file}>{offering.nda_file_name}</a> : "No template uploaded."}
            </td>
            <td className="text-right">
              {offering.nda_file_name && (
                <button type="submit" className="btn btn-outlined-red btn-sm mr-2" onClick={ndaFileDeleteHandler}>
                  <img width={16} src={deleteIcon} />
                </button>
              )}
              <input className="hide" type="file" id="ndaFileInput" name="nda_file" accept="application/pdf" onChange={fileChangeHandler} />
              <button type="submit" className="btn btn-outlined-main btn-sm" onClick={ndaFileChangeBtnHandler}>Upload New</button>
            </td>
          </tr>
        </tbody>
      </table>
      <hr />
      <table className="table table-sm table-borderless backend-info">
        <tbody>
          <tr>
            <td>Upload IP (Investor Presentation)</td>
            <td>
              {offering.ip_file_name ? <a rel="noreferrer" target="_blank" href={offering.ip_file}>{offering.ip_file_name}</a> : "No IP file provided."}
            </td>
            <td className="text-right">
              {offering.ip_file_name && (
                <button type="submit" className="btn btn-outlined-red btn-sm mr-2" onClick={ipFileDeleteHandler}>
                  <img width={16} src={deleteIcon} />
                </button>
              )}
              <input className="hide" type="file" id="ipFileInput" name="ip_file" accept="application/pdf" onChange={fileChangeHandler} />
              <button type="submit" className="btn btn-outlined-main btn-sm" onClick={ipFileChangeBtnHandler}>Upload New</button>
            </td>
          </tr>
        </tbody>
      </table>
      <hr />
      <table className="table table-sm table-borderless backend-info">
        <tbody>
          <tr>
            <td>Upload Subscription Document</td>
            <td>
              {offering.subscription_doc_name ? <a rel="noreferrer" target="_blank" href={offering.subscription_doc}>{offering.subscription_doc_name}</a> : "No Subscription Document provided."}
            </td>
            <td className="text-right">
              {offering.subscription_doc_name
                && (
                  <button type="submit" className="btn btn-outlined-red btn-sm mr-2" onClick={subscriptionDocDeleteHandler}>
                    <img width={16} src={deleteIcon} />
                  </button>
                  )
              }
              <input className="hide" type="file" id="subscriptionDoc" name="subscription_doc" accept="application/pdf" onChange={fileChangeHandler} />
              <button type="submit" className="btn btn-outlined-main btn-sm" onClick={subsDocChangeBtnHandler}>Upload New</button>
            </td>
          </tr>
        </tbody>
      </table>
      <hr />
      <table className="table table-sm table-borderless backend-info">
        <tbody>
          <tr>
            <td>Upload main image</td>
            <td>
              <div className="img-thumb">
                {offering.main_image ? <img src={offering.main_image} /> : "No image available."}
              </div>
            </td>
            <td className="text-right">
              {offering.main_image
                && (
                  <button type="submit" className="btn btn-outlined-red btn-sm mr-2" onClick={mainImageDeleteHandler}>
                    <img width={16} src={deleteIcon} />
                  </button>
                )
              }
              <input className="hide" type="file" id="mainImage" name="main_image" accept="image/jpeg, image/png" onChange={fileChangeHandler} />
              <button type="submit" className="btn btn-outlined-main btn-sm" onClick={mainImageBtnHandler}>Upload New</button>
            </td>
          </tr>
        </tbody>
      </table>
      <hr />
      <table className="table table-sm table-borderless backend-info">
        <tbody>
          <tr>
            <td>Upload gallery</td>
            <td>
              <div className="row">
                {offering.gallery.length === 0
                  ? <div className="col-3">No image available.</div>
                  : (
                    offering.gallery.map(image => (
                      <div className="col-3">
                        <div className="img-thumb-sm">
                          <img src={image.url} />
                          <button type="submit" className="btn btn-outlined-red btn-sm" data-id={image.id} onClick={galleryImageRemover}>
                            <img width={16} src={deleteIcon} />
                          </button>
                        </div>
                      </div>
                      ))
                  )
                }
              </div>
              <input className="hide" type="file" id="galaryImage" name="main_image" accept="image/jpeg, image/png" onChange={galleryImageChangeHandler} />
              <div className="btn btn-outlined-main btn-sm mt-3" onClick={galaryAddBtnClickHandler}>
                Upload New
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default DocAndMedia
