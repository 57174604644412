import React, { useState, useEffect } from "react"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css"
import filterFactory, { selectFilter, customFilter } from "react-bootstrap-table2-filter"
// eslint-disable-next-line import/extensions
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css"
import paginationFactory from "react-bootstrap-table2-paginator"
import BootstrapTable from "react-bootstrap-table-next"
import { bronIdCountryList } from "components/countries"

import axios from "axios"

const EntityList = ({ setEntity }) => {
  const [entities, setEntities] = useState([])

  const onClickHandler = (e, entityId) => {
    e.preventDefault()
    setEntity(entityId)
  }

  let onEntityFilter = null

  useEffect(() => {
    axios.get("/api/backend/entities/index")
      .then(response => {
        setEntities(response.data.json.data)
    })
  }, [])

  const arrayToJson = arr => {
    const obj = {}
    arr.forEach(e => {
      obj[e] = e
    })
    return obj
  }

  const countries = {}
  bronIdCountryList.forEach(c => {
    countries[c.value] = c.label
  })

  const entitiesType = [
    "Individual"
  ]

  const statuses = [
    "Signed Up",
    "Attention Required",
    "Active"
  ]

  const investors = [
    "Wholesale Investor",
    "Expert Investor",
    "Accredited Investor",
    "Retail Investor"
  ]

  const columns = [{
      dataField: "name",
      text: "Entity Name",
      sort: true,
      filter: customFilter(),
      filterRenderer: onFilter => {
        onEntityFilter = onFilter
        return null
      },
      formatter: (cell, row) => <a onClick={e => onClickHandler(e, row.id)} className="" href={`/backend/entity/${cell}`}>{cell}</a>
    },
    {
      dataField: "id",
      text: "Entity ID",
      sort: true
    },
    {
      dataField: "type",
      text: "Entity Type",
      sort: true,
      filter: selectFilter({ options: arrayToJson(entitiesType) })
    },
    {
      dataField: "country",
      text: "Country",
      sort: true,
      filter: selectFilter({
        options: countries
      })
    },
    {
      dataField: "id_verification",
      text: "ID Verification",
      sort: true,
      formatter: cell => {
        if (cell !== "Verified") {
          return <span className="text-danger">{cell}</span>
        }
        return cell
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      filter: selectFilter({ options: arrayToJson(statuses) }),
      formatter: cell => {
        if (cell === "Attention Required") {
          return <span className="text-danger">Attention Required</span>
        }
        return cell
      }
    },
    {
      dataField: "investor_class",
      text: "Investor Class",
      sort: true,
      filter: selectFilter({ options: arrayToJson(investors) })
    },
    {
      dataField: "s708",
      text: "s708",
      sort: true,
      formatter: cell => {
        if (["Required", "Not Verified"].includes(cell)) {
          return <span className="text-danger">{ cell }</span>
        }
        return cell
      }
    },
    {
      dataField: "ai_doc",
      text: "AI Doc",
      sort: true,
      formatter: cell => {
        if (["Required", "Not Verified"].includes(cell)) {
          return <span className="text-danger">{ cell }</span>
        }
        return cell
      }
    }
  ]
  const onEntityChange = event => {
    onEntityFilter(event.currentTarget.value)
  }

  return (
    <>
      <div className="container-fluid pt-4">
        <div className="d-flex justify-content-between align-items-center">
          <h3>Entities</h3>
          {/* <a className="btn btn-main" href="backend-entity-detail-new">Add New</a> */}
        </div>
        <div className="d-flex mt-4 mb-3">
          <input
            className="form-control mr-2"
            placeholder="Search Entity"
            type="search"
            onChange={onEntityChange} />
          <select className="form-control custom-select mr-2">
            <option value="">All Entity Types</option>
            {entitiesType.map(e => <option key={e} value={e}>{e}</option>)}
          </select>

          <select
            className="form-control custom-select mr-2"
            onChange={e => {
              document.getElementById("select-filter-column-country").value = e.currentTarget.value
              document.getElementById("select-filter-column-country").dispatchEvent(new Event("change", { bubbles: true }))
            }}>
            <option value="">All Countries</option>
            {bronIdCountryList.map(c => <option key={c.value} value={c.value}>{c.label}</option>)}
          </select>

          <select
            className="form-control custom-select mr-2"
            onChange={e => {
              document.getElementById("select-filter-column-status").value = e.currentTarget.value
              document.getElementById("select-filter-column-status").dispatchEvent(new Event("change", { bubbles: true }))
            }}>
            <option value="">All Status</option>
            {statuses.map(s => <option key={s} value={s}>{s}</option>)}
          </select>

          <select
            className="form-control custom-select"
            onChange={e => {
              document.getElementById("select-filter-column-investor_class").value = e.currentTarget.value
              document.getElementById("select-filter-column-investor_class").dispatchEvent(new Event("change", { bubbles: true }))
            }}>
            <option value="">All Investor Class</option>
            {investors.map(i => <option key={i} value={i}>{i}</option>)}
          </select>
        </div>
      </div>
      <div className="entities-table">
        <BootstrapTable
          keyField="id"
          data={entities}
          columns={columns}
          filter={filterFactory()}
          wrapperClasses="table-responsive"
          pagination={paginationFactory()} />
      </div>
    </>
  )
}

export default EntityList
