import React from "react"
import { Link } from "react-router-dom"


const FluidWelcomePage = () => (
  <div className="marketplace-index">
    <div className="container">
      <h1 className="mt-0 pt-5">Thank you for activating your digital asset account</h1>
      <p>
        Your account is being processed and will be ready in the next 24 hours. We will notify you via email once it&apos;s ready. Please contact us if you do not receive an email in the next 24 hours.
      </p>

      <p>
        In the meantime, to transfer funds into your digital asset account, please make sure your bank details have been added in Aura Marketplace settings.
      </p>

      <p>
        Your digital asset account is proudly powered by Leonie Hill Ai - a associated entity of Aura Group.
      </p>

      <Link
        className="btn btn-outlined-main btn-lg mt-4"
        to="/account/banking"
        onClick={() => { window.location.href = "/account/banking" }}>
        Add My Account Details
      </Link>
    </div>
  </div>
  )


export default FluidWelcomePage
