import React, { Component } from "react"
import { connect } from "react-redux"
import { FIELD_VALIDATIONS } from "./formErrors"
import { checkEmailAvailability } from "../../requesters"
import { getBaseUrl } from "../../reducers"

class GetStarted extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isEighteen: false,
      isNotAmerican: false,
      isCompliant: false,
      isEmailAvailable: true,
      formValidations: {
        invalidFirstname: false,
        invalidSurname: false,
        invalidEmail: false,
        invalidPassword: false,
        invalidPasswordConfirmation: false
      }
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  isValid = formData => {
    const {
      firstname, surname,
      email, password,
      password_confirmation
    } = formData

    const {
      isEighteen, isNotAmerican,
      isCompliant, formValidations
    } = this.state

    const isPassword = password === password_confirmation && password && password.length >= 6
    const isPresent = firstname && surname && email
    const isLegal = isEighteen && isNotAmerican && isCompliant
    const isValid = Object.keys(formValidations).every(k => !formValidations[k])

    if (isLegal && isPresent && isPassword && isValid) {
      return false
    }
    return true
  }

  validateEmail = value => {
    const validEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return validEmail.test(String(value).toLowerCase())
  }

  validatePassword = value => {
    const validPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,50}$/
    return validPassword.test(String(value))
  }

  validateName = value => {
    const validName = /^[a-z '"-]{1,50}$/i
    return validName.test(String(value))
  }

  fieldIsValid = (attribute, value) => {
    const {
      formData: {
        password
      }
    } = this.props
    const { isEmailAvailable } = this.state

    const fieldIsValid = {
      firstname: this.validateName(value),
      surname: this.validateName(value),
      email: this.validateEmail(value) && isEmailAvailable,
      password: this.validatePassword(value),
      password_confirmation: value === password
    }[attribute]

    return fieldIsValid
  }

  emailIsAvailable = email => {
    const { baseUrl } = this.props
    checkEmailAvailability(baseUrl, email).then(data => {
      this.setState({ isEmailAvailable: data.response.json.data.available })
    })
  }

  errorMessage = attribute => {
    const {
      formValidations: {
        invalidFirstname,
        invalidSurname,
        invalidEmail,
        invalidPassword,
        invalidPasswordConfirmation
      }
    } = this.state

    const message = _.find(FIELD_VALIDATIONS, ["field", attribute])

    const invalid = {
      firstname: invalidFirstname,
      surname: invalidSurname,
      email: invalidEmail,
      password: invalidPassword,
      password_confirmation: invalidPasswordConfirmation
    }[attribute]

    if (invalid) {
      return (
        <div className="err-msg text-danger">{message.message}</div>
      )
    }

    return null
  }

  render() {
    const {
      isEighteen,
      isNotAmerican,
      isCompliant,
      formValidations
    } = this.state

    const {
      handleAttributeChange,
      handleSubmit,
      formData
    } = this.props

    return (
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8">
            <div className="text-center">
              <h1 className="mb-2">Get Started</h1>
              <p>Signing up for Aura Group Marketplace is fast and free.</p>
            </div>
            <div className="my-5">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder="First name"
                      autoComplete="disableAll"
                      type="text"
                      maxLength={50}
                      onChange={e => {
                        handleAttributeChange({ name: e.currentTarget.name, value: e.currentTarget.value })
                        this.setState({ formValidations: { ...formValidations, invalidFirstname: !this.fieldIsValid("firstname", formData.firstname) } })
                      }}
                      name="firstname" />
                    { this.errorMessage("firstname", formData.firstname) }
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder="Last name"
                      autoComplete="disableAll"
                      type="text"
                      maxLength={50}
                      onChange={e => {
                        handleAttributeChange({ name: e.currentTarget.name, value: e.currentTarget.value })
                        this.setState({ formValidations: { ...formValidations, invalidSurname: !this.fieldIsValid("surname", formData.surname) } })
                      }}
                      name="surname" />
                    { this.errorMessage("surname", formData.surname) }
                  </div>
                </div>
              </div>
              <div className="form-group">
                <input
                  className="form-control"
                  placeholder="Email address"
                  autoComplete="disableAll"
                  type="text"
                  onChange={e => {
                    this.emailIsAvailable(e.currentTarget.value)
                    handleAttributeChange({ name: e.currentTarget.name, value: e.currentTarget.value })
                    this.setState({ formValidations: { ...formValidations, invalidEmail: !this.fieldIsValid("email", formData.email) } })
                  }}
                  name="email" />
                { this.errorMessage("email", formData.email) }
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Create Password"
                  autoComplete="disableAll"
                  maxLength={50}
                  onChange={e => {
                    handleAttributeChange({ name: e.currentTarget.name, value: e.currentTarget.value })
                    this.setState({ formValidations: { ...formValidations, invalidPassword: !this.fieldIsValid("password", formData.password) } })
                  }}
                  name="password" />
                { this.errorMessage("password", formData.password) }
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Confirm Password"
                  autoComplete="disableAll"
                  maxLength={50}
                  onChange={e => {
                    handleAttributeChange({ name: e.currentTarget.name, value: e.currentTarget.value })
                    this.setState({ formValidations: { ...formValidations, invalidPasswordConfirmation: !this.fieldIsValid("password_confirmation", formData.password_confirmation) } })
                  }}
                  name="password_confirmation" />
                { this.errorMessage("password_confirmation", formData.password_confirmation) }
              </div>
            </div>
            <div className="custom-control custom-checkbox mb-2">
              <input
                className="custom-control-input"
                id="customCheck1"
                type="checkbox"
                defaultChecked={isEighteen}
                onChange={() => this.setState({ isEighteen: !isEighteen })} />
              <label className="custom-control-label" htmlFor="customCheck1">I confirm that I am over 18 years old</label>
            </div>
            <div className="custom-control custom-checkbox mb-2">
              <input
                className="custom-control-input"
                id="customCheck2"
                type="checkbox"
                defaultChecked={isNotAmerican}
                onChange={() => this.setState({ isNotAmerican: !isNotAmerican })} />
              <label className="custom-control-label" htmlFor="customCheck2">I confirm that I am not a US Person. Aura Group Marketplace does not currently provide investment opportunities to US Persons</label>
            </div>
            <div className="custom-control custom-checkbox mb-2">
              <input
                className="custom-control-input"
                id="customCheck3"
                type="checkbox"
                defaultChecked={isCompliant}
                onChange={() => this.setState({ isCompliant: !isCompliant })} />
              <label className="custom-control-label" htmlFor="customCheck3">
                {`I agree to Aura Group Marketplace’s `}
                <a href="https://www.aura.co/termsandconditions" target="_blank" rel="noopener noreferrer">Terms of Use</a>
                {` and `}
                <a href="https://www.aura.co/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                .
              </label>
            </div>
            <div className="text-center my-5">
              <button
                type="button"
                className="btn btn-main px-5 py-3"
                disabled={this.isValid(formData)}
                onClick={handleSubmit}>
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  baseUrl: getBaseUrl(state)
})

export default connect(mapStateToProps, {})(GetStarted)
