import React from "react"
import { useToasts } from "react-toast-notifications"
import Money from "components/Money"
import { jsonToFormData } from "components/CommonMethods"
import {
  confirmFund, cancelInvestment, uploadDocument, confirmSign
} from "../../../requesters"

import CancelInvestmentModal from "../../common/CancelInvestmentModal"
import ConfirmFundModal from "../../common/ConfirmFundModal"

const PendingRow = ({ investment, reloadData }) => {
  const { addToast } = useToasts()

  const confirmFundEventhandler = () => {
    confirmFund(investment.id).then(data => {
      const res = data.response.json
      if (res.success) {
        addToast("Fund confirmed successfully", { appearance: "success" })
        reloadData()
      } else {
        addToast(res.message, { appearance: "error" })
      }
    })
  }

  const confirmSignEventhandler = () => {
    confirmSign(investment.id).then(data => {
      const res = data.response.json
      if (res.success) {
        addToast("Fund confirmed successfully", { appearance: "success" })
        reloadData()
      } else {
        addToast(res.message, { appearance: "error" })
      }
    })
  }

  const cancelInvestmentHandler = () => {
    cancelInvestment(investment.id).then(data => {
      const res = data.response.json
      if (res.success) {
        addToast("Investment has been cancelled", { appearance: "success" })
        reloadData()
      } else {
        addToast(res.message, { appearance: "error" })
      }
    })
  }

  const uploadInvestmentDoc = uploadedFile => {
    uploadDocument(investment.id, jsonToFormData({ doc: uploadedFile, status: "signed" }, "investment")).then(data => {
      const res = data.response.json
      if (res.success) {
        addToast("Document uploaded successfully.", { appearance: "success" })
        reloadData()
      } else {
        addToast(res.message, { appearance: "error" })
      }
    })
  }
  const uploadButtonClickHandler = () => {
    const elem = document.getElementById("investmentDoc")
    elem.value = ""
    elem.click()
  }

  return (
    <tr>
      <td>{investment.offering.name}</td>
      <td className="text-right">
        <Money currency="$" amount={investment.amount} />
      </td>
      <td className="text-center target-date-status">
        {
          !investment.document_url && <span>Not provided</span>
        }
        {
          investment.document_url && investment.status === "signed" && <div className="bg-light">{`Due ${investment.offering.documentation_date}`}</div>
        }
        {
          investment.document_url && investment.status === "signature_confirmed" && <div className="bg-dark-green">{`Due ${investment.offering.documentation_date}`}</div>
        }
      </td>
      <td className="text-center target-date-status">
        <div className="bg-light">{`Due ${investment.offering.funding_date}`}</div>
      </td>
      <td className="text-center target-date-status">
        <div className="bg-light">{investment.offering.issue_date}</div>
      </td>
      <td className="text-right">
        <div className="d-flex justify-content-end">
          {
            investment.status === "signature_confirmed" && (
              <button
                type="submit"
                className="btn btn-sm btn-main ml-3"
                data-target={`#confirmFundModal-${investment.id}`}
                data-toggle="modal">
                Confirm Fund
              </button>
            )
          }
          {
            investment.status === "signed" && (
              <button
                type="submit"
                className="btn btn-sm btn-main ml-3"
                onClick={confirmSignEventhandler}>
                Confirm Signature
              </button>
            )
          }
          <div className="dropdown ml-2">
            <button type="button" className="btn btn-sm btn-outlined-main dropdown-toggle" data-toggle="dropdown" id="dropdownMenuLink" />
            <div
              aria-labelledby="dropdownMenuLink"
              className="dropdown-menu dropdown-menu-right"
              x-placement="bottom-end"
              style={{
                position: "absolute", willChange: "transform", top: 0, left: 0, transform: "translate3d(26px, 31px, 0px)"
              }}>
              {
                investment.status === "pending" && (
                  <>
                    <input className="hide" type="file" id="investmentDoc" name="doc" accept="application/pdf" onChange={e => uploadInvestmentDoc(e.target.files[0])} />
                    <button className="dropdown-item" type="button" onClick={uploadButtonClickHandler}> Upload Signed Subscription Document</button>
                  </>
                )
              }
              {
                !investment.document_url && investment.offering.subscription_doc_url && <a className="dropdown-item" rel="noreferrer" target="_blank" href={investment.offering.subscription_doc_url}>Download Subscription Document</a>
              }
              {
                investment.document_url && <a className="dropdown-item" rel="noreferrer" target="_blank" href={investment.document_url}>Download Signed Subscription Document</a>
              }
              {investment.offering.im_doc_url && investment.status !== "waiting" && <a className="dropdown-item" rel="noreferrer" target="_blank" href={investment.offering.im_doc_url}> Download IM</a>}

              <button
                className="dropdown-item"
                type="button"
                data-target={`#cancelModal-${investment.id}`}
                data-toggle="modal">
                Cancel Investment
              </button>
            </div>
          </div>
        </div>
      </td>
      <CancelInvestmentModal cancelInvestmentHandler={cancelInvestmentHandler} investmentId={investment.id} />
      <ConfirmFundModal confirmFundEventhandler={confirmFundEventhandler} investmentId={investment.id} />
    </tr>
  )
}

export default PendingRow
