/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable quote-props */
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { useToasts } from "react-toast-notifications"
import { getBaseUrl } from "../../../reducers"
import IdDetailRow from "./IdDetailRow"
import IdEdit from "./IdEdit"

export const mapIdentityDocument = doc => {
  switch (doc?.type) {
    case "UtilityBill":
      return {
        type: "Utility Bill",
        file: doc.file_identifier,
        url: doc.link
      }
    case "GovernmentLetter":
      return {
        type: "Government Letter",
        file: doc.file_identifier,
        url: doc.link
      }
    case "BankStatement":
      return {
        type: "Bank Statement",
        file: doc.file_identifier,
        url: doc.link
      }
    default:
      return { type: "Not Provided" }
  }
}

const UserIdTaxDetail = ({
 user, baseUrl, refetchUserDetail
}) => {
  const [secondId, setSecondId] = useState({})
  const [isOpen, setIsOpen] = useState(false)
  const [isIdEditable, setIsIdEditable] = useState(false)
  const { addToast } = useToasts()
  const [verified, setVerified] = useState(user.background_check_status === "verified")

  useEffect(() => {
    setSecondId(mapIdentityDocument(user.proof_of_residence))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const IdRowElement = () => (isIdEditable ? (
    <IdEdit
      baseUrl={baseUrl}
      user={user}
      refetchUserDetail={refetchUserDetail}
      doc={user?.identification_documents[0]}
      setIsIdEditable={setIsIdEditable} />
  ) : (
    <IdDetailRow
      country={user?.country}
      doc={user?.identification_documents[0]}
      isIdEditable={isIdEditable}
      setIsIdEditable={setIsIdEditable} />
  ))

  return (
    <div className="card my-3">
      <div className="card-header">ID Verification</div>
      <div className="card-body">
        <div className="d-flex align-items-center justify-content-between">
          <p className="mb-0">{ user.background_check_msg}</p>
          <button
            type="button"
            aria-controls="collapseExample"
            aria-expanded="false"
            className="btn btn-link btn-sm collapsed"
            data-toggle="collapse"
            onClick={() => setIsOpen(!isOpen)}
            href="#collapseExample">
            {isOpen ? "Hide Details" : "View Details"}
          </button>
        </div>
        <div className="collapse" id="collapseExample" style={{}}>
          <hr />
          <table className="table table-sm table-borderless backend-info">
            <tbody>
              <tr>
                <td>Country</td>
                <td>{ user.country_full_name }</td>
              </tr>
              <tr>
                <td>Gender</td>
                <td>{ user.gender }</td>
              </tr>
              <tr>
                <td>Date of Birth</td>
                <td>{ user.dob }</td>
              </tr>
              <tr>
                <td>Source of Wealth</td>
                <td>{ user.income_source}</td>
              </tr>
              <tr>
                <td>Source of Funds</td>
                <td>{ user?.fund_source}</td>
              </tr>
              <tr>
                <td>Address</td>
                <td>{ user.address?.full_address}</td>
              </tr>
            </tbody>
          </table>
          <hr />
          <strong>Tax Details </strong>
          <table className="table table-sm table-borderless backend-info">
            <tbody>
              {
                user.tax_details?.map((tax, index) => (
                  <React.Fragment key={tax.tfn}>
                    <tr>
                      <td>
                        Country of Tax Residence&nbsp;
                        {index + 1}
                      </td>
                      <td>{tax.country}</td>
                    </tr>
                    <tr>
                      <td>TIN/TFN Number</td>
                      <td>{tax.tfn || tax.explanation}</td>
                    </tr>
                  </React.Fragment>
                  ))
              }
            </tbody>
          </table>
          <hr />
          {["AU", "SG"].includes(user.country) && (
            <IdRowElement />
          )}
          <hr />
          {["SG"].includes(user.country) && (
            <>
              <div className="d-flex justify-content-between align-items-center">
                <strong>ID Document 2</strong>
                {/* <button
                  type="button"
                  className="btn btn-link btn-sm collapsed"
                  onClick={() => setIsIdEditable(!isIdEditable)}>
                  {isIdEditable ? "Cancel" : "Edit"}
                </button> */}
              </div>
              <table className="table table-sm table-borderless backend-info">
                <tbody>
                  <tr>
                    <td>
                      ID Type
                    </td>
                    <td>
                      {secondId.type}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      File
                    </td>
                    <td>
                      <a rel="noreferrer" target="_blank" href={secondId.url?.url}>Link</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )}

          {/* <div className="d-flex justify-content-between align-items-center">
            <strong>Document Verification</strong>
            <button
              type="button"
              // onClick={() => { verifyUserId(verified ? "rejected" : "verified"); setVerified(!verified) }}
              className="btn btn-secondary btn-sm">
              {verified ? "Reject" : "Verify"}
            </button>
          </div> */}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  baseUrl: getBaseUrl(state)
})

export default connect(mapStateToProps)(UserIdTaxDetail)
