import React from "react"

import SidebarNav from "./SidebarNav"
import ChangePasswordModal from "../modals/ChangePasswordModal"
import ChangeMobileModal from "../modals/ChangeMobileModal"
import ChangeEmailModal from "../modals/ChangeEmailModal"

import "react-phone-number-input/style.css"

const Settings = ({ currentUser }) => (
  <div className="container">
    <div className="row no-gutters">
      <SidebarNav />
      <div className="col-lg-9">
        <h2 className="mb-1 mt-5">Settings</h2>
        <h3 className="mt-5 mb-4">Personal Details</h3>
        <div className="form-group row mb-2">
          <div className="col-5 col-form-label">Password</div>
          <div className="col-6">
            <div className="input-group input-group-settings">
              <input className="form-control" name="password" type="text" defaultValue="***" />
              <div className="input-group-append">
                <button className="btn btn-transparent-main" type="button" data-target="#settingsChangePasswordModal" data-toggle="modal">Change</button>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group row mb-2">
          <div className="col-5 col-form-label">Email</div>
          <div className="col-6">
            <div className="input-group input-group-settings">
              <input className="form-control" name="password" type="text" defaultValue={currentUser.email} />
              <div className="input-group-append">
                <button className="btn btn-transparent-main" type="button" data-target="#settingsChangeEmailModal" data-toggle="modal">Change</button>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group row mb-5">
          <div className="col-5 col-form-label">Mobile</div>
          <div className="col-6">
            <div className="input-group input-group-settings">
              <input className="form-control" name="password" type="text" defaultValue={currentUser.phone_number} />
              <div className="input-group-append">
                <button className="btn btn-transparent-main" type="button" data-target="#settingsChangeMobileModal" data-toggle="modal">Change</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ChangePasswordModal currentUser={currentUser} />
    <ChangeMobileModal currentUser={currentUser} />
    <ChangeEmailModal />
  </div>
)

export default Settings
