import React from "react"
import { useToasts } from "react-toast-notifications"
import { formatWithComma } from "components/CommonMethods"
import { cancelInvestment } from "../../requesters"
import CancelInvestmentModal from "./CancelInvestmentModal"

const WaitlistRow = ({ reloadData, investment }) => {
  const isExpired = investment.status === "expired"
  const isEoi = investment.is_eoi
  const { addToast } = useToasts()

  const cancelInvestmentHandler = () => {
    cancelInvestment(investment.id).then(data => {
      const res = data.response.json
      if (res.success) {
        addToast("Investment has been cancelled", { appearance: "success" })
        reloadData()
      } else {
        addToast(res.message, { appearance: "error" })
      }
    })
  }

  return (
    <div className="d-lg-table-row table-item border-bottom">
      <div className="d-flex justify-content-between d-lg-content">
        <div className="yt-td d-lg-table-cell">{investment.offering_name}</div>
        <div className="yt-td d-lg-table-cell text-lg-right">
          {isExpired ? "Expired" : `$${formatWithComma(investment.investment)}`}
        </div>
      </div>
      <div className="yt-td d-lg-table-cell text-lg-right text-sub">
        {isExpired ? `Closed ${investment.closing_date}` : `Closes ${investment.closing_date}`}
      </div>
      <div className="yt-td d-lg-table-cell text-lg-right">
        {
          isEoi ? (
            <button
              className="btn btn-outlined-main btn-sm mt-2 mt-lg-0"
              type="button"
              data-target={`#waitlist-delete-${investment.id}`}
              data-toggle="modal">
              {isExpired ? "Remove" : "Cancel EOI"}
            </button>
          ) : (
            <button
              className="btn btn-outlined-main btn-sm mt-2 mt-lg-0"
              type="button"
              data-target={`#waitlist-delete-${investment.id}`}
              data-toggle="modal">
              {isExpired ? "Remove" : "Cancel Waitlist"}
            </button>
          )
        }
      </div>
      <CancelInvestmentModal cancelInvestmentHandler={cancelInvestmentHandler} dataTarget={`waitlist-delete-${investment.id}`} />
    </div>
  )
}

export default WaitlistRow
