import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import EmailVerification from "./EmailVerification"
import AccountCreation from "./AccountCreation"
import AddPhone from "./AddPhone"
import VerifyPhone from "./VerifyPhone"
import { getCurrentUser } from "../../reducers"

class VerificationForms extends Component {
  render() {
    const {
      currentUser,
      currentUser: {
        registration_status
      },
      setSaveAndExitData
    } = this.props

    const redirectUser = () => {
      window.location.href = "/marketplace"
    }

    switch (registration_status) {
      case "partial":
        return <EmailVerification currentUser={currentUser} />
      case "email_verified":
        return <AddPhone currentUser={currentUser} />
      case "phone_pending":
        return <VerifyPhone currentUser={currentUser} />
      case "phone_verified":
      case "partial_submission":
      case "waiting_background_check":
        return <AccountCreation currentUser={currentUser} setSaveAndExitData={setSaveAndExitData} />
      case "pending_approval":
        return <AccountCreation currentUser={currentUser} setSaveAndExitData={setSaveAndExitData} />
      case "complete":
        redirectUser()
        return null
      default:
        return null
    }
  }
}

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state)
})

export default withRouter(connect(mapStateToProps)(VerificationForms))
