/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import AddressAutocomplete from "components/AddressAutocomplete"
import { bronIdCountryList } from "components/countries"
import { onlyNumbers } from "components/CommonMethods"
import  ManualAddressForm  from "./ManualAddressForm"

import ErrorMessage from "./ErrorMessage"
import {
  validName,
  validDob,
  validDescription,
  validDropdown,
  validMiddleName,
  validateDobObject,
  validateAddress
} from "./formErrors"

const ProfileForm = ({
  handleAttributeChange,
  handleAddressSelection,
  handleManualAddressInput,
  setIsValid,
  isClicked,
  formData,
  address,
  currentUser,
  setSaveAndExitDataFromState
}) => {
  const [dateOfBirth, setDateOfBirth] = useState({
    day: null,
    month: null,
    year: null
  })

  const [wealthSource, setWealthSource] = useState()
  const [validAddress, setValidAddress] = useState()
  const [showManualForm, setShowManualForm] = useState(address?.full_address?.includes("."))

  const [profileFormValidation, setProfileFormValidation] = useState({
    country: formData.country !== "ROTW" ? true : null,
    firstname: true,
    middle_name: true,
    surname: true,
    dob: false,
    gender: null,
    income_source: null,
    fund_source: "",
    wealth_description: null
  })

  const storeAndValidateDob = () => {
    if (validateDobObject(dateOfBirth)) {
      const dateStr = `${dateOfBirth.month}/${dateOfBirth.day}/${dateOfBirth.year}`
      const date = new Date(dateStr)
      handleAttributeChange({ name: "dob", value: date })
      setProfileFormValidation({ ...profileFormValidation, dob: true })
    } else {
      handleAttributeChange({ name: "dob", value: false })
      setProfileFormValidation({ ...profileFormValidation, dob: false })
    }
  }

  const isValid = () => {
    const isValid = Object.keys(profileFormValidation).every(k => profileFormValidation[k])
    setIsValid(isValid && validAddress)
  }

  useEffect(() => {
    isValid()
  }, [profileFormValidation, validAddress])

  useEffect(() => {
    setProfileFormValidation({ ...profileFormValidation, wealth_description:  wealthSource !== "other" })
  }, [wealthSource])

  const isFieldValid = (attribute, value) => {
    const fieldIsValid = {
      firstname: validName(value),
      middle_name: validMiddleName(value),
      fund_source: validDescription(value),
      surname: validName(value),
      country: validDropdown(value),
      gender: validDropdown(value),
      income_source: validDropdown(value),
      wealth_description: wealthSource === "other" ? validDescription(value) : true,
      dob: validDob(value)
    }[attribute]

    return fieldIsValid
  }

  useEffect(() => {
    if ((dateOfBirth.day && dateOfBirth.month && dateOfBirth.year)) {
      storeAndValidateDob()
      isValid()
    }
  }, [dateOfBirth])

  useEffect(() => {
    setSaveAndExitDataFromState()
    let validate = false
    if (formData.mapped_dob.day || formData.mapped_dob.month || formData.mapped_dob.year) {
      validate = true
      setDateOfBirth(formData.mapped_dob)
    }

    if (formData.income_source) {
      validate = true
      setWealthSource(formData.income_source)
    }
    if (formData.addressDetails?.full_address) {
      validate = true
      setValidAddress(validateAddress(formData.addressDetails.full_address))
    }

    if (validate) {
      setProfileFormValidation({
        country: validDropdown(formData.country),
        gender: validDropdown(formData.gender),
        firstname: validName(formData.firstname),
        middle_name: validMiddleName(formData.middle_name),
        surname: validName(formData.surname),
        dob: validateDobObject(formData.mapped_dob),
        income_source: validDropdown(formData.income_source),
        fund_source: validDescription(formData.fund_source),
        wealth_description: formData.wealthSource === "other" ? validDescription("") : true
      })
    }
  }, [])

  const storeAndValidateField = e => {
    const {
      value, name
    } = e.target

    handleAttributeChange({ name, value })

    setProfileFormValidation({ ...profileFormValidation, [e.target.name]: isFieldValid(name, value) })
  }

  let updatedCountryArray = bronIdCountryList.slice(2)
  if (["AU", "SG"].includes(currentUser.country)) {
    updatedCountryArray = currentUser.country === "AU" ? [{
      value: "AU",
      label: "Australia"
    }]
    : [{
      value: "SG",
      label: "Singapore"
    }]
  }
  return (
    <>
      <div className="text-center">
        <h2 className="mt-5 pt-4">Setup an account in your name</h2>
        <p>You can add an entity to your profile later on</p>
      </div>
      <div className="mt-5">
        <div className="form-group row mb-2">
          <div className="col-sm-6 col-form-label">Country</div>
          <div className="col-sm-6">
            <select
              className="form-control custom-select"
              defaultValue={formData.country !== "ROTW" ? formData.country : ""}
              autoComplete="disableAll"
              name="country"
              onBlur={storeAndValidateField}
              onChange={storeAndValidateField}>
              <option value="" disabled>Select</option>
              {updatedCountryArray.map(c => (
                <option
                  key={c.value}
                  value={c.value}>
                  {c.label}
                </option>
              ))}
            </select>
            {isClicked && !profileFormValidation.country && <ErrorMessage message="Please select a country" /> }
          </div>
        </div>
        <div className="form-group row mb-2">
          <div className="col-sm-6 col-form-label">First name (as per your photo ID)</div>
          <div className="col-sm-6">
            <input
              className="form-control"
              type="text"
              placeholder="First Name"
              defaultValue={formData.firstname}
              autoComplete="disableAll"
              onBlur={storeAndValidateField}
              name="firstname"
              onChange={storeAndValidateField} />
            {isClicked && !profileFormValidation.firstname && <ErrorMessage message="Please enter alphabetic characters, spaces, apostrophes, and hyphens only" /> }
          </div>
        </div>
        <div className="form-group row mb-2">
          <div className="col-sm-6 col-form-label">Middle name (optional)</div>
          <div className="col-sm-6">
            <input
              className="form-control"
              type="text"
              placeholder="Middle Name"
              defaultValue={formData.middle_name}
              autoComplete="disableAll"
              onBlur={storeAndValidateField}
              onChange={storeAndValidateField}
              name="middle_name" />
            {profileFormValidation.middle_name === false && <ErrorMessage message="Please enter alphabetic characters, spaces, apostrophes, and hyphens only" /> }
          </div>
        </div>
        <div className="form-group row mb-2">
          <div className="col-sm-6 col-form-label">Last name (as per your photo ID)</div>
          <div className="col-sm-6">
            <input
              className="form-control"
              type="text"
              placeholder="Last Name"
              defaultValue={formData.surname}
              autoComplete="disableAll"
              onBlur={storeAndValidateField}
              onChange={storeAndValidateField}
              name="surname" />
            {isClicked && !profileFormValidation.surname && <ErrorMessage message="Please enter alphabetic characters, spaces, apostrophes, and hyphens only" /> }
          </div>
        </div>
        <div className="form-group row mb-2">
          <div className="col-sm-6 col-form-label">Gender</div>
          <div
            className="col-sm-6">
            <select
              className="form-control custom-select"
              defaultValue={formData.gender ? formData.gender : ""}
              autoComplete="disableAll"
              name="gender"
              onBlur={storeAndValidateField}
              onChange={storeAndValidateField}>
              <option value="" disabled>Select</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
            {isClicked && !profileFormValidation.gender && <ErrorMessage message="Please select an option" /> }
          </div>
        </div>
        <div className="form-group row mb-2">
          <div className="col-sm-6 col-form-label">Date of Birth</div>
          <div className="col-sm-6">
            <div
              className="form-row">
              <div className="col-4">
                <input
                  className="form-control"
                  placeholder="DD"
                  onKeyDown={onlyNumbers}
                  type="number"
                  defaultValue={formData.mapped_dob.day}
                  autoComplete="disableAll"
                  min={1}
                  max={31}
                  name="day"
                  onChange={e => setDateOfBirth({ ...dateOfBirth, day: e.currentTarget.value })}
                  onBlur={e => setDateOfBirth({ ...dateOfBirth, day: e.currentTarget.value })} />
              </div>
              <div className="col-4">
                <input
                  className="form-control"
                  placeholder="MM"
                  onKeyDown={onlyNumbers}
                  type="number"
                  defaultValue={formData.mapped_dob.month}
                  autoComplete="disableAll"
                  min={1}
                  max={12}
                  name="month"
                  onChange={e => setDateOfBirth({ ...dateOfBirth, month: e.currentTarget.value })}
                  onBlur={e => setDateOfBirth({ ...dateOfBirth, month: e.currentTarget.value })} />
              </div>
              <div className="col-4">
                <input
                  className="form-control"
                  placeholder="YYYY"
                  onKeyDown={onlyNumbers}
                  type="number"
                  min={(new Date()).getUTCFullYear() - 100}
                  max={(new Date()).getUTCFullYear() - 18}
                  defaultValue={formData.mapped_dob.year}
                  autoComplete="disableAll"
                  onChange={e => setDateOfBirth({ ...dateOfBirth, year: e.currentTarget.value })}
                  onBlur={e => setDateOfBirth({ ...dateOfBirth, year: e.currentTarget.value })}
                  name="year" />
              </div>
            </div>
            {isClicked && profileFormValidation.dob === false && <ErrorMessage message="Must be a valid date over 18 years" /> }
          </div>
        </div>
        <div className="form-group row mb-2">
          <div className="col-sm-6 col-form-label">Source of Wealth</div>
          <div
            className="col-sm-6">
            <select
              defaultValue={formData.income_source || ""}
              autoComplete="disableAll"
              className="form-control custom-select"
              name="income_source"
              onBlur={storeAndValidateField}
              onChange={e => {
                storeAndValidateField(e)
                setWealthSource(e.target.value)
              }}>
              <option value="" disabled>Select</option>
              <option value="employment">Employment</option>
              <option value="inheritance_gift">Inheritance/Gift</option>
              <option value="super_pension">Superannuation/Pension</option>
              <option value="business">Business Activity</option>
              <option value="financial_investments">Financial Investments</option>
              <option value="other">Other (please specify)</option>
            </select>
            {isClicked && !profileFormValidation.income_source && <ErrorMessage message="Please select an option" /> }
          </div>
        </div>
        {wealthSource === "other" && (
        <div className="form-group mt-3">
          <label>If your source of wealth is not in the above list, please provide a description</label>
          <input
            className="form-control"
            type="text"
            placeholder="Description"
            defaultValue={formData.wealth_description}
            autoComplete="disableAll"
            name="wealth_description"
            onBlur={storeAndValidateField}
            onChange={storeAndValidateField} />
          {isClicked && !profileFormValidation.wealth_description && <ErrorMessage message="A description must be provided if other is chosen" /> }
        </div>
          )}
        <div className="form-group row mb-2">
          <div className="col-sm-6 col-form-label">Source of funds</div>
          <div className="col-sm-6">
            <input
              className="form-control"
              type="text"
              placeholder="Funds Source"
              defaultValue={formData.fund_source}
              autoComplete="disableAll"
              onBlur={storeAndValidateField}
              onChange={storeAndValidateField}
              name="fund_source" />
            {isClicked && !profileFormValidation.fund_source && <ErrorMessage message="A description must be provided" /> }
            <small>
              <p className="mt-2">
                Where will you remit money from, for example 'CBA account in name of John Smith'; or 'DBS account in name of Daphne Ng'. We can only accept funds from an account in the name of the account holder.
              </p>
            </small>
          </div>
        </div>
        <div className="form-group mt-3">
          <label>Address</label>
          {!showManualForm && (
            <>
              <AddressAutocomplete
                placeholder="Address"
                inputClass="form-control"
                onChangeHandler={() => setValidAddress(false)}
                address={address}
                handleAddressSelection={e => {
                  handleAddressSelection(e)
                  setValidAddress(true)
                }}
                elmName="address"
                onBlur={() => setValidAddress(!!validAddress)} />
              {isClicked && !validAddress && <ErrorMessage message="Please select a valid address" /> }
            </>
          )}
        </div>
        <button
          className="btn btn-outlined-main mt-3"
          type="button"
          onClick={() => setShowManualForm(!showManualForm)}>
          {showManualForm ? "Go back to address search" : "Cannot find your address? Click here"}
        </button>
        {showManualForm && (
          <ManualAddressForm
            setValidAddress={setValidAddress}
            handleManualAddressInput={handleManualAddressInput}
            address={address} />
        )}
      </div>
    </>
  )
}

export default ProfileForm
