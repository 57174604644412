/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React from "react"

const Timeline = ({ status, title, date }) => {
  // let statusClasses

  // if (status === "green") {
  //   statusClasses = "bg-dark-green"
  // } else if (status === "red") {
  //   statusClasses = "bg-red"
  // }

  return (
    <tr>
      {/* {status && <td className="status"><div className={`circle ${statusClasses}`} /></td>} */}
      <td className="p-0">{title}</td>
      <td className="p-0">{date}</td>
    </tr>
  )
}

export default Timeline
