import React, { useState, useEffect } from "react"

import Dropzone from "../Dropzone"
import ErrorMessage from "./ErrorMessage"

export default function LicenceForm({
  handleIdChange,
  updateValidId,
  formData: {
    card_number, number, front_image,
    back_image, state
  }
}) {
  const [licenceFieldValidation, setLicenceFieldValidation] = useState({
    state: undefined,
    number: undefined,
    card_number: undefined,
  })

  const [validFrontImage, setValidFrontImage] = useState(!!front_image?.name)
  const [validBackImage, setValidBackImage] = useState(!!back_image?.name)

  const hasExistingFrontImage = !!front_image?.name
  const hasExistingBackImage = !!back_image?.name

  const isValid = () => {
    const isValid = Object.keys(licenceFieldValidation).every(k => licenceFieldValidation[k])
    updateValidId(isValid && validFrontImage && validBackImage)
  }

  useEffect(() => {
    if (number || state || card_number) {
      setLicenceFieldValidation({
        ...licenceFieldValidation, state: !!state, number: !!number, card_number: !!card_number
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    isValid()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licenceFieldValidation, validFrontImage, validBackImage])

  const isFieldValid = value => (
    !!value
  )

  const validateField = e => {
    setLicenceFieldValidation({ ...licenceFieldValidation, [e.target.name]: isFieldValid(e.target.value) })
  }

  const handleFrontDropZoneBlur = e => {
    const image = e.currentTarget.querySelector('[type="file"]').value
    setValidFrontImage(hasExistingFrontImage || !!image)
  }

  const handleBackDropZoneBlur = e => {
    const image = e.currentTarget.querySelector('[type="file"]').value
    setValidBackImage(hasExistingBackImage || !!image)
  }

  return (
    <>
      <div className="form-group row mb-2">
        <div className="col-sm-6 col-form-label">State</div>
        <div className="col-sm-6">
          <select
            defaultValue={state === undefined ? "" : state}
            autoComplete="disableAll"
            className="form-control"
            name="state"
            onBlur={validateField}
            onChange={e => {
              validateField(e)
              handleIdChange({ name: e.currentTarget.name, value: e.currentTarget.value })
            }}>
            <option value="" disabled>Select</option>
            <option value="ACT">ACT</option>
            <option value="NSW">NSW</option>
            <option value="NT">Northern Territory</option>
            <option value="QLD">Queensland</option>
            <option value="SA">South Australia</option>
            <option value="TAS">Tasmania</option>
            <option value="VIC">Victoria</option>
            <option value="WA">Western Australia</option>
          </select>
          {licenceFieldValidation.state === false && <ErrorMessage message="Please select a state" />}
        </div>
      </div>
      <div className="form-group row mb-2">
        <div className="col-sm-6 col-form-label">Driver Licence No.</div>
        <div className="col-sm-6">
          <input
            className="form-control"
            placeholder="e.g. 12345673"
            defaultValue={number}
            autoComplete="disableAll"
            type="numeric"
            name="number"
            onBlur={e => validateField(e)}
            onChange={e => {
              validateField(e)
              handleIdChange({ name: e.currentTarget.name, value: e.currentTarget.value })
            }} />
          {licenceFieldValidation.number === false && <ErrorMessage message="Please provide a valid number" />}
        </div>
      </div>
      <div className="form-group row mb-2">
        <div className="col-sm-6 col-form-label">Card No.</div>
        <div className="col-sm-6">
          <input
            className="form-control"
            placeholder="e.g. 12345673"
            defaultValue={card_number}
            autoComplete="disableAll"
            type="numeric"
            name="card_number"
            onBlur={e => validateField(e)}
            onChange={e => {
              validateField(e)
              handleIdChange({ name: e.currentTarget.name, value: e.currentTarget.value })
            }} />
          {licenceFieldValidation.number === false && <ErrorMessage message="Please provide a valid number" />}
        </div>
      </div>
      <div className="form-group mt-3" onBlur={handleFrontDropZoneBlur}>
        <label>Front Image (JPG/PNG/PDF)</label>
        <Dropzone
          wrapperClass="drag-drop"
          inactiveText={front_image?.name || "Drag and drop the file or click to Browse Files"}
          activeText="Drop your file here"
          multiple={false}
          defaultValue={front_image}
          name="front_image"
          fileTypes="image/jpeg, image/png, application/pdf"
          fileTypeNames=".jpeg .png .pdf"
          handleFileUpload={e => {
            handleIdChange(e)
            setValidFrontImage(hasExistingFrontImage || !!e.value)
          }} />
        {validFrontImage === false && <ErrorMessage message="Please provide a front image of your licence" />}
      </div>
      <div className="form-group mt-3" onBlur={handleBackDropZoneBlur}>
        <label>Back Image (JPG/PNG/PDF)</label>
        <Dropzone
          wrapperClass="drag-drop"
          inactiveText={back_image?.name || "Drag and drop the file or click to Browse Files"}
          activeText="Drop your file here"
          multiple={false}
          defaultValue={back_image}
          name="back_image"
          fileTypes="image/jpeg, image/png, application/pdf"
          fileTypeNames=".jpeg .png .pdf"
          handleFileUpload={e => {
            handleIdChange(e)
            setValidBackImage(hasExistingBackImage || !!e.value)
          }} />
        {validBackImage === false && <ErrorMessage message="Please provide a back image of your licence" />}
      </div>
    </>
  )
}