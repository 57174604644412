import React from "react"
import DeleteUserModal from "./DeleteUserModal"

const PersonalDetailEdit = ({ user }) => (
  <>
    <div className="card-body">
      <form id="editDetails">
        <table className="table table-sm table-borderless backend-info">
          <tbody>
            <tr>
              <td>First Name</td>
              <td><input className="form-control" name="firstname" type="text" defaultValue={user.firstname} /></td>
            </tr>
            <tr>
              <td>Middle Name</td>
              <td><input className="form-control" name="middle_name" type="text" defaultValue={user.middle_name} /></td>
            </tr>
            <tr>
              <td>Last Name</td>
              <td><input className="form-control" name="surname" type="text" defaultValue={user.surname} /></td>
            </tr>
            <tr>
              <td>Email</td>
              <td>
                <input className="form-control" name="email" type="email" defaultValue={user.email} />
              </td>
            </tr>
            <tr>
              <td>Mobile</td>
              <td>
                <input className="form-control" name="phone_number_attributes_id" type="hidden" defaultValue={user.phone_number?.id} />
                <input className="form-control" name="phone_number_attributes_phone_number" type="text" defaultValue={user.phone_number?.phone} />
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
    {/* <div className="card-footer">
      <a className="btn btn-main btn-sm mr-2" href="backend-entity-detail" onClick={onEditSavehandler}>Save</a>
      <a className="btn btn-secondary btn-sm mr-2" onClick={onEditCanenlhandler} href="/">
        Cancel
      </a>
    </div> */}
    <DeleteUserModal user={user} />
  </>
)

export default PersonalDetailEdit
