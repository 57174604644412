import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import _ from "lodash"
import { getAuditLogs } from "../../requesters"
import { getBaseUrl } from "../../reducers"
import AuditLogsTable from "./AuditLogsTable"

const Logs = ({ baseUrl }) => {
  const [auditLogs, setAuditLogs] = useState([])
  const [quantity, setQuantity] = useState(100)

  const fetchAuditLogs = () => {
    getAuditLogs(baseUrl, quantity).then(res => {
      const data = _.get(res, "response.json.data", [])
      setAuditLogs(data)
    })
  }

  const inputOnChange = e => {
    setQuantity(e.target.value)
  }

  useEffect(() => {
    fetchAuditLogs()
  }, [])

  return (
    <div className="container-fluid pt-4">
      <div className="d-flex justify-content-between align-items-center">
        <h3>Audit Logs</h3>
      </div>
      <div className="d-flex justify-content-start align-items-center">
        <h4>Fetching: </h4>
        <input
          className="form-control col-1"
          autoComplete="disableAll"
          type="number"
          maxLength={5}
          defaultValue={100}
          onChange={inputOnChange} />
        <button className="btn btn-secondary btn-sm mr-2" onClick={fetchAuditLogs} type="button">Fetch</button>
      </div>
      {!!auditLogs.length && <AuditLogsTable auditLogs={auditLogs} />}
    </div>
  )
}

const mapStateToProps = state => ({
  baseUrl: getBaseUrl(state)
})

export default connect(mapStateToProps)(Logs)
