import React from "react"
import Money from "components/Money"

const CancelledRow = ({ investment }) => (
  <tr>
    <td>{investment.offering.name}</td>
    <td className="text-right">
      <Money currency="$" amount={investment.amount} />
    </td>
    <td className="text-center target-date-status">
      <div className="bg-light">{investment.updated_at}</div>
    </td>
  </tr>
  )

export default CancelledRow
