import React, { useState, useEffect } from "react"
import { useToasts } from "react-toast-notifications"
import axios from "axios"

const ChangePasswordModal = () => {
  const [password, setPassword] = useState()
  const [passwordConfirmation, setPasswordConfirmation] = useState()
  const [currentPassword, setCurrentPassword] = useState()
  const [invalidPassword, setInvalidPassword] = useState(false)
  const [notMatchingPassword, setNotMatchingPassword] = useState(false)

  const validatePassword = value => value?.replace(/\s+/g, "").length >= 3

  const { addToast } = useToasts()

  useEffect(() => {
    const isPasswordValid = validatePassword(password)
    const isPasswordSame = password === passwordConfirmation
    setInvalidPassword(!isPasswordValid)
    setNotMatchingPassword(!isPasswordSame)
  }, [password, passwordConfirmation])

  function submitForm(e) {
    e.preventDefault()
    const path = "/users"
    const params = {
      user: {
        password,
        password_confirmation: passwordConfirmation,
        current_password: currentPassword
      }
    }

    async function updatePassword() {
      axios.patch(path, params)
        .then(response => {
          setPasswordConfirmation(undefined)
          setCurrentPassword(undefined)
          setPassword(undefined)
          if (response.status === 200) {
            addToast("Your password has been updated!", { appearance: "success" })
            document.getElementById("btnCloseModal").click()
          }
        })
        .catch(error => {
          addToast(error.message, { appearance: "error" })
          setPassword(undefined)
       })
    }

    if (!invalidPassword && !notMatchingPassword) updatePassword()
  }

  return (
    <div aria-labelledby="pendingCancelledPopup" className="modal fade" id="settingsChangePasswordModal" role="dialog" tabIndex={-1} style={{ display: "none" }} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header border-0 p-0"></div>
          <div className="modal-body">
            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true"> ×</span></button>
            <div className="row justify-content-center">
              <div className="col-lg-11">
                <h2 className="mt-4">Change Password</h2>
                <form onSubmit={submitForm}>
                  <div className="my-4">
                    <div className="form-group row mb-2">
                      <div className="col-sm-6 col-form-label">Current Password</div>
                      <div className="col-sm-6">
                        <input
                          className="form-control"
                          type="password"
                          onChange={e => setCurrentPassword(e.target.value)}
                          value={currentPassword || ""} />
                      </div>
                    </div>
                    <div className="form-group row mb-2">
                      <div className="col-sm-6 col-form-label">New Password</div>
                      <div className="col-sm-6">
                        <input
                          className="form-control"
                          type="password"
                          onChange={e => setPassword(e.target.value)}
                          value={password || ""} />
                        {password && invalidPassword && <div className="err-msg text-danger">invalid password</div> }
                      </div>
                    </div>
                    <div className="form-group row mb-2">
                      <div className="col-sm-6 col-form-label">New Password Confirmation</div>
                      <div className="col-sm-6">
                        <input
                          className="form-control"
                          type="password"
                          onChange={e => setPasswordConfirmation(e.target.value)}
                          value={passwordConfirmation || ""} />
                        {password && notMatchingPassword && <div className="err-msg text-danger">passwords need to match</div>}
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn btn-main py-3 px-5 mr-2"
                    type="submit">
                    Confirm
                  </button>
                  <button
                    className="btn btn-outlined-main py-3 px-5"
                    id="btnCloseModal"
                    data-dismiss="modal"
                    type="button">
                    Cancel
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="modal-footer border-0">
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangePasswordModal
