import React, { Component } from "react"
import { connect } from "react-redux"
import axios from "axios"

import CountrySelect from "./CountrySelect"
import GetStarted from "./GetStarted"
import InvestorType from "./InvestorType"

import { getCurrentUser } from "../../reducers"
import EmailVerification from "../VerificationForms/EmailVerification"

class RegisterForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isSubmitted: false,
      formData: {
        country: undefined,
        investor_type: undefined,
        firstname: undefined,
        surname: undefined,
        email: undefined,
        currentUserId: undefined,
        password: undefined,
        password_confirmation: undefined,
        role: "user",
        registration_status: "partial"
      }
    }
  }

  handleAttributeChange = event => {
    const { formData } = this.state
    const {
      name,
      value
    } = event

    formData[name] = value

    this.setState({ formData })
  }


  handleChangeCountry = event => {
    const { formData } = this.state
    const {
      name,
      value
    } = event

    formData[name] = value
    formData.investor_type = undefined

    this.setState({ formData })
  }

  handleSubmit = () => {
    const { formData } = this.state

    const path = "/users"

    const token = document.querySelector("[name=csrf-token]").content

    axios.defaults.headers.common["X-CSRF-TOKEN"] = token

    return axios.post(path, { user: formData })
      .then(res => this.setState({ isSubmitted: true, currentUserId: res.data.user_id, currentUserEmail: res.data.user_email }))
      .catch(error => (error))
  }

  render() {
    const {
      isSubmitted,
      formData,
      currentUserId,
      currentUserEmail,
      formData: {
        country,
        investor_type
      }
    } = this.state

    const {
      currentUser
    } = this.props

    // Might not be necessary but catches weird state where admin gets redirected to get-started instead of marketplace
    if (["complete"].includes(currentUser?.registration_status)) {
      window.location.href = "/marketplace"
    } else if (["phone_pending", "email_verified", "partial"].includes(currentUser?.registration_status)) {
      window.location.href = "/verification"
    }

    if (isSubmitted || currentUser?.registration_status === "partial") {
      return <EmailVerification currentUserId={currentUserId} currentUser={currentUser} currentUserEmail={currentUserEmail} />
    } if (country && investor_type) {
      return <GetStarted formData={formData} handleSubmit={this.handleSubmit} handleAttributeChange={this.handleAttributeChange} />
    } if (country) {
      return <InvestorType country={country} handleAttributeChange={this.handleAttributeChange} />
    }
    return <CountrySelect handleAttributeChange={this.handleChangeCountry} />
  }
}

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state)
})

export default connect(mapStateToProps)(RegisterForm)
