import React from "react"
import { formatWithComma } from "components/CommonMethods"

const Money = ({ currency, amount }) => (
  <span>
    {`${currency} ${formatWithComma(amount)}`}
  </span>
)

export default Money
