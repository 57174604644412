import React from "react"
import Money from "components/Money"

const Progress = ({ offering }) => {
  const percentageVal = offering.waitlist_active ? "100" : offering.progress.in_percentage
  const fundingEl = offering.waitlist_active ? (
    <div>{`${offering.total_deal_size} Fully Funded`}</div>
  ) : (
    <>
      <Money currency={offering.currency} amount={offering.progress.in_amount} />
      {` of ${offering.total_deal_size} Funded`}
    </>
  )
  return (
    <div className="funding-progress mb-4 pb-3">
      <div className="bg-light-main text-center p-3">
        {offering.is_offering_eoi ? (
          <>
            <div className="h2 text-main mb-0">
              TBA
            </div>
            <div className="text-lg">
              {`${offering.total_deal_size} to be Funded`}
            </div>
          </>
      ) : (
        <>
          <div className="h2 text-main mb-0">
            {percentageVal}
            %
          </div>
          <small className="text-muted">
            {fundingEl}
          </small>
        </>
      )}
      </div>
      <div className="progress">
        <div
          aria-valuemax={100}
          aria-valuemin={0}
          aria-valuenow={0}
          className="progress-bar"
          role="progressbar"
          style={{ width: `${offering.is_offering_eoi ? 0 : percentageVal}%` }} />
        {/* style={{ width: `${offering.is_offering_eoi ? 0 : offering.progress.in_percentage}%` }} /> */}
      </div>
    </div>
  )
}

export default Progress
