import React from "react"
import { useToasts } from "react-toast-notifications"
import { jsonToFormData, formatWithComma, tidyUpString } from "components/CommonMethods"
import { Link } from "react-router-dom"
import { cancelInvestment, updateInvestment } from "../../requesters"
import CancelInvestmentModal from "./CancelInvestmentModal"

const PendingRow = ({ reloadData, investment }) => {
  const { addToast } = useToasts()
  const isDeposited = investment.status === "deposited"

  const cancelInvestmentHandler = () => {
    cancelInvestment(investment.id).then(data => {
      const res = data.response.json
      if (res.success) {
        addToast("Investment has been cancelled", { appearance: "success" })
        reloadData()
      } else {
        addToast(res.message, { appearance: "error" })
      }
    })
  }

  const uploadInvestmentDoc = uploadedFile => {
    updateInvestment(investment.id, jsonToFormData({ doc: uploadedFile, status: "signed" }, "investment")).then(data => {
      const res = data.response.json
      if (res.success) {
        addToast("Document uploaded successfully.", { appearance: "success" })
        reloadData()
      } else {
        addToast(res.message, { appearance: "error" })
      }
    })
  }
  const uploadButtonClickHandler = () => {
    const elem = document.getElementById("investmentDoc")
    elem.value = ""
    elem.click()
  }

  return (
    <div className="d-lg-table-row table-item border-bottom">
      <Link className="d-lg-content table-link-wrapper" to={`/marketplace/${investment.offering_id}`} onClick={() => { window.location.href = `/marketplace/${investment.offering_id}` }}>
        <div className="d-flex justify-content-between d-lg-content">
          <div className="yt-td d-lg-table-cell">{investment.offering_name}</div>
          <div className="yt-td d-lg-table-cell text-lg-right">
            $
            {formatWithComma(investment.investment)}
          </div>
        </div>

        <div className="yt-td d-lg-table-cell text-lg-center text-sub">
          {
          investment.document_url
          ? <div className="bg-dark-green">Done</div>
          : <div className="bg-light">{`Due ${investment.documentation_date}`}</div>
        }
        </div>
        <div className="yt-td d-lg-table-cell text-lg-center text-sub">
          {
          investment.status === "deposited"
          ? <div className="bg-dark-green">Done</div>
          : <div className="bg-light">{`Due ${investment.documentation_date}`}</div>
        }
        </div>
        <div className="yt-td d-lg-table-cell text-lg-center mt-2 mt-lg-0">
          <div>{tidyUpString(investment.status)}</div>
        </div>
        <div className="yt-td d-lg-table-cell text-lg-center mt-2 mt-lg-0">
          <div>{investment.issue_date}</div>
        </div>
      </Link>
      <div className="yt-td d-lg-table-cell text-lg-right">
        <div className="d-flex justify-content-lg-end align-items-center">
          Ref#
          {" "}
          {investment.reference_no}
          <div className="dropdown ml-2">
            <button type="button" className="btn btn-sm btn-outlined-main dropdown-toggle" data-toggle="dropdown" id="dropdownMenuLink" />
            <div
              aria-labelledby="dropdownMenuLink"
              className="dropdown-menu dropdown-menu-right"
              x-placement="bottom-end"
              style={{
                position: "absolute", willChange: "transform", top: 0, left: 0, transform: "translate3d(26px, 31px, 0px)"
              }}>
              {
                // To Do: might have to change this after changes from 384
                investment.status === "pending" && (
                  <>
                    <input className="hide" type="file" id="investmentDoc" name="doc" accept="application/pdf" onChange={e => uploadInvestmentDoc(e.target.files[0])} />
                    <button className="dropdown-item" type="button" onClick={uploadButtonClickHandler}> Upload Signed Subscription Document</button>
                  </>
                )
              }
              {
                !investment.document_url && investment.subscription_doc_url && <a className="dropdown-item" rel="noreferrer" target="_blank" href={investment.subscription_doc_url}>Download Subscription Document</a>
              }
              {
                investment.document_url && <a className="dropdown-item" rel="noreferrer" target="_blank" href={investment.document_url}>Download Signed Subscription Document</a>
              }
              {investment.im_doc_url && investment.status !== "waiting" && <a className="dropdown-item" rel="noreferrer" target="_blank" href={investment.im_doc_url}> Download IM</a>}
              {
                !isDeposited && (
                  <button className="dropdown-item" type="button" data-target="#cancelPendingInvestModal" data-toggle="modal">Cancel Investment</button>
                )
              }
            </div>
          </div>
        </div>
      </div>
      {
       !isDeposited && <CancelInvestmentModal cancelInvestmentHandler={cancelInvestmentHandler} dataTarget="cancelPendingInvestModal" />
      }
    </div>
  )
}

export default PendingRow
