import React, { useState, useEffect } from "react"

import Dropzone from "../Dropzone"
import ErrorMessage from "./ErrorMessage"

export default function NriCardForm({
  handleIdChange,
  updateValidId,
  formData: {
    number, front_image,
    back_image
  }
}) {
  const [nriCardFieldValidation, setLicenceFieldValidation] = useState({
    number: undefined
  })

  const [validFrontImage, setValidFrontImage] = useState(!!front_image?.name)
  const [validBackImage, setValidBackImage] = useState(!!back_image?.name)

  const hasExistingFrontImage = !!front_image?.name
  const hasExistingBackImage = !!back_image?.name

  const isValid = () => {
    const isValid = Object.keys(nriCardFieldValidation).every(k => nriCardFieldValidation[k])
    updateValidId(isValid && validFrontImage && validBackImage)
  }

  useEffect(() => {
    if (number) {
      setLicenceFieldValidation({ ...nriCardFieldValidation, number: !!number })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    isValid()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nriCardFieldValidation, validFrontImage, validBackImage])

  const isFieldValid = value => {
    const validName = /^[a-zA-Z0-9]{9}$/i
    return validName.test(String(value))
  }

  const validateField = e => {
    setLicenceFieldValidation({ ...nriCardFieldValidation, [e.target.name]: isFieldValid(e.target.value) })
  }

  const handleFrontDropZoneBlur = e => {
    const image = e.currentTarget.querySelector('[type="file"]').value
    setValidFrontImage(hasExistingFrontImage || !!image)
  }

  const handleBackDropZoneBlur = e => {
    const image = e.currentTarget.querySelector('[type="file"]').value
    setValidBackImage(hasExistingBackImage || !!image)
  }

  return (
    <>
      <div className="form-group row mb-2">
        <div className="col-sm-6 col-form-label">NRIC Number</div>
        <div className="col-sm-6">
          <input
            className="form-control"
            placeholder="e.g. 123456734"
            defaultValue={number}
            autoComplete="disableAll"
            type="numeric"
            name="number"
            onBlur={e => validateField(e)}
            onChange={e => {
              validateField(e)
              handleIdChange({ name: e.currentTarget.name, value: e.currentTarget.value })
            }} />
          {nriCardFieldValidation.number === false && <ErrorMessage message="Please provide a valid NRIC number" />}
        </div>
      </div>
      <div className="form-group mt-3" onBlur={handleFrontDropZoneBlur}>
        <label>Front Image (JPG/PNG/PDF)</label>
        <Dropzone
          wrapperClass="drag-drop"
          inactiveText={front_image?.name || "Drag and drop the file or click to Browse Files"}
          activeText="Drop your file here"
          multiple={false}
          defaultValue={front_image}
          name="front_image"
          fileTypes="image/jpeg, image/png, application/pdf"
          fileTypeNames=".jpeg .png .pdf"
          handleFileUpload={e => {
            handleIdChange(e)
            setValidFrontImage(hasExistingFrontImage || !!e.value)
          }} />
        {validFrontImage === false && <ErrorMessage message="Please provide a front image of your NRIC" />}
      </div>
      <div className="form-group mt-3" onBlur={handleBackDropZoneBlur}>
        <label>Back Image (JPG/PNG/PDF)</label>
        <Dropzone
          wrapperClass="drag-drop"
          inactiveText={back_image?.name || "Drag and drop the file or click to Browse Files"}
          activeText="Drop your file here"
          multiple={false}
          defaultValue={back_image}
          name="back_image"
          fileTypes="image/jpeg, image/png, application/pdf"
          fileTypeNames=".jpeg .png .pdf"
          handleFileUpload={e => {
            handleIdChange(e)
            setValidBackImage(hasExistingBackImage || !!e.value)
          }} />
        {validBackImage === false && <ErrorMessage message="Please provide a back image of your NRIC" />}
      </div>
    </>
  )
}
