import React from "react"
import { useToasts } from "react-toast-notifications"
import { cancelInvestment } from "../../requesters"
import CancelInvestmentModal from "./CancelInvestmentModal"

const WaitlistMobileModal = ({ waitlistInvestments, reloadData }) => {
  const { addToast } = useToasts()

  const cancelInvestmentHandler = investment => {
    cancelInvestment(investment.id).then(data => {
      const res = data.response.json
      if (res.success) {
        addToast("Investment has been cancelled", { appearance: "success" })
        reloadData()
      } else {
        addToast(res.message, { appearance: "error" })
      }
    })
  }

  return (
    <div aria-hidden="true" aria-labelledby="exampleModalLabel" className="modal fade right" id="modalWaitlist" tabIndex="-1">
      <div className="modal-dialog modal-full">
        <div className="modal-content">
          <div className="modal-header border-bottom-0"><button aria-label="Close" className="close ml-1 pl-0" data-dismiss="modal" type="button"><span aria-hidden="true"><img src="/assets/icons/icn_back-55141d6f1b87a5f930857dbe6d182084394d28e6c19718dc01e62ea5b8e12a00.svg" /></span></button></div>
          <div className="modal-body">
            <h3 className="mb-3">Waitlist</h3>
            <div className="d-lg-table w-100 yt-table table">
              <div className="d-lg-table-row d-none">
                <div className="yt-th d-lg-table-cell">Offering</div>
                <div className="yt-th d-lg-table-cell text-right">Amount</div>
                <div className="yt-th d-lg-table-cell text-right">Closing Date</div>
                <div className="yt-th d-lg-table-cell"></div>
              </div>
              {waitlistInvestments.map(investment => {
                const isExpired = investment.status === "expired"
                return (
                  <div className="d-lg-table-row table-item border-bottom" key={`waitlist-row-${investment.id}`}>
                    <div className="d-flex justify-content-between d-lg-content">
                      <div className="yt-td d-lg-table-cell">{investment.offering_name}</div>
                      <div className="yt-td d-lg-table-cell text-lg-right">
                        {isExpired ? "Expired" : `$${investment.investment}`}
                      </div>
                    </div>
                    <div className="yt-td d-lg-table-cell text-lg-right text-sub">
                      <span>{isExpired ? `Closed ${investment.closing_date}` : `Closes ${investment.closing_date}`}</span>
                    </div>
                    <div
                      className="yt-td d-lg-table-cell text-lg-right">
                      <div
                        className="btn btn-sm btn-outlined-main mt-2 mt-lg-0"
                        data-target={`#waitlist-delete-${investment.id}`}
                        data-toggle="modal">
                        {isExpired ? "Cancel" : "Cancel Waitlist"}
                      </div>
                    </div>
                    <CancelInvestmentModal
                      cancelInvestmentHandler={() => cancelInvestmentHandler(investment)}
                      dataTarget={`waitlist-delete-${investment.id}`} />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WaitlistMobileModal
