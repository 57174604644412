import React, { useState, useEffect } from "react"
// import dayjs from "dayjs"
import { formatWithComma } from "components/CommonMethods"
import { getYieldBatchDetail } from "../../requesters"

const Batches = ({ batch, currency }) => {
  const [currentBatch, setCurrentBatch] = useState({})

  const getBatch = () => {
  getYieldBatchDetail(batch.id).then(data => {
    const batch = data.response.json.data
    setCurrentBatch(batch)
  })
}

  useEffect(() => {
    getBatch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

   return (
     <tbody>
       <tr>
         <td>
           {currentBatch.date}
         </td>
         <td>
           {currentBatch.batch_id}
         </td>
         <td>{currentBatch.status}</td>
         <td>{currentBatch.admin_user}</td>
         <td className="text-right">
           {currency}
           {" "}
           {formatWithComma(currentBatch.total_interest)}
         </td>
         <td className="text-right">
           {currency}
           {" "}
           {formatWithComma(currentBatch.total_principal)}
         </td>
         <td className="text-right">
           {currency}
           {" "}
           {formatWithComma(currentBatch.total_other)}
         </td>
       </tr>
     </tbody>
    )
  }


export default Batches
