import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter, Switch, Route } from "react-router-dom"
import { ToastProvider } from "react-toast-notifications"
import ProtectedRoute from "components/utils/ProtectedRoute"
import Settings from "./Accounts/Settings"
import AccountsBase from "./Accounts/AccountsBase"
import PastInvestmentList from "./Accounts/PastInvestmentList"
import Banking from "./Accounts/Banking"
import AddBanking from "./Accounts/AddBanking"
import Transactions from "./Accounts/Transactions"

import * as reducers from "../reducers"

class Main extends Component {
  render() {
    const {
      currentUser
    } = this.props

    return (
      <ToastProvider autoDismiss>
        <Switch>
          <ProtectedRoute
            exact
            currentUser={currentUser}
            path="/account"
            redirectedRoute="/login"
            component={() => <AccountsBase {...this.props} />} />
          <Route
            exact
            path="/account/past-investments"
            render={props => <PastInvestmentList {...this.props} {...props} />} />
          <Route
            exact
            path="/account/banking"
            render={props => <Banking {...this.props} {...props} />} />
          <Route
            exact
            path="/account/banking/add"
            render={props => <AddBanking {...this.props} {...props} />} />
          <Route
            exact
            path="/account/transactions"
            render={props => <Transactions {...this.props} {...props} />} />
          <Route
            exact
            path="/account/settings"
            render={props => <Settings {...this.props} {...props} />} />
        </Switch>
      </ToastProvider>
    )
  }
}

const mapStateToProps = state => ({
  baseUrl: reducers.getBaseUrl(state),
  currentUser: reducers.getCurrentUser(state),
  currentEntity: reducers.getCurrentEntity(state)
})

export default withRouter(connect(mapStateToProps)(Main))
