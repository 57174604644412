import React, { useState } from "react"
import { useToasts } from "react-toast-notifications"
import Money from "components/Money"
import { onlyNumbers } from "components/CommonMethods"
import { addInvestment } from "../../../requesters"

const OfflinePaymentsModal = ({ offering, reloadData, reloadOffering }) => {
  const [investmentAmount, setInvestedAmount] = useState(offering.need_fixed_investment ? offering.min_investment : 0)

  const { addToast } = useToasts()

  const investButtonClickHandler = () => {
      addInvestment(offering.id, investmentAmount).then(data => {
        const res = data.response.json
        if (res.success) {
          addToast("Congratulations! Your investment has been added.", { appearance: "success" })
        } else {
          addToast(res.message, { appearance: "error" })
        }
        reloadData()
        reloadOffering()
      })
    }

  return (
    <div aria-labelledby="pendingCancelledPopup" className="modal fade" id="offlinePaymentsModal" role="dialog" tabIndex={-1} style={{ display: "none" }} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header border-0">
            <button aria-label="Close" className="close" data-dismiss="modal" type="button">
              <span aria-hidden="true"> ×</span>
            </button>
          </div>
          <div className="modal-body pt-0">
            <h3 className="modal-title mb-3">Add External Investment</h3>
            <p className="text-muted">Enter the amount invested.</p>
            <p>
              <Money currency={offering.currency} amount={offering.min_investment} />
            </p>
            <p>
              <Money currency={offering.currency} amount={offering.remaining_investment} />
            </p>
            <div className="strong mb-2">Confirm Amount</div>
            <div className="input-group mr-2 w-75">
              <div className="input-group-prepend"><span className="input-group-text">{offering.currency}</span></div>
              <input
                className="form-control"
                type="number"
                onKeyDown={onlyNumbers}
                defaultValue={offering.need_fixed_investment ? offering.min_investment : undefined}
                readOnly={offering.need_fixed_investment}
                placeholder={offering.min_investment}
                autoComplete="disableAll"
                min={0}
                onChange={e => setInvestedAmount(parseFloat(e.target.value))} />
            </div>
          </div>
          <div className="modal-footer border-0">
            <button
              className="btn btn-main"
              id="btnCloseModal"
              data-dismiss="modal"
              type="submit"
              disabled={!investmentAmount}
              onClick={investButtonClickHandler}>
              Confirm
            </button>
            <button className="btn btn-secondary" id="btnCloseModal" data-dismiss="modal" type="button">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default OfflinePaymentsModal
