import React, { useState } from "react"
import { useToasts } from "react-toast-notifications"
import { updateUserProfile } from "../../../requesters"
import Passport from "./IdentificationType/Passport"
import NriCard from "./IdentificationType/NriCard"
import DriverLicence from "./IdentificationType/DriverLicence"

const IdEdit = ({
  baseUrl, user, setIsIdEditable, refetchUserDetail
}) => {
  const [idType, setIdType] = useState(user.identification_documents[0]?.document_type)
  const { addToast } = useToasts()

  const onSaveHandler = details => {
    updateUserProfile(baseUrl, user.id, details).then(data => {
      const res = data.response.json
      if (res.success) {
        refetchUserDetail()
        setIsIdEditable(false)
        addToast("User detail updated successfully.", { appearance: "success" })
      } else {
        addToast(res.message, { appearance: "error" })
      }
    })
  }

  const IdElement = () => {
    switch (idType) {
      case "Passport":
        return (
          <Passport
            user={user}
            doc={user.identification_documents[0]}
            handleSave={details => onSaveHandler(details)}
            handleCancel={() => setIsIdEditable(false)} />
        )
      case "NriCard":
        return (
          <NriCard
            user={user}
            doc={user.identification_documents[0]}
            handleSave={details => onSaveHandler(details)}
            handleCancel={() => setIsIdEditable(false)} />
        )
      case "DriverLicence":
        return (
          <DriverLicence
            user={user}
            doc={user.identification_documents[0]}
            handleSave={details => onSaveHandler(details)}
            handleCancel={() => setIsIdEditable(false)} />
        )
      default:
        return <div />
    }
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <strong>ID Document 1</strong>
      </div>
      <table className="table table-sm table-borderless backend-info">
        <tbody>
          <tr>
            <td>ID Type</td>
            <td className="p-0">
              <select
                defaultValue={idType || ""}
                autoComplete="disableAll"
                className="form-control custom-select"
                name="sgd_value"
                onChange={e => setIdType(e.target.value)}>
                <option value="">Select</option>
                <option value="Passport">Passport</option>
                {user.country === "SG" && <option value="NriCard">NRIC</option>}
                {user.country === "AU" && <option value="DriverLicence">Driver Licence</option>}
              </select>
            </td>
          </tr>
          <IdElement />
        </tbody>
      </table>
    </>
  )
}

export default IdEdit
