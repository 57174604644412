/* eslint-disable no-unused-expressions */
import axios from "axios"
import normalize from "json-api-normalizer"

export const generateTwoFactor = formData => {
  const path = `/api/users/generate_two_factor`
  return axios.post(path, formData)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const resendEmailConfirmation = (baseUrl, identifier) => {
  const path = `${baseUrl}/users/${identifier}/email_changed`
  return axios.post(path, {})
    .then(({ data, status, }) => ({ response: normalize(data), status }))
    .catch(error => { error })
}

export const updateUser = user => {
  const path = "/users"

  return axios.patch(path, { user })
    .then(({ data, status }) => ({ response: normalize(data), status }))
    .catch(error => { error })
}

export const addUserPhone = (baseUrl, identifier, phone) => {
  const path = `${baseUrl}/users/${identifier}/add_phone`

  return axios.post(path, { phone })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const verifyPin = (baseUrl, identifier, code) => {
  const path = `${baseUrl}/users/${identifier}/verify_pin`

  return axios.patch(path, { pin: code })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const resendConfirmationCode = (baseUrl, identifier) => {
  const path = `${baseUrl}/users/${identifier}/resend_pin`

  return axios.patch(path, {})
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const setupUserProfile = (baseUrl, identifier, userDetails) => {
  const path = `${baseUrl}/users/${identifier}/setup_profile`
  return axios.post(path, userDetails, { headers: { "Content-Type": "multipart/form-data" } })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const checkEmailAvailability = (baseUrl, emailAddress) => {
  const path = `${baseUrl}/users/email_availability`
  return axios.get(path, { params: { email: emailAddress } })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const getUserDetails = (baseUrl, identifier) => {
  const path = `${baseUrl}/users/${identifier}/details`
  return axios.get(path)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const createEntity = (baseUrl, identifier, formData) => {
  const path = `${baseUrl}/users/${identifier}/create_entity`
  return axios.post(path, { formData })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}
