import React from "react"

const TransactionMobileModal = ({ transactions }) => (
  <div aria-hidden="true" aria-labelledby="exampleModalLabel" className="modal fade right" id="modalTransaction" tabIndex="-1">
    <div className="modal-dialog modal-full">
      <div className="modal-content">
        <div className="modal-header border-bottom-0"><button aria-label="Close" className="close ml-1 pl-0" data-dismiss="modal" type="button"><span aria-hidden="true"><img src="/assets/icons/icn_back-55141d6f1b87a5f930857dbe6d182084394d28e6c19718dc01e62ea5b8e12a00.svg" /></span></button></div>
        <div className="modal-body">
          <h3 className="mb-3">Transactions</h3>
          <div className="d-lg-table w-100 yt-table table">
            <div className="d-lg-table-row d-none">
              <div className="yt-th d-lg-table-cell">Offering</div>
              <div className="yt-th d-lg-table-cell text-right">Amount</div>
              <div className="yt-th d-lg-table-cell text-right">Issue Date</div>
              <div className="yt-th d-lg-table-cell"></div>
            </div>
            {transactions.map(transaction => (
              <div className="d-lg-table-row table-item border-bottom" key={`transaction-${transaction.type}-${transaction.id}-mobile`}>
                <div className="d-flex justify-content-between d-lg-content">
                  <div className="yt-td d-lg-table-cell">{transaction.offering_name}</div>
                  <div className="yt-td d-lg-table-cell text-lg-right">
                    {transaction.type}
                  </div>
                </div>
                <div className="yt-td d-lg-table-cell text-lg-right text-sub">
                  $
                  {transaction.amount}
                  {" "}
                  {transaction.currency}
                </div>
              </div>
                ))
              }
          </div>
        </div>
      </div>
    </div>
  </div>
  )

export default TransactionMobileModal
