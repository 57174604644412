import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useToasts } from "react-toast-notifications"

import { jsonToFormData, onlyNumbers } from "components/CommonMethods"
import ErrorMessage from "components/VerificationForms/ErrorMessage"
import Dropzone from "components/Dropzone"

import { updateEntityInvestorCert } from "../../requesters/UserRequester"
import { getBaseUrl } from "../../reducers"

const AddWholesaleCertForm = ({ currentEntity, baseUrl }) => {
  const [file, setFile] = useState(undefined)
  const [selectedFileName, setSelectedFileName] = useState()
  const [issueDate, setIssueDate] = useState({
    day: undefined,
    month: undefined,
    year: undefined
  })
  const [invalidDate, setInvalidDate] = useState(true)
  const { addToast } = useToasts()

  const validateIssueDate = () => {
    let monthStr = issueDate.month
    let dayStr = issueDate.day
    // 333: issue with parsing single digit day/months on mobile Safari
    if (issueDate.month < 10 && issueDate.month[0] !== "0") {
      monthStr = `0${issueDate.month}`
    }
    if (issueDate.day < 10 && issueDate.day[0] !== "0") {
      dayStr = `0${issueDate.day}`
    }
    const date = new Date(`${issueDate.year}-${monthStr}-${dayStr}`)
    const today = new Date()
    const validDate = new Date()
    validDate.setMonth(validDate.getMonth() - 6) // Certificate needs to be issued in the last 6 months
    setInvalidDate(date < validDate || date > today)
  }

  useEffect(() => {
    if (!(issueDate.day === undefined && issueDate.month === undefined && issueDate.year === undefined)) { // Do not call at the beginning
      validateIssueDate(issueDate)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issueDate])

  const fileChangeHandler = e => {
    const file = e.value[0]
    setFile(file)
    setSelectedFileName(file.name)
  }

  const makeSubmitParams = () => {
    const data = {
      issue_date: `${issueDate.year}-${issueDate.month}-${issueDate.day}`,
      certificate_verified: false
    }
    if (file) {
      data.file = file
    }
    data.id = currentEntity.investor_certificate?.id
    return data
  }

  const updateUserDetails = data => {
    updateEntityInvestorCert(baseUrl, currentEntity.id, data).then(data => {
      const res = data?.response.json
      if (res?.success) {
        window.location.reload()
        addToast("User detail updated successfully.", { appearance: "success" })
      } else {
        addToast(res.message, { appearance: "error" })
      }
    })
  }

  const handleSave = () => {
    const data = makeSubmitParams()
    updateUserDetails(jsonToFormData(data, "user"))
  }

  return (
    <>
      <div className="mt-4">
        <div className="form-group mb-2">
          <div className="col-form-label">Issue Date</div>
          <div className="form-row">
            <div className="col-4 col-md-3">
              <input
                className="form-control"
                placeholder="DD"
                onKeyDown={onlyNumbers}
                type="number"
                min={1}
                max={31}
                autoComplete="disableAll"
                name="day"
                onBlur={validateIssueDate}
                onChange={e => {
                  setIssueDate({ ...issueDate, day: e.currentTarget.value })
                }} />
            </div>
            <div className="col-4 col-md-3">
              <input
                className="form-control"
                placeholder="MM"
                onKeyDown={onlyNumbers}
                type="number"
                min={1}
                max={12}
                autoComplete="disableAll"
                name="month"
                onBlur={validateIssueDate}
                onChange={e => {
                  setIssueDate({ ...issueDate, month: e.currentTarget.value })
                }} />
            </div>
            <div className="col-4 col-md-3">
              <input
                className="form-control"
                placeholder="YYYY"
                onKeyDown={onlyNumbers}
                type="number"
                autoComplete="disableAll"
                min={(new Date()).getUTCFullYear() - 1}
                max={(new Date()).getUTCFullYear()}
                name="year"
                onBlur={validateIssueDate}
                onChange={e => {
                  setIssueDate({ ...issueDate, year: e.currentTarget.value })
                }} />
            </div>
          </div>
        </div>
        <div className="form-group mb-2">
          {invalidDate && <ErrorMessage message="Certificate needs to be issued in the last 6 months" />}
        </div>
        <div className="form-group mt-4">
          <label>Please upload your current s708 certification so that we can review and confirm your investor status as soon as possible.</label>
          <Dropzone
            wrapperClass="drag-drop"
            inactiveText={selectedFileName?.name || "Drag and drop the file or click to Browse Files"}
            activeText="Drop your PDF here"
            multiple={false}
            name="doc_file"
            fileTypes="image/jpeg, image/png, application/pdf"
            fileTypeNames=".jpeg .png .pdf"
            handleFileUpload={e => {
              fileChangeHandler(e)
              setSelectedFileName(e.value)
            }} />
        </div>
        {!file && <ErrorMessage message="Please upload your current s708 certification" />}
      </div>
      <div className="modal-footer border-0 px-0">
        <button
          className="btn btn-main"
          id="btnCloseModal"
          data-dismiss="modal"
          type="button"
          disabled={invalidDate || !file || !issueDate.day || !issueDate.month || !issueDate.day}
          onClick={handleSave}>
          Confirm
        </button>
        <button
          className="btn btn-outlined-main"
          id="btnCloseWholesaleModal"
          data-dismiss="modal"
          type="button">
          Cancel
        </button>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  baseUrl: getBaseUrl(state)
})

export default connect(mapStateToProps)(AddWholesaleCertForm)