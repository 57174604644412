import React from "react"
import { useToasts } from "react-toast-notifications"
import Money from "components/Money"
import {
  confirmSign, confirmFund, cancelInvestment
} from "../../../requesters"

import CancelInvestmentModal from "../../common/CancelInvestmentModal"
import ConfirmFundModal from "../../common/ConfirmFundModal"

const PendingInvestmentRow = ({
 investment, offering, reloadData, setCurrentInvestment, reloadOffering
}) => {
  const { addToast } = useToasts()

  const confirmSignEventhandler = () => {
    confirmSign(investment.id).then(data => {
      const res = data.response.json
      if (res.success) {
        addToast("Fund confirmed successfully", { appearance: "success" })
        reloadData()
      } else {
        addToast(res.message, { appearance: "error" })
      }
    })
  }

  const confirmFundEventhandler = () => {
    confirmFund(investment.id).then(data => {
      const res = data.response.json
      if (res.success) {
        addToast("Fund confirmed successfully", { appearance: "success" })
        reloadData()
        reloadOffering()
      } else {
        addToast(res.message, { appearance: "error" })
      }
    })
  }

  const cancelInvestmentHandler = () => {
    cancelInvestment(investment.id).then(data => {
      const res = data.response.json
      if (res.success) {
        addToast("Investment has been cancelled", { appearance: "success" })
        reloadData()
        reloadOffering()
      } else {
        addToast(res.message, { appearance: "error" })
      }
    })
  }

  const resendHellosignHandler = () => {
    const elem = document.getElementById("investmentDoc")
    elem.value = ""
    elem.click()
  }

  return (
    <tr key={`investment-${investment.id}`}>
      <td>{investment.entity_name}</td>
      <td>{investment.entity_type}</td>
      <td>{ investment.entity_phone}</td>
      <td className="text-right">
        <Money currency="$" amount={investment.amount} />
      </td>
      <td className="text-center target-date-status">
        {
          investment.document_url
            ? <div className="bg-dark-green">Signed</div>
            : <div className="bg-light">{`Due ${investment.documentation_date}`}</div>
        }
      </td>
      <td className="text-center target-date-status">
        <div className="bg-light">
          {`Due ${investment.funding_date}`}
        </div>
      </td>
      <td className="text-right">
        <div className="d-flex justify-content-end">
          <div>
            {investment.waiting_rank !== null ? `Waitlist # ${investment.waiting_rank + 1}` : `Ref# ${investment.reference_no}`}
            {
              investment.status === "signed" && (
                <button
                  type="submit"
                  className="btn btn-sm btn-main ml-3"
                  onClick={confirmSignEventhandler}>
                  Confirm Signature
                </button>
              )
            }
            {
              investment.status === "signature_confirmed" && (
                <button
                  type="submit"
                  className="btn btn-sm btn-main ml-3"
                  data-target={`#confirmFundModal-${investment.id}`}
                  data-toggle="modal">
                  Confirm Fund
                </button>
              )
            }
          </div>
          <div className="dropdown ml-2">
            <button type="button" className="btn btn-sm btn-outlined-main dropdown-toggle" data-toggle="dropdown" id="dropdownMenuLink" />
            <div
              aria-labelledby="dropdownMenuLink"
              className="dropdown-menu dropdown-menu-right"
              x-placement="bottom-end"
              style={{
                position: "absolute", willChange: "transform", top: 0, left: 0, transform: "translate3d(26px, 31px, 0px)"
              }}>
              {
                investment.status === "pending" && (
                  <button className="dropdown-item" type="button" onClick={resendHellosignHandler}>Resend HelloSign request</button>
                )
              }
              {
                !investment.document_url && offering.subscription_doc && <a className="dropdown-item" rel="noreferrer" target="_blank" href={offering.subscription_doc}>Download Subscription Document</a>
              }
              {
                investment.document_url && <a className="dropdown-item" rel="noreferrer" target="_blank" href={investment.document_url}>Download Signed Subscription Document</a>
              }
              {offering.im_file && investment.status !== "waiting" && <a className="dropdown-item" rel="noreferrer" target="_blank" href={offering.im_file}> Download IM</a>}
              {investment.status === "waiting" && (
                <button
                  className="dropdown-item"
                  type="button"
                  data-target="#convertToSubModal"
                  data-toggle="modal"
                  onClick={() => setCurrentInvestment(investment)}>
                  {" "}
                  Convert to Subscribe
                </button>
              )}
              <button
                className="dropdown-item"
                type="button"
                data-target={`#cancelModal-${investment.id}`}
                data-toggle="modal">
                Cancel Investment
              </button>
            </div>
          </div>
        </div>
      </td>
      <CancelInvestmentModal cancelInvestmentHandler={cancelInvestmentHandler} investmentId={investment.id} />
      <ConfirmFundModal confirmFundEventhandler={confirmFundEventhandler} investmentId={investment.id} />
    </tr>
  )
}

export default PendingInvestmentRow