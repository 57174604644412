import React, { useEffect, useState } from "react"

const PersonalCommentEdit = ({ user, saveHandler }) => {
  const [referral, setReferral] = useState()
  const [accountManager, setAccountManager] = useState()
  const [generalNotes, setGeneralNotes] = useState()

  useEffect(() => {
    setReferral(user.referral)
    setAccountManager(user.account_manager)
    setGeneralNotes(user.general_notes)
  }, [])

  const handleUpdateComments = () => {
    saveHandler(referral, accountManager, generalNotes)
  }
  return (
    <>
      <tr>
        <td>Referral</td>
        <td>
          <input className="form-control" name="referral" type="text" defaultValue={referral || ""} onChange={e => setReferral(e.target.value)} />
        </td>
      </tr>
      <tr>
        <td>Account Manager</td>
        <td>
          <input className="form-control" name="account_manager" type="text" defaultValue={accountManager || ""} onChange={e => setAccountManager(e.target.value)} />
        </td>
      </tr>
      <tr>
        <td>General Notes</td>
        <td>
          <input className="form-control" name="general_notes" type="text" defaultValue={generalNotes || ""} onChange={e => setGeneralNotes(e.target.value)} />
        </td>
      </tr>
      <button type="button" className="btn btn-main btn-sm mr-2" onClick={handleUpdateComments}>Save Comments</button>
    </>
  )
}

export default PersonalCommentEdit
