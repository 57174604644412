import React, { useState } from "react"

const NewReturnModal = ({ issuedOfferings, setSelectedOffering, setSelectedReturn }) => {
  const [selected, setSelected] = useState()
  const handleNext = () => {
    // find the issued offering but be aware the stored id is an integer
    setSelectedOffering(issuedOfferings.find(i => `${i.id}` === selected))
    setSelectedReturn("create")
  }
  return (
    <div aria-labelledby="newYieldReturnsModalLabel" className="modal fade" id="newYieldReturnsModal" role="dialog" tabIndex={-1} style={{ display: "none" }} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header border-0">
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" id="btnCloseModal">
              <span aria-hidden="true"> ×</span>
            </button>
          </div>
          <div className="modal-body pt-0">
            <h3 className="modal-title mb-3">Select Deal</h3>
            <select className="form-control" onChange={e => setSelected(e.target.value)}>
              <option style={{ display: "none" }}>Select an Offering</option>
              {issuedOfferings.map(offering => <option value={offering.id} key={offering.id}>{offering.offering_name}</option>)}
            </select>
          </div>
          <div className="modal-footer border-0">
            <button className="btn btn-secondary" id="btnCloseModal" data-dismiss="modal" type="button">Cancel</button>
            <button
              className="btn btn-main"
              id="btnCloseModal"
              data-dismiss="modal"
              type="button"
              onClick={handleNext}
              disabled={!selected}>
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewReturnModal
