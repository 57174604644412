import React from "react"
import { useToasts } from "react-toast-notifications"
import { jsonToFormData } from "components/CommonMethods"
import { cancelInvestment, updateInvestment } from "../../requesters"
import CancelInvestmentModal from "./CancelInvestmentModal"

const PendingMobileModal = ({ reloadData, pendingInvestments }) => {
  const { addToast } = useToasts()

  const cancelInvestmentHandler = investment => {
    cancelInvestment(investment.id).then(data => {
      const res = data.response.json
      if (res.success) {
        addToast("Investment has been cancelled", { appearance: "success" })
        reloadData()
      } else {
        addToast(res.message, { appearance: "error" })
      }
    })
  }
  const uploadInvestmentDoc = (uploadedFile, investment) => {
    updateInvestment(investment.id, jsonToFormData({ doc: uploadedFile, status: "signed" }, "investment")).then(data => {
      const res = data.response.json
      if (res.success) {
        addToast("Document uploaded successfully.", { appearance: "success" })
        reloadData()
      } else {
        addToast(res.message, { appearance: "error" })
      }
    })
  }
  const uploadButtonClickHandler = () => {
    const elem = document.getElementById("investmentDoc")
    elem.value = ""
    elem.click()
  }

  return (
    <div aria-hidden="true" aria-labelledby="exampleModalLabel" className="modal fade right" id="modalPending" tabIndex="-1">
      <div className="modal-dialog modal-full">
        <div className="modal-content">
          <div className="modal-header border-bottom-0"><button aria-label="Close" className="close ml-1 pl-0" data-dismiss="modal" type="button"><span aria-hidden="true"><img src="/assets/icons/icn_back-55141d6f1b87a5f930857dbe6d182084394d28e6c19718dc01e62ea5b8e12a00.svg" /></span></button></div>
          <div className="modal-body">
            <h3 className="mb-3">Pending</h3>
            <p className="w-75">Your investment will be confirmed on issue date if funds have been deposited by target funding date.</p>
            <div className="d-lg-table w-100 yt-table table">
              <div className="d-lg-table-row d-none">
                <div className="yt-th d-lg-table-cell">Offering</div>
                <div className="yt-th d-lg-table-cell text-right">Amount</div>
                <div className="yt-th d-lg-table-cell text-center">Documentation</div>
                <div className="yt-th d-lg-table-cell text-center">Funding</div>
                <div className="yt-th d-lg-table-cell"></div>
              </div>
              {pendingInvestments.map(investment => (
                <div className="d-lg-table-row table-item border-bottom" key={`pending-row-${investment.id}`}>
                  <div className="d-flex justify-content-between d-lg-content">
                    <div className="yt-td d-lg-table-cell">{investment.offering_name}</div>
                    <div className="yt-td d-lg-table-cell text-lg-right">
                      $
                      {investment.investment}
                    </div>
                  </div>
                  <div className="d-flex d-lg-content align-items-center mt-2">
                    <div className="yt-td d-lg-table-cell text-center text-sub">
                      {
                      investment.document_url
                      ? <div className="bg-dark-green px-2">Signed</div>
                      : <div className="bg-light px-2">{`Documentation Due ${investment.documentation_date}`}</div>
                        }
                    </div>
                    <div className="yt-td d-lg-table-cell text-center text-sub ml-1">
                      {investment.subscription_doc_url
                        ? <div className="bg-dark-green px-2">Signed</div>
                        : <div className="bg-light px-2">{`Funds Due ${investment.funding_date}`}</div>
                      }
                    </div>
                  </div>
                  <div className="yt-td d-lg-table-cell text-lg-right">
                    <div className="d-flex justify-content-lg-end align-items-center">
                      Ref#
                      {" "}
                      {investment.reference_no}
                      <div className="dropdown ml-2">
                        <button type="button" className="btn btn-sm btn-outlined-main dropdown-toggle" data-toggle="dropdown" id="dropdownMenuLink" />
                        <div
                          aria-labelledby="dropdownMenuLink"
                          className="dropdown-menu dropdown-menu-right"
                          x-placement="bottom-end"
                          style={{
                            position: "absolute", willChange: "transform", top: 0, left: 0, transform: "translate3d(26px, 31px, 0px)"
                          }}>
                          {
                            investment.status === "pending" && (
                              <>
                                <input className="hide" type="file" id="investmentDoc" name="doc" accept="application/pdf" onChange={e => uploadInvestmentDoc(e.target.files[0])} />
                                <button className="dropdown-item" type="button" onClick={uploadButtonClickHandler}> Upload Signed Subscription Document</button>
                              </>
                            )
                          }
                          {
                            !investment.document_url && investment.subscription_doc_url && <a className="dropdown-item" rel="noreferrer" target="_blank" href={investment.subscription_doc_url}>Download Subscription Document</a>
                          }
                          {
                            investment.document_url && <a className="dropdown-item" rel="noreferrer" target="_blank" href={investment.document_url}>Download Signed Subscription Document</a>
                          }
                          {investment.im_doc_url && investment.status !== "waiting" && <a className="dropdown-item" rel="noreferrer" target="_blank" href={investment.im_doc_url}> Download IM</a>}

                          <button className="dropdown-item" type="button" data-target={`#pending-delete-${investment.id}`} data-toggle="modal">Cancel Investment</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <CancelInvestmentModal
                    cancelInvestmentHandler={() => cancelInvestmentHandler(investment)}
                    dataTarget={`pending-delete-${investment.id}`} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PendingMobileModal
