import startPortalApp from "apps/Portal"
import BaseController from "./base"

class Controller extends BaseController {
  call() {
    super.call()
    const rootElem = document.getElementById("portal-app")
    if (rootElem) {
      startPortalApp(rootElem)
    }
  }
}

export default Controller
