import React, { Fragment, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import featureFlags from "components/utils/FeatureFlags"
import { getOfferingList } from "../../requesters"
import Offering from "./Offering"
import ClosedOffering from "./ClosedOffering"
import UpcomingOffering from "./UpcomingOffering"
import { getCurrentUser, getCurrentEntity } from "../../reducers"
import FeaturedOfferingCard from "./FeaturedOfferingCard"

const OfferingList = () => {
  const [pastOfferings, setPastOfferings] = useState([])
  const [currentOfferings, setCurrentOfferings] = useState([])

  const currentUser = useSelector(getCurrentUser)
  const currentEntity = useSelector(getCurrentEntity)

  const reloadData = () => {
    getOfferingList().then(data => {
      const offerings = data.response.json.data
      if (featureFlags("STAGING")) {
        // eslint-disable-next-line no-console
        console.log("OFFERINGS>>>", offerings.map(ele => ({
          id: ele.id, name: ele.name, status: ele.status, sorting_index: ele.sorting_index
        })))
      }
      const current = []
      const past = []
      offerings.forEach(offering => {
        if (offering.sorting_index === 0) {
          current.push(offering)
        } else if (offering.status === "closed" || offering.status === "issued" || offering.status === "completed") {
          past.push(offering)
        } else if (offering.status === "upcoming") {
          current.push(offering)
        } else if (offering.status === "live") {
          current.push(offering)
        }
      })
      setPastOfferings(past)
      setCurrentOfferings(current)
    })
  }

  const redirectToDisplay = id => {
    window.location.href = `/marketplace/${id}`
  }

  useEffect(() => {
    reloadData()
  }, [])

  const showOfferings = currentEntity.country === "SG"
    ? currentUser.registration_status === "complete" && currentEntity.investor_certificate?.sg_certificate_verified
    : ["complete", "pending_approval", "waiting_background_check", "partial_submission", "phone_verified"].includes(currentUser.registration_status)

  const generateOfferingCard = offering => {
    if (offering.sorting_index === 0) {
      return <FeaturedOfferingCard offering={offering} redirectToDisplay={redirectToDisplay} />
    } if (offering.status === "upcoming") {
      return <UpcomingOffering offering={offering} redirectToDisplay={redirectToDisplay} />
    } if (offering.status === "live") {
      return <Offering offering={offering} redirectToDisplay={redirectToDisplay} />
    }
    return null
  }

  return (
    <div className="marketplace-index">
      <div className="container">
        <h1 className="mt-0 pt-5">Current Offerings</h1>
        {showOfferings ? (
          <>
            {
              !!currentOfferings.length && currentOfferings.map(offering => (
                <Fragment key={offering.id}>
                  {generateOfferingCard(offering)}
                </Fragment>
              ))
            }
          </>
        ) : (
          <div className="row">
            <div className="col-md-6">
              <p className="mt-0">You need to have your identity and accredited investor profile verified to see upcoming or live deals.</p>
            </div>
          </div>
          )
        }
      </div>
      <div className="container">
        {!!pastOfferings.length && <h1 className="mt-0 pt-5">Past Offerings</h1>}
        <div className="row">
          {!!pastOfferings.length && pastOfferings.map(offering => (<div className="col-lg-6" key={`past-offer-${offering.id}`}><ClosedOffering offering={offering} /></div>))}
        </div>
      </div>
    </div>
  )
}

export default OfferingList
