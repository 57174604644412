/* eslint-disable no-else-return */
import React from "react"
import { formatWithComma } from "components/CommonMethods"

const TransactionRow = ({ transaction }) => {
  const plusOrMinus = transaction => {
    if (transaction.type === "Funding") {
      return "+$"
    } else if (transaction.type === "Principal") {
      return "-$"
    } else {
      return "$"
    }
  }
  return (
    <div className="d-md-table-row table-item transaction-item border-bottom">
      <div className="d-flex d-md-content justify-content-between">
        <div className="d-flex d-md-content flex-column">
          <div className="yt-td d-md-table-cell p-0 p-md-3 border-bottom"><div className="text-muted">{transaction.date}</div></div>
          <div className="yt-td d-md-table-cell text-wrap p-0 p-md-3 border-bottom">
            <div className="text-truncate">{transaction.offering_name}</div>
            <small>{transaction.description}</small>
          </div>
        </div>
        <div className="yt-td d-table-cell text-right border-bottom">
          {`${plusOrMinus(transaction)} ${formatWithComma(transaction.amount)}`}
          {" "}
          {transaction.currency}
        </div>
      </div>
    </div>
  )
}

export default TransactionRow