export function formatWithComma(value) {
  return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0"
}

const buildFormData = (formData, data, parentKey) => {
  if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
    })
  } else {
    const value = data == null ? "" : data
    formData.append(parentKey, value)
  }
}

export function sortTransactionsDate(value) {
  value.sort((a, b) => {
    const dateA = new Date(a.date)
    const dateB = new Date(b.date)
    return dateB - dateA
  })
}

// Block special characters and e on input...
export const onlyNumbers = evt => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()

export const tidyUpString = str => {
  const splitStr = str.toLowerCase().split("_")
  const result = splitStr.map(string => (
    string.charAt(0).toUpperCase() + string.substring(1)
  ))
  return result.join(" ")
}

// Not used yet....
export function sumArray(value) {
  value.reduce((a, b) => a + b)
}

export function jsonToFormData(data, key) {
  const formData = new FormData()
  buildFormData(formData, data, key)
  return formData
}

export const getCurrentFinancialYear = () => {
  let fiscalyear = ""
  const today = new Date()
  if ((today.getMonth() + 1) <= 3) {
    fiscalyear = `${today.getFullYear() - 1}-07-01`
  } else {
    fiscalyear = `${today.getFullYear()}-07-01`
  }
  return fiscalyear
}

// Returns true if the current device is Mobile
export const isMobileDevice = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf("IEMobile") !== -1)
