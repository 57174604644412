import React, { useState, useEffect } from "react"
import { getUserDetails } from "../../requesters"
import EntityDetail from "./EntityDetail"
import EntityList from "./EntityList"


const Entities = () => {
  const [activePage, setActivePage] = useState("list")
  const [selectedEntity, setSelectedEntity] = useState()
  const [user, setUser] = useState(undefined)

  const loadUserDetail = () => {
    getUserDetails("/api", selectedEntity).then(data => {
      setUser(data.response.json.data)
      setActivePage("detail")
    })
  }

  useEffect(() => {
    if (selectedEntity) {
      loadUserDetail()
    } else {
      setActivePage("list")
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEntity])

  switch (activePage) {
    case "list":
      return <EntityList setEntity={setSelectedEntity} />
    case "detail":
      return (
        <EntityDetail
          initialEntity={user}
          setSelectedEntity={setSelectedEntity}
          refetchUserDetail={loadUserDetail} />
      )
    default:
      return <EntityList />
  }
}

export default Entities
