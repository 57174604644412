import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { useToasts } from "react-toast-notifications"
import Breadcrumb from "./Breadcrumb"
import {
 getUserDetails, sendResetPasswordEmail, updateUserProfile, updateUserNotes
} from "../../requesters"
import { getBaseUrl } from "../../reducers"
import InvestorDocDetail from "./InvestorDocDetail"
import PersonalDetail from "./PersonalDetail"
import Investments from "./Investments"
import EntityIdTaxDetail from "../common/IdTaxDetail/EntityIdTaxDetail"
import BankDetails from "./BankDetails"

const VIEW_DETAILS = 0

const EntityDetail = ({
  initialEntity, setSelectedEntity, baseUrl
}) => {
  const [user, setUser] = useState(initialEntity.user)
  const [entity, setEntity] = useState(initialEntity)
  const [editMode, setEditMode] = useState(VIEW_DETAILS)
  const { addToast } = useToasts()

  const loadUserDetail = () => {
    getUserDetails(baseUrl, entity.id).then(data => {
      setUser(data.response.json.data?.user)
      setEntity(data.response.json.data)
    })
  }

  const mapUserDetails = () => {
    const userDetails = {}
    document.querySelectorAll("#editDetails input").forEach(element => { userDetails[element.name] = element.value })
    userDetails.phone_number_attributes = {}
    userDetails.phone_number_attributes.id = userDetails.phone_number_attributes_id
    userDetails.phone_number_attributes.phone_number = userDetails.phone_number_attributes_phone_number
    delete userDetails.phone_number_attributes_phone_number
    delete userDetails.phone_number_attributes_id
    userDetails.id = user.id
    return userDetails
  }

  const updateUserDetails = data => {
    updateUserProfile(baseUrl, user.id, { user: data }).then(data => {
      const res = data.response.json
      if (res.success) {
        setEditMode(VIEW_DETAILS)
        loadUserDetail()
        addToast("User detail updated successfully.", { appearance: "success" })
      } else {
        addToast(res.message, { appearance: "error" })
      }
    })
  }

  const saveDetailsHandler = e => {
    e.preventDefault()
    updateUserDetails(mapUserDetails())
  }

  const saveCommentHandler = (referral, account_manager, general_notes) => {
    updateUserNotes(baseUrl, user.id, { referral, account_manager, general_notes }).then(data => {
      const res = data.response.json
      if (res.success) {
        setEditMode(VIEW_DETAILS)
        loadUserDetail()
        addToast("User detail updated successfully.", { appearance: "success" })
      } else {
        addToast(res.message, { appearance: "error" })
      }
    })
  }

  const investorEditSaveHandler = () => {
    loadUserDetail()
  }

  const onResetPasswordHandler = () => {
    sendResetPasswordEmail(baseUrl, user.id).then(() => {
      addToast("Reset password email has been sent.", { appearance: "success" })
    })
  }

  useEffect(() => {
    setEntity(initialEntity)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialEntity])

  const verifiedUser = entity.background_check_status === "verified"
  const verfiedWholesaleCert = entity.investor_certificate?.wholesale_certificate_verified
  const verfiedAiDoc = entity.investor_certificate?.sg_certificate_verified

  return (
    <div className="container">
      <Breadcrumb name={user.name} setEntity={setSelectedEntity} />
      <div className="row">
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              <h3><strong className={`text-main user-status-${user.general_status?.toLowerCase()?.replace(" ", "-")}`}>{ user.general_status}</strong></h3>
              <h2>{ entity.name}</h2>
              <div className="mb-3">
                { entity.country}
                <div className="text-muted">
                  Joined on &nbsp;
                  {user.joined_on}
                </div>
                <div className="text-muted">
                  {`Entity#${entity.id}`}
                </div>
              </div>
              <ul className="list-unstyled">
                <li className="my-2">
                  {entity.investments_count}
                  {" "}
                  Investments
                </li>
                <li className="my-2">
                  {entity.pending_investments_count}
                  {" "}
                  Pending
                </li>
                <li className="my-2">
                  {entity.waitlist_investments_count}
                  {" "}
                  Waitlist
                </li>
              </ul>
              <div className="dropdown-divider" />
              <strong>Verification</strong>
              <ul className="list-unstyled">
                {verifiedUser ? (
                  <li className="my-2">✔ Identity</li>
                ) : (
                  <li className="my-2">Identity Not Verified</li>
                )}
                {verfiedWholesaleCert ? (
                  <li className="my-2">✔ s708 Wholesale Certificate</li>
                ) : (
                  <li className="my-2">s708 Not Verified</li>
                )}
                {verfiedAiDoc ? (
                  <li className="my-2">✔ Accredited Investor</li>
                ) : (
                  <li className="my-2">AI Not Verified</li>
                )}
                <div className="dropdown-divider" />
                {user.fluid_user_id ? (
                  <li className="my-2">
                    ✔ User Signed up for Fluid (
                    {user.fluid_user_id}
                    )
                  </li>
                ) : (
                  <li className="my-2">User not signed up for Fluid</li>
                )}
                {entity.fluid_entity_id ? (
                  <li className="my-2">
                    ✔ Entity Signed up for Fluid (
                    {entity.fluid_entity_id}
                    )
                  </li>
                ) : (
                  <li className="my-2">Entity not signed up for Fluid</li>
                )}
              </ul>
              <hr />
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <div className="card mb-3">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                Account Information
              </div>
            </div>
            <PersonalDetail
              entity={entity}
              user={user}
              editMode={editMode}
              setEditMode={setEditMode}
              saveCommentHandler={saveCommentHandler}
              saveDetailsHandler={saveDetailsHandler}
              setSelectedEntity={setSelectedEntity}
              onResetPasswordHandler={onResetPasswordHandler} />
          </div>
          <EntityIdTaxDetail entity={entity} user={user} refetchUserDetail={loadUserDetail} />
          <BankDetails details={entity?.bank_details} />
          <InvestorDocDetail
            user={user}
            entity={entity}
            editSaveHandler={investorEditSaveHandler} />
        </div>
      </div>
      <Investments entity={entity} />
    </div>

  )
}

const mapStateToProps = state => ({
  baseUrl: getBaseUrl(state)
})

export default connect(mapStateToProps)(EntityDetail)