import React from "react"
import { NavLink } from "react-router-dom"

const Sidebar = () => {
  const clickHander = e => {
    e.preventDefault()
    window.location.href = e.target.getAttribute("href")
  }
  return (
    <nav className="col-md-2 col-xl-1 bg-light sidebar">
      <div className="sidebar-sticky">
        <ul className="nav flex-md-column flex-nowrap">
          <li className="nav-item">
            <NavLink exact className="nav-link" to="/backend/" onClick={clickHander}>Dashboard</NavLink>
          </li>
          <li className="nav-item">
            <NavLink exact className="nav-link" to="/backend/users" onClick={clickHander}>Onboarding</NavLink>
          </li>
          <li className="nav-item">
            <NavLink exact className="nav-link" to="/backend/entities" onClick={clickHander}>Entities</NavLink>
          </li>
          <li className="nav-item">
            <NavLink exact className="nav-link" to="/backend/offerings" onClick={clickHander}>Marketplace</NavLink>
          </li>
          <li className="nav-item">
            <NavLink exact className="nav-link" to="/backend/returns" onClick={clickHander}>Yield Returns</NavLink>
          </li>
          <li className="nav-item">
            <NavLink exact className="nav-link" to="/backend/logs" onClick={clickHander}>Audit Logs</NavLink>
          </li>
          <li className="nav-item">
            <NavLink exact className="nav-link" to="/logout" onClick={clickHander}>Log Out</NavLink>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default Sidebar
