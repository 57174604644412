import React, { useEffect } from "react"
import { idCheckIcon } from "components/Icons"

const IdentitySubmitted = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="row justify-content-center">
      <div className="col-xl-7 col-lg-8">
        <div className="text-center">
          <h2 className="mt-5 pt-4">Identification</h2>
          <img
            className="my-4"
            src={idCheckIcon} />
          <p>You have completed the process of KYC</p>
          <p>
            We are still waiting for your identity verification. We’ll notify
            you when it is complete. We’ll be in touch if we need further
            information.
          </p>
        </div>
      </div>
    </div>
  )
}

export default IdentitySubmitted
