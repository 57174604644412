/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Modal } from "react-bootstrap"

import Money from "components/Money"
import { yieldTreeLogo } from "components/Icons"

import { connect } from "react-redux"
import PayoutPrompt from "./PayoutPrompt"
import TaxDetailsPrompt from "./TaxDetailsPrompt"
import { getEntityDetails } from "../../../../requesters"
import { getBaseUrl } from "../../../../reducers"

const ReviewInvestment = ({
  baseUrl, currentEntity, offering, handleSubmit, investment, investmentSuccess, currentUser
}) => {
  const [agreementChecked, setAgreementChecked] = useState(false)
  const [disableButton, setDisableButton] = useState(true)
  const [inflight, setInflight] = useState(false)
  const [bankDetails, setBankDetails] = useState()

  useEffect(() => {
    setDisableButton(!agreementChecked || !bankDetails || !investment?.amount)
  }, [agreementChecked])

  useEffect(() => {
    getEntityDetails(baseUrl, currentEntity.id).then(res => {
      const bankDetailsArray = res?.response?.json?.data?.bank_details || []
      // TO DO: proper selection of bank acc for this offering
      const appropriateBankAcc = bankDetailsArray.find(b => b.country === "AU") || null
      setBankDetails(appropriateBankAcc)
    })
  }, [])

  const handleReviewSubmit = () => {
    setInflight(true)
    handleSubmit()
  }

  const handleBackToOffering = () => {
    window.location.href = `/marketplace/${offering.id}`
  }

  return (
    <>
      <nav className="navbar navbar-light bg-light">
        <div className="container">
          <Link
            className="nav-control-link"
            onClick={handleBackToOffering}>
            Back
          </Link>
          <Link
            className="navbar-brand"
            to="/marketplace">
            <img src={yieldTreeLogo} />
          </Link>
          <div />
        </div>
      </nav>
      <div className="container pb-5">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-8">
            <h2 className="mt-5 pt-4">Review Investment</h2>
            <div className="mt-5">
              <table className="table table-between mb-4">
                <tbody>
                  <tr>
                    <td>Deal Name</td>
                    <td>{offering.name}</td>
                  </tr>
                  {offering.free_text_value_1 && (
                    <tr>
                      <td>{offering.free_text_heading_1}</td>
                      <td>{offering.free_text_value_1}</td>
                    </tr>
                  )}
                  {offering.free_text_value_2 && (
                    <tr>
                      <td>{offering.free_text_heading_2}</td>
                      <td>{offering.free_text_value_2}</td>
                    </tr>
                  )}
                  <tr>
                    <td>Issue Date</td>
                    <td>{offering.issue_date}</td>
                  </tr>
                  <tr>
                    <td>Expected Maturity Date</td>
                    <td>{offering.target_maturity_date}</td>
                  </tr>
                  <tr>
                    <td>Expected Term</td>
                    <td>{offering.expected_term}</td>
                  </tr>
                  <tr>
                    <td>Investment Amount</td>
                    <td><Money currency={offering.currency} amount={investment?.amount} /></td>
                  </tr>
                </tbody>
              </table>
              <div className="d-flex">
                {offering.im_file && (
                  <a
                    className="btn btn-outlined-main btn-lg"
                    href={offering.im_file}
                    target="_blank"
                    rel="noopener noreferrer">
                    Download Offering Documents
                  </a>
                )}
                {offering.ip_file && (
                  <a
                    className="btn btn-outlined-main btn-lg ml-3"
                    href={offering.ip_file}
                    target="_blank"
                    rel="noopener noreferrer">
                    Download Investor Presentation File (IP)
                  </a>
                )}
              </div>
            </div>
            <div className="mt-5 pt-5" />
            <PayoutPrompt bankDetails={bankDetails} />
            {!currentUser.taxDetails.length && (
              <>
                <div className="mt-5 pt-5" />
                <TaxDetailsPrompt />
              </>
            )}
            <div className="mt-5 pt-5" />
            <div className="custom-control custom-checkbox mb-2">
              <input className="custom-control-input" id="customCheck1" type="checkbox" onChange={e => setAgreementChecked(e.target.checked)} />
              <label className="custom-control-label" htmlFor="customCheck1">
                I confirm that I have read and understood the Offering Documents.
              </label>
            </div>
          </div>
        </div>
        <div className="mt-5 pt-5 text-center">
          {inflight ? (<div>Submitting...</div>) : (<button type="submit" disabled={disableButton} onClick={handleReviewSubmit} className="btn btn-main btn-lg">Subscribe to Investment</button>)}
          <div className="text-muted small mt-3 w-50 mx-auto">
            Note that your investment will be confirmed only after documentation has
            been signed and funds deposited by target funding date.
          </div>
        </div>
      </div>
      <Modal centered onHide={() => window.location.reload()} show={investmentSuccess} size="lg">
        <div className="modal-content">
          <div className="modal-header border-0">
            <button aria-label="Close" className="close" onClick={() => window.location.reload()} type="button">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body pt-0 text-center">
            <h3 className="mb-3">Congratulations on signing the investment!</h3>
            <p>Please check your inbox for the request to sign the documents on the HelloSign platform.</p>
          </div>
          <div className="modal-footer mx-auto border-0 pb-5">
            <button className="btn btn-main btn-lg" onClick={handleBackToOffering} type="button">
              Okay
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

const mapStateToProps = state => ({
  baseUrl: getBaseUrl(state)
})

export default connect(mapStateToProps)(ReviewInvestment)
