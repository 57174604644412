/* eslint-disable no-unused-expressions */
import axios from "axios"
import normalize from "json-api-normalizer"

export const addUserPhone = (baseUrl, identifier, phone) => {
  const path = `${baseUrl}/users/${identifier}/add_phone`
  return axios.post(path, { phone })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const updateUser = (baseUrl, data) => {
  const path = `${baseUrl}/users`
  return axios.put(path, data)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => ({ error }))
}

export const resendConfirmationCode = (baseUrl, identifier) => {
  const path = `${baseUrl}/users/${identifier}/resend_pin`
  return axios.patch(path, {})
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const verifyPin = (baseUrl, identifier, code) => {
  const path = `${baseUrl}/users/${identifier}/verify_pin`
  return axios.patch(path, { pin: code })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

// need this for adding the AI SG doc or wholesale cert
export const updateEntityInvestorCert = (baseUrl, identifier, details) => {
  const path = `${baseUrl}/entities/${identifier}/update_cert`
  return axios.post(path, details)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const updateEntityTaxDetails = (baseUrl, identifier, details) => {
  const path = `${baseUrl}/entities/${identifier}/tax_details`
  return axios.put(path, details)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const deleteEntityTaxDetail = (baseUrl, identifier, details) => {
  const path = `${baseUrl}/entities/${identifier}/tax_details`
  return axios.post(path, details)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const checkEmailAvailability = (baseUrl, emailAddress) => {
  const path = `${baseUrl}/users/email_availability`
  return axios.get(path, { params: { email: emailAddress } })
}

export const addBankAccount = (baseUrl, identifier, form) => {
  const path = `${baseUrl}/entities/${identifier}/add_bank_details`
  return axios.post(path, form)
  .then(({ data, status }) => ({ response: data, status }))
  .catch(error => { error })
}

export const getTransactions = (baseUrl, identifier) => {
  const path = `${baseUrl}/entities/${identifier}/transactions`
  return axios.get(path)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const resendEmailConfirmation = (baseUrl, identifier) => {
  const path = `${baseUrl}/users/${identifier}/email_changed`
  return axios.post(path, {})
    .then(({ data, status, }) => ({ response: normalize(data), status }))
    .catch(error => { error })
}

export const removeBankDetail = (baseUrl, identifier, bankDetailId) => {
  const path = `${baseUrl}/entities/${identifier}/remove_bank_details`
  return axios.patch(path, { bank_detail_id: bankDetailId })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const getEntityDetails = (baseUrl, identifier) => {
  const path = `${baseUrl}/entities/${identifier}/details`
  return axios.get(path)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const getPastInvestments = (baseUrl, identifier) => {
  const path = `${baseUrl}/entities/${identifier}/past_investments`
  return axios.get(path)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const getEntityList = (baseUrl, identifier) => {
  const path = `${baseUrl}/users/${identifier}/entity_list`
  return axios.get(path)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const switchEntity = (baseUrl, entityId) => {
  const path = `${baseUrl}/users/switch_entity`
  return axios.post(path, { entity_id: entityId })
  .then(({ data, status }) => ({ response: data, status }))
  .catch(error => { error })
}

export const createNewEntity = (baseUrl, form) => {
  const path = `${baseUrl}/users/create_new_entity`
  return axios.put(path, { form })
  .then(({ data, status }) => ({ response: data, status }))
  .catch(error => { error })
}

export const verifyCurrentEntity = (baseUrl, entity) => {
  const path = `${baseUrl}/users/verify_current_entity`
  return axios.put(path, { entity_id: entity })
  .then(({ data, status }) => ({ response: data, status }))
  .catch(error => { error })
}
