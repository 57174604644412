import React, { useState, useEffect } from "react"
import { bankingCountryList } from "components/countries"

const ManualAddressForm = ({ handleManualAddressInput, address, setValidAddress }) => {
  const getCountry = country => {
    const foo = bankingCountryList.find(element => element.value === country)
    return foo?.label
  }

  const [manualAddress, setManualAddress] = useState({})

  useEffect(() => {
    const fullAddress = `${manualAddress?.unit_number || ""} ${manualAddress.street_number || ""} ${manualAddress.street_name || ""}, ${manualAddress.suburb || ""} ${manualAddress.state || ""} ${manualAddress.postcode || ""}, ${getCountry(manualAddress.country) || ""}.`
    handleManualAddressInput({ ...manualAddress, full_address: fullAddress })
    if (
      manualAddress.street_number
      && manualAddress.street_name
      && manualAddress.postcode
      && manualAddress.country
    ) {
      setValidAddress(true)
    }
  }, [manualAddress])

  return (
    <div className="content">
      <div className="container">
        <div className="my-5">
          <div className="form-group row mb-2">
            <div className="col-sm-6 col-form-label">Street Number</div>
            <div className="col-sm-6">
              <input
                className="form-control"
                type="text"
                autoComplete="disableAll"
                defaultValue={address.street_number || ""}
                onChange={e => {
                  setManualAddress({ ...manualAddress, street_number: e.target.value })
                }} />
            </div>
          </div>
          <div className="form-group row mb-2">
            <div className="col-sm-6 col-form-label">Street Name</div>
            <div className="col-sm-6">
              <input
                className="form-control"
                type="text"
                autoComplete="disableAll"
                defaultValue={address.street_name || ""}
                onChange={e => {
                  setManualAddress({ ...manualAddress, street_name: e.target.value })
                }} />
            </div>
          </div>
          <div className="form-group row mb-2">
            <div className="col-sm-6 col-form-label">Unit Number (if applicable)</div>
            <div className="col-sm-6">
              <input
                className="form-control"
                type="text"
                autoComplete="disableAll"
                defaultValue={address.unit_number || ""}
                onChange={e => {
                  setManualAddress({ ...manualAddress, unit_number: e.target.value })
                }} />
            </div>
          </div>
          <div className="form-group row mb-2">
            <div className="col-sm-6 col-form-label">Suburb (if applicable)</div>
            <div className="col-sm-6">
              <input
                className="form-control"
                type="text"
                autoComplete="disableAll"
                defaultValue={address.suburb || ""}
                onChange={e => {
                    setManualAddress({ ...manualAddress, suburb: e.target.value })
                }} />
            </div>
          </div>
          <div className="form-group row mb-2">
            <div className="col-sm-6 col-form-label">State (if applicable)</div>
            <div className="col-sm-6">
              <input
                className="form-control"
                type="text"
                autoComplete="disableAll"
                defaultValue={address.state || ""}
                onChange={e => {
                  setManualAddress({ ...manualAddress, state: e.target.value })
                }} />
            </div>
          </div>
          <div className="form-group row mb-2">
            <div className="col-sm-6 col-form-label">Postcode</div>
            <div className="col-sm-6">
              <input
                className="form-control"
                type="text"
                autoComplete="disableAll"
                defaultValue={address.postcode || ""}
                onChange={e => {
                  setManualAddress({ ...manualAddress, postcode: e.target.value })
                }} />
            </div>
          </div>
          <div className="form-group row mb-2">
            <div className="col-sm-6 col-form-label">Country</div>
            <div className="col-sm-6">
              <select
                className="form-control custom-select mr-2"
                defaultValue={address.country || ""}
                name="country"
                onChange={e => {
                  setManualAddress({ ...manualAddress, country: e.target.value })
                }}>
                {bankingCountryList.map(c => (
                  <option
                    key={c.value}
                    value={c.value}>
                    {c.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManualAddressForm