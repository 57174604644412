import React from "react"

export default function ContinueLandingPage() {
  const onClickHandler = () => {
    window.location.href = "/verification"
  }
  return (
    <div className="content">
      <div className="container text-center">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8">
            <h2 className="mt-5 mb-4 pt-5">You’re almost ready to view the marketplace</h2>
            <p>Just a few more steps to complete your account</p>
            <button
              className="btn btn-outlined-main btn-lg"
              onClick={onClickHandler}
              type="button">
              Complete Account
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}