import React, { useState } from "react"
import { allCountryList } from "components/countries"
import { jsonToFormData } from "components/CommonMethods"

const Passport = ({
  user,
  doc,
  handleSave,
  handleCancel,
}) => {
  const [idCountry, setIdCountry] = useState(doc?.document?.country)
  const [idNumber, setIdNumber] = useState(doc?.document?.number)
  const [idExpiry, setIdExpiry] = useState(doc?.document?.expiry_date)
  const [file, setFile] = useState(doc?.document?.image)
  const [selectedFileUrl, setSelectedFileUrl] = useState(doc?.document.image?.url)

  const onUploadClickHandler = e => {
    e.preventDefault()
    document.getElementById("uploadId").click()
  }

  const fileChangeHandler = e => {
    const newFile = e.target.files[0]
    setFile(e.target.files)
    setSelectedFileUrl(URL.createObjectURL(newFile))
  }

  const makeSubmitParams = () => {
    const data = {
      rank: 1,
      document_type: "Passport",
      passport_attributes: {
        country: idCountry,
        expiry_date: idExpiry,
        number: idNumber,
      }
    }
    if (file && file[0]) {
      const imageFile = file[0]
      data.passport_attributes.image = imageFile
    }
    if (doc?.document_type === "Passport") {
      data.passport_attributes.id = doc?.document?.id
    }
    data.id = doc?.id
    return {
      id: user.id,
      identification_documents_attributes: [data],
    }
  }

  const onSaveHandler = () => {
    const userInfoFormData = jsonToFormData(makeSubmitParams(), "user")
    handleSave(userInfoFormData)
  }

  return (
    <>

      <tr>
        <td>Country of Issue</td>
        <td className="p-0">
          <select
            className="form-control custom-select"
            defaultValue={idCountry === undefined ? "" : idCountry}
            name="country"
            autoComplete="disableAll"
            onChange={e => {
                  setIdCountry(e.currentTarget.value)
                }}>
            <option value="" disabled>Select</option>
            {allCountryList.map(c => <option key={c.value} value={c.value}>{c.label}</option>)}
          </select>
        </td>
      </tr>
      <tr>
        <td>Expiry Date</td>
        <td className="p-0">
          <input
            className="form-control"
            type="date"
            defaultValue={idExpiry}
            onChange={e => setIdExpiry(e.currentTarget.value)} />
        </td>
      </tr>
      <tr>
        <td>Passport No.</td>
        <td className="p-0">
          <input
            className="form-control"
            defaultValue={idNumber}
            onChange={e => setIdNumber(e.currentTarget.value)} />
        </td>
      </tr>
      <tr>
        <td>File</td>
        <td className="px-0">
          <div className="d-flex align-items-center justify-content-between">
            <a
              rel="noreferrer"
              target="_blank"
              href={selectedFileUrl}>
              {selectedFileUrl || doc?.document?.image?.url ? "Link" : ""}
            </a>
            <div className="hide">
              <input
                type="file"
                id="uploadId"
                name="id_image"
                accept="image/jpeg, image/png, application/pdf"
                onChange={fileChangeHandler} />
            </div>
            <button
              className="btn btn-outlined-main btn-sm"
              type="button"
              onClick={onUploadClickHandler}>
              Upload New
            </button>
          </div>
        </td>
      </tr>
      <tr>
        <td>
        </td>
        <td>
          <button type="button" className="btn btn-main btn-sm mr-2" onClick={onSaveHandler}>Save</button>
          <button type="button" className="btn btn-secondary btn-sm mr-2" onClick={() => handleCancel(false)}>Cancel</button>
        </td>
      </tr>
    </>
  )
}

export default Passport
