/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import { allCountryList } from "components/countries"
import { onlyNumbers } from "components/CommonMethods"
import Dropzone from "../Dropzone"
import ErrorMessage from "./ErrorMessage"

export default function PassportForm({
  handleIdChange,
  updateValidId,
  setDate,
  date,
  date: {
    day, month, year
  },
  formData: {
    country, number,
    image
  }
}) {
  const [passportFieldValidation, setPassportFieldValidation] = useState({
    country: undefined,
    number: undefined,
    expiry_date: undefined
  })

  const hasExistingImage = !!image?.name

  const [validImage, setValidImage] = useState(!!image?.name)
  const [expiryDate, setExpiryDate] = useState({
    day: undefined,
    month: undefined,
    year: undefined
  })

  const isValid = () => {
    const isValid = Object.keys(passportFieldValidation).every(k => passportFieldValidation[k])
    updateValidId(isValid && validImage)
  }

  const validateExpiryDate = () => {
    const date = new Date(`${expiryDate.month}/${expiryDate.day}/${expiryDate.year}`)
    setPassportFieldValidation({ ...passportFieldValidation, expiry_date: date > new Date() })
  }

  const isFieldValid = value => (
    !!value
  )

  useEffect(() => {
    setExpiryDate(date)
    if (country || number) {
      setPassportFieldValidation({ ...passportFieldValidation, country: !!country, number: !!number })
    }
  }, [])

  useEffect(() => {
    isValid()
  }, [passportFieldValidation])

  useEffect(() => {
    if (!(expiryDate.day === undefined && expiryDate.month === undefined && expiryDate.year === undefined)) {
      validateExpiryDate()
      isValid()
    }
  }, [expiryDate, validImage])

  const validateField = e => {
    setPassportFieldValidation({ ...passportFieldValidation, [e.target.name]: isFieldValid(e.target.value) })
  }

  const handleDropZoneBlur = e => {
    const image = e.currentTarget.querySelector('[type="file"]').value
    setValidImage(hasExistingImage || !!image)
  }

  return (
    <>
      <div className="form-group row mb-2">
        <div className="col-sm-6 col-form-label">Country of Issue</div>
        <div className="col-sm-6">
          <select
            className="form-control custom-select"
            defaultValue={country === undefined ? "" : country}
            name="country"
            autoComplete="disableAll"
            onBlur={e => validateField(e)}
            onChange={e => {
              validateField(e)
              handleIdChange({ name: e.currentTarget.name, value: e.currentTarget.value })
            }}>
            <option value="" disabled>Select</option>
            {allCountryList.map(c => <option key={c.value} value={c.value}>{c.label}</option>)}
          </select>
          {passportFieldValidation.country === false && <ErrorMessage message="Please select a valid Country of Issue" />}
        </div>
      </div>
      <div className="form-group row mb-2">
        <div className="col-sm-6 col-form-label">Passport No.</div>
        <div className="col-sm-6">
          <input
            className="form-control"
            placeholder="e.g. 1234567312"
            type="text"
            autoComplete="disableAll"
            defaultValue={number}
            onKeyDown={onlyNumbers}
            name="number"
            onBlur={e => validateField(e)}
            onChange={e => {
              validateField(e)
              handleIdChange({ name: e.currentTarget.name, value: e.currentTarget.value })
            }} />
          {passportFieldValidation.number === false && <ErrorMessage message="Please provide a valid Passport No." />}
        </div>
      </div>
      <div className="form-group row mb-2">
        <div className="col-sm-6 col-form-label">Expiry Date</div>
        <div className="col-sm-6">
          <div className="form-row">
            <div className="col-4">
              <input
                className="form-control"
                placeholder="DD"
                type="number"
                onKeyDown={onlyNumbers}
                autoComplete="disableAll"
                min={1}
                max={31}
                defaultValue={day}
                name="day"
                onBlur={() => validateExpiryDate()}
                onChange={e => {
                  setDate(handleIdChange, date, { name: e.currentTarget.name, value: e.currentTarget.value })
                  setExpiryDate({ ...expiryDate, day: e.currentTarget.value })
                }} />

            </div>
            <div className="col-4">
              <input
                className="form-control"
                placeholder="MM"
                type="number"
                onKeyDown={onlyNumbers}
                defaultValue={month}
                name="month"
                autoComplete="disableAll"
                min={1}
                max={12}
                onBlur={() => validateExpiryDate()}
                onChange={e => {
                  setDate(handleIdChange, date, { name: e.currentTarget.name, value: e.currentTarget.value })
                  setExpiryDate({ ...expiryDate, month: e.currentTarget.value })
                }} />
            </div>
            <div className="col-4">
              <input
                className="form-control"
                placeholder="YYYY"
                type="number"
                onKeyDown={onlyNumbers}
                defaultValue={year}
                name="year"
                autoComplete="disableAll"
                onBlur={() => validateExpiryDate()}
                min={(new Date()).getUTCFullYear()}
                max={(new Date()).getUTCFullYear() + 100}
                onChange={e => {
                  setDate(handleIdChange, date, { name: e.currentTarget.name, value: e.currentTarget.value })
                  setExpiryDate({ ...expiryDate, year: e.currentTarget.value })
                }} />
            </div>
            {passportFieldValidation.expiry_date === false && <ErrorMessage message="Please provide a valid Expiry Date" />}
          </div>
        </div>
      </div>
      <div className="form-group mt-3" onBlur={handleDropZoneBlur}>
        <label>Upload Document (JPG/PNG/PDF)</label>
        <Dropzone
          wrapperClass="drag-drop"
          inactiveText={(image?.name) || "Drag and drop the file or click to Browse Files"}
          activeText="Drop your file here"
          multiple={false}
          defaultValue={image}
          name="image"
          fileTypes="image/jpeg, image/png, application/pdf"
          fileTypeNames=".jpeg .png .pdf"
          handleFileUpload={e => {
            handleIdChange(e)
            setValidImage(hasExistingImage || !!e.value)
          }} />
        {validImage === false && <ErrorMessage message="Please provide a valid image" />}
      </div>
    </>
  )
}
