import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import UserAccounts from "./UserAccounts"
import { getEntityList, switchEntity } from "../../requesters/UserRequester"
import { getCurrentEntity } from "../../reducers"
import AddEntity from "./AddEntity"

const AccountSwitch = ({
 baseUrl, currentUser
}) => {
  const [entityList, setEntityList] = useState([])
  const currentEntity = useSelector(getCurrentEntity)

  const getEntities = () => {
    getEntityList(baseUrl, currentUser.id).then(data => {
      const entities = data.response.json.data
      setEntityList(entities)
    })
  }

  const handleSwitchEntity = entityId => {
    switchEntity(baseUrl, entityId).then(() => window.location.reload())
  }

  useEffect(() => {
    getEntities()
  }, [])

  return (
    <>
      <select
        className="form-control custom-select mr-2"
        name="country"
        defaultValue=""
        onChange={e => {
          handleSwitchEntity(e.target.value)
        }}>
        <option value="" disabled>Pick an account</option>
        {entityList.map(c => (
          <option
            key={c.id}
            disabled={c.id === currentEntity.id}
            value={c.id}>
            {c.name}
          </option>
        ))}
      </select>
      <button
        className="btn btn-outlined-main mt-3"
        type="button"
        disabled={currentUser.registration_status !== "complete"}
        data-target="#addAccountModal"
        data-toggle="modal">
        +Add Account
      </button>
      <div className="d-flex mt-3">
        {currentUser.registration_status !== "complete" && (<small>You will need to finish Registration before adding new Accounts</small>)}
      </div>
      <UserAccounts currentEntity={currentEntity} />
      <AddEntity baseUrl={baseUrl} currentUser={currentUser} />
    </>
  )
}

export default AccountSwitch
