import React from "react"
import Money from "components/Money"
import { onlyNumbers } from "components/CommonMethods"

const YieldReturnRow = ({
  isBatchExported, yieldReturn, offeringCurrency, offeringCountry, interest, principal, setValue, other
}) => {
  const relatedBankAccount = yieldReturn.entity_bank_account.find(element => element.country === offeringCountry) || yieldReturn.entity_bank_account[0]
  return (
    <tr>
      <td className="bg-light">{yieldReturn.entity_name}</td>
      {/* To Do: Change the payee reference to dynamic data when we have it */}
      <td>342937204</td>
      <td>{relatedBankAccount?.bsb_number || relatedBankAccount?.swift_code}</td>
      <td>{relatedBankAccount?.account_number}</td>
      <td className="bg-light">
        <Money currency={offeringCurrency} amount={yieldReturn.amount} />
      </td>
      <td className="text-right td-input">
        <div className="input-group">
          <div className="input-group-prepend"><span className="input-group-text">{offeringCurrency}</span></div>
          <input
            className="form-control"
            disabled={isBatchExported}
            placeholder="-"
            onKeyDown={onlyNumbers}
            type="number"
            value={interest || 0}
            onChange={e => setValue("interest", parseInt(e.target.value, 10))} />
        </div>
      </td>
      <td className="text-right td-input">
        <div className="input-group">
          <div className="input-group-prepend"><span className="input-group-text">{offeringCurrency}</span></div>
          <input
            className="form-control"
            disabled={isBatchExported}
            placeholder="-"
            onKeyDown={onlyNumbers}
            type="number"
            value={principal || 0}
            onChange={e => setValue("principal", parseInt(e.target.value, 10))} />
        </div>
      </td>
      <td className="text-right td-input">
        <div className="input-group">
          <div className="input-group-prepend"><span className="input-group-text">{offeringCurrency}</span></div>
          <input
            className="form-control"
            disabled={isBatchExported}
            placeholder="-"
            onKeyDown={onlyNumbers}
            type="number"
            value={other || 0}
            onChange={e => setValue("other", parseInt(e.target.value, 10))} />
        </div>
      </td>
      <td className="text-right">
        <Money currency={offeringCurrency} amount={(interest || 0) + (principal || 0) + (other || 0)} />
      </td>
    </tr>
  )
  }
export default YieldReturnRow
