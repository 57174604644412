import React, { useEffect, useState, useRef } from "react"

export default function AddressAutocomplete({
  wrapperClass, placeholder,
  inputClass, handleAddressSelection,
  onChangeHandler, onBlur, elmName, address
}) {
  const [query, setQuery] = useState(address?.full_address || "")
  const autoCompleteRef = useRef(null)
  let autoComplete

  const loadScript = (url, callback) => {
    const script = document.createElement("script")
    script.type = "text/javascript"

    if (script.readyState) {
      script.onreadystatechange = () => {
        if (script.readyState === "loaded" || script.readyState === "complete") {
          script.onreadystatechange = null
          callback()
        }
      }
    } else {
      script.onload = () => callback()
    }

    script.src = url
    document.getElementsByTagName("head")[0].appendChild(script)
  }

  async function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace()
    const query = addressObject.formatted_address
    updateQuery(query)
    handleAddressSelection(addressObject)
  }

  function handleScriptLoad(updateQuery, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current
    )
    autoComplete.setFields(["address_components", "formatted_address"])
    autoComplete.addListener("place_changed", () => handlePlaceSelect(updateQuery))
    setTimeout(() => {
      document.querySelector("[name='address']").setAttribute("autocomplete", "disableAll")
    }, 1000)
  }

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${gon.maps_key}&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className={wrapperClass}>
      <input
        ref={autoCompleteRef}
        autoComplete="on"
        className={inputClass}
        onChange={e => {
          setQuery(e.target.value)
          onChangeHandler()
        }}
        value={query}
        placeholder={placeholder}
        onBlur={onBlur}
        name={elmName} />
    </div>
  )
}
