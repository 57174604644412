import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { getFluidLoginUrl, getFluidUserEnabled, getFluidEntityEnabled } from "../../requesters"
import { getCurrentUser, getCurrentEntity, getBaseUrl } from "../../reducers"
import FirstTimeFluidSignup from "./FirstTimeFluidSignup"
import ReturningFluidUser from "./ReturningFluidUser"
import SecondTimeFluidSignup from "./SecondTimeFluidSignup"
import UnverifiedMessage from "./UnverifiedMessage"

const CryptoPage = () => {
  const baseUrl = useSelector(getBaseUrl)
  const currentUser = useSelector(getCurrentUser)
  const currentEntity = useSelector(getCurrentEntity)
  const [error, setError] = useState("")
  const [fluidLoginUrl, setFluidLoginUrl] = useState()
  // const [allowLogin, setAllowLogin] = useState(false)
  const [fluidExistingUser, setFluidExistingUser] = useState({})
  const [fluidExistingEntity, setFluidExistingEntity] = useState({})

  const getFluidUrl = () => {
    getFluidLoginUrl(baseUrl).then(response => {
    if (_.get(response, "response.json.success")) {
      setFluidLoginUrl(_.get(response, "response.json.data.url"))
    } else setError(_.get(response, "response.json.message"))
    })
  }

  // const handleOptions = () => {
  //   setAllowLogin(fluidExistingUser && fluidExistingEntity)
  // }

  const fluidInfo = () => {
    getFluidUserEnabled(baseUrl, currentUser.id).then(response => {
      if (_.get(response, "response.json.success")) {
        setFluidExistingUser(_.get(response, "response.json.data.fluid_status"))
      } else setError(_.get(response, "response.json.message"))
    })
    getFluidEntityEnabled(baseUrl, currentEntity.id)
      .then(response => {
        if (_.get(response, "response.json.success")) {
          setFluidExistingEntity(_.get(response, "response.json.data.fluid_status"))
        } else setError(_.get(response, "response.json.message"))
      })
  }

  // useEffect(() => {
  //   handleOptions()
  // }, [fluidExistingUser, fluidExistingEntity])

  useEffect(() => {
    fluidInfo()
    getFluidUrl()
  }, [])

  const renderSection = () => {
    if (currentEntity.background_check_status !== "verified") {
      return <UnverifiedMessage />
    }
    if (fluidExistingUser && fluidExistingEntity) {
      return <ReturningFluidUser fluidLoginUrl={fluidLoginUrl} />
    }
    if (fluidExistingUser && !fluidExistingEntity) {
      return <SecondTimeFluidSignup />
    }
    if (!fluidExistingUser) {
      return <FirstTimeFluidSignup fluidExistingEntity={fluidExistingEntity} />
    }
    return (
      <div>
        <h1>Something went wrong</h1>
      </div>
    )
  }

  return (
    <div className="marketplace-index">
      <div className="container">
        {currentEntity.country === "AU" ? (
          <>
            {renderSection()}
          </>
        ) : (
          <div className="text-center">
            <b className="mt-0"> Crypto Trading is only available to customers in Australia.</b>
          </div>
        )}
        {error && <div className="err-msg text-danger">{error}</div>}
      </div>
    </div>
  )
}

export default CryptoPage
