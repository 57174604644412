import { useSelector } from "react-redux"
import { useCookies } from "react-cookie"
import { getRouter } from "../reducers"

const excludedRoutes = [
  "/login"
]

export default function LastLocationCookie() {
  const cookieName = "last_route"
  const [cookies, setCookie] = useCookies([cookieName])
  const lastLocation =  _.get(useSelector(getRouter), "location.pathname")

  if (lastLocation && !_.includes(excludedRoutes, lastLocation)) {
    // eslint-disable-next-line no-console
    console.info("cookies", cookies, lastLocation)
    setCookie(cookieName, lastLocation, { path: "/" })
  }

  return null
}