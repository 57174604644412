import React from "react"

const InvestorProfiles = ({ currentEntity }) => {
  const wholesaleVerified = currentEntity.investor_certificate?.wholesale_certificate_verified
  const sgCertificateVerified = currentEntity.investor_certificate?.sg_certificate_verified
  return (
    <div className="d-lg-table w-100 yt-table table">
      <div className="d-lg-table-row d-none">
        <div className="yt-th d-lg-table-cell">Document</div>
        <div className="yt-th d-lg-table-cell text-right">Status</div>
        <div className="yt-th d-lg-table-cell text-right">Date</div>
      </div>
      {currentEntity.investor_certificate?.wholesale_cert?.name && (
        <div className="d-lg-table-row table-item border-bottom">
          <div className="d-flex justify-content-between d-lg-content">
            <div className="yt-td d-lg-table-cell pl-0">s708 Certificate (AU)</div>
            <div className="yt-td d-lg-table-cell text-lg-right">
              {wholesaleVerified ? <span className="text-main">Verified</span> : <span className="text-danger">Not Yet Verified</span>}
            </div>
          </div>
          <div className="yt-td d-lg-table-cell text-lg-right text-sub">
            Issued on
            &nbsp;
            {currentEntity.investor_certificate?.issue_date}
          </div>
        </div>
      )}
      {currentEntity.investor_certificate?.doc_file?.name && (
        <div className="d-lg-table-row table-item border-bottom">
          <div className="d-flex justify-content-between d-lg-content">
            <div className="yt-td d-lg-table-cell pl-0">Accredited Investor Documentation (SG)</div>
            <div className="yt-td d-lg-table-cell text-lg-right">
              {sgCertificateVerified ? <span className="text-main">Verified</span> : <span className="text-danger">Not Yet Verified</span>}
            </div>
          </div>
          <div className="yt-td d-lg-table-cell text-lg-right text-sub">
            Signed on
            &nbsp;
            {currentEntity.investor_certificate?.doc_issue_date}
          </div>
        </div>
      )}
    </div>
  )
}
export default InvestorProfiles
