import BaseController from "controllers/accounts/base"

import startAccountsApp from "apps/Accounts"

class Controller extends BaseController {
  call() {
    super.call()

    const rootElem = document.getElementById("accounts-app")
    if (rootElem) {
      startAccountsApp(rootElem)
    }
  }
}

export default Controller
