import React, { useState, useEffect } from "react"

const IdDetailRow = ({
 country, doc, isIdEditable, setIsIdEditable
}) => {
  const [firstId, setFirstId] = useState({})

  const mapIdentityDocument = doc => {
    switch (doc?.document_type) {
      case "DriverLicence":
        return {
          "ID Type": "Driver Licence",
          State: doc.document?.state,
          "Driver Licence No.": doc.document?.number
        }
      case "Passport":
        return {
          "ID Type": "Passport",
          "Country of Issue": doc.document?.country,
          "Passport No.": doc.document?.number,
          "Expiry Date": doc.document?.expiry_date
        }
      case "NriCard":
        return {
          "ID Type": "National Registration Identity Card",
          "NRIC No.": doc.document?.number,
        }
      default:
        return { "ID Type": "Not Provided" }
    }
  }

  useEffect(() => {
    setFirstId(mapIdentityDocument(doc))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <strong>ID Document 1</strong>
        {country === "SG" && (
          <button
            type="button"
            className="btn btn-link btn-sm collapsed"
            onClick={() => setIsIdEditable(!isIdEditable)}>
            {isIdEditable ? "Cancel" : "Edit"}
          </button>
        )}
      </div>
      <table className="table table-sm table-borderless backend-info">
        <tbody>
          {
            Object.keys(firstId).map(element => (
              <React.Fragment key={`id-document-1-${element}`}>
                <tr>
                  <td>
                    {element}
                  </td>
                  <td>
                    { firstId[element]}
                  </td>
                </tr>
              </React.Fragment>
            ))
          }
          {doc?.document_type === "Passport" ? (
            <tr>
              <td>
                File
              </td>
              <td>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={doc?.document?.image?.url}>
                  {doc?.document?.image?.url ? "Link" : ""}
                </a>
              </td>
            </tr>
          ) : (
            <>
              <tr>
                <td>
                  Front Image
                </td>
                <td>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={doc?.document?.front_image?.url}>
                    {doc?.document?.front_image?.url ? "Link" : ""}
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  Back Image
                </td>
                <td>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href={doc?.document?.back_image?.url}>
                    {doc?.document?.back_image?.url ? "Link" : ""}
                  </a>
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </>
  )
}

export default IdDetailRow