import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { useToasts } from "react-toast-notifications"
// import { jsonToFormData } from "components/CommonMethods"
import TruncatedText from "components/TruncatedText"
import MultilineText from "components/MultilineText"
import { defaultBackground } from "components/Icons"
import HelloSign  from "hellosign-embedded"
import { getBaseUrl } from "../../reducers"
import {
  getOfferingDetail, getInvestmentDetail, getFundedInvestments, sendHelloSignRequest, addInvestment
} from "../../requesters"
import GalleryGrid from "./GalleryGrid"
import Investment from "./Investment/index"
import ReviewInvestment from "./Investment/Pending/ReviewInvestment"
import {
  createEmbeddedWithTemplate, getSignatureClientId, requestOfferingSignature, updateOfferingNdaSigned
} from "../../../Portal/requesters/OfferingRequester"
import { updateUser } from "../../../Accounts/requesters/UserRequester"
import { history } from "../../store"

const Details = ({
  match, currentUser, currentEntity, baseUrl
}) => {
  const { addToast } = useToasts()
  const [ndaSigned, setNdaSigned] = useState(false)
  const {
    params: { offeringId, method },
  } = match
  const [offering, setOffering] = useState(undefined)
  const [galleryCount, setGalleryCount] = useState(0)
  const [onReview, setOnReview] = useState(false)
  const [investment, setInvestment] = useState({ status: "open" })
  const [issuedInvestments, setIssuedInvestments] = useState([])
  const [isInvestmentSuccessful, setIsInvestmentSuccessful] = useState(false)

  const offeringImage = offering?.main_image || defaultBackground

  const reloadData = () => {
    getOfferingDetail(offeringId).then(data => {
      const offering = data.response.json.data
      if (offering[`hidden_to_${currentEntity.region}`]) {
        window.location.href = "/marketplace"
      } else if (["Archived", "Draft"].includes(offering?.status)) {
        window.location.href = "/marketplace"
      }
      setGalleryCount(offering.gallery.length)
      setOffering(offering)

      if (!offering.nda_required || (offering.nda_signed && currentUser.signatureId)) {
        setNdaSigned(true)
      }
    })

    getInvestmentDetail(offeringId, currentEntity.id).then(data => {
      const details = data.response?.json?.data
      if (details.status !== "cancelled") {
        setInvestment(details)
      }
    })

    getFundedInvestments(baseUrl, currentEntity.id).then(r => {
      const { funded_investments, yield_returns } = r.response.json.data
      const output = funded_investments.map(investment => {
        let runningTotalReturn = 0
        let runningTotalPrincipal = 0
        yield_returns.forEach(yr => {
          if (yr.offering_id === investment.offering_id) {
            if (yr.return_type === "Return") {
              runningTotalReturn += yr.amount
            } else {
              runningTotalPrincipal += yr.amount
            }
          }
        })

        return {
          totalReturn: runningTotalReturn,
          totalPrincipal: runningTotalPrincipal,
          ...investment
        }
      })
      setIssuedInvestments(output)
    })
  }

  const handleSubmitInvestment = () => {
    addInvestment(offering.id, investment.amount, currentEntity.id)
      .then(({ response }) => {
        const res = response.json
        if (res.success) {
          sendHelloSignRequest(res.data?.id).then(({ response: helloSignResponse }) => {
            const resHelloSign = helloSignResponse.json
            if (resHelloSign?.success) {
              setIsInvestmentSuccessful(true)
            } else {
              addToast(`Error ${helloSignResponse?.status}: ${resHelloSign?.message}`, { appearance: "error" })
            }
          }).catch(err => {
            addToast(err.message || err.detail, { appearance: "error", autoDismiss: false })
          })
        }
        return false
      })
      .catch(err => {
        addToast(err.message, { appearance: "error", autoDismiss: false })
      })
  }

  const startNdaSignForm = async () => {
    const embedded = await createEmbeddedWithTemplate(offering.id, currentUser.firstname, currentUser.email)

    const signatureId = embedded.response.data.signatures.at(-1).data.signature_id

    const signature = await requestOfferingSignature(offering.id, signatureId)

    const signatureClient = await getSignatureClientId(offering.id)

    if (signature && signatureClient) {
      const { data } = signature.response
      const client = new HelloSign({ clientId: signatureClient.response })

      client.open(data.sign_url, {
        testMode: true,
        skipDomainVerification: true
      })

      client.on("finish", () => {
        updateOfferingNdaSigned(offering.id)
        updateUser(baseUrl, { signature_id: signatureId })
        setNdaSigned(true)
      })
    }
  }

  useEffect(() => {
    reloadData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [method])

  const handleSetInvestment = amount => {
    setInvestment({ ...investment, amount })
    setOnReview(!onReview)
    history.push(`/marketplace/${offeringId}/review`)
  }

  if (!offering) return null
  return (
    <div className="content">
      {
        (onReview && investment?.amount > 0) ? (
          <ReviewInvestment
            currentUser={currentUser}
            currentEntity={currentEntity}
            handleSubmit={handleSubmitInvestment}
            investment={investment}
            investmentSuccess={isInvestmentSuccessful}
            offering={offering} />
        ) : (
          <>
            <div className="offering-hero">
              <img className="img-fluid" src={offeringImage} />
            </div>
            <div className="container mt-md-5 mt-4">
              <div className="row">
                <div className="col-md-8 pr-md-5 order-2 order-md-1">
                  <h2>{offering.name}</h2>
                  <p className="text-muted">
                    {offering.country}
                      &nbsp;·&nbsp;
                    {offering.asset_type}
                  </p>
                  <div className="row pt-3">
                    {offering.free_text_value_1 && (
                      <div className="col-lg-3 col-4">
                        <div className="small text-muted">{offering.free_text_heading_1}</div>
                        <h3>
                          {offering.free_text_value_1}
                        </h3>
                      </div>
                    )}
                    {offering.free_text_value_2 && (
                      <div className="col-lg-3 col-4">
                        <div className="small text-muted">{offering.free_text_heading_2}</div>
                        <h3>
                          {offering.free_text_value_2}
                        </h3>
                      </div>
                    )}
                    <div className="col-lg-3 col-4">
                      <div className="small text-muted">Expected Term</div>
                      <h3>
                        {offering.expected_term}
                      </h3>
                    </div>
                  </div>
                  <div className="pt-4" />
                  <hr />
                  <h3 className="mb-3 mt-5">Overview</h3>
                  <div className="offering-overview">
                    <TruncatedText text={offering.overview} length={700} />
                  </div>
                  <div className="row">
                    <div className="col-xl-6 mb-4">
                      <div className="border p-4 h-100">
                        <h3 className="medium">Investment Terms</h3>
                        <table className="table table-sm table-borderless table-between">
                          <tbody>
                            <tr>
                              <td>Currency</td>
                              <td>{ offering.currency}</td>
                            </tr>
                            {
                              offering?.management_fee && (
                                <tr>
                                  <td>Management Fee</td>
                                  <td>
                                    {offering?.management_fee}
                                  </td>
                                </tr>
                              )
                            }
                            {
                              offering?.performance_fee && (
                                <tr>
                                  <td>Performance Fee</td>
                                  <td>
                                    {offering?.performance_fee}
                                  </td>
                                </tr>
                              )
                            }
                            {
                              offering?.free_text_value_1 && (
                                <tr>
                                  <td>{offering.free_text_heading_1}</td>
                                  <td>
                                    {offering?.free_text_value_1}
                                  </td>
                                </tr>
                              )
                            }
                            {
                              offering?.free_text_value_2 && (
                                <tr>
                                  <td><strong>{offering.free_text_heading_2}</strong></td>
                                  <td>
                                    <strong>
                                      {offering.free_text_value_2}
                                    </strong>
                                  </td>
                                </tr>
                              )
                            }
                            {
                              offering?.redemption && (
                                <tr>
                                  <td>Redemption</td>
                                  <td>
                                    {offering.redemption}
                                  </td>
                                </tr>
                              )
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-xl-6 mb-4">
                      <div className="border p-4 h-100">
                        <h3 className="medium">Schedule</h3>
                        <table className="table table-sm table-borderless table-between">
                          <tbody>
                            <tr>
                              <td>Interest Schedule</td>
                              <td>{ offering.interest_schedule }</td>
                            </tr>
                            <tr>
                              <td>Principal Schedule</td>
                              <td>{ offering.principal_schedule }</td>
                            </tr>
                            <tr>
                              <td>Prefunded</td>
                              <td>{ offering.prefunded_deal }</td>
                            </tr>
                            <tr>
                              <td>Issue Date</td>
                              <td>{ offering.issue_date }</td>
                            </tr>
                            <tr>
                              <td>Maturity</td>
                              <td>{ offering.target_maturity_date }</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="border p-4 mb-4">
                    <h3 className="medium">Structure</h3>
                    <table className="table table-sm table-borderless table-between">
                      <tbody>
                        <tr>
                          <td>Deal Type</td>
                          <td>{ offering.deal_type }</td>
                        </tr>
                        <tr>
                          <td>Asset Type</td>
                          <td>{ offering.asset_type }</td>
                        </tr>
                        <tr>
                          <td>Asset Value</td>
                          <td>{offering.asset_value}</td>
                        </tr>
                        <tr>
                          <td>Investor Structure</td>
                          <td>{ offering.investor_structure }</td>
                        </tr>
                        <tr>
                          <td>Co-investment?</td>
                          <td>{ offering.coinvestment_deal }</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex">
                    {
                      !ndaSigned && (
                        <button
                          type="button"
                          onClick={startNdaSignForm}
                          className="btn btn-outlined-main btn-lg">
                          Sign NDA to Access Further Details
                        </button>
                      )
                    }
                    {
                      ndaSigned && !!offering.im_file && (
                        <a
                          className="btn btn-outlined-main btn-lg"
                          href={offering.im_file}
                          target="_blank"
                          rel="noopener noreferrer">
                          Download Offering Documents
                        </a>
                      )
                    }
                    {
                      (ndaSigned && !!offering.ip_file) && (
                        <a
                          className="btn btn-outlined-main btn-lg ml-3"
                          href={offering.ip_file}
                          target="_blank"
                          rel="noopener noreferrer">
                          Download Investor Presentation (IP)
                        </a>
                      )
                    }
                  </div>
                  <div className="pt-4" />
                </div>
                <Investment
                  offering={offering}
                  issuedInvestments={issuedInvestments}
                  user={currentUser}
                  currentEntity={currentEntity}
                  reloadData={reloadData}
                  investment={investment}
                  handleSetInvestment={handleSetInvestment} />
              </div>
            </div>
            <div className="container">
              <hr />
              {
                offering.free_text_value_3 && (
                  <div className="detail-wrapp">
                    <h3 className="mb-3 mt-5">{offering.free_text_subheading_3}</h3>
                    <MultilineText text={offering.free_text_value_3} />
                  </div>
                )
              }
              {
                offering.free_text_value_4 && (
                  <div className="detail-wrapp">
                    <h3 className="mb-3 mt-5">{offering.free_text_subheading_4}</h3>
                    <MultilineText text={offering.free_text_value_4} />
                  </div>
                )
              }
              {
                offering.free_text_value_5 && (
                  <div className="detail-wrapp">
                    <h3 className="mb-3 mt-5">{offering.free_text_subheading_5}</h3>
                    <MultilineText text={offering.free_text_value_5} />
                  </div>
                )
              }
              {
                offering.free_text_value_6 && (
                  <div className="detail-wrapp">
                    <h3 className="mb-3 mt-5">{offering.free_text_subheading_6}</h3>
                    <MultilineText text={offering.free_text_value_6} />
                  </div>
                )
              }
              {
                offering.free_text_value_7 && (
                  <div className="detail-wrapp">
                    <h3 className="mb-3 mt-5">{offering.free_text_subheading_7}</h3>
                    <MultilineText text={offering.free_text_value_7} />
                  </div>
                )
              }
              {
                offering.free_text_value_8 && (
                  <div className="detail-wrapp">
                    <h3 className="mb-3 mt-5">{offering.free_text_subheading_8}</h3>
                    <MultilineText text={offering.free_text_value_8} />
                  </div>
                )
              }
              {
                galleryCount > 0 && (
                  <div className="detail-wrapp">
                    <h3 className="mb-3 mt-5 d-inline-block">Gallery</h3>
                    <small style={{ paddingLeft: "10px" }}>
                      {`${galleryCount} ${galleryCount === 1 ? "Photo" : "Photos"}`}
                    </small>
                    <GalleryGrid offering={offering} />
                  </div>
                )
              }
            </div>
          </>
        )
      }
    </div>
  )
}

const mapStateToProps = state => ({
  baseUrl: getBaseUrl(state)
})

export default connect(mapStateToProps)(Details)
