import React from "react"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import { useToasts } from "react-toast-notifications"
import { removeBankDetails } from "../../../requesters"
import { getBaseUrl } from "../../../reducers"

const RemoveBanking = ({
 setBankIdToBeDeleted, offeringId, bankIdToBeDeleted, baseUrl, reloadOffering
}) => {
  const { addToast } = useToasts()

  const removeBanking = () => {
    removeBankDetails(baseUrl, offeringId, bankIdToBeDeleted).then(data => {
      const res = data.response.json
      if (res.success) {
        setBankIdToBeDeleted()
        addToast("Bank Account removed!", { appearance: "success" })
        reloadOffering()
      } else {
        addToast(res.message, { appearance: "error" })
      }
    })
  }
  return (
    <div aria-labelledby="pendingCancelledPopup" className="modal fade" id="removeBanking" role="dialog" tabIndex={-1} style={{ display: "none" }} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header border-0">
            <button aria-label="Close" className="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body pt-0 text-center">
            <h3 className="modal-title mb-3">Confirm to remove Bank Account details?</h3>
          </div>
          <div className="modal-footer border-0 justify-content-center">
            <button className="btn btn-main" id="btnCloseModal" data-dismiss="modal" type="button" onClick={() => removeBanking()}>
              Confirm
            </button>
            <button className="btn btn-outlined-main" id="btnCloseModal" data-dismiss="modal" type="button">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  baseUrl: getBaseUrl(state)
})

export default withRouter(connect(mapStateToProps)(RemoveBanking))
