import React, { Component } from "react"
import { Link } from "react-router-dom"

class CountrySelect extends Component {
  render() {
    const {
      handleAttributeChange
    } = this.props

    const name = "country"

    return (
      <div className="container text-center onboarding">
        <h2>Welcome to Aura Marketplace</h2>
        <h5>To begin setting up your Aura Marketplace account, first select your country of residence.</h5>
        <div className="row justify-content-center my-5">
          <div className="col-md-4 col-lg-3">
            <button
              type="button"
              onClick={e => handleAttributeChange({ value: e.target.value, name: e.target.name })}
              value="AU"
              name={name}
              className="btn btn-selection fs-2 btn-block mb-3">
              Australia
              <h6>Sign me up</h6>
            </button>
          </div>
          <div className="col-md-4 col-lg-3">
            <button
              type="button"
              onClick={e => handleAttributeChange({ value: e.target.value, name: e.target.name })}
              value="SG"
              name={name}
              className="btn btn-selection fs-2 btn-block mb-3">
              Singapore
              <h6>Sign me up</h6>
            </button>
          </div>
          <div className="col-md-4 col-lg-3">
            <button
              type="button"
              onClick={e => handleAttributeChange({ value: e.target.value, name: e.target.name })}
              value="ROTW"
              name={name}
              className="btn btn-selection fs-2 btn-block mb-3">
              Rest of the World
              <h6>Sign me up</h6>
            </button>
          </div>
        </div>
        <div>
          Already have an account?
          <Link to="/login" className="ml-1">Log In</Link>
        </div>
      </div>
    )
  }
}

export default CountrySelect
