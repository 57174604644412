/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"

const InvestorDocRow = ({
  title, issueDate, verified, fileUrl, editHandler
}) => {
  const onClickHandler = e => {
    e.preventDefault()
    editHandler(true)
  }

  return (
    <tr>
      <td><strong>{ title}</strong></td>
      <td>{fileUrl && issueDate}</td>
      <td>{verified ? <span className="text-main">Verified</span> : (fileUrl && <span className="text-danger">Not Verified</span>)}</td>
      <td className="text-right p-0">
        <div className="dropdown">
          <button aria-expanded="false" aria-haspopup="true" className="btn btn-secondary btn-sm dropdown-toggle" data-toggle="dropdown" id="dropdownMenuButton" type="button" />
          <div aria-labelledby="dropdownMenuButton"
            className="dropdown-menu dropdown-menu-right"
            x-placement="bottom-start"
            style={{
              position: "absolute",
              willChange: "transform",
              top: 0,
              left: 0,
              transform: "translate3d(20px, 31px, 0px)"
            }}>
            {fileUrl
              ? (
                <>
                  <a className="dropdown-item" rel="noreferrer" target="_blank" href={fileUrl}> View AI File</a>
                  <a className="dropdown-item" href="#" onClick={e => onClickHandler(e)}>Edit</a>
                </>
              )
              : <a className="dropdown-item" href="#" onClick={e => onClickHandler(e)}>Add</a>
            }
          </div>
        </div>
      </td>
    </tr>
  )
}

export default InvestorDocRow
