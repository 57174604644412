import React from "react"

const BannerIdWarning = ({ currentEntity }) => {
  if (!currentEntity) return null
  if (currentEntity.background_check_status === "verified") return null
  if (
    currentEntity.user.registration_status === "pending_approval"
    || currentEntity.background_check_status === "pending"
    || currentEntity.background_check_status === "rejected"
  ) {
    return (
      <div className="text-center" style={{ background: "#ffffc9", padding: "0.5em" }}>
        <span className="mr-4">This account is awaiting verification.</span>
      </div>
    )
  }

  return (
    <div className="text-center" style={{ background: "#ffffc9", padding: "0.5em" }}>
      <strong>Next Step: </strong>
      <span className="mr-4">Verify your ID to invest.</span>
      {currentEntity.user.registration_status === "complete" ? (
        <button
          type="button"
          onClick={() => window.open(currentEntity.redirect_url)}
          disabled={!currentEntity.redirect_url}
          className="btn btn-main btn-sm">
          Verify
        </button>
      ) : (
        <button
          type="button"
          onClick={() => { window.location.href = "/complete-account" }}
          className="btn btn-main btn-sm">
          Verify
        </button>
      )}
    </div>
  )
}

export default BannerIdWarning