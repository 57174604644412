import React, { useState } from "react"
import { jsonToFormData } from "components/CommonMethods"

const NriCard = ({
  user,
  doc,
  handleSave,
  handleCancel,
}) => {
  const [idNumber, setIdNumber] = useState(doc?.document?.number)
  const [file, setFile] = useState(doc?.document?.front_image)
  const [fileTwo, setFileTwo] = useState(doc?.document?.back_image)
  const [selectedFileUrl, setSelectedFileUrl] = useState(doc?.document.front_image?.url)
  const [selectedFileUrlTwo, setSelectedFileUrlTwo] = useState(doc?.document.back_image?.url)

  const onUploadClickHandlerOne = e => {
    e.preventDefault()
    document.getElementById("uploadIdOne").click()
  }

  const onUploadClickHandlerTwo = e => {
    e.preventDefault()
    document.getElementById("uploadIdTwo").click()
  }

  const fileChangeHandlerOne = e => {
    const newFile = e.target.files[0]
    setFile(e.target.files)
    setSelectedFileUrl(URL.createObjectURL(newFile))
  }

  const fileChangeHandlerTwo = e => {
    const newFile = e.target.files[0]
    setFileTwo(e.target.files)
    setSelectedFileUrlTwo(URL.createObjectURL(newFile))
  }

  const makeSubmitParams = () => {
    const data = {
      rank: 1,
      document_type: "NriCard",
      nri_card_attributes: {
        number: idNumber,
      }
    }
    if (file && file[0]) {
      const imageFile = file[0]
      data.nri_card_attributes.front_image = imageFile
    }
    if (fileTwo && fileTwo[0]) {
      const imageFile = fileTwo[0]
      data.nri_card_attributes.back_image = imageFile
    }
    if (doc?.document_type === "NriCard") {
      data.nri_card_attributes.id = doc?.document?.id
    }
    data.id = doc?.id
    return {
      id: user.id,
      identification_documents_attributes: [data],
    }
  }

  const onSaveHandler = () => {
    const userInfoFormData = jsonToFormData(makeSubmitParams(), "user")
    handleSave(userInfoFormData)
  }

  return (
    <>
      <tr>
        <td>NRIC No.</td>
        <td className="p-0">
          <input
            className="form-control"
            defaultValue={idNumber}
            onChange={e => setIdNumber(e.currentTarget.value)} />
        </td>
      </tr>
      <tr>
        <td>Front Image</td>
        <td className="px-0">
          <div className="d-flex align-items-center justify-content-between">
            <a
              rel="noreferrer"
              target="_blank"
              href={selectedFileUrl}>
              {selectedFileUrl || doc?.document?.front_image?.url ? "Link" : ""}
            </a>
            <div className="hide">
              <input
                type="file"
                id="uploadIdOne"
                name="id_image"
                accept="image/jpeg, image/png, application/pdf"
                onChange={fileChangeHandlerOne} />
            </div>
            <button
              className="btn btn-outlined-main btn-sm"
              type="button"
              onClick={onUploadClickHandlerOne}>
              Upload New
            </button>
          </div>
        </td>
      </tr>
      <tr>
        <td>Back Image</td>
        <td className="px-0">
          <div className="d-flex align-items-center justify-content-between">
            <a
              rel="noreferrer"
              target="_blank"
              href={selectedFileUrlTwo}>
              {selectedFileUrlTwo || doc?.document?.back_image?.url ? "Link" : ""}
            </a>
            <div className="hide">
              <input
                type="file"
                id="uploadIdTwo"
                name="id_image"
                accept="image/jpeg, image/png, application/pdf"
                onChange={fileChangeHandlerTwo} />
            </div>
            <button
              className="btn btn-outlined-main btn-sm"
              type="button"
              onClick={onUploadClickHandlerTwo}>
              Upload New
            </button>
          </div>
        </td>
      </tr>
      <tr>
        <td>
        </td>
        <td>
          <button type="button" className="btn btn-main btn-sm mr-2" onClick={onSaveHandler}>Save</button>
          <button type="button" className="btn btn-secondary btn-sm mr-2" onClick={() => handleCancel(false)}>Cancel</button>
        </td>
      </tr>
    </>
  )
}

export default NriCard
