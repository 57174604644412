import React from "react"
import { formatWithComma } from "components/CommonMethods"
import Timeline from "./Timeline"

const Closed = ({ offering, investment, returnData }) => (
  <div className="col-md-4 order-1 order-md-2 mb-5">
    <div className="card shadow-sm card-body sticky-top">
      <div className="funding-progress mb-4 pb-3">
        <div className="py-3">
          <div className="h2 mb-0">Closed</div>
          <small className="text-muted">Recently Funded</small>
        </div>
        <div className="progress">
          <div aria-valuemax={100} aria-valuemin={0} aria-valuenow={100} className="progress-bar" role="progressbar" style={{ width: "100%" }} />
        </div>
      </div>
      <h3 className="mb-3">Your Investment</h3>
      <div className="d-flex justify-content-between">
        {` Amount (${offering.currency})`}
        <strong className="med">
          {`$${formatWithComma(investment.amount)}`}
        </strong>
      </div>
      <table className="table table-borderless yt-table table-sm table-between mt-4">
        <tbody>
          <tr>
            <td className="p-0">Interest Received</td>
            <td className="p-0">
              {`$${formatWithComma(returnData?.totalReturn)}`}
            </td>
          </tr>
          <tr>
            <td className="p-0">Principal Repaid</td>
            <td className="p-0">Fully Repaid</td>
          </tr>
        </tbody>
      </table>
      <hr className="mb-0" />
      <div className="mt-4">
        <table className="table table-borderless yt-table table-sm table-between">
          <tbody>
            <Timeline title="Issue Date" date={offering.issue_date} />
            <Timeline title="Target Maturity Date" date={offering.target_maturity_date} />
          </tbody>
        </table>
      </div>
    </div>
  </div>
  )

export default Closed
