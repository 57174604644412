import { combineReducers }  from "redux"
import { connectRouter } from "connected-react-router"
import createReducer from "components/utils/createReducer"

const config = createReducer(
  function () {
    const { ...initialState } = gon
    return initialState
  }()
)({})

export default history => combineReducers({
  router: connectRouter(history),
  config
})

// Routes
export const getRouter = state => state.router

// Config
export const getBaseUrl = state => state.config.urls.api
export const getCurrentUser = state => state.config.current_user
export const getCurrentEntity = state => state.config.current_user.current_entity
export const getUserDetails = state => state.config.user_details
