import React, { useState, useEffect } from "react"

import Dropzone from "../Dropzone"
import ErrorMessage from "./ErrorMessage"

export default function ProveResidenceForm({
  handleIdChange,
  updateValidId,
  formData: {
    file
  },
  documentType,
}) {
  const [validFile, setValidFile] = useState(!!file?.name)

  const hasExistingFile = !!file?.name

  useEffect(() => {
    updateValidId(validFile)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validFile])

  const handleBackDropZoneBlur = e => {
    const image = e.currentTarget.querySelector('[type="file"]').value
    setValidFile(hasExistingFile || !!image)
  }

  return (
    <>
      <div className="form-group mt-3" onBlur={handleBackDropZoneBlur}>
        <label>{`The issue date of the ${documentType} has to be less than 3 months old`}</label>
        <Dropzone
          wrapperClass="drag-drop"
          inactiveText={file?.name || "Drag and drop the file or click to Browse Files"}
          activeText="Drop your file here"
          multiple={false}
          defaultValue={file}
          name="file"
          fileTypes="image/jpeg, image/png, application/pdf"
          fileTypeNames=".jpeg .png .pdf"
          handleFileUpload={e => {
            handleIdChange(e)
            setValidFile(hasExistingFile || !!e.value)
          }} />
        {validFile === false && <ErrorMessage message={`Please provide a image/pdf of your ${documentType}`} />}
      </div>
    </>
  )
}