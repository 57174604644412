/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react"

import { yieldTreeLogoInverse, linkedInIcon } from "components/Icons"

export default function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="d-flex align-items-center justify-content-between mb-5">
          <a
            className="link-wrapper"
            to="#">
            <img src={yieldTreeLogoInverse} width="130px" />
          </a>
          <a href="https://www.linkedin.com/company/aura-group-co/" className="link-wrapper" rel="noreferrer" target="_blank">
            <img src={linkedInIcon} />
          </a>
        </div>
        <div>
          &copy;
          {" "}
          {new Date().getFullYear()}
          {" "}
          Aura Group Marketplace | Level 24, 52 Martin Place, Sydney, NSW 2000
        </div>
        <ul className="list-inline mt-3">
          <li className="list-inline-item">
            <a href="https://www.aura.co/privacy-policy" target="_blank" rel="noopener noreferrer" className="link-wrapper strong mr-3">
              Privacy Policy
            </a>
          </li>
          <li className="list-inline-item">
            <a href="https://www.aura.co/termsandconditions" target="_blank" rel="noopener noreferrer" className="link-wrapper strong mr-3">
              Terms Of Use
            </a>
          </li>
        </ul>
        <hr />
        <div className="text-muted">
          <p className="small">
            Investments made available on the website are only available to wholesale, accredited or professional investors. Any examples shown are for illustration purposes only. Any rate of return shown is a target rate which is not guaranteed and is determined by the performance of the individual transaction entered into and may achieve lower than expected returns. It is important for you to read the Offering Documents before you make any investment decision. Information provided is general information only and should not be taken as financial, legal, or tax advice. It does not consider the specific needs, investment objectives, or financial situation of any particular investor. You must consider whether the investments are suitable for you and your personal circumstances. Past performance is not an indication of future performance, and investors risk losing part or all of their principal investment.
          </p>
          <p className="small">
            Aura Group subsidiaries issuing this information include Aura Group (Singapore) Pte Ltd (Registration No. 201537140R) which is regulated by the Monetary Authority of Singapore as a holder of a Capital Markets Services Licence, and Aura Capital Pty Ltd (ACN 143 700 887) Australian Financial Services Licence 366230 holder in Australia.
          </p>
        </div>
      </div>
    </footer>
  )
}
