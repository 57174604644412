import React, { useState } from "react"
import { connect } from "react-redux"
import { useToasts } from "react-toast-notifications"
import { getBaseUrl } from "../../reducers"
import { deleteUser } from "../../requesters"

const DeleteUserModal = ({ baseUrl, user }) => {
  const [confirm, setConfirm] = useState(false)
  const { addToast } = useToasts()

  const handleDelete = () => {
    deleteUser(baseUrl, user.id).then(
      res => {
        if (res.response.json.success) {
          addToast(res.response.json.message, { appearance: "success" })
          setTimeout(() => window.location.reload(), 3000)
        } else {
          addToast(`${res.response.json.message}: ${res.response.json.data.error}`, { appearance: "error" })
        }
      }
    )
  }
  return (
    <>
      <div
        data-toggle="modal"
        data-target="#errorModal"
        className="btn btn-secondary btn-sm float-right">
        Delete User
      </div>
      <div
        aria-labelledby="pendingCancelledPopup"
        className="modal fade"
        id="errorModal"
        role="dialog"
        tabIndex={-1}
        style={{ display: "none" }}
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button">
                <span aria-hidden="true"> ×</span>
              </button>
            </div>
            <div className="modal-body pt-0 text-center">
              <h3 className="mb-3">WARNING: Confirm Deleting this User?</h3>
              <p>This process is not reversible</p>
            </div>
            <div className="custom-control pt-0 text-center custom-checkbox mb-2">
              <input
                className="custom-control-input"
                id="customCheck1"
                type="checkbox"
                onChange={e => setConfirm(e.target.checked)} />
              <label className="custom-control-label" htmlFor="customCheck1">
                Confirm Delete
              </label>
            </div>
            <div className="modal-footer mx-auto border-0 pb-5">
              <button
                className="btn btn-secondary"
                id="btnCloseModal"
                data-dismiss="modal"
                type="button">
                Cancel
              </button>
              <button
                className="btn btn-outlined-main"
                id="btnCloseModal"
                data-dismiss="modal"
                type="button"
                onClick={handleDelete}
                disabled={!confirm}>
                Confirm Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  baseUrl: getBaseUrl(state)
})

export default connect(mapStateToProps)(DeleteUserModal)