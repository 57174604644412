import React from "react"
import { defaultBackground } from "components/Icons"
import { Link } from "react-router-dom"

const UpcomingOffering = ({ offering, redirectToDisplay }) => {
  const offeringImage = offering.main_image || defaultBackground

  return (
    <div className="card card-offering mb-4 border-0" key={`offering-${offering.id}`}>
      <div className="row no-gutters">
        <div className="col-md-3 col-lg-2">
          <div className="label-upcoming">Upcoming</div>
          <img className="card-img" src={offeringImage} />
        </div>
        <div className="col-md-9 col-lg-10">
          <div className="card-body px-4">
            <div className="row">
              <div className="col-lg-8">
                <Link to={`/marketplace/${offering.id}`} style={{ color: "black" }}>
                  <h3>{offering.name}</h3>
                </Link>
                <p className="text-muted">
                  {offering.country}
                  &nbsp;·&nbsp;
                  {offering.asset_type}
                </p>
                <p className="abstract">
                  {offering.overview}
                </p>
                <div className="green-bar my-4" />
                <div className="row">
                  {offering.free_text_value_1 && (
                    <div className="col-4 col-xl-3">
                      <div className="small text-muted">{offering.free_text_heading_1}</div>
                      <h3>
                        {offering.free_text_value_1}
                      </h3>
                    </div>
                  )}
                  {offering.free_text_value_2 && (
                    <div className="col-4 col-xl-3">
                      <div className="small text-muted">{offering.free_text_heading_2}</div>
                      <h3>
                        {offering.free_text_value_2}
                      </h3>
                    </div>
                  )}
                  <div className="col-4 col-xl-3">
                    <div className="small text-muted">Expected Term</div>
                    <h3>{offering.expected_term}</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 d-sm-flex flex-lg-column justify-content-sm-between pt-3">
                <div className="text-lg-right">
                  Coming Soon
                  <div className="h2 text-main mb-0">
                    TBA
                  </div>
                  {offering.total_deal_size}
                  &nbsp;
                  to be funded
                </div>
                <div className="mt-sm-auto text-sm-right mt-4">
                  <div className="mb-1">
                    Offer Closes&nbsp;
                    {offering.close_date}
                  </div>
                  <button className="btn btn-outlined-main btn-lg" type="button" onClick={() => redirectToDisplay(offering.id)}>View Details</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpcomingOffering
