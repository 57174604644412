const bankingCountryList = [
  {
    value: "",
    label: "-"
  },
  {
    value: "AU",
    label: "Australia",
    currency: "AUD"
  },
  {
    value: "US",
    label: "United States of America",
    currency: "USD"
  },
  {
    value: "SG",
    label: "Singapore",
    currency: "SGD"
  },
  {
    value: "AT",
    label: "Austria",
    currency: "EU"
  },
  {
    value: "BE",
    label: "Belgium",
    currency: "EU"
  },
  {
    value: "BR",
    label: "Brazil",
    currency: "BRL"
  },
  {
    value: "CA",
    label: "Canada",
    currency: "CAD"
  },
  {
    value: "CN",
    label: "China",
    currency: "CNY"
  },
  {
    value: "DK",
    label: "Denmark",
    currency: "EU"
  },
  {
    value: "FR",
    label: "France",
    currency: "EU"
  },
  {
    value: "DE",
    label: "Germany",
    currency: "EU"
  },
  {
    value: "HK",
    label: "Hong Kong",
    currency: "HKD"
  },
  {
    value: "IT",
    label: "Italy",
    currency: "EU"
  },
  {
    value: "MX",
    label: "Mexico",
    currency: "MXN"
  },
  {
    value: "NL",
    label: "Netherlands",
    currency: "EU"
  },
  {
    value: "NZ",
    label: "New Zealand",
    currency: "NZD"
  },
  {
    value: "NO",
    label: "Norway",
    currency: "EU"
  },
  {
    value: "ES",
    label: "Spain",
    currency: "EU"
  },
  {
    value: "SE",
    label: "Sweden",
    currency: "EU"
  },
  {
    value: "CH",
    label: "Switzerland",
    currency: "EU"
  },
  {
    value: "GB",
    label: "United Kingdom",
    currency: "GBP"
  }
]

export default bankingCountryList