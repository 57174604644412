import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import PhoneInput, { formatPhoneNumberIntl } from "react-phone-number-input"
import { getBaseUrl } from "../../reducers"
import { addUserPhone } from "../../requesters/UserRequester"
import  VerifyPhone  from "../Accounts/VerifyPhone"

const ChangeMobileModal = ({ currentUser }) => {
  const defaultCountryCode = {
    AU: "AU",
    SG: "SG",
    ROTW: "GB"
  }[currentUser.country]

  const baseUrl = useSelector(getBaseUrl)

  const [formSubmitted, setFormSubmitted] = useState(false)
  const [phone, setPhone] = useState(undefined)
  const [phoneWithoutCountryCode, setPhoneWithoutCountryCode] = useState(undefined)
  const [validPhone, setValidPhone] = useState(undefined)
  const [showError, setShowError] = useState(undefined)

  const validatePhone = value => {
    const validPhone = /^[0-9]{4,15}$/
    return validPhone.test(String(value))
  }

  useEffect(() => {
    if (phone !== undefined) {
      setShowError(true)
      const intlPhone = formatPhoneNumberIntl(phone)
      const phoneNumber = phone.replace(intlPhone.split(" ")[0], "")
      setPhoneWithoutCountryCode(phoneNumber)
    }
  }, [phone])

  useEffect(() => {
    setValidPhone(validatePhone(phoneWithoutCountryCode))
  }, [phoneWithoutCountryCode])

  const onSubmit = e => {
    e.preventDefault()
    const phoneWithCountryCode = phone
     addUserPhone(baseUrl, currentUser.id, phoneWithCountryCode).then(data => {
      const res = data.response.json
      if (res.success) { setFormSubmitted(true) }
    })
  }

return (
  <div aria-labelledby="pendingCancelledPopup" className="modal fade" id="settingsChangeMobileModal" role="dialog" tabIndex={-1} style={{ display: "none" }} aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div className="modal-content">
        <div className="modal-header border-0 p-0"></div>
        <div className="modal-body text-center">
          <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true"> ×</span></button>
          <div className="row justify-content-center">
            <div className="col-lg-11">
              {!formSubmitted
                ? (
                  <form onSubmit={onSubmit}>
                    <h2 className="mt-4 mb-3">Update Mobile</h2>
                    <p>In order to protect the security of your account, please verify your phone number to continue</p>
                    <div className="form-group my-4">
                      <div className="input-group">
                        <div className="input-group">
                          <PhoneInput
                            international
                            autoComplete="off"
                            placeholder="Enter phone number"
                            name="phone"
                            countryCallingCodeEditable={false}
                            defaultCountry={defaultCountryCode}
                            value={phone}
                            onChange={setPhone} />
                          { (showError && validPhone === false) && <div className="err-msg text-danger"> Invalid Phone. Phone must be between 4 and 15 characters.</div> }
                        </div>
                      </div>
                    </div>
                    <input
                      type="submit"
                      value="Next"
                      className="btn btn-main py-3 px-5"
                      disabled={!validPhone} />
                  </form>
                ) : (<VerifyPhone currentUser={currentUser} />) }
            </div>
          </div>
        </div>
        <div className="modal-footer border-0">
        </div>
      </div>
    </div>
  </div>
  )
}
export default ChangeMobileModal