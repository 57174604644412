/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react"
import { connect } from "react-redux"
import { useToasts } from "react-toast-notifications"
import { jsonToFormData } from "components/CommonMethods"
import { updateEntityInvestorCert, sendCertificateVerificationEmail } from "../../requesters"
import { getBaseUrl } from "../../reducers"
import InvestorDocRow from "./InvestorDocRow"
import InvestorDocEdit from "./InvestorDocEdit"
import WholesaleDocEdit from "./WholesaleDocEdit"

const InvestorDocDetail = ({
 entity, user, editSaveHandler, baseUrl
}) => {
  const [certEditable, setCertEditable] = useState(false)
  const [docEditable, setDocEditable] = useState(false)
  const { addToast } = useToasts()

  const handleCertEditableChange = () => {
    setCertEditable(true)
  }

  const handleDocEditableChange = () => {
    setDocEditable(true)
  }

  const handleEditCancel = () => {
    setCertEditable(false)
    setDocEditable(false)
  }

  const handleInvestorSave = details => {
    const isCertVerified = (details.ai_doc_verified && !entity.investor_certificate?.sg_certificate_verified)
      || (details.certificate_verified && !entity.investor_certificate?.wholesale_certificate_verified)
    updateEntityInvestorCert(baseUrl, entity.id, jsonToFormData(details, "user")).then(data => {
      const response = data.response.json
      if (response.success) {
        if (isCertVerified) {
          sendCertificateVerificationEmail(baseUrl, user.id, details).then(res => {
            const emailResponse = res.response.json
            if (emailResponse) {
              addToast(emailResponse.message, { appearance: "success" })
            }
          })
        }
        editSaveHandler()
        handleEditCancel()
        addToast("User detail updated successfully.", { appearance: "success" })
      } else {
        addToast(response.message, { appearance: "error" })
      }
    })
  }

  return (
    <div className="card my-3">
      <div className="card-header">Investor Profile</div>
      <div className="card-body">
        <table className="table table-sm table-borderless yt-table">
          <thead>
            <tr>
              <th className="pl-0">Documents</th>
              <th>Date</th>
              <th className="pl-0">Status</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {
              certEditable
                ? (
                  <WholesaleDocEdit
                    title="s708 Wholesale Certificate"
                    issueDate={entity.investor_certificate?.issue_date}
                    verified={entity.investor_certificate?.wholesale_certificate_verified}
                    fileUrl={entity.investor_certificate?.wholesale_cert?.url}
                    fileName={entity.investor_certificate?.wholesale_cert?.name}
                    saveHandler={handleInvestorSave}
                    entity={entity}
                    cancelHandler={handleEditCancel} />
                  )
                : (
                  <InvestorDocRow
                    title="s708 Wholesale Certificate"
                    issueDate={entity.investor_certificate?.issue_date}
                    verified={entity.investor_certificate?.wholesale_certificate_verified}
                    fileUrl={entity.investor_certificate?.wholesale_cert?.url}
                    editHandler={handleCertEditableChange} />
                )
            }
            {
              docEditable
                ? (
                  <InvestorDocEdit
                    title="AI Documentation"
                    verified={entity.investor_certificate?.sg_certificate_verified}
                    fileUrl={entity.investor_certificate?.doc_file?.url}
                    supFile1={entity.investor_certificate?.sup_doc_1}
                    supFile2={entity.investor_certificate?.sup_doc_2}
                    supFile3={entity.investor_certificate?.sup_doc_3}
                    sgdValue={entity.investor_certificate?.sgd_value}
                    fileName={entity.investor_certificate?.doc_file?.name}
                    saveHandler={handleInvestorSave}
                    entity={entity}
                    cancelHandler={handleEditCancel} />
                )
                : (
                  <InvestorDocRow
                    title="AI Documentation"
                    issueDate={entity.investor_certificate?.doc_issue_date}
                    verified={entity.investor_certificate?.sg_certificate_verified}
                    fileUrl={entity.investor_certificate?.doc_file?.url}
                    editHandler={handleDocEditableChange} />
                )
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  baseUrl: getBaseUrl(state)
})

export default connect(mapStateToProps)(InvestorDocDetail)