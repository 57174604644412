import React, { useEffect, useState } from "react"
import { useToasts } from "react-toast-notifications"
import { formatWithComma, onlyNumbers } from "components/CommonMethods"
import { joinWaitlist } from "../../../requesters"
import Progress from "./Progress"
import ErrorModal from "./ErrorModal"

const DEFAULT_MINIMUM_INVESTMENT = 500000

const ExpressionOfInterest = ({
 offering, reloadData, currentEntity
}) => {
  const [enableInvestment, setEnableInvestment] = useState(false)
  const [investmentAmount, setInvestedAmount] = useState(offering.need_fixed_investment ? offering.min_investment : 0)
  const [showModal, setShowModal] = useState(false)
  const minInvestmentLbl = "Minimum amount"

  // edge cases where fresh entities do not have an investor cert
  let userRestriction = "500k"
  if (currentEntity.country === "SG") {
    userRestriction = (currentEntity.investor_certificate && currentEntity.investor_certificate[`invest_limit_${offering.region}`]) || "restricted"
  } else {
    userRestriction = (currentEntity.investor_certificate && currentEntity.investor_certificate[`invest_limit_${offering.region}`]) || "500k"
  }

  const { addToast } = useToasts()
  useEffect(() => {
    setEnableInvestment(
      Number.isFinite(investmentAmount)
      && investmentAmount >= offering.min_investment
    )
  }, [investmentAmount])

  const investButtonClickHandler = async () => {
    if (currentEntity.background_check_status === "verified"
      && (userRestriction === "unrestricted" || (userRestriction === "500k" && investmentAmount >= DEFAULT_MINIMUM_INVESTMENT))
    ) {
      const data = await joinWaitlist(offering.id, investmentAmount, currentEntity.id)
      const res = data.response.json
      if (res.success) {
        setShowModal(false)
        addToast("Congratulations! Your expression of interest has been received.", { appearance: "success" })
      } else {
        addToast(res.message, { appearance: "error" })
      }
      reloadData()
    } else {
      setShowModal(true)
    }
  }

  // Ideally this needs to be in a callback from closing the error modal
  // Fixes bug where dismissing the error modal wasn't resetting the showModal variable
  useEffect(() => {
    setShowModal(false)
  }, [investmentAmount])

  return (
    <div className="col-md-4 order-1 order-md-2 mb-5">
      <div className="card shadow-sm card-body sticky-top">
        <Progress offering={offering} />
        <h3 className="mb-3">Expression of Interest</h3>
        <div className="form-group input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text border-right-0" id="basic-addon1">
              {`Amount (${offering.currency})`}
            </span>
          </div>
          <input
            onKeyDown={onlyNumbers}
            type="number"
            min={0}
            aria-label="Investment Amount"
            className="form-control border-left-0 text-right"
            onChange={e => setInvestedAmount(parseFloat(e.target.value))}
            defaultValue={offering.need_fixed_investment ? offering.min_investment : undefined}
            placeholder="$0.00" />
        </div>
        <div className="text-center">
          <div className="small">
            {minInvestmentLbl}
            &nbsp;
            {`${offering.currency} ${formatWithComma(offering.min_investment)}`}
          </div>
        </div>
        <button
          type="submit"
          onClick={investButtonClickHandler}
          data-target="#errorModal"
          className="btn btn-lg btn-block btn-main mt-4"
          data-toggle="modal"
          disabled={!enableInvestment}>
          Submit
        </button>
      </div>
      {showModal && (
        <ErrorModal
          offeringCountry={offering.country}
          entity={currentEntity}
          userNotVerified={currentEntity.background_check_status !== "verified"}
          userRestriction={userRestriction} />
      )}
    </div>
  )
}

export default ExpressionOfInterest
