import React, { useCallback, useState } from "react"
import { useDropzone } from "react-dropzone"
import ErrorMessage from "./VerificationForms/ErrorMessage"

export default function Dropzone({
  wrapperClass, activeText,
  inactiveText, handleFileUpload,
  name, multiple, defaultValue, fileTypes, fileTypeNames
}) {
  const [file, setFile] = useState(null)
  const [errorMessage, setErrorMessage] = useState(undefined)
  const onDrop = useCallback((acceptedFile, rejectedFile) => {
    if (acceptedFile.length) {
      handleFileUpload({ name, value: acceptedFile })
      setFile(acceptedFile)
      setErrorMessage(undefined)
    } else if (rejectedFile.length) {
      setErrorMessage(rejectedFile[0].errors[0].code)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple, accept: fileTypes })

  function boxText() {
    if (file && file[0]) {
      return file[0].name
    }
    if (defaultValue && defaultValue[0]) {
      return defaultValue[0].name
    }
    return (isDragActive ? activeText : inactiveText)
  }

  return (
    <>
      <div className={wrapperClass} {...getRootProps()}>
        <input {...getInputProps()} />
        { boxText() }
      </div>
      {errorMessage && <ErrorMessage message={errorMessage === "file-invalid-type" ? `File type must be ${fileTypeNames || fileTypes}` : errorMessage} />}
    </>
  )
}
