import { createStore, applyMiddleware } from "redux"
import { composeWithDevTools }          from "redux-devtools-extension"
import createSagaMiddleware             from "redux-saga"
import thunk                            from "redux-thunk"
import { routerMiddleware }             from "connected-react-router"

import { createBrowserHistory } from "history"
import createRootReducer from "./reducers/index"

export const history = createBrowserHistory({ basename: `` })

export default function configureStore(initialState) {
  const RouterMiddleware  = routerMiddleware(history)
  const SagaMiddleware    = createSagaMiddleware()

  return {
    ...createStore(
      createRootReducer(history),
      initialState,
      composeWithDevTools(
        applyMiddleware(
          RouterMiddleware,
          SagaMiddleware
        ),
        applyMiddleware(thunk)
      )
    ),
    runSaga: SagaMiddleware.run
  }
}
