export const FIELD_VALIDATIONS = [
  { field: "validCountry", message: "Please select a country" },
  { field: "validFirstname", message: "Please enter alphabetic characters, spaces, apostrophes, and hyphens only" },
  { field: "validMiddlename", message: "Please enter alphabetic characters, spaces, apostrophes, and hyphens only" },
  { field: "validSurname", message: "Please enter alphabetic characters, spaces, apostrophes, and hyphens only" },
  { field: "validGender", message: "Please select an option" },
  { field: "validDateOfBirth", message: "Must be a valid date over 18 years" },
  { field: "validWealthSource", message: "Please select an option" },
  { field: "validWealthDescription", message: "A description must be provided if other is chosen" },

  { field: "validCountry", message: "Please select a country" },
  { field: "validTfn", message: "Please provide a valid TFN/TIN" },
  { field: "validExplanation", message: "Please provide few details" },
  { field: "validTaxDetailAcceptance", message: "You must agree with above statement" },
  { field: "validAddress", message: "Please provide a valid address" }
]

export function validDropdown(value) {
  return !!value
}

export function validateAddress(value) {
  return !!value
}

export function validName(value) {
  const validName = /^[a-z '"-]{1,50}$/i
  return validName.test(String(value))
}

export function validMiddleName(value) {
  const validMiddleName = /^[a-z '"-]{0,50}$/i
  return validMiddleName.test(String(value))
}

export function validDob(value) {
  const validDate = new Date(value)
  if (!validDate) {
    return false
  }
  return new Date().getYear() - validDate.getYear() >= 18
}

export function validateDobObject(dateOfBirth) {
  const dateStr = `${dateOfBirth.month}/${dateOfBirth.day}/${dateOfBirth.year}`
  return validDob(dateStr)
}

export function validDescription(value) {
  return !!value
}

/*  Tax details form start */

export function validCountry(value) {
  return !!value
}

export function validTfn(value, notApplicable) {
  if (notApplicable) {
    return true
  }
  return !!value
}

export function validExplanation(value, notApplicable) {
  if (notApplicable) {
    return !!value
  }
  return true
}
/*  Tax details form end */