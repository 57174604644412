import React from "react"
import { Modal } from "react-bootstrap"

const ErrorModal = ({
  offeringCountry, entity, show, onHide, userNotVerified, userRestriction
}) => {
  let errorHeader = "Submit your investor documentation to proceed"
  let errorMessage = "To invest less than $500k in a deal we need to have received and verified your s708 Wholesale Certificate. If you have not yet uploaded your document, you can do so in your <a href='/account' target='_blank'>Account Settings</a>. Verification of your uploaded documents usually takes less than 24 hours."
  if (userNotVerified) {
    errorHeader = "Identity Verification Pending"
    errorMessage = "We need to verify your identity before you are able to invest into marketplace deals. This usually takes less than 24 hours. If you are still not able to proceed, please get in touch."
  } else if (entity.country === "SG" && userRestriction === "restricted") {
    errorHeader = "Submit your Accredited Investor documentation to proceed"
    errorMessage = "To invest in a deal we need to have received and verified your Accredited Investor documentation. Verification usually takes less than 24 hours once it has been uploaded. If you are still not able to proceed, please get in touch."
  } else if (offeringCountry === "Singapore" && userRestriction === "restricted") {
    errorHeader = "Submit your Accredited Investor documentation to proceed"
    errorMessage = "To invest in a Singapore deal we need to have received and verified your Accredited Investor documentation. Verification usually takes less than 24 hours once it has been uploaded. If you are still not able to proceed, please get in touch."
  }

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <div className="modal-content">
        <div className="modal-header border-0">
          <button aria-label="Close" className="close" onClick={onHide} type="button">
            <span aria-hidden="true"> ×</span>
          </button>
        </div>
        <div className="modal-body pt-0 text-center">
          <h3 className="mb-3">{errorHeader}</h3>
          {/* eslint-disable-next-line react/no-danger */}
          <p dangerouslySetInnerHTML={{ __html: errorMessage }}></p>
        </div>
        <div className="modal-footer mx-auto border-0 pb-5">
          <button className="btn btn-main btn-lg" onClick={onHide} type="button">
            Okay
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ErrorModal
