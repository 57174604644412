import React from "react"
import Money from "components/Money"

const CancelledInvestmentRow = ({
 investment
}) => (
  <tr key={`investment-${investment.id}`}>
    <td>{investment.entity_name}</td>
    <td>{investment.entity_type}</td>
    <td>{ investment.entity_phone}</td>
    <td className="text-right">
      <Money currency="$" amount={investment.amount} />
    </td>
    <td>{ investment.updated_at}</td>
  </tr>
  )

export default CancelledInvestmentRow