import React from "react"
import { Link } from "react-router-dom"

const SidebarNav = () => (
  <div className="col-lg-3 d-none d-lg-block">
    <ul className="nav flex-column mt-5 sticky-top account-menu">
      <li className="nav-item">
        <Link className="nav-link" to="/account/" activestyle="nav-link active">Account</Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link" to="/account/past-investments">Past Investments</Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link" to="/account/banking">Banking</Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link" to="/account/transactions">Transactions</Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link" to="/account/settings">Settings</Link>
      </li>
    </ul>
  </div>
  )

export default SidebarNav
