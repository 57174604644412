/* eslint-disable no-unused-expressions */
import axios from "axios"

export const getUserDetails = (baseUrl, identifier) => {
  const path = `${baseUrl}/backend/entities/${identifier}/details`
  return axios.get(path)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const getUnregisteredUserDetails = (baseUrl, identifier) => {
  const path = `${baseUrl}/users/${identifier}/details`
  return axios.get(path)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const getTransactions = (baseUrl, identifier) => {
  const path = `${baseUrl}/entities/${identifier}/transactions`
  return axios.get(path)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const sendResetPasswordEmail = (baseUrl, identifier) => {
  const path = `${baseUrl}/users/${identifier}/send_reset_password_email`
  return axios.post(path)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const sendCertificateVerificationEmail = (baseUrl, identifier, attributes) => {
  const path = `${baseUrl}/users/${identifier}/send_certificate_email`
  return axios.post(path, attributes)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const updateUserProfile = (baseUrl, identifier, userDetails) => {
  const path = `${baseUrl}/users/${identifier}/update`
  return axios.put(path, userDetails)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const updateUserNotes = (baseUrl, identifier, userDetails) => {
  const path = `${baseUrl}/users/${identifier}/update_notes`
  return axios.put(path, userDetails)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

// need this for adding the AI SG doc or wholesale cert
export const updateEntityInvestorCert = (baseUrl, identifier, details) => {
  const path = `${baseUrl}/entities/${identifier}/update_cert`
  return axios.post(path, details)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const getUserInvestments = (baseUrl, identifier) => {
  const path = `${baseUrl}/backend/entities/${identifier}/investments`
  return axios.get(path)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const getFundedInvestments = (baseUrl, identifier) => {
  const path = `${baseUrl}/backend/entities/${identifier}/funded_investments`
  return axios.get(path)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const getAuditLogs = (baseUrl, quantity = 100) => {
  const path = `${baseUrl}/backend/entities/1/audit`
  return axios.post(path, { quantity })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const verifyUserIdentity = (baseUrl, identifier, status) => {
  const path = `${baseUrl}/backend/entities/${identifier}/verify`
  return axios.post(path, status)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const getOnboardingUsers = () => {
  const path = `/api/users/unregistered_users`
  return axios.get(path)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const deleteUser = (baseUrl, identifier) => {
  const path = `${baseUrl}/backend/entities/${identifier}/delete`
  return axios.post(path)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}
