import React from "react"

const ConfirmFundModal = ({ investmentId, confirmFundEventhandler }) => (
  <div aria-labelledby="confirmFundModal" className="modal fade" id={`confirmFundModal-${investmentId}`} role="dialog" tabIndex={-1} style={{ display: "none" }} aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content">
        <div className="modal-header border-0">
          <button aria-label="Close" className="close" data-dismiss="modal" type="button">
            <span aria-hidden="true"> ×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <h3 className="modal-title mb-3">Confirm fund?</h3>
        </div>
        <div className="modal-footer border-0">
          <button className="btn btn-primary" id="btnCloseModal" data-dismiss="modal" type="button" onClick={confirmFundEventhandler}>
            Confirm
          </button>
          <button className="btn btn-secondary" id="btnCloseModal" data-dismiss="modal" type="button">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
)

export default ConfirmFundModal