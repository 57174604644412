// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
// require("trix")
// require("@rails/actiontext")
require("channels")

import axios from "axios"
import './bootstrap_custom.js'
import '../stylesheets/application'
import * as Sentry from '@sentry/browser'
import boot from "../lib/boot"

// Axios Configuration
const configureAxios = axios => {
  axios.defaults.headers.common["X-CSRF-Token"] = document.getElementsByName("csrf-token")[0].getAttribute("content")
  axios.defaults.headers.common.Accept = "application/json"
  axios.defaults.headers.common["Content-Type"] = "application/json"
  if (_.has(gon, "auth_token")) {
    axios.defaults.headers.common["X-Auth-Token"] = _.get(gon, "auth_token")
  }
}

$(() => {
  // Sentry.init({ dsn: 'https://fc825f312d8c4c10855f412aa2326686@sentry.io/201906' });
  configureAxios(axios)

  // Raven.context(function() {
  boot.init()
  // });
})

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
