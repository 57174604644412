import React from "react"
import { useToasts } from "react-toast-notifications"
import Money from "components/Money"
import { cancelInvestment } from "../../../requesters"
import CancelInvestmentModal from "../../common/CancelInvestmentModal"

const FundedInvestmentRow = ({
 investment, offering, reloadData, reloadOffering
}) => {
  const investmentName = investment.entity_role ? "External" : investment.entity_name
  const { addToast } = useToasts()

  const cancelInvestmentHandler = () => {
    cancelInvestment(investment.id).then(data => {
      const res = data.response.json
      if (res.success) {
        addToast("Investment has been cancelled", { appearance: "success" })
        reloadData()
        reloadOffering()
      } else {
        addToast(res.message, { appearance: "error" })
      }
    })
  }

  return (
    <>
      <tr key={`investment-${investment.id}`}>
        <td>{investmentName}</td>
        <td>{`# ${investment.reference_no}`}</td>
        <td>{investment.entity_type}</td>
        <td className="text-right">
          <Money currency="$" amount={investment.amount} />
        </td>
        <td className="text-right">
          <Money currency="$" amount={investment.interest_received} />
        </td>
        <td className="text-right">
          <Money currency="$" amount={investment.primary_repaid} />
        </td>
        <td className="text-right">
          <Money currency="$" amount={investment.other} />
        </td>
        <td className="text-right">
          <div className="d-flex justify-content-end">
            <div className="dropdown ml-2">
              <button type="button" className="btn btn-sm btn-outlined-main dropdown-toggle" data-toggle="dropdown" id="dropdownMenuLink" />
              <div
                aria-labelledby="dropdownMenuLink"
                className="dropdown-menu dropdown-menu-right"
                x-placement="bottom-end"
                style={{
                position: "absolute", willChange: "transform", top: 0, left: 0, transform: "translate3d(26px, 31px, 0px)"
              }
            }>
                <a className="dropdown-item" href={investment.document_url} rel="noreferrer" target="_blank">Download Subscription Document</a>
                <a className="dropdown-item" href={offering.im_file} rel="noreferrer" target="_blank"> Download IM</a>
                {
                  investmentName === "External" && (
                    <button
                      className="dropdown-item"
                      type="button"
                      data-target={`#cancelModal-${investment.id}`}
                      data-toggle="modal">
                      Cancel Investment
                    </button>
                  )
                }
              </div>
            </div>
          </div>
        </td>
        <CancelInvestmentModal cancelInvestmentHandler={cancelInvestmentHandler} investmentId={investment.id} />
      </tr>
    </>
  )
}

export default FundedInvestmentRow
