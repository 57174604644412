import React from "react"
import { formatWithComma } from "components/CommonMethods"
import Progress from "./Progress"
import Timeline from "./Timeline"
import CancelInvestment from "./CancelInvestment"

const OnWaitlist = ({ offering, investment, reloadData }) => (
  <div className="col-md-4 order-1 order-md-2 mb-5">
    <div className="card shadow-sm card-body sticky-top">
      <Progress offering={offering} />
      <h3 className="mb-3">Oversubscribed</h3>
      <div className="small mb-4">
        Offer currently fully allocated. You have been placed on the waitlist.
      </div>
      <div className="d-flex justify-content-between">
        {` Amount (${offering.currency})`}
        <strong className="med">
          {`$${formatWithComma(investment.amount)}`}
        </strong>
      </div>
      <CancelInvestment text="Cancel Waitlist" linkClass="btn btn-lg btn-block btn-outlined-main mt-4" investment={investment} reloadData={reloadData} />
      <div className="mt-4">
        <table className="table table-borderless yt-table table-sm table-between">
          <tbody>
            <Timeline title="Investor Funding Date" date={offering.investor_funding_date} />
            <Timeline title="Issue Date" date={offering.issue_date} />
          </tbody>
        </table>
      </div>
    </div>
  </div>

  )

export default OnWaitlist
