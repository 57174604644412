import React, { useState } from "react"

const titleCase = s => s.replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()) // Initial char (after -/_)
   .replace(/[-_]+(.)/g, (_, c) => ` ${ c.toUpperCase()}`) // First char after each -/_


const BankDetails = ({
  details
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="card my-3">
      <div className="card-header">Bank Details</div>
      <div className="card-body">
        <div className="d-flex align-items-center justify-content-between">
          <p className="mb-0">Details</p>
          <button
            type="button"
            aria-controls="collapseBank"
            aria-expanded="false"
            className="btn btn-link btn-sm collapsed"
            data-toggle="collapse"
            onClick={() => setIsOpen(!isOpen)}
            href="#collapseBank">
            {isOpen ? "Hide Details" : "View Details"}
          </button>
        </div>
        <div className="collapse" id="collapseBank" style={{}}>
          <hr />
          {details?.map(detail => (
            <table className="table table-sm table-borderless backend-info" key={detail.account_number}>
              <tbody>
                {Object.keys(detail).map(key => (
                  <tr key={key}>
                    <td>{titleCase(key)}</td>
                    <td>{detail[key]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ))}
        </div>
      </div>
    </div>
  )
}

export default BankDetails
