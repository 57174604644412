import BaseController from "controllers/marketplace/base"

import startMarketplaceApp from "apps/Marketplace"

class Controller extends BaseController {
  call() {
    super.call()

    const rootElem = document.getElementById("marketplace-app")
    if (rootElem) {
      startMarketplaceApp(rootElem)
    }
  }
}

export default Controller
