import React, { useState, useEffect } from "react"
import { useToasts } from "react-toast-notifications"
import Spinner from "components/Spinner"
import { updateYieldBatch, exportAbaFile } from "../../requesters"
import YieldBatchCalculation from "./YieldBatchCalculation"

const ReturnDetail = ({ selectedReturn }) => {
  const { addToast } = useToasts()
  const [interestArray, setInterestArray] = useState([])
  const [principalArray, setPrincipalArray] = useState([])
  const [otherArray, setOtherArray] = useState([])
  const [yieldBatchObj, setYieldBatchObj] = useState({ ...selectedReturn })
  const [requestState, setRequestState] = useState()

  const updateBatchButtonClickHandler = () => {
    const form = {
      interest_description: yieldBatchObj.interest_description,
      principal_description: yieldBatchObj.principal_description,
      other_description: yieldBatchObj.other_description,
      interest_array: interestArray,
      principal_array: principalArray,
      other_array: otherArray
    }
    updateYieldBatch(selectedReturn.id, { form }).then(data => {
      const res = data.response.json
      if (res.success) {
        addToast("Batch has been updated!", { appearance: "success" })
      } else {
        addToast(res.message, { appearance: "error" })
      }
    })
  }

  const exportAbaHandler = () => {
    updateBatchButtonClickHandler()
    setRequestState("inFlight")
    const entityBankDetails = yieldBatchObj.investment_details.map(i => i.entity_bank_account.find(element => element.country === yieldBatchObj.country)
      || i.entity_bank_account[0])

    // Check the interest array is not the empty array
    // (transactions are missed if the YieldBatch is only full of "Other" amounts)
    const iterativeArray = interestArray.length ? interestArray : otherArray

    const interestTransactions = iterativeArray.map((i, index) => ({
      yield_batch_id: yieldBatchObj.id,
      account_name: entityBankDetails[index]?.account_name,
      bsb: entityBankDetails[index]?.bsb_number,
      account_number: entityBankDetails[index]?.account_number,
      entity_id: entityBankDetails[index]?.entity_id,
      description: yieldBatchObj?.interest_description,
      offering_name: selectedReturn.offering_name,
      currency: selectedReturn.currency,
      return_type: "Return",
      amount: 100 * (otherArray[index] || 0 + i || 0)
    }))

    const principalTransactions = principalArray.map((i, index) => ({
      yield_batch_id: yieldBatchObj.id,
      account_name: entityBankDetails[index]?.account_name,
      bsb: entityBankDetails[index]?.bsb_number,
      account_number: entityBankDetails[index]?.account_number,
      entity_id: entityBankDetails[index]?.entity_id,
      description: yieldBatchObj?.principal_description,
      offering_name: selectedReturn.offering_name,
      currency: selectedReturn.currency,
      return_type: "Principal",
      amount: 100 * (i || 0)
    }))

    const form = []
    let transactionError = false
    // eslint-disable-next-line no-unused-vars
    const array = [...interestTransactions, ...principalTransactions].every(transaction => {
      if (transaction.amount > 0 && !!transaction.account_number && !!transaction.account_name) {
        form.push(transaction)
        return true
      } if (transaction.amount > 0 && (!transaction.account_number || !transaction.account_name)) {
        transactionError = true
        return false
      }
        return true
    })

    if (transactionError) {
      addToast("Entities' Bank Details are missing; please remove any yield amounts in their row", { appearance: "error" })
      setRequestState(undefined)
    } else {
      exportAbaFile(yieldBatchObj.id, form).then(data => {
        const res = data.response.json
        if (res.success) {
          setRequestState("submitted")
          addToast("ABA file exported!", { appearance: "success" })
          const data = new Blob([res.data.download_data], { type: "aba" })
          const textFile = window.URL.createObjectURL(data)
          // returns a URL you can use as a href
          const a = document.createElement("a")
          a.download = "export.aba"
          a.href = textFile
          // For Firefox https://stackoverflow.com/a/32226068
          document.body.appendChild(a)
          a.click()
          a.remove()
        } else {
          addToast(res.message, { appearance: "error" })
        }
      })
    }
  }

  const refreshDetailHandler = () => {
    window.location.href = "/backend/returns"
  }

  useEffect(() => {
    if (!interestArray.length && yieldBatchObj?.interest_array) {
      setInterestArray(yieldBatchObj.interest_array)
    }
   if (!principalArray.length && yieldBatchObj?.principal_array) {
      setPrincipalArray(yieldBatchObj.principal_array)
    }
    if (!otherArray.length && yieldBatchObj?.other_array) {
      setOtherArray(yieldBatchObj.other_array)
    }
  }, [yieldBatchObj])

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb"></nav>
      <ol className="breadcrumb pb-0">
        <li className="breadcrumb-item">
          <a onClick={refreshDetailHandler} to="/backend/returns" href="/backend/returns">Yield Returns</a>
        </li>
        <li aria-current="page" className="breadcrumb-item active">{selectedReturn.batch_id}</li>
      </ol>
      <div className="card my-3">
        <div className="card-header">
          {yieldBatchObj.status !== "Exported"
            && (
            <div className="d-flex align-items-center justify-content-end">
              <button
                type="submit"
                disabled={requestState}
                onClick={updateBatchButtonClickHandler}
                className="btn btn-success">
                Save
              </button>
              {requestState === "submitted"
                ? (
                  <div className="btn btn-secondary ml-2">
                    <Spinner />
                  </div>
                ) : (
                  <button
                    type="submit"
                    onClick={exportAbaHandler}
                    disabled={requestState}
                    className="btn btn-primary ml-2">
                    Export ABA
                  </button>
                )}
            </div>
          )}
        </div>
        <div className="card-body">
          {/* <div className="pb-3 text-muted text-center">
            When the ABA file is exported, the transactions in the User dashboard aren’t updated until 9am
          </div> */}
          <h2><strong className="text-warning">{selectedReturn.status}</strong></h2>
          <strong>{selectedReturn.batch_id}</strong>
          <h2>{selectedReturn.offering_name}</h2>
          <div className="mb-3">{`${selectedReturn.country} · Legal`}</div>
          <ul className="list-unstyled">
            <li>
              Target Investor Yield:
              <strong>
                {selectedReturn.target_investor_yield}
              </strong>
            </li>
            <li>
              Interest Schedule:
              <strong>{selectedReturn.interest_schedule}</strong>
            </li>
            <li>
              Principal Schedule:
              <strong>{selectedReturn.principal_schedule}</strong>
            </li>
          </ul>

          {selectedReturn.investment_details.length ? (
            <YieldBatchCalculation
              isBatchExported={yieldBatchObj.status === "Exported"}
              interestArray={interestArray}
              setInterestArray={setInterestArray}
              principalArray={principalArray}
              setPrincipalArray={setPrincipalArray}
              otherArray={otherArray}
              setOtherArray={setOtherArray}
              yieldBatchObj={selectedReturn}
              setYieldBatchObj={setYieldBatchObj} />
            ) : (
              <div
                className="pb-3 text-muted text-center">
                This Offering does not have applicable Investments
              </div>
            )}
        </div>
      </div>
    </div>
  )
}

export default ReturnDetail
