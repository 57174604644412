import React, { Component, Fragment } from "react"
import { infoIcon } from "components/Icons"
import { allCountryList } from "components/countries"
import CountrySelector from "../../../Register/components/VerificationForms/AccountCreation/CountrySelector"
import ErrorMessage from "../../../Register/components/VerificationForms/AccountCreation/ErrorMessage"
import {
  validCountry,
  validTfn,
  validExplanation,
} from "../../../Register/components/VerificationForms/AccountCreation/formErrors"

class TaxDetailsForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      taxCount: 1,
      maxTaxCount: 6,
      unavailableTaxes: [],
      taxDetailsData: [],
      taxDetailsValid: undefined,
      validation: [],
    }
  }

  componentDidMount = () => {
    const { taxDetails } = this.props

    taxDetails.forEach((e, i) => {
      allCountryList.forEach(ct => {
        if (e.country === ct.label) {
          taxDetails[i].country = ct.value
        }
      })
    })

    if (taxDetails.length) {
      this.setState({
        taxCount: taxDetails.length,
        unavailableTaxes: taxDetails
          .map((elem, index) => (elem.country && !elem.available ? index : null))
          .filter(x => x != null),
        taxDetailsData: taxDetails,
        taxDetailsValid: false,
      })
    }

    $('[data-toggle="popover"]').popover()
  };

  handleChange = checked => {
    const { handleTaxDetailsAcceptance } = this.props

    this.mapAllTaxDetails()
    handleTaxDetailsAcceptance(checked)
  };

  handleInputChange = event => {
    if (event) { event.target.dataset.value = event.target.value }
    this.mapAllTaxDetails()
  };

  mapAllTaxDetails = () => {
    // eslint-disable-next-line no-unused-vars
    const { taxDetailsData, taxCount } = this.state
    const { handleAttributeChange } = this.props
    const taxValidation = []
    const taxDetailsDataTemp = []
    const taxCountries = document.getElementsByClassName("tax-details-country-wrapper")

    Array.from(taxCountries).map((details, index) => {
      const country = details.querySelector('[name="country"]').value
      const taxNumber = details.querySelector('[name="tfn"]').dataset.value
      const taxId = details.querySelector('[name="id"]').value
      const taxNumberNotApplicable = details.querySelector('[name="not_available"]').checked
      const explanationInput = details.querySelector('[name="explanation"]')

      const explanation = explanationInput ? explanationInput.value : ""

      taxValidation[index] = {
        country: validCountry(country),
        tfn: validTfn(taxNumber, taxNumberNotApplicable),
        explanation: validExplanation(explanation, taxNumberNotApplicable),
      }

      const taxDetails = {
        country,
        tfn: taxNumberNotApplicable ? "" : taxNumber,
        available: !taxNumberNotApplicable,
        explanation: taxNumberNotApplicable ? explanation : "",
        id: taxId,
      }
      taxDetailsDataTemp.push(taxDetails)

      return null
    })

    this.setState({ validation: taxValidation }, () => {
      this.validateAllTaxDetail()
    })

    this.setState({ taxDetailsData: taxDetailsDataTemp }, () => {
      handleAttributeChange(taxDetailsDataTemp, taxCount)
    })
  };

  validateAllTaxDetail = () => {
    const { validation } = this.state
    let isValid = true
    validation.forEach(elem => {
      if (!(elem.country && elem.tfn && elem.explanation)) {
        isValid = false
      }
    })

    this.setState({ taxDetailsValid: isValid })
  };

  handleCountryAdd = () => {
    const { taxCount, taxDetailsValid } = this.state
    this.handleInputChange()
    if (taxDetailsValid) {
      this.setState({ taxCount: taxCount + 1 })
    }
  };

  handleCountryRemove = id => {
    const { taxCount } = this.state
    const { handleCountryRemove } = this.props
    if (id) handleCountryRemove(id)
    this.setState({ taxCount: taxCount - 1 })
  };

  isInvalid = (index, field) => {
    const { validation } = this.state
    if (validation[index]) {
      return !validation[index][field]
    }
    return false
  };

  render() {
    const { taxCount, unavailableTaxes, maxTaxCount } = this.state
    const { taxDetails } = this.props

    const tinTFNDescription = "Enter your Taxpayer Identification Number (TIN), or Tax File Number (TFN)"
    return (
      <>
        <h3 className="mt-5 pt-4">Tax Details</h3>
        <div className="mt-1">
          {_.times(taxCount, index => (
            <Fragment key={`detail-${index}`}>
              <div id={`taxDetailsCountry-${index}`} className="tax-details-country-wrapper">
                <input type="hidden" name="id" defaultValue={taxDetails[index] && taxDetails[index].id} />
                <div className="form-group row mb-2">
                  <div className="col-sm-6 col-form-label">
                    <strong>{`Country of Tax Residence ${index + 1}`}</strong>
                  </div>
                  <div className="col-sm-6">
                    <CountrySelector
                      name="country"
                      defaultValue={taxDetails[index] && taxDetails[index].country}
                      onChange={() => {
                        this.handleInputChange()
                      }} />
                    {this.isInvalid(index, "country") && <ErrorMessage message="Please select a country" />}
                  </div>
                </div>
                <div className="form-group row mb-2">
                  <div className="col-sm-6 col-form-label">
                    Enter your TIN or TFN Number&nbsp;
                    <img
                      className="img-fluid"
                      data-toggle="popover"
                      data-trigger="hover"
                      data-content={tinTFNDescription}
                      src={infoIcon}
                      width="14px"
                      height="14px" />
                  </div>
                  <div className="col-sm-6">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="TIN or TFN"
                      autoComplete="off"
                      data-value={taxDetails[index] && taxDetails[index].tfn}
                      defaultValue={taxDetails[index] && taxDetails[index].tfn && taxDetails[index].tfn.replace(/\d(?=\d{3})/mg, "*")}
                      onChange={e => { this.handleInputChange(e) }}
                      name="tfn" />
                    {this.isInvalid(index, "tfn") && <ErrorMessage message="Please provide a valid TFN" />}
                    <div className="custom-control custom-checkbox mt-2">
                      <input
                        className="custom-control-input"
                        id={`customCheck1-${index}`}
                        name="not_available"
                        autoComplete="disableAll"
                        type="checkbox"
                        checked={unavailableTaxes.includes(index)}
                        onChange={() => {
                          if (unavailableTaxes.includes(index)) {
                            unavailableTaxes.splice(unavailableTaxes.indexOf(index), 1)
                          } else {
                            unavailableTaxes.push(index)
                          }
                          this.setState({ taxCount })
                          this.handleInputChange()
                        }} />
                      <label className="custom-control-label" htmlFor={`customCheck1-${index}`}>
                        {" "}
                        Not Available
                      </label>
                    </div>
                  </div>
                </div>
                {unavailableTaxes.includes(index) && (
                  <div className="form-group mt-3">
                    <label>If TIN not available, please provide an explanation</label>
                    <input
                      className="form-control"
                      type="text"
                      name="explanation"
                      autoComplete="disableAll"
                      defaultValue={taxDetails[index] && taxDetails[index].explanation}
                      placeholder="Explanation"
                      onChange={() => {
                        this.handleInputChange()
                      }} />
                    {this.isInvalid(index, "explanation") && <ErrorMessage message="Please provide an explanation" />}
                  </div>
                )}
                {taxCount !== index + 1 ? (
                  <hr className="py-3" />
                ) : (
                  <>
                    {index !== 0 && (
                      <button
                        type="button"
                        className="btn btn-link mt-3 text-danger"
                        onClick={() => this.handleCountryRemove(taxDetails[index] ? taxDetails[index].id : null)}>
                        Remove
                      </button>
                    )}
                    <hr />
                    {taxCount < maxTaxCount && (
                      <button type="button" className="btn btn-link" onClick={e => this.handleCountryAdd(e)}>
                        + Add Another Country
                      </button>
                    )}
                  </>
                )}
              </div>
            </Fragment>
          ))}
          <div className="mt-5 mb-4">
            <div className="custom-control custom-checkbox mt-2">
              <input
                className="custom-control-input"
                id="customCheck2"
                type="checkbox"
                name="tax_detail_acceptance"
                onChange={e => {
                  this.handleChange(e.target.checked)
                }} />
              <label className="custom-control-label" htmlFor="customCheck2">
                {" "}
                I certify that these are all of the countries and jurisdictions of which I am considered to be a tax
                resident
              </label>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default TaxDetailsForm
