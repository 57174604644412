/* eslint-disable no-use-before-define */
/* eslint-disable quote-props */
import React, { useState } from "react"
import { connect } from "react-redux"
import { useToasts } from "react-toast-notifications"
import Breadcrumb from "./Breadcrumb"
import {
 getUnregisteredUserDetails, sendResetPasswordEmail, updateUserNotes, updateUserProfile
} from "../../requesters"
import { getBaseUrl } from "../../reducers"
import InvestorDocDetail from "./InvestorDocDetail"
import PersonalDetail from "./PersonalDetail"
import UserIdTaxDetail from "../common/IdTaxDetail/UserIdTaxDetail"

const VIEW_DETAILS = 0

const UserDetail = ({
 user, setUser, baseUrl
}) => {
  const [currentUser, setCurrentUser] = useState(user)
  const [editMode, setEditMode] = useState(VIEW_DETAILS)
  const { addToast } = useToasts()

  const loadUserDetail = () => {
    getUnregisteredUserDetails(baseUrl, user.id).then(data => {
      setCurrentUser(data.response.json.data)
      setEditMode(VIEW_DETAILS)
    })
  }

  const mapUserDetails = () => {
    const userDetails = {}
    document.querySelectorAll("#editDetails input").forEach(element => { userDetails[element.name] = element.value })
    userDetails.phone_number_attributes = {}
    userDetails.phone_number_attributes.id = userDetails.phone_number_attributes_id
    userDetails.phone_number_attributes.phone_number = userDetails.phone_number_attributes_phone_number
    delete userDetails.phone_number_attributes_phone_number
    delete userDetails.phone_number_attributes_id
    userDetails.id = user.id
    return userDetails
  }

  const saveDetailsHandler = e => {
    e.preventDefault()
    updateUserDetails(mapUserDetails())
  }

  const saveCommentHandler = (referral, account_manager, general_notes) => {
    updateUserNotes(baseUrl, user.id, { referral, account_manager, general_notes }).then(data => {
      const res = data.response.json
      if (res.success) {
        setEditMode(VIEW_DETAILS)
        loadUserDetail()
        addToast("User detail updated successfully.", { appearance: "success" })
      } else {
        addToast(res.message, { appearance: "error" })
      }
    })
  }

  const updateUserDetails = data => {
    updateUserProfile(baseUrl, user.id, { user: data }).then(data => {
      const res = data.response.json
      if (res.success) {
        loadUserDetail()
        setEditMode(VIEW_DETAILS)
        addToast("User detail updated successfully.", { appearance: "success" })
      } else {
        addToast(res.message, { appearance: "error" })
      }
    })
  }

  const investorEditSaveHandler = () => {
    loadUserDetail()
  }

  const onResetPasswordHandler = () => {
    sendResetPasswordEmail(baseUrl, user.id).then(() => {
      addToast("Reset password email has been sent.", { appearance: "success" })
    })
  }

  return (
    <div className="container">
      <Breadcrumb name={user.name} setEntity={setUser} />
      <div className="row">
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              <h3><strong className={`text-main user-status-${user.registration_status?.toLowerCase()?.replace(" ", "-")}`}>{ user.registration_status.toLowerCase()?.replace(" ", "-")}</strong></h3>
              <h2>{ user.name}</h2>
              <div className="mb-3">
                { user.country}
                <div className="text-muted">
                  Joined on &nbsp;
                  {user.joined_on}
                </div>
              </div>
              <ul className="list-unstyled">
                <li className="my-2">
                  Users must finish onboarding before being able to invest
                </li>
              </ul>
              <hr />
            </div>
          </div>
        </div>
        <div className="col-md-8">
          <div className="card mb-3">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                Account Information
              </div>
            </div>
            <PersonalDetail
              editMode={editMode}
              setEditMode={setEditMode}
              user={currentUser}
              saveCommentHandler={saveCommentHandler}
              saveDetailsHandler={saveDetailsHandler}
              onResetPasswordHandler={onResetPasswordHandler} />
          </div>
          <UserIdTaxDetail user={currentUser} refetchUserDetail={loadUserDetail} baseUrl={baseUrl} />
          <InvestorDocDetail
            user={currentUser}
            editSaveHandler={investorEditSaveHandler} />
        </div>
      </div>
    </div>

  )
}

const mapStateToProps = state => ({
  baseUrl: getBaseUrl(state)
})

export default connect(mapStateToProps)(UserDetail)