/**
 * 126 - Aus Residents only need Passport or Driver Licence (only one ID); code kept incase they want to revert to original
 * https://trello.com/c/vlqiRuMn/126-identification-australia-residents
 */
import React, { Component } from "react"

import { backIcon } from "components/Icons"
import {
  PassportForm,
  LicenceForm,
  // MedicareForm,
  NriCardForm,
  ProveResidenceForm
} from "components/VerificationForms"
import Spinner from "components/Spinner"

class IdentityForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      inflight: false,
      validIdOne: false,
      validIdTwo: false
    }
  }

  componentDidMount() {
    const { setSaveAndExitDataFromState } = this.props
    window.scrollTo(0, 0)
    setSaveAndExitDataFromState() // To set the selected activeform even user does not change anything on current tab
  }

  updateValidIdOne = valid => {
    this.setState({ validIdOne: valid })
  }

  updateValidIdTwo = valid => {
    this.setState({ validIdTwo: valid })
  }

  handleBackClick = () => {
    const {
      handleFormChange,
      handleProfileIsValid,
    } = this.props

    handleProfileIsValid(false)
    handleFormChange("profile")
  }

  handleSubmit = () => {
    const { handleCreateEntity } = this.props
    this.setState({ inflight: true })
    handleCreateEntity("pending_approval")
  }

  setDate = (handleDateChange, date, event) => {
    const {
      name,
      value
    } = event

    date[name] = value

    handleDateChange({ date })
    const expiryDate = new Date(`${date.month}/${date.day}/${date.year}`)

    if (expiryDate) {
      handleDateChange({ name: "expiry", value: expiryDate })
    }
  }

  setForm = (type, handleIdChange, formData, updateValidId) => {
    switch (type) {
      case "passport":
        return <PassportForm handleIdChange={handleIdChange} formData={formData[type]} setDate={this.setDate} date={formData[type].date} updateValidId={updateValidId} />
      case "licence":
        return <LicenceForm handleIdChange={handleIdChange} formData={formData[type]} updateValidId={updateValidId} />
      // 126 - Aus Residents only need Passport or Driver Licence; code kept incase they want to revert to original
      // case "medicare":
      //   return <MedicareForm handleIdChange={handleIdChange} formData={formData[type]} setDate={this.setDate} date={formData[type].date} updateValidId={updateValidId} />
      case "nric":
        return <NriCardForm handleIdChange={handleIdChange} formData={formData[type]} updateValidId={updateValidId} />
      case "utilityBill":
        return <ProveResidenceForm handleIdChange={handleIdChange} formData={formData} updateValidId={updateValidId} documentType="Utility Bill" />
      case "bankStatement":
        return <ProveResidenceForm handleIdChange={handleIdChange} formData={formData} updateValidId={updateValidId} documentType="Bank Statement" />
      case "governmentLetter":
        return <ProveResidenceForm handleIdChange={handleIdChange} formData={formData} updateValidId={updateValidId} documentType="Government Letter" />
      default: return null
    }
  }

  render() {
    const {
      handleIdOneChange,
      handleIdTwoChange,
      idOne,
      idOne: {
        activeType: oneType
      },
      idTwo,
      idTwo: {
        activeType: twoType
      },
      idDocument1,
      idDocument2,
      country,
    } = this.props

    const { inflight, validIdOne, validIdTwo } = this.state

    return (
      <div className="row justify-content-center">
        <div className="col-xl-7 col-lg-8">
          <div className="text-center"><h2 className="mt-5 pt-4">Identification</h2></div>
          <div className="my-5">
            <div className="form-group row mb-2">
              <div className="col-sm-6 col-form-label">
                <strong>ID Document</strong>
              </div>
              <div className="col-sm-6">
                <select
                  className="form-control custom-select"
                  value={oneType === undefined ? "" : oneType}
                  name="activeType"
                  onChange={e => handleIdOneChange({ name: e.currentTarget.name, value: e.currentTarget.value })}>
                  <option value="" disabled>Select</option>
                  {
                    idDocument1.map(doc => (
                      <option key={doc.value} value={doc.value}>{ doc.text}</option>
                    ))
                  }
                </select>
              </div>
            </div>
            { this.setForm(oneType, handleIdOneChange, idOne, this.updateValidIdOne) }
          </div>
          <hr />
          {country !== "AU" && (
            <div className="my-5">
              <div className="form-group row mb-2">
                <div className="col-sm-6 col-form-label">
                  <strong>{country === "SG" ? "Proof of Residence" : "Second ID Document"}</strong>
                </div>
                <div className="col-sm-6">
                  <select
                    className="form-control custom-select"
                    value={twoType === undefined ? "" : twoType}
                    name="activeType"
                    onChange={e => handleIdTwoChange({ name: e.currentTarget.name, value: e.currentTarget.value })}>
                    <option value="" disabled>Select</option>
                    {
                      idDocument2.map(doc => (
                        <option key={doc.value} value={doc.value}>{ doc.text}</option>
                      ))
                    }
                  </select>
                </div>
              </div>
              { this.setForm(twoType, handleIdTwoChange, idTwo, this.updateValidIdTwo) }
            </div>
          )}
          <div className="d-flex justify-content-between form-nav align-items-center">
            {inflight ? (
              <>
                <span />
                <Spinner />
                <span />
              </>
            ) : (
              <>
                <button
                  type="submit"
                  className="btn btn-lg btn-plain pl-0"
                  onClick={this.handleBackClick}>
                  <div className="d-flex align-items-center justify-content-start">
                    <img className="mr-2" src={backIcon} />
                    Back
                  </div>
                </button>
                <button
                  type="submit"
                  className="btn btn-main btn-lg"
                  disabled={country === "AU" ? !validIdOne : !(validIdOne && validIdTwo)}
                  onClick={this.handleSubmit}>
                  Create Account
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default IdentityForm