import React, { Component } from "react"
import { connect } from "react-redux"
import axios from "axios"

import { getCurrentUser } from "../../reducers"
import MainConfirmPasswordResetForm from "./mainConfirmPasswordResetForm"

class ConfirmPasswordResetForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formData: {
        password: undefined,
        password_confirmation: undefined
      }
    }
  }

  handleAttributeChange = event => {
    const { formData } = this.state
    const {
      name,
      value
    } = event

    formData[name] = value

    this.setState({ formData })
  }

  // eslint-disable-next-line no-useless-escape
  getQueryStringValue = key => decodeURIComponent(window.location.search.replace(new RegExp(`^(?:.*[&\\?]${ encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") }(?:\\=([^&]*))?)?.*$`, "i"), "$1"))

  handleSubmit = () => {
    const { formData } = this.state

    const path = "/users/password"
    const params = {
      ...formData,
      // authenticity_token: $('meta[name="csrf-token"]').prop("content"),
      reset_password_token: this.getQueryStringValue("reset_password_token")
    }

    // {"authenticity_token"=>"[FILTERED]", "user"=>{"reset_password_token"=>"[FILTERED]", "password"=>"[FILTERED]", "password_confirmation"=>"[FILTERED]"}, "commit"=>"Change my password"}

    axios.put(path, { user: params, authenticity_token: $('meta[name="csrf-token"]').prop("content") })
      .then(() => {
        // window.location.href = "/login"
      })
      .catch(error => (error))
  }

  render() {
    const {
      formData
    } = this.state

    const {
        currentUser
      } = this.props

    if (currentUser) {
        window.location.href = "/"
    }
    return (
      <MainConfirmPasswordResetForm formData={formData} handleSubmit={this.handleSubmit} handleAttributeChange={this.handleAttributeChange} />
    )
  }
}

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state)
})

export default connect(mapStateToProps)(ConfirmPasswordResetForm)
