import React from "react"
import Gallery from "react-grid-gallery"

const GalleryGrid = ({ offering }) => {
  const IMAGES = offering.gallery.map(img => ({
      src: img.url,
      thumbnail: img.url,
      thumbnailWidth: 320
    }))

  return (
    <Gallery images={IMAGES} backdropClosesModal enableImageSelection={false} />
  )
}

export default GalleryGrid
