import React, { Component } from "react"
import Spinner from "components/Spinner"
import { backIcon, infoIcon } from "components/Icons"
import { AccreditedForm, WholesaleForm } from "components/VerificationForms"

class InvestorForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      validHasCertificate: undefined,
      validWholeSaleCertificate: false,
      hasWholesaleCert: undefined,
      validHasInvestorDoc: undefined,
      validInvestorDocument: undefined,
      hasInvestDocs: undefined,
      formSubmitted: false
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    const {
      setSaveAndExitDataFromState,
      wholesaleCertificate: {
        id,
        hasWholesaleCertifcate,
        hasInvestorDocs
      }
    } = this.props

    setSaveAndExitDataFromState()
    if (id) {
      this.setState({
        validHasCertificate: [true, false].includes(hasWholesaleCertifcate),
        hasWholesaleCert: this.booleanOrValue(hasWholesaleCertifcate),
        validWholeSaleCertificate: this.booleanOrValue(hasWholesaleCertifcate),
        hasInvestDocs:  this.booleanOrValue(hasInvestorDocs),
        validHasInvestorDoc: [true, false].includes(hasInvestorDocs),
        validInvestorDocument: this.booleanOrValue(hasInvestorDocs)
      })
    }

    $('[data-toggle="popover"]').popover()
  }

  booleanOrValue = value => ([true, false].includes(value) ? value : undefined)

  setValidWholeSaleCertificate = valid => {
    this.setState({ validWholeSaleCertificate: valid })
  }

  setValidAccrediatedForm = valid => {
    this.setState({ validInvestorDocument: valid })
  }

  setDate = (handleDateChange, fieldName, date, event) => {
    const {
      name,
      value
    } = event

    date[name] = value

    const issueDate = new Date(`${date.month}/${date.day}/${date.year}`)
    handleDateChange({ name: fieldName, value: issueDate })
  }

  setWholesaleForm = value => {
    const {
      handleWholesaleChange,
      wholesaleCertificate
    } = this.props

    if (value) {
      return (
        <WholesaleForm
          setValidWholeSaleCertificate={this.setValidWholeSaleCertificate}
          handleWholesaleChange={handleWholesaleChange}
          wholesaleCertificate={wholesaleCertificate}
          setDate={this.setDate} />
      )
    }
    return <WholesaleBlurb />
  }

  setAccreditedForm = value => {
    const {
      handleWholesaleChange,
      wholesaleCertificate
    } = this.props

    if (value) {
      return (
        <AccreditedForm
          setValidAccrediatedForm={this.setValidAccrediatedForm}
          handleWholesaleChange={handleWholesaleChange}
          wholesaleCertificate={wholesaleCertificate}
          setDate={this.setDate} />
      )
    }
    return <AccreditedBlurb />
  }

  setFormSubmitted = () => {
    this.setState(false)
  }

  createEntity = () => {
    this.setState({ formSubmitted: true })
    const { handleCreateEntity } = this.props
    handleCreateEntity("pending_approval")
  }

  render() {
    const {
      handleFormChange,
      handleAttributeChange,
      handleWholesaleChange
    } = this.props

    const { hasWholesaleCert, hasInvestDocs, formSubmitted } = this.state

    const {
      validHasCertificate, validInvestorDocument,
      validWholeSaleCertificate, validHasInvestorDoc
    } = this.state

    const wholesaleTooltipContent = "To have full access to Australian deals, Wholesale Investors need to have a valid s708 certificate. You can upload this later if necessary"
    const accreditedInvestorContent = "To access Singapore deals, investors must provide documentation to show they are Accredited Investors. You can upload this later if necessary"

    return (
      <div className="row justify-content-center">
        <div className="col-xl-7 col-lg-8">
          <div className="text-center">
            <h2 className="mt-5 pt-4">Investor</h2>
          </div>
          <div className="my-5">
            <div className="d-md-flex align-items-center justify-content-between mb-5">
              <div className="mb-2 mb-md-0">
                <strong>I have an s708 Wholesale Certificate </strong>
                <img className="img-fluid" data-toggle="popover" data-trigger="hover" data-content={wholesaleTooltipContent} src={infoIcon} />
              </div>
              <div className="btn-group btn-group-toggle">
                <button
                  type="button"
                  className={`btn ${hasWholesaleCert ? "active" : ""}`}
                  onClick={() => {
                    this.setState({ validHasCertificate: true, hasWholesaleCert: true })
                    handleWholesaleChange({ name: "hasWholesaleCertifcate", value: true })
                  }}>
                  Yes
                </button>
                <button
                  type="button"
                  className={`btn ${hasWholesaleCert === false ? "active" : ""}`}
                  onClick={() => {
                    this.setState({ validHasCertificate: true, hasWholesaleCert: false })
                    handleAttributeChange({ name: "wholesale_investor", value: false })
                    handleWholesaleChange({ name: "hasWholesaleCertifcate", value: false })
                  }}>
                  No
                </button>
              </div>
            </div>
            { hasWholesaleCert !== undefined && this.setWholesaleForm(hasWholesaleCert) }
          </div>
          <hr />
          <div className="my-5">
            <div className="d-md-flex align-items-center justify-content-between mb-5">
              <div className="mb-2 mb-md-0">
                <strong>I have Accredited Investor documentation </strong>
                <img className="img-fluid" data-toggle="popover" data-trigger="hover" data-content={accreditedInvestorContent} src={infoIcon} />
              </div>
              <div className="btn-group btn-group-toggle">
                <button
                  type="button"
                  className={`btn ${hasInvestDocs ? "active" : ""}`}
                  onClick={() => {
                    handleAttributeChange({ name: "accredited_investor", value: true })
                    this.setState({ validHasInvestorDoc: true, hasInvestDocs: true })
                    handleWholesaleChange({ name: "hasInvestorDocs", value: true })
                  }}>
                  Yes
                </button>
                <button
                  type="button"
                  className={`btn ${hasInvestDocs === false ? "active" : ""}`}
                  onClick={() => {
                    handleAttributeChange({ name: "accredited_investor", value: false })
                    this.setState({ validHasInvestorDoc: true, hasInvestDocs: false })
                    handleWholesaleChange({ name: "hasInvestorDocs", value: false })
                  }}>
                  No
                </button>
              </div>
            </div>
            { hasInvestDocs !== undefined && this.setAccreditedForm(hasInvestDocs) }
          </div>
          <div className="d-flex justify-content-between form-nav align-items-center">
            <button
              type="button"
              className="btn btn-lg btn-plain pl-0"
              onClick={() => handleFormChange("identity")}>
              <div className="d-flex align-items-center justify-content-start">
                <img className="mr-2" src={backIcon} />
                Back
              </div>
            </button>
            {formSubmitted ? (
              <div
                className="btn btn-main btn-lg">
                <Spinner />
              </div>
              ) : (
                <button
                  type="submit"
                  disabled={!(validHasCertificate && (!hasWholesaleCert || validWholeSaleCertificate) && validHasInvestorDoc && (!hasInvestDocs || validInvestorDocument))}
                  onClick={this.createEntity}
                  className="btn btn-main btn-lg">
                  Create Account
                </button>
              )
            }
          </div>
        </div>
      </div>
    )
  }
}

const WholesaleBlurb = () => (
  <small>
    <p>
      That’s OK. If you have one in the future, you can upload it at any time in your account settings.
      <br />
      Please note that without an s708 certificate, you can only see offerings with minimum investment of $500K for Australian deals.
    </p>
    <p>
      {`If you need more information about how to obtain an s708 certificate, please see the FAQ - `}
      <a href="/">How do I get an s708 Certificate</a>
    </p>
  </small>
)

const AccreditedBlurb = () => (
  <small>
    <p>
      That’s OK. If you have one in the future, you can upload it at any time in your account settings.
      <br />
      Please note that without Accredited Investor documentation, Singapore deal availability will be limited. If you are Singapore resident, all deal availability will be limited.
    </p>
    <p>
      {`If you need more information about how to obtain an Accredited investor documentation, please see the FAQ - `}
      <a href="/">How do I get an Accredited investor documentation</a>
    </p>
  </small>
)

export default InvestorForm
