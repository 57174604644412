import React, { Component } from "react"
import { closeIcon } from "components/Icons"
import SelectedField from "./SelectedField"

class InvestorType extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showRetail: false,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  australiaInvestors = handleAttributeChange => (
    <>
      <button
        type="button"
        onClick={e => handleAttributeChange({ value: e.currentTarget.value, name: e.currentTarget.name })}
        value="wholesale"
        name="investor_type"
        className="btn btn-selection btn-selection-main btn-block mb-3 text-left px-5">
        <strong>Wholesale Investor</strong>
        <p className="small mb-0">
          I have gross income of more than A$250k annually for the past 2 years, or I have net assets of more than A$2.5m. I have a valid
          <span><strong> s708 (Wholesale) Certificate</strong></span>
          , or I am able to get one.
          <br />
          OR
          <br />
          I will only be investing more than A$500k per deal I wish to enter.
        </p>
      </button>
      <button
        type="button"
        onClick={e => handleAttributeChange({ value: e.currentTarget.value, name: e.currentTarget.name })}
        value="expert"
        name="investor_type"
        className="btn btn-selection btn-selection-main btn-block mb-3 text-left px-5">
        <strong>Expert Investor</strong>
        <p className="small mb-0">I have more than $10m in gross assets, or hold an AFSL, or control a company that employs more that 20 people.</p>
      </button>
    </>
  )

  singaporeInvestors = handleAttributeChange => (
    <>
      <button
        type="button"
        onClick={e => handleAttributeChange({ value: e.currentTarget.value, name: e.currentTarget.name })}
        value="accredited"
        name="investor_type"
        className="btn btn-selection btn-selection-main btn-block mb-3 text-left px-5">
        <strong>Accredited Investor</strong>
        <p className="small mb-0">I have gross income of more than SGD$300k in the previous 12 months; or I have net assets of at least SGD$2m (of which the net equity in my primary residence accounts for a maximum of SGD$1m); or I have net financial assets in excess of SGD$1m. I have documentary evidence of this or can have this certified by a qualified accountant.</p>
      </button>
    </>
  )

  rotwInvestors = handleAttributeChange => (
    <>
      <p className="mt-3 small">Aura Group Marketplace Investors must qualify as either Wholesale or Expert investors under Australian regulations:</p>
      <button
        type="button"
        onClick={e => handleAttributeChange({ value: e.currentTarget.value, name: e.currentTarget.name })}
        value="wholesale"
        name="investor_type"
        className="btn btn-selection btn-selection-main btn-block mb-3 text-left px-5">
        <strong>Wholesale Investor</strong>
        <p className="small mb-0">
          I have gross income of more than A$250k annually for the past 2 years, or I have net assets of more than A$2.5m. I have a valid
          <span><strong> s708 (Wholesale) Certificate</strong></span>
          , or I am able to get one.
          <br />
          OR
          <br />
          I will only be investing more than A$500k per deal I wish to enter.
        </p>
      </button>
      <button
        type="button"
        onClick={e => handleAttributeChange({ value: e.currentTarget.value, name: e.currentTarget.name })}
        value="expert"
        name="investor_type"
        className="btn btn-selection btn-selection-main btn-block mb-3 text-left px-5">
        <strong>Expert Investor</strong>
        <p className="small mb-0">I have more than $10m in gross assets, or hold an AFSL, or control a company that employs more that 20 people.</p>
      </button>
    </>
  )

  setInvestorTypes = handleAttributeChange => {
    const {
      country
    } = this.props

    switch (country) {
      case "AU":
        return this.australiaInvestors(handleAttributeChange)
      case "SG":
        return this.singaporeInvestors(handleAttributeChange)
      case "ROTW":
        return this.rotwInvestors(handleAttributeChange)
      default:
        return <div>Invalid Country Type</div>
    }
  }

  render() {
    const {
      showRetail,
    } = this.state

    const {
      country,
      handleAttributeChange
    } = this.props

    const countryName = {
      AU: "Australia",
      SG: "Singapore",
      ROTW: "Rest of the World"
    }[country]

    const retailMessage = {
      AU: "I do not meet the criteria of either Wholesale or Expert Investor.",
      SG: "I do not meet the criteria of an Accredited Investor.",
      ROTW: ""
    }[country]

    return (
      <div className="container text-center onboarding">
        <SelectedField
          title="Select Your Country"
          fieldName="country"
          selectedValue={countryName}
          onClick={e => handleAttributeChange({ value: e.currentTarget.value, name: e.currentTarget.name })}
          closeIcon={closeIcon} />
        <div className="my-5">
          <h2 className="mb-3">Select Investor Type</h2>
          <div className="row justify-content-center">
            <div className="col-lg-9">
              { this.setInvestorTypes(handleAttributeChange) }
              <button
                type="button"
                onClick={() => this.setState({ showRetail: !showRetail })}
                value="retail"
                name="investor_type"
                className={`btn btn-selection btn-selection-main btn-block mb-3 text-left px-5 ${showRetail ? "active" : ""}`}>
                <strong>Retail Investor</strong>
                {!showRetail ? (
                  <p className="small mb-0">{retailMessage}</p>
                ) : (
                  <p className="small mb-0 text-white">{retailMessage}</p>
                )
              }
              </button>
            </div>
            <div className="col-lg-6">
              {showRetail && <p>Currently Aura Group does not provide investment opportunities to retail clients.</p>}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default InvestorType
