import React from "react"
import { Provider } from "react-redux"
import ReactDOM from "react-dom"

import { ConnectedRouter }  from "connected-react-router"
import { ThemeProvider }    from "styled-components"

import { mainTheme }        from "themes"
import { CookiesProvider } from "react-cookie"
import configureStore, { history } from "./store"
import LastLocationCookie from "./components/LastLocationCookie"
import Header from "./components/Header"
import Main from "./components/Main"
import Sidebar from "./components/Sidebar"

const startPortalApp = rootElem => {
  const store = configureStore()

  ReactDOM.render(
    <CookiesProvider>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ThemeProvider theme={mainTheme}>
            <Header />
            <Sidebar />
            <main className="col-md-10 ml-sm-auto col-xl-11 px-4 pt-0" role="main">
              <Main />
            </main>
            <LastLocationCookie />
          </ThemeProvider>
        </ConnectedRouter>
      </Provider>
    </CookiesProvider>,
    rootElem
  )
}

export default startPortalApp
