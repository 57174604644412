export const TERMS_ARRAY = [
  "Access to digital asset investments are provided by a third party company and platform operated by Leonie Hill Digital Pty Ltd ACN 625 613 041 (AUSTRAC DCE100578761) (“Leonie Hill”), which is an affiliate of Aura Group. Client assets are segregated from Leonie Hill and held in a bankruptcy remote entity called Leonie Hill Custody Pty Ltd (AUSTRAC DCE100756107).  Aura Group is acting as a conduit to the platform via the Aura Group Marketplace. The investments available are not Aura Group investments. No advice or recommendations have been made or should deemed to have been made by accessing the digital asset platform via Aura Group.",
  "Digital assets such as crypto currencies are highly speculative investments with significant risks in which 100% of your capital can be lost. Markets for digital assets can be extremely volatile, meaning the value of assets can go down as well as up frequently. Liquidity is not guaranteed and there is a risk that the market for any particular asset will cease to exist. ",
  "You agree that Aura Group can share your information with Leonie Hill including (but not limited to) personal information, identity documents, email addresses and telephone numbers, and any other information required by the third party to operate an account for you and perform their “Know Your Client” (KYC) duties, and that these details may need to be updated and shared from time to time. You agree that Leonie Hill can use these details to the extent that their Privacy Policy allows, including to verify your KYC.",
  "By continuing to access the digital asset platform via the Aura Group Marketplace you are agreeing for Aura Group to arrange to open a digital asset account for you with Leonie Hill. The digital asset account is operated by Leonie Hill, and not by Aura Group. ",
  "By continuing to access the digital assets pages on the Aura Group Marketplace you are agreeing to these terms and conditions which are subject to change from time to time. If you do not agree to these terms and conditions you should not continue through to or access in any way the digital asset platform. ",
]

export const TICKBOXES_ARRAY = [
  { name: "isFirstChecked", label: "I agree to the Digital Asset Terms and Conditions above." },
  { name: "isSecondChecked", label: "I agree that Aura Group can share my details with Leonie Hill." },
  { name: "isThirdChecked", label: "I acknowledge that digital assets are highly speculative and can result in a total loss of capital." },
]