import BaseController from "controllers/registrations/base"
import startRegisterApp from "apps/Register"
class Controller extends BaseController {
  call() {
    super.call()

    const rootElem = document.getElementById("register-app")
    if (rootElem) {
      startRegisterApp(rootElem)
    }
  }
}

export default Controller