import React from "react"

const SelectedField = ({
  title, fieldName, selectedValue, closeIcon, onClick
}) => (
  <div className="justify-content-center">
    <h2 className="text-center">{title}</h2>
    <div className="row justify-content-center my-5">
      <div className="col-lg-9">
        <button
          type="button"
          onClick={onClick}
          value={undefined}
          name={fieldName}
          className="btn btn-selection active justify-content-center btn-block mb-3">
          { selectedValue }
          <img className="float-right mt-1 mr-2" src={closeIcon} />
        </button>
      </div>
    </div>
  </div>
)

export default SelectedField
