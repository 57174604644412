/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react"
import dayjs from "dayjs"

import InvestorSupportingDocEdit from "./InvestorSupportingDocEdit"

const InvestorDocEdit = ({
  entity,
  title,
  verified,
  fileUrl,
  fileName,
  saveHandler,
  cancelHandler,
  supFile1,
  supFile2,
  supFile3,
  sgdValue
}) => {
  const [file, setFile] = useState(undefined)
  const [fileVerified, setFileVerified] = useState(verified)
  const [selectedFileName, setSelectedFileName] = useState(fileName)
  const [selectedSupFile1, setSelectedSupFile1] = useState(supFile1)
  const [selectedSupFile2, setSelectedSupFile2] = useState(supFile2)
  const [selectedSupFile3, setSelectedSupFile3] = useState(supFile3)
  const [selectedFileUrl, setSelectedFileUrl] = useState(fileUrl)
  const [selectedSgdValue, setSelectedSgdValue] = useState(sgdValue)

  const onUploadClickHandler = e => {
    e.preventDefault()
    document.getElementById("investorDoc").click()
  }

  const onCancelHandler = e => {
    e.preventDefault()
    cancelHandler()
  }

  const fileChangeHandler = e => {
    const file = e.target.files[0]
    setFile(file)
    setSelectedFileName(file.name)
    setSelectedFileUrl(URL.createObjectURL(file))
  }

  const makeSubmitParams = () => {
    const data = {
      sgd_value: selectedSgdValue,
      doc_issue_date: dayjs().format("YYYY-MM-DD"),
      ai_doc_verified: fileVerified
    }

    if (entity.investor_certificate?.id) {
      data.id = entity.investor_certificate?.id
      if (file) {
        data.doc_file = file
      }
      if (selectedSupFile1?.file) {
        data.sup_doc_1 = selectedSupFile1.file
      }
      if (selectedSupFile2?.file) {
        data.sup_doc_2 = selectedSupFile2.file
      }
      if (selectedSupFile3?.file) {
        data.sup_doc_3 = selectedSupFile3.file
      }
    }

    return data
  }

  const onSaveHandler = e => {
    e.preventDefault()
    saveHandler(makeSubmitParams())
  }

  return (
    <>
      <tr>
        <td><strong>{title}</strong></td>
      </tr>
      <tr>
        <td className="p-0" colSpan={4}>
          <table className="table table-sm table-borderless backend-info">
            <tbody>
              <tr>
                <td>Type</td>
                <td className="p-0">
                  <select
                    defaultValue={selectedSgdValue || ""}
                    autoComplete="disableAll"
                    className="form-control custom-select"
                    name="sgd_value"
                    onChange={e => setSelectedSgdValue(e.target.value)}>
                    <option value="">Select</option>
                    <option value="SGD300k">SGD300k - I had a gross income of at least SGD300k in the previous 12 months</option>
                    <option value="SGD1m">SGD1m - I have net financial assets in excess of SGD1m </option>
                    <option value="SGD2m">SGD2m - I have net assets of at least SGD2m (where my primary residence accounts for no more than SGD1m)</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>File</td>
                <td className="px-0">
                  <div className="d-flex align-items-center justify-content-between">
                    <a rel="noreferrer" target="_blank" href={selectedFileUrl}>{ selectedFileName}</a>
                    <div className="hide">
                      <input type="file" id="investorDoc" name="investor_doc" accept="image/jpeg, image/png, application/pdf" onChange={fileChangeHandler} />
                    </div>

                    <a className="btn btn-outlined-main btn-sm" href="#" onClick={onUploadClickHandler}>Upload New</a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Supporting Documents</td>
                <td className="px-0">
                  <InvestorSupportingDocEdit selectedFile={selectedSupFile1} setSelectedFile={setSelectedSupFile1} index={1} />
                  <InvestorSupportingDocEdit selectedFile={selectedSupFile2} setSelectedFile={setSelectedSupFile2} index={2} />
                  <InvestorSupportingDocEdit selectedFile={selectedSupFile3} setSelectedFile={setSelectedSupFile3} index={3} />
                </td>
              </tr>
              <tr>
                <td>Document Verification</td>
                <td>
                  <div className="custom-control custom-checkbox">
                    <input className="custom-control-input" id="customCheck1" type="checkbox" defaultChecked={verified} onChange={e => setFileVerified(e.currentTarget.checked)} />
                    <label className="custom-control-label" htmlFor="customCheck1">Verified</label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                </td>
                <td>
                  <a className="btn btn-main btn-sm mr-2" href="#" onClick={onSaveHandler}>Save</a>
                  <a className="btn btn-secondary btn-sm mr-2" href="#" onClick={onCancelHandler}>Cancel</a>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </>
  )
}

export default InvestorDocEdit
