import React from "react"

const CancelInvestmentModal = ({ cancelInvestmentHandler, dataTarget }) => (
  <div
    aria-labelledby="pendingCancelledPopup"
    className="modal fade"
    id={dataTarget}
    role="dialog"
    tabIndex={-1}
    style={{ display: "none" }}
    aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content border-0 text-center">
        <div className="modal-header border-0 pb-0">
          <button aria-label="Close" className="close" data-dismiss="modal" type="button">
            <span aria-hidden="true"> ×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <h3 className="modal-title mb-3">Confirm to cancel investment?</h3>
        </div>
        <div className="modal-footer border-0 justify-content-center">
          <button className="btn btn-main" id="btnCloseModal" data-dismiss="modal" type="button" onClick={cancelInvestmentHandler}>
            Confirm
          </button>
          <button className="btn btn-outlined-main" id="btnCloseModal" data-dismiss="modal" type="button">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
)

export default CancelInvestmentModal