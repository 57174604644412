import React from "react"
import Open from "./Open"
import Closed from "./Closed"
import Issued from "./Issued"
import Deposited from "./Deposited"
import JoinWaitlist from "./JoinWaitlist"
import OnWaitlist from "./OnWaitlist"
import Pending from "./Pending"
import Signed from "./Signed"
import Missed from "./Missed"
import ExpressionOfInterest from "./ExpressionOfInterest"

const Investment = ({
  offering, investment, reloadData, handleSetInvestment, user, currentEntity, issuedInvestments
}) => {
  const returnData = issuedInvestments.find(i => i.offering_id === offering.id)
  const InvestmentComponent = () => {
    if (investment) {
      switch (investment.status) {
        case "cancelled":
        case "open":
          if (offering.is_offering_eoi) {
            return <ExpressionOfInterest user={user} currentEntity={currentEntity} offering={offering} investment={investment} reloadData={reloadData} />
          }
          if (offering.waitlist_active) {
            return (
              <JoinWaitlist
                user={user}
                currentEntity={currentEntity}
                offering={offering}
                investment={investment}
                reloadData={reloadData} />
            )
          }
          if (["Closed", "Issued", "Completed"].includes(offering.status)) {
            return <Missed offering={offering} />
          }
          return (
            <Open
              currentEntity={currentEntity}
              handleSetInvestment={handleSetInvestment}
              offering={offering} />
          )
        case "pending":
          return (
            <Pending
              offering={offering}
              investment={investment}
              reloadData={reloadData} />
          )
        case "waiting":
          return offering.is_offering_eoi
            ? <Pending offering={offering} investment={investment} reloadData={reloadData} />
            : <OnWaitlist offering={offering} investment={investment} reloadData={reloadData} />
        case "signed":
        case "signature_confirmed":
          return <Signed offering={offering} investment={investment} reloadData={reloadData} />
        case "deposited":
          return <Deposited offering={offering} investment={investment} />
        case "issued":
        case "completed":
          return <Issued returnData={returnData} offering={offering} investment={investment} reloadData={reloadData} />
        case "closed":
          return <Closed returnData={returnData} offering={offering} investment={investment} reloadData={reloadData} />
        default:
          return <></>
      }
    }
    return <></>
  }

  return (
    <InvestmentComponent />
  )
}

export default Investment