import React, { useState } from "react"
import { useToasts } from "react-toast-notifications"
import { addYieldBatch } from "../../requesters"
import YieldBatchCalculation from "./YieldBatchCalculation"

const CreateYieldBatch = ({ offeringDetail }) => {
  const { addToast } = useToasts()
  const [interestArray, setInterestArray] = useState([])
  const [principalArray, setPrincipalArray] = useState([])
  const [otherArray, setOtherArray] = useState([])
  const [yieldBatchObj, setYieldBatchObj] = useState({ ...offeringDetail })

  const saveBatchButtonClickHandler = () => {
    const form = {
      offering_id: offeringDetail.id,
      interest_description: yieldBatchObj.interest_description,
      principal_description: yieldBatchObj.principal_description,
      other_description: yieldBatchObj.other_description,
      interest_array: interestArray,
      principal_array: principalArray,
      other_array: otherArray
    }
    addYieldBatch({ form }).then(data => {
      const res = data.response.json
      if (res.success) {
        addToast("Congratulations! Your batch has been added.", { appearance: "success" })
        window.location.href = "/backend/returns"
      } else {
        addToast(res.message, { appearance: "error" })
      }
    })
  }

  const refreshDetailHandler = () => {
    window.location.href = "/backend/returns"
  }

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb"></nav>
      <ol className="breadcrumb pb-0">
        <li className="breadcrumb-item">
          <a onClick={refreshDetailHandler} href="/backend/returns">Yield Returns</a>
        </li>
        <li aria-current="page" className="breadcrumb-item active">Draft Yield Return</li>
      </ol>
      <div className="card my-3">
        <div className="card-header">
          <div className="d-flex align-items-center justify-content-end">
            <button
              type="submit"
              onClick={saveBatchButtonClickHandler}
              className="btn btn-success">
              Save
            </button>
          </div>
        </div>
        <div className="card-body">
          <h2><strong className="text-warning">Draft</strong></h2>
          <strong>{offeringDetail.deal_id}</strong>
          <h2>{offeringDetail.offering_name}</h2>
          {/* what will below be?? */}
          <div className="mb-3">{`${offeringDetail.country} · Legal`}</div>
          <ul className="list-unstyled">
            <li>
              Target Investor Yield:
              <strong>
                {offeringDetail.target_investor_yield}
              </strong>
            </li>
            <li>
              Interest Schedule:
              <strong>{offeringDetail.interest_schedule}</strong>
            </li>
            <li>
              Principal Schedule:
              <strong>{offeringDetail.principal_schedule}</strong>
            </li>
          </ul>
          {offeringDetail.investment_details.length ? (
            <YieldBatchCalculation
              interestArray={interestArray}
              setInterestArray={setInterestArray}
              principalArray={principalArray}
              setPrincipalArray={setPrincipalArray}
              otherArray={otherArray}
              setOtherArray={setOtherArray}
              yieldBatchObj={yieldBatchObj}
              setYieldBatchObj={setYieldBatchObj}
              offeringDetail={offeringDetail} />
            ) : (
              <div
                className="pb-3 text-muted text-center">
                This Offering does not have applicable Investments
              </div>
            )}
        </div>
      </div>
    </div>
  )
}

export default CreateYieldBatch
