import React from "react"
import { bankingCountryList } from "components/countries"
import { formatWithComma } from "components/CommonMethods"
import { Link } from "react-router-dom"

const InvestmentRow = ({ investment, currency }) => {
  const getCurrency = currency => {
    const foundCountry = bankingCountryList.find(element => element.currency === currency)
    return foundCountry.value
  }

  return (
    <Link className="d-lg-content table-link-wrapper" to={`/marketplace/${investment.offering_id}`} onClick={() => { window.location.href = `/marketplace/${investment.offering_id}` }}>
      <div className="d-lg-table-row table-item">
        <div className="d-flex justify-content-between d-lg-content">
          <div className="yt-td d-lg-table-cell">
            <img
              className="country-flag"
              src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${getCurrency(currency)}.svg`}
              style={{ height: "20px", paddingRight: "1rem" }} />
            {investment.offering_name}
          </div>
          <div className="yt-td d-lg-table-cell text-right">
            $
            {formatWithComma(investment.investment)}
            {" "}
            {investment.currency}
          </div>
          <div className="yt-td d-none d-lg-table-cell text-center">
            {investment.interest_rate}
            %
          </div>
          <div className="yt-td d-none d-lg-table-cell text-right">{investment.issue_date}</div>
        </div>
        <div className="d-flex justify-content-between d-lg-content flex-row-reverse">
          <div className="yt-td d-lg-table-cell text-right text-sub text-main">
            +$
            {formatWithComma(investment.totalReturn)}
            {" "}
            {investment.currency}
          </div>
          <div className="yt-td d-none d-lg-table-cell text-right">
            +$
            {formatWithComma(investment.totalPrincipal)}
            {" "}
            {investment.currency}
          </div>
          <div className="yt-td d-lg-table-cell text-right text-sub">
            <span>Mature&nbsp;</span>
            {investment.target_maturity_date}
          </div>
        </div>
      </div>
    </Link>
  )
}

export default InvestmentRow
