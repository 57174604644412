import React from "react"
import Money from "components/Money"
import { useToasts } from "react-toast-notifications"
import { cancelInvestment } from "../../../requesters"

import CancelInvestmentModal from "../../common/CancelInvestmentModal"

const WaitlistRow = ({ investment, reloadData }) => {
  const { addToast } = useToasts()

  const cancelInvestmentHandler = () => {
    cancelInvestment(investment.id).then(data => {
      const res = data.response.json
      if (res.success) {
        addToast("Investment has been cancelled", { appearance: "success" })
        reloadData()
      } else {
        addToast(res.message, { appearance: "error" })
      }
    })
  }

  return (
    <div className="d-lg-table-row table-item border-bottom">
      <div className="d-flex justify-content-between d-lg-content">
        <div className="yt-td d-lg-table-cell">
          {investment.offering.name}
        </div>
        <div className="yt-td d-lg-table-cell text-lg-right">
          <Money currency="$" amount={investment.amount} />
        </div>
      </div>
      <div className="yt-td d-lg-table-cell text-lg-right text-sub">
        {investment.offering.issue_date}
      </div>
      <div className="yt-td d-lg-table-cell text-lg-right">
        <button
          className="btn btn-sm btn-outlined-main mt-2 mt-lg-0"
          type="button"
          data-target={`#cancelModal-${investment.id}`}
          data-toggle="modal">
          Cancel Investment
        </button>
      </div>
      <CancelInvestmentModal cancelInvestmentHandler={cancelInvestmentHandler} investmentId={investment.id} />
    </div>
  )
}

export default WaitlistRow
