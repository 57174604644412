/* eslint-disable no-unused-expressions */
import axios from "axios"

export const confirmFund = id => axios.put(`/api/backend/investments/${id}/confirm_fund`)
.then(({ data, status }) => ({ response: data, status }))
  .catch(error => { error })


export const cancelInvestment = id => axios.put(`/api/backend/investments/${id}/cancel`)
.then(({ data, status }) => ({ response: data, status }))
  .catch(error => { error })


export const confirmSign = id => axios.put(`/api/backend/investments/${id}/confirm_sign`)
.then(({ data, status }) => ({ response: data, status }))
  .catch(error => { error })

export const convertWaitlistToPending = (id, investmentAmount) => {
  const path = `/api/backend/investments/${id}/subscribe`
  return axios.put(path, { amount: investmentAmount, status: "pending" })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const uploadDocument = (id, formData) => {
  const path = `/api/backend/investments/${id}/upload_document`
  return axios.put(path, formData, { header: { "Content-Type": "multipart/form-data" } })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const addInvestment = (id, investmentAmount) => {
  const path = `/api/backend/investments/${id}/add_offline_investment`
  return axios.post(path, { amount: investmentAmount, status: "deposited" })
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}
