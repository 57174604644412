import React, { useState } from "react"
import dayjs from "dayjs"
import SidebarNav from "./SidebarNav"
import InvestorProfiles from "./InvestorProfiles"
import AddInvestorProfileModal from "./AddInvestorProfileModal"
import AccountSwitch from "./AccountSwitch"
import TaxDetailsForm from "./TaxDetailsForm"
import { updateEntityTaxDetails, deleteEntityTaxDetail } from "../../requesters/UserRequester"


const AccountsBase = ({ baseUrl, currentUser, currentEntity }) => {
  const [taxDetailIsValid, setTaxDetailIsValid] = useState(false)
  const [taxDetailAccepted, setTaxDetailAccepted] = useState(false)
  const [taxDetails, setTaxDetails] = useState(currentEntity.tax_details.length ? currentEntity.tax_details : [{
    id: undefined,
    country: undefined,
    taxNumber: undefined,
    explanation: undefined
  }])

  const saveAndExit = () => {
    if (taxDetailAccepted && taxDetailIsValid) {
      updateEntityTaxDetails(baseUrl, currentEntity.id, { user: { id: currentEntity.id, tax_details: taxDetails } }).then(data => {
        const res = data.response.json
        if (res.success) {
          window.location.href = res.data.redirect_url || "/account"
        } else {
          // eslint-disable-next-line no-alert
          alert("Oops! Something went wrong!") // TODO replace this with UI
        }
      })
    }
  }

  const handleTaxDetailsChanges = (value, count) => {
    setTaxDetailIsValid(value.length === count)
    setTaxDetails(value)
  }

  const handleCountryRemove = id => {
    deleteEntityTaxDetail(baseUrl, currentEntity.id, { user: { id: currentEntity.id, detailId: id } }).then(data => {
      const res = data.response.json
      if (!res.success) {
         // eslint-disable-next-line no-alert
         alert("Oops! Something went wrong!") // TODO replace this with UI
      }
    })
  }

  const handleTaxDetailsAcceptance = checked => {
    setTaxDetailAccepted(checked)
  }

  return (
    <div className="content">
      <div className="container">
        <div className="row no-gutters">
          <SidebarNav />
          <div className="col-lg-9">
            <h1 className="mb-1 mt-5">
              {currentEntity.firstname
              ? `${currentUser.firstname} ${currentUser.surname}`
              : currentEntity.name}
            </h1>
            <p className="mb-5">
              {`Member since ${dayjs(currentEntity.joined_on || undefined).format("MMM YYYY")}`}
            </p>
            <h3>Switch Accounts</h3>
            <AccountSwitch
              baseUrl={baseUrl}
              currentUser={currentUser} />
            <div className="mt-5"></div>
            <h3>Investor Profiles</h3>
            <InvestorProfiles currentEntity={currentEntity} />
            <button
              className="btn btn-outlined-main mt-3"
              type="button"
              data-target="#addInvestorProfileModal"
              data-toggle="modal">
              Add
            </button>
            <AddInvestorProfileModal currentEntity={currentEntity} />
            <TaxDetailsForm
              handleAttributeChange={handleTaxDetailsChanges}
              handleCountryRemove={handleCountryRemove}
              handleTaxDetailsAcceptance={handleTaxDetailsAcceptance}
              taxDetails={taxDetails} />
            <button className="btn btn-outlined-main" disabled={!taxDetailAccepted && !taxDetailIsValid} type="button" onClick={saveAndExit}>
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountsBase
