import React from "react"

const UnverifiedMessage = () => (
  <>
    <h1 className="mt-0 pt-5">Ready to trade digital assets?</h1>
    <h3>Your account verification has not yet been completed.</h3>
    <p>
      {`To be able to trade digital assets, your Aura Group Marketplace account must have been verified first. To finalise verification of your
account, please contact us `}
      {" "}
      <a href="mailto: invest@aura.co" className="">invest@aura.co</a>
    </p>
    <p>
      Aura Group has partnered with Leonie Hill to bring you some of the top crypto assets available, with deep liquidity, secure private order flow, and
      institutional grade custody.
    </p>
    <li>Leonie Hill is a technology platform enabling institutional clients to trade digital assets such as Bitcoin and Ethereum.</li>
    <li>Aura Group is a major shareholder and strategic partner.</li>
    <li>Client assets are held 1-1 in secure custody through Leonie Hill Custody Pty Ltd, a bankruptcy remote entity.</li>
    <li>Leonie Hill never rehypothecates, lends, or trades against client assets unless there is express permission.</li>
  </>
)

export default UnverifiedMessage
