/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react"
import Money from "components/Money"
import FundedInvestmentRow from "./FundedInvestmentRow"
import PendingInvestmentRow from "./PendingInvestmentRow"
import CancelledInvestmentRow from "./CancelledInvestmentRow"
import { getInvestments } from "../../../requesters"
import ConvertToSubscriptionModal from "./ConvertToSubscriptionModal"
import OfflinePaymentsModal from "./OfflinePaymentsModal"

const Investment = ({ offering, reloadOffering }) => {
  const [fundedInvestments, setFundedInvestments] = useState([])
  const [pendingInvestments, setPendingInvestments] = useState([])
  const [cancelledInvestments, setCancelledInvestments] = useState([])
  const [currentInvestment, setCurrentInvestment] = useState()

  const reloadData = () => {
    getInvestments(offering.id).then(data => {
      const investments = data.response.json.data
      const funded = []
      const pending = []
      const cancelled = []
      investments.map(investment => {
        if (["deposited", "issued", "closed"].includes(investment.status)) {
          funded.push(investment)
        } else if (investment.status === "cancelled") {
          cancelled.push(investment)
        } else {
          pending.push(investment)
        }
      })
      setFundedInvestments(funded)
      setCancelledInvestments(cancelled)
      setPendingInvestments(pending)
    })
  }
  useEffect(() => {
    reloadData()
  }, [])

  return (
    <div>
      <div className="card my-3">
        <div className="card-header">
          <div className="d-flex align-items-center justify-content-between">
            Funded Investments
            <button className="btn btn-link btn-sm" type="button" data-target="#offlinePaymentsModal" data-toggle="modal">Add</button>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-link-row yt-table" style={{ minHeight: "160px" }}>
              <thead>
                <tr>
                  <th>Entity Name</th>
                  <th>Reference</th>
                  <th>Entity Type</th>
                  <th className="text-right">Amount</th>
                  <th className="text-right">Interest Received</th>
                  <th className="text-right">Primary Repaid</th>
                  <th className="text-right">Other</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {
                  fundedInvestments.length
                    ? (
                      <>
                        {fundedInvestments.map(investment => (
                          <FundedInvestmentRow
                            investment={investment}
                            offering={offering}
                            reloadData={reloadData}
                            reloadOffering={reloadOffering}
                            key={`funded-invest-${investment.id}`} />
                        ))}
                        <tr className="strong">
                          <td />
                          <td />
                          <td />
                          <td className="text-right">
                            <Money currency="$" amount={fundedInvestments.reduce((n, { amount }) => n + amount, 0)} />
                          </td>
                          <td className="text-right">
                            <Money currency="$" amount={fundedInvestments.reduce((n, { interest_received }) => n + interest_received, 0)} />
                          </td>
                          <td className="text-right">
                            <Money currency="$" amount={fundedInvestments.reduce((n, { primary_repaid }) => n + primary_repaid, 0)} />
                          </td>
                          <td className="text-right">
                            <Money currency="$" amount={fundedInvestments.reduce((n, { other }) => n + other, 0)} />
                          </td>
                        </tr>
                      </>
                    )
                    : <tr><td colSpan="7" className="text-center">No Funded Investment</td></tr>
                }
              </tbody>
            </table>
            <OfflinePaymentsModal offering={offering} reloadData={reloadData} reloadOffering={reloadOffering} />
          </div>
        </div>
      </div>

      <div className="card my-3">
        <div className="card-header">Pending &amp; Waitlist</div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-link-row yt-table" style={{ minHeight: "160px" }}>
              <thead>
                <tr>
                  <th>Entity Name</th>
                  <th>Entity Type</th>
                  <th>Contact</th>
                  <th className="text-right">Amount</th>
                  <th className="text-center">Subscription Document</th>
                  <th className="text-center">Funding</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {
                  pendingInvestments.length
                    ? pendingInvestments.map(investment => (
                      <PendingInvestmentRow
                        setCurrentInvestment={setCurrentInvestment}
                        investment={investment}
                        offering={offering}
                        reloadData={reloadData}
                        reloadOffering={reloadOffering}
                        key={`pending-invest-${investment.id}`} />
                    ))
                    : <tr><td colSpan="7" className="text-center">No Pending Investments</td></tr>
                }
              </tbody>
            </table>
            <ConvertToSubscriptionModal
              offering={offering}
              investment={currentInvestment}
              reloadData={reloadData}
              reloadOffering={reloadOffering} />
          </div>
        </div>
      </div>
      <div className="card my-3">
        <div className="card-header">Cancelled</div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-link-row yt-table" style={{ minHeight: "160px" }}>
              <thead>
                <tr>
                  <th>Entity Name</th>
                  <th>Entity Type</th>
                  <th>Contact</th>
                  <th className="text-right">Amount</th>
                  <th>Updated At</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {
                  cancelledInvestments.length
                    ? cancelledInvestments.map(investment => (
                      <CancelledInvestmentRow
                        investment={investment}
                        key={`cancelled-invest-${investment.id}`} />
                    ))
                    : <tr><td colSpan="7" className="text-center">No Cancelled Investments</td></tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Investment
