import React from "react"
import { useToasts } from "react-toast-notifications"
import { cancelInvestment } from "../../../requesters"

const CancelInvestment = ({
 investment, reloadData, linkClass, text
}) => {
  const { addToast } = useToasts()
  const cssClass = linkClass || "dropdown-item"
  const btnText = text || "Cancel Investment"
  const cancelInvestmentHandler = e => {
    e.preventDefault()
    cancelInvestment(investment.id).then(data => {
      const res = data.response.json
      if (res.success) {
        addToast("Your investment has been cancelled!", { appearance: "success" })
        reloadData()
      } else {
        addToast(res.message, { appearance: "error" })
      }
    })
  }
  return (
    <button className={cssClass} type="button" onClick={cancelInvestmentHandler}>{btnText}</button>
  )
}

export default CancelInvestment
