// import React, { Component } from "react"
import React, { useState } from "react"
import { useSelector } from "react-redux"

import { getBaseUrl } from "../../reducers"
import { resendEmailConfirmation } from "../../requesters"

export default function EmailVerification({ currentUserId, currentUser, currentUserEmail }) {
  const baseUrl = useSelector(getBaseUrl)
  const [showResendLink, setShowResendLink] = useState(true)

  const sendConfirmationEmail = () => {
    // Check User is created or logged in
    if (currentUserId || currentUser?.id) {
      resendEmailConfirmation(baseUrl, currentUserId || currentUser.id).then(() => {
        setShowResendLink(false)
        setTimeout(() => { setShowResendLink(true) }, 10000)
      })
    }
  }

  return (
    <div className="container text-center">
      <div className="row justify-content-center">
        <div className="col-lg-6 col-md-8">
          <h2 className="mt-5 mb-4 pt-5">Please confirm your email to complete sign up</h2>
          <p>
            We’ve just sent you an email to:
            {" "}
            <strong className="text-main">{currentUserEmail || currentUser?.email}</strong>
            , with a confirmation link.
          </p>
          <p>If you do not receive a confirmation email, please check your spam folder and make sure you have entered a valid email address.</p>
          {showResendLink && <button type="button" onClick={sendConfirmationEmail} className="btn btn-link">Resend Verification Email</button>}
          {!showResendLink && <p className="btn btn-plain">Email resent!</p>}
        </div>
      </div>
    </div>
  )
}
