import React, { Component } from "react"
import { connect } from "react-redux"
import { FIELD_VALIDATIONS } from "./formErrors"
import { getBaseUrl } from "../../reducers"

class MainConfirmPasswordResetForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formValidations: {
        invalidPassword: false,
        invalidPasswordConfirmation: false
      }
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  isValid = formData => {
    const {
      password,
      password_confirmation
    } = formData

    const {
        formValidations
      } = this.state

    const isPassword = password && password === password_confirmation
    const isValid = Object.keys(formValidations).every(k => !formValidations[k])

    return (isPassword && isValid)
  }

  validatePassword = value => {
    const validPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{6,50}$/
    return validPassword.test(String(value))
  }


  fieldIsValid = (attribute, value) => {
    const {
      formData: {
        password
      }
    } = this.props

    const fieldIsValid = {
      password: this.validatePassword(value),
      password_confirmation: value === password
    }[attribute]

    return fieldIsValid
  }

  errorMessage = attribute => {
    const {
      formValidations: {
        invalidPassword,
        invalidPasswordConfirmation
      }
    } = this.state

    const message = _.find(FIELD_VALIDATIONS, ["field", attribute])

    const invalid = {
      password: invalidPassword,
      password_confirmation: invalidPasswordConfirmation
    }[attribute]

    if (invalid) {
      return (
        <div className="err-msg text-danger">{message.message}</div>
      )
    }

    return null
  }

  handleCancel() {
    window.location.href = "/login"
  }

  render() {
    const {
      formValidations,
    } = this.state

    const {
      handleAttributeChange,
      handleSubmit,
      formData,
    } = this.props

    return (
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8">
            <h1 className="text-center">
              Reset Password
            </h1>
            <div className="text-center">
              Please enter your new password.
            </div>
            <br />
            <br />
            <form>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  className="form-control"
                  autoComplete="disableAll"
                  maxLength={50}
                  onChange={e => {
                    handleAttributeChange({ name: e.currentTarget.name, value: e.currentTarget.value })
                    this.setState({ formValidations: { ...formValidations, invalidPassword: !this.fieldIsValid("password", formData.password) } })
                  }}
                  name="password" />
                { this.errorMessage("password", formData.password) }
              </div>
              <div className="form-group">
                <label htmlFor="password_confirmation">Confirm Password</label>
                <input
                  type="password"
                  className="form-control"
                  autoComplete="disableAll"
                  maxLength={50}
                  onChange={e => {
                    handleAttributeChange({ name: e.currentTarget.name, value: e.currentTarget.value })
                    this.setState({ formValidations: { ...formValidations, invalidPasswordConfirmation: !this.fieldIsValid("password_confirmation", formData.password_confirmation) } })
                  }}
                  name="password_confirmation" />
                { this.errorMessage("password_confirmation", formData.password_confirmation) }
              </div>
              <div className="text-center my-5">
                <div className="form-group">
                  <button type="button" className="btn btn-main btn-lg" onClick={this.handleCancel}>Cancel</button>
                  &nbsp;&nbsp;
                  <button type="submit" className="btn btn-main btn-lg" disabled={!this.isValid(formData)} onClick={handleSubmit}>Reset</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  baseUrl: getBaseUrl(state)
})

export default connect(mapStateToProps, {})(MainConfirmPasswordResetForm)
