import React, { useState, useEffect } from "react"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import { useToasts } from "react-toast-notifications"

import { updateUser } from "../../requesters/UserRequester"
import { getBaseUrl } from "../../reducers"

const ChangeEmailModal = ({ baseUrl }) => {
  const [email, setEmail] = useState(undefined)
  const [error, setError] = useState(null)
  const [invalidFormat, setInvalidFormat] = useState(true)

  const { addToast } = useToasts()

  useEffect(() => {
    const validateEmail = value => {
      const validEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return validEmail.test(String(value).toLowerCase())
    }
    if (email) setInvalidFormat(!validateEmail(email))
  }, [email])

  async function onSubmit(e) {
    e.preventDefault()

    const { response, error } = await updateUser(baseUrl, { email })
    if (_.get(response, "status") === "ok") {
      $("#settingsChangeEmailModal").modal("hide")
      addToast("Check your inbox to verify changes", { appearance: "success" })
    } else {
      setError(_.first(_.get(error, "response.data.errors")).detail)
    }
  }

  return (
    <div aria-labelledby="pendingCancelledPopup" className="modal fade" id="settingsChangeEmailModal" role="dialog" tabIndex={-1} style={{ display: "none" }} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header border-0 p-0"></div>
          <div className="modal-body">
            <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true"> ×</span></button>
            <div className="row justify-content-center text-center">
              <div className="col-lg-11">
                <h2 className="mt-4">Change Email</h2>
                <form className="my-4" onSubmit={onSubmit}>
                  <div className="form-group mb-4">
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Enter email address"
                      onChange={e => setEmail(e.target.value)} />
                    {error && <div className="err-msg text-danger">{error}</div>}
                  </div>
                  <input
                    type="submit"
                    value="Next"
                    className="btn btn-main py-3 px-5"
                    disabled={invalidFormat} />
                </form>
              </div>
            </div>
          </div>
          <div className="modal-footer border-0">
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  baseUrl: getBaseUrl(state)
})

export default withRouter(connect(mapStateToProps)(ChangeEmailModal))
