import React, { useEffect } from "react"
import { idCheckIcon } from "components/Icons"

const IdentityWaiting = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const reloadPage = () => {
    window.location.reload()
  }

  return (
    <div className="row justify-content-center">
      <div className="col-xl-7 col-lg-8">
        <div className="text-center">
          <h2 className="mt-5 pt-4">Identification</h2>
          <img
            className="my-4"
            src={idCheckIcon} />
          <p>Verifying your identity…</p>
          <p>
            Your verification status will update automatically ones it is
            completed. If not, click on Update Status below to refresh.
          </p>
          <button className="btn btn-outlined-main btn-lg mt-4" onClick={reloadPage} type="button">
            Refresh Status
          </button>
        </div>
      </div>
    </div>
  )
}

export default IdentityWaiting
