import React, { useEffect, useState } from "react"
import { formatWithComma } from "components/CommonMethods"
import Progress from "./Progress"
import Timeline from "./Timeline"
import ErrorModal from "./ErrorModal"

const DEFAULT_MINIMUM_INVESTMENT = 499999

const Open = ({
  offering, handleSetInvestment, currentEntity
}) => {
  const [enableInvestment, setEnableInvestment] = useState(false)
  const [investmentAmount, setInvestmentAmount] = useState(offering.need_fixed_investment ? offering.min_investment : undefined)
  const [showModal, setShowModal] = useState(false)
  const minInvestmentLbl = offering.need_fixed_investment ? "Investment amount" : "Minimum amount"

  // edge cases where fresh entities do not have an investor cert
  let userRestriction = "500k"
  if (currentEntity.country === "SG") {
    userRestriction = (currentEntity.investor_certificate && currentEntity.investor_certificate[`invest_limit_${offering.region}`]) || "restricted"
  } else {
    userRestriction = (currentEntity.investor_certificate && currentEntity.investor_certificate[`invest_limit_${offering.region}`]) || "500k"
  }

  useEffect(() => {
    setEnableInvestment(
      Number.isFinite(investmentAmount)
      && investmentAmount > 0
      && investmentAmount >= offering.min_investment
      && offering.remaining_amount >= investmentAmount
    )
    // setShowModal(false)
  }, [investmentAmount])

  const handleSubmit = e => {
    e.preventDefault()
    if (currentEntity.background_check_status === "verified"
      && (userRestriction === "unrestricted" || (userRestriction === "500k" && investmentAmount > DEFAULT_MINIMUM_INVESTMENT))
    ) {
      handleSetInvestment(investmentAmount)
    } else {
      setShowModal(true)
    }
  }

  const handleSetInvestmentAmount = e => {
    setInvestmentAmount(parseFloat(e.target.value))
  }

  return (
    <div className="col-md-4 order-1 order-md-2 mb-5">
      <form onSubmit={handleSubmit}>
        <div className="card shadow-sm card-body sticky-top">
          <Progress offering={offering} />
          <h3 className="mb-3">Invest</h3>
          <div className="form-group input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text border-right-0" id="basic-addon1">
                {`Amount (${offering.currency})`}
              </span>
            </div>
            <input
              pattern="/d+"
              step="1"
              type="number"
              min={0}
              aria-label="Investment Amount"
              className="form-control border-left-0 text-right"
              onChange={handleSetInvestmentAmount}
              value={investmentAmount}
              readOnly={offering.need_fixed_investment}
              placeholder="$0.00" />
          </div>
          <div className="text-center">
            <div className="small">
              {minInvestmentLbl}
            &nbsp;
              {`${offering.currency} ${formatWithComma(offering.min_investment)}`}
            </div>
          </div>
          <button
            type="submit"
            data-target="#errorModal"
            className="btn btn-lg btn-block btn-main mt-4"
            data-toggle="modal"
            disabled={!enableInvestment}>
            Invest
          </button>
          <div className="mt-4">
            <table className="table table-borderless yt-table table-sm table-between">
              <tbody>
                <Timeline title="Closing Date" date={offering.closing_date} />
                <Timeline title="Investor Funding Date" date={offering.investor_funding_date} />
              </tbody>
            </table>
          </div>
        </div>
      </form>
      <ErrorModal
        entity={currentEntity}
        show={showModal}
        onHide={() => setShowModal(false)}
        userNotVerified={currentEntity.background_check_status !== "verified"}
        userRestriction={userRestriction}
        offeringCountry={offering.country} />
    </div>
  )
}

export default Open
