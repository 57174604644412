import React from "react"
import { formatWithComma } from "components/CommonMethods"

const Transactions = ({ transaction }) => {
  const plusOrMinus = transaction => {
    if (transaction.type === "Funding") {
      return "+$"
    } if (transaction.type === "Principal") {
      return "-$"
    }
      return "$"
  }
  return (
    <tr>
      <td>{transaction.date}</td>
      <td>{transaction.offering_name}</td>
      <td>{transaction.type}</td>
      <td>
        {`${plusOrMinus(transaction)} ${formatWithComma(transaction.amount)} ${transaction.currency}`}
      </td>
    </tr>
  )
}
export default Transactions
