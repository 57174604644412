import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import dayjs from "dayjs"
import { getCurrentFinancialYear, sortTransactionsDate } from "components/CommonMethods"
import { getBaseUrl } from "../../reducers"
import InvestmentRow from "./InvestmentRow"
import PendingRow from "./PendingRow"
import PendingMobileModal from "./PendingMobileModal"
import WaitlistRow from "./WaitlistRow"
import WaitlistMobileModal from "./WaitlistMobileModal"
import TransactionRow from "./TransactionRow"
import TransactionMobileModal from "./TransactionMobileModal"
import { getInvestments, getTransactions } from "../../requesters/UsersRequester"
import CountryCard from "./CountryCard"

const Dashboard = ({ baseUrl, currentEntity }) => {
  const [issuedInvestments, setIssuedInvestments] = useState([])
  const [pendingInvestments, setPendingInvestments] = useState([])
  const [waitlistInvestments, setWaitlistInvestments] = useState([])
  const [transactions, setTransactions] = useState([])
  const [principal, setPrincipal] = useState({})
  const [interest, setInterest] = useState({})
  const [ytdFilter, setYtdFilter] = useState(false)
  const [principalYtd, setPrincipalYtd] = useState(false)
  const [interestYtd, setInterestYtd] = useState(false)

  const reloadData = () => {
    getInvestments(baseUrl, currentEntity.id)
      .then(data => {
        const investments = data.response.json.data
        const issued = []
        const pending = []
        const waiting = []
        investments.forEach(investment => {
          if (investment.is_archive) {
            // do nothing if Offering is Archived
          } else if (["signed", "signature_confirmed", "pending", "deposited"].includes(investment.status)) {
            pending.push(investment)
          } else if (investment.status === "issued") {
            issued.push(investment)
          } else if (investment.status === "waiting" || investment.status === "expired") {
            waiting.push(investment)
          }
        })
        setIssuedInvestments(issued)
        setPendingInvestments(pending)
        setWaitlistInvestments(waiting)
      })
        .then(() => {
          getTransactions(baseUrl, currentEntity.id).then(data => {
            const transactions = data.response.json.data
            setTransactions(transactions)

            const principalOutstanding = {}
            const totalInterest = {}
            const principalOutstandingYtd = {}
            const totalInterestYtd = {}

            const startOfFy = dayjs(getCurrentFinancialYear()).format("YYYY-MM-DD")

            transactions.forEach(t => {
              if (t.type === "Return") {
                if (totalInterest[t.currency]) {
                  totalInterest[t.currency] += t.amount
                } else {
                  totalInterest[t.currency] = t.amount
                }
              } else if (principalOutstanding[t.currency]) {
                principalOutstanding[t.currency] += t.type === "Funding" ? t.amount : -1 * t.amount
              } else {
                principalOutstanding[t.currency] = t.type === "Funding" ? t.amount : -1 * t.amount
              }

              if (dayjs(t.date).format("YYYY-MM-DD") > startOfFy) {
                if (t.type === "Return") {
                  if (totalInterestYtd[t.currency]) {
                    totalInterestYtd[t.currency] += t.amount
                  } else {
                    totalInterestYtd[t.currency] = t.amount
                  }
                } else if (principalOutstandingYtd[t.currency]) {
                  principalOutstandingYtd[t.currency] += t.type === "Funding" ? t.amount : -1 * t.amount
                } else {
                  principalOutstandingYtd[t.currency] = t.type === "Funding" ? t.amount : -1 * t.amount
                }
              }
            })
            setInterest(totalInterest)
            setPrincipal(principalOutstanding)
            setInterestYtd(totalInterestYtd)
            setPrincipalYtd(principalOutstandingYtd)
          })
        })
  }

  useEffect(() => {
    if (transactions.length) {
      const output = issuedInvestments.map(investment => {
        let runningTotalReturn = 0
        let runningTotalPrincipal = 0
        transactions.forEach(yr => {
          if (yr.offering_id === investment.offering_id) {
            if (yr.return_type === "Return") {
              runningTotalReturn += yr.amount
            } else {
              runningTotalPrincipal += yr.amount
            }
          }
        })

        return {
          totalReturn: runningTotalReturn,
          totalPrincipal: runningTotalPrincipal,
          ...investment
        }
      })
      setIssuedInvestments(output)
    }
  }, [transactions])

  useEffect(() => {
    if (transactions.length) {
      const output = issuedInvestments.map(investment => {
        let runningTotalReturn = 0
        let runningTotalPrincipal = 0
        transactions.forEach(yr => {
          if (yr.offering_id === investment.offering_id) {
            if (yr.type === "Return") {
              runningTotalReturn += yr.amount
            } else if (yr.type === "Principal") {
              runningTotalPrincipal += yr.amount
            }
          }
        })

        return {
          totalReturn: runningTotalReturn,
          totalPrincipal: runningTotalPrincipal,
          ...investment
        }
      })
      setIssuedInvestments(output)
    }
  }, [transactions])

  useEffect(() => {
    reloadData()
  }, [])

  if (!issuedInvestments.length && !waitlistInvestments.length && !pendingInvestments.length) {
    return (
      <div className="content">
        <div className="container text-center">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-8">
              <h2 className="mt-5 pt-5">You have no investments</h2>
              <p>Go to Marketplace and view our unique offerings</p>
              <Link
                className="btn btn-secondary btn-lg mt-4"
                to="/marketplace"
                onClick={() => { window.location.href = "/marketplace" }}>
                Start Investing
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const principalKeys = Object.keys(principal)
  sortTransactionsDate(transactions)
  const sortedTransactions = transactions.slice(0, 10)

  return (
    <div className="content">
      <div className="container">
        <div className="d-flex align-items-end justify-content-between mb-3">
          <div className="left">
            <h1 className="mb-1">
              Hello
              {" "}
              {currentEntity.firstname || currentEntity.name}
            </h1>
            <p className="mb-0 investor-type">
              {currentEntity.investor_class}
            </p>
          </div>
          <div className="dropdown special-dropdown">
            <div
              aria-expanded="false"
              aria-haspopup="true"
              className="dropdown-toggle"
              data-toggle="dropdown"
              id="dropdownMenuLink"
              type="button">
              {ytdFilter ? "YTD" : "Lifetime TD"}
            </div>
            <div aria-labelledby="dropdownMenuLink" className="dropdown-menu dropdown-menu-right">
              <button className="dropdown-item" type="button" onClick={() => setYtdFilter(true)}> YTD</button>
              <button className="dropdown-item" type="button" onClick={() => setYtdFilter(false)}>Lifetime TD</button>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-borderless card-totals-table">
            <tbody>
              <tr>
                {!ytdFilter && principalKeys.map(k => (
                  <CountryCard
                    currency={k}
                    interest={interest[k]}
                    principal={principal[k]}
                    key={`issued-investment-${k}`} />
                ))}
                {ytdFilter && principalKeys.map(k => (
                  <CountryCard
                    currency={k}
                    interest={interestYtd[k] || 0}
                    principal={principalYtd[k] || 0}
                    key={`issued-investment-${k}`} />
                ))}
              </tr>
            </tbody>
          </table>
        </div>
        <div className="orders-transactions d-lg-none">
          <h3 className="mb-4 mt-5">Orders &amp; Transactions</h3>
          <div className="orders-transactions-list d-flex">
            <div className="mr-2" data-target="#modalPending" data-toggle="modal">
              <div className="orders-transactions-item bg-light p-3">
                <strong>Pending</strong>
                <div className="text-muted">
                  x
                  {pendingInvestments.length}
                </div>
              </div>
            </div>
            <div className="mr-2" data-target="#modalWaitlist" data-toggle="modal">
              <div className="orders-transactions-item bg-light p-3">
                <strong>Waitlist</strong>
                <div className="text-muted">
                  x
                  {waitlistInvestments.length}
                </div>
              </div>
            </div>
            <div className="mr-2" data-target="#modalTransaction" data-toggle="modal">
              <div className="orders-transactions-item bg-light p-3">
                <strong>Transactions</strong>
                <div className="text-muted">
                  x
                  {transactions.length}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ul className="nav nav-pills mb-4 mt-5 flex-nowrap dashboard-tabs">
          <li className="nav-item" role="presentation"><a aria-controls="pills-investment" aria-selected="true" className="nav-link active" data-toggle="pill" href="#pills-investment" id="pills-investment-tab" role="tab"> Investments</a></li>
          <li className="nav-item d-none d-lg-inline" role="presentation"><a aria-controls="pills-pending" aria-selected="false" className="nav-link" data-toggle="pill" href="#pills-pending" id="pills-pending-tab" role="tab"> Pending</a></li>
          <li className="nav-item d-none d-lg-inline" role="presentation"><a aria-controls="pills-waitlist" aria-selected="false" className="nav-link" data-toggle="pill" href="#pills-waitlist" id="pills-waitlist-tab" role="tab"> Waitlist</a></li>
          <li className="nav-item d-none d-lg-inline" role="presentation"><a aria-controls="pills-transaction" aria-selected="false" className="nav-link" data-toggle="pill" href="#pills-transaction" id="pills-transaction-tab" role="tab"> Transactions</a></li>
        </ul>
        <div id="pills-tabContent" className="tab-content pb-5">
          <div aria-labelledby="pills-investment-tab" className="tab-pane fade active show" id="pills-investment" role="tabpanel">
            <div className="d-lg-table w-100 yt-table table">
              {!!issuedInvestments.length && (
                <div className="d-lg-table-row d-none">
                  <div className="yt-th d-lg-table-cell">Offering</div>
                  <div className="yt-th d-lg-table-cell text-right">Investment</div>
                  <div className="yt-th d-lg-table-cell text-center">Interest Rate</div>
                  <div className="yt-th d-lg-table-cell text-right">Issue Date</div>
                  <div className="yt-th d-lg-table-cell text-right">Total Return</div>
                  <div className="yt-th d-lg-table-cell text-right">Principal Repaid</div>
                  <div className="yt-th d-lg-table-cell text-right">Expected Maturity</div>
                </div>
                )
              }
              {issuedInvestments.length
                ? issuedInvestments.map(investment => (
                  <InvestmentRow
                    reloadData={reloadData}
                    currency={investment.currency}
                    investment={investment}
                    key={`issued-investment-${investment.id}`} />
                ))
                : <div>No Issued Investments</div>
              }
            </div>
          </div>
          <div aria-labelledby="pills-pending-tab" className="tab-pane fade show" id="pills-pending" role="tabpanel">
            <p className="w-75">Your investment will be confirmed on issue date if funds have been deposited by target funding date.</p>
            <div className="d-lg-table w-100 yt-table table">
              {!!pendingInvestments.length && (
                <div className="d-lg-table-row d-none">
                  <div className="yt-th d-lg-table-cell">Offering</div>
                  <div className="yt-th d-lg-table-cell text-right">Amount</div>
                  <div className="yt-th d-lg-table-cell text-center">Documentation</div>
                  <div className="yt-th d-lg-table-cell text-center">Funding</div>
                  <div className="yt-th d-lg-table-cell text-center">Status</div>
                  <div className="yt-th d-lg-table-cell text-center">Issue Date</div>
                  <div className="yt-th d-lg-table-cell"></div>
                </div>
                )
              }
              {pendingInvestments.length
                ? pendingInvestments.map(investment => (
                  <PendingRow
                    reloadData={reloadData}
                    investment={investment}
                    key={`pending-investment-${investment.id}`} />
                ))
                : <div>No Pending Investments</div>
              }
            </div>
          </div>
          <div aria-labelledby="pills-waitlist-tab" className="tab-pane fade" id="pills-waitlist" role="tabpanel">
            <div className="d-lg-table w-100 yt-table table">
              {!!waitlistInvestments.length && (
                <div className="d-lg-table-row d-none">
                  <div className="yt-th d-lg-table-cell">Offering</div>
                  <div className="yt-th d-lg-table-cell text-right">Amount</div>
                  <div className="yt-th d-lg-table-cell text-right">Closing Date</div>
                  <div className="yt-th d-lg-table-cell"></div>
                </div>
                )
              }
              {waitlistInvestments.length
                ? waitlistInvestments.map(investment => (
                  <WaitlistRow
                    reloadData={reloadData}
                    investment={investment}
                    key={`waitlist-investment-${investment.id}`} />
                ))
                : <div>No Waitlist items here</div>
              }
            </div>
          </div>
          <div aria-labelledby="pills-transaction-tab" className="tab-pane fade" id="pills-transaction" role="tabpanel">
            <div className="d-md-table w-100 yt-table table">
              {!!transactions.length && (
                <div className="d-md-table-row d-none">
                  <div className="yt-th d-md-table-cell">Transaction</div>
                  <div className="yt-th d-md-table-cell"></div>
                  <div className="yt-th d-md-table-cell text-right">Amount</div>
                </div>
                )
              }
              {transactions.length
                ? sortedTransactions.map(transaction => (
                  <TransactionRow
                    transaction={transaction}
                    key={`transaction-${transaction.type}-${transaction.id}`} />
                ))
                : <div>No Transactions</div>
              }
            </div>
          </div>
        </div>
      </div>
      <WaitlistMobileModal waitlistInvestments={waitlistInvestments} reloadData={reloadData} />
      <PendingMobileModal pendingInvestments={pendingInvestments} reloadData={reloadData} />
      <TransactionMobileModal transactions={transactions} />
    </div>
  )
}

const mapStateToProps = state => ({
  baseUrl: getBaseUrl(state)
})

export default connect(mapStateToProps)(Dashboard)
