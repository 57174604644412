import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import PendingRow from "./PendingRow"
import WaitlistRow from "./WaitlistRow"
import CancelledRow from "./CancelledRow"
import Transactions from "./Transactions"
import InvestmentRow from "./InvestmentRow"
import { getBaseUrl } from "../../../reducers"
import { getUserInvestments, getFundedInvestments, getTransactions } from "../../../requesters"

const Investments = ({ entity, baseUrl }) => {
  const [issuedInvestments, setIssuedInvestments] = useState([])
  const [pendingInvestments, setPendingInvestments] = useState([])
  const [waitingInvestments, setWaitingInvestments] = useState([])
  const [cancelledInvestments, setCancelledInvestments] = useState([])
  const [transactions, setTransactions] = useState([])

  const reloadData = () => {
    getUserInvestments(baseUrl, entity.id).then(data => {
      const investments = data.response.json.data
      const pending = []
      const waiting = []
      const cancelled = []

      // eslint-disable-next-line array-callback-return
      investments.map(investment => {
        switch (investment.status) {
          case "pending":
          case "signed":
          case "closed":
          case "signature_confirmed":
            pending.push(investment)
            break
          case "waiting":
          case "expired":
            waiting.push(investment)
            break
          case "cancelled":
            cancelled.push(investment)
            break
          default:
            break
        }
      })
      setPendingInvestments(pending)
      setWaitingInvestments(waiting)
      setCancelledInvestments(cancelled)
    })
    getFundedInvestments(baseUrl, entity.id).then(r => {
      const { funded_investments, yield_returns } = r.response.json.data
      const output = funded_investments.map(investment => {
        let runningTotalReturn = 0
        let runningTotalPrincipal = 0
        yield_returns.forEach(yr => {
          if (yr.offering_id === investment.offering_id) {
            if (yr.return_type === "Return") {
              runningTotalReturn += yr.amount
            } else {
              runningTotalPrincipal += yr.amount
            }
          }
        })

        return {
          totalReturn: runningTotalReturn,
          totalPrincipal: runningTotalPrincipal,
          ...investment
         }
      })
      setIssuedInvestments(output)
    })
    getTransactions(baseUrl, entity.id).then(data => {
      const transactions = data.response.json.data
      setTransactions(transactions)
    })
  }

  useEffect(() => {
    reloadData()
  }, [])

  return (
    <>
      <div className="card my-3">
        <div className="card-header">Investments</div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-link-row yt-table">
              <thead>
                <tr>
                  <th>Offering</th>
                  <th>Investment</th>
                  <th>Interest Rate</th>
                  <th>Issue Date</th>
                  <th className="text-right">Interest Received</th>
                  <th className="text-right">Principal Repaid</th>
                  <th>Expected Maturity</th>

                </tr>
              </thead>
              <tbody>
                {
                  issuedInvestments.length
                    ? issuedInvestments.map(investment => <InvestmentRow investment={investment} key={`entity-investments-row-${investment.id}`} />)
                    : <tr><td colSpan="8" className="text-center">This entity has no funded investments</td></tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="card my-3">
        <div className="card-header">Pending</div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-link-row yt-table">
              <thead>
                <tr>
                  <th>Offering</th>
                  <th>Amount</th>
                  <th>Subscription Document</th>
                  <th>Funding</th>
                  <th>Issue Date</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {
                  pendingInvestments.length
                    ? pendingInvestments.map(investment => <PendingRow investment={investment} reloadData={reloadData} key={`entity-pending-row-${investment.id}`} />)
                    : <tr><td colSpan="6" className="text-center">You have no pending investments</td></tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="card my-3">
        <div className="card-header">Waitlist</div>
        <div className="card-body">
          <div className="d-lg-table w-100 yt-table table">
            <div className="d-lg-table-row d-none">
              <div className="yt-th d-lg-table-cell">Offering</div>
              <div className="yt-th d-lg-table-cell text-right">Amount</div>
              <div className="yt-th d-lg-table-cell text-right">Issue Date</div>
              <div className="yt-th d-lg-table-cell" />
            </div>
            {
              waitingInvestments.length
                ? waitingInvestments.map(investment => <WaitlistRow investment={investment} reloadData={reloadData} key={`entity-waitlist-row-${investment.id}`} />)
                : <tr><td colSpan="6" className="text-center">You have no investments</td></tr>
            }
          </div>
        </div>
      </div>
      <div className="card my-3">
        <div className="card-header">Transactions</div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-link-row yt-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Deal Name</th>
                  <th>Description</th>
                  <th>Amount</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {
                  transactions.length
                    ? transactions.map(transaction => <Transactions transaction={transaction} key={`entity-cancelled-row-${transaction.id}`} />)
                    : <tr><td colSpan="6" className="text-center">You have no transactions</td></tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="card my-3">
        <div className="card-header">Cancelled</div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-link-row yt-table">
              <thead>
                <tr>
                  <th>Offering</th>
                  <th>Amount</th>
                  <th>Updated At</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {
                  cancelledInvestments.length
                    ? cancelledInvestments.map(investment => <CancelledRow investment={investment} key={`entity-cancelled-row-${investment.id}`} />)
                    : <tr><td colSpan="6" className="text-center">You have no cancelled investments</td></tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  baseUrl: getBaseUrl(state)
})

export default connect(mapStateToProps)(Investments)
