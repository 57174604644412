const bronIdCountryList = [
  {
    value: "AU",
    label: "Australia"
  },
  {
    value: "SG",
    label: "Singapore"
  },
  {
    value: "AT",
    label: "Austria"
  },
  {
    value: "BE",
    label: "Belgium"
  },
  {
    value: "BR",
    label: "Brazil"
  },
  {
    value: "CA",
    label: "Canada"
  },
  {
    value: "CN",
    label: "China"
  },
  {
    value: "DK",
    label: "Denmark"
  },
  {
    value: "FR",
    label: "France"
  },
  {
    value: "DE",
    label: "Germany"
  },
  {
    value: "IT",
    label: "Italy"
  },
  {
    value: "MX",
    label: "Mexico"
  },
  {
    value: "NL",
    label: "Netherlands"
  },
  {
    value: "NZ",
    label: "New Zealand"
  },
  {
    value: "NO",
    label: "Norway"
  },
  {
    value: "ES",
    label: "Spain"
  },
  {
    value: "SE",
    label: "Sweden"
  },
  {
    value: "CH",
    label: "Switzerland"
  },
  {
    value: "GB",
    label: "United Kingdom"
  }
]

export default bronIdCountryList