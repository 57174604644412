/* eslint-disable no-unused-expressions */
import axios from "axios"

export const getYieldBatch = () => axios.get("/api/backend/returns/index")
  .then(({ data, status }) => ({ response: data, status }))
  .catch(error => { error })

export const getYieldBatchDetail = id => axios.get(`/api/backend/returns/${id}/details`)
  .then(({ data, status }) => ({ response: data, status }))
  .catch(error => { error })

export const getIssuedOfferings = () => axios.get("/api/backend/returns/issued_offerings")
  .then(({ data, status }) => ({ response: data, status }))
  .catch(error => { error })

export const addYieldBatch = form => {
  const path = "/api/backend/returns/add_yield_batch"
  return axios.post(path, form)
  .then(({ data, status }) => ({ response: data, status }))
  .catch(error => { error })
}

export const updateYieldBatch = (id, formData) => {
  const path = `/api/backend/returns/${id}`
  return axios.put(path, formData)
    .then(({ data, status }) => ({ response: data, status }))
    .catch(error => { error })
}

export const exportAbaFile = (id, form) => {
  const path = `/api/backend/returns/${id}/export_aba`
  return axios.post(path, { form })
  .then(({ data, status }) => ({ response: data, status }))
  .catch(error => { error })
}
