import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter, Route, Switch } from "react-router-dom"

import ProtectedRoute from "components/utils/ProtectedRoute"

import * as reducers from "../reducers"

import RegisterForm from "./RegisterForm"
import LoginForm from "./LoginForm"
import PasswordResetForm from "./PasswordResetForm"
import ConfirmPasswordResetForm from "./ConfirmPasswordResetForm"
import VerificationForms from "./VerificationForms"
import ContinueLanding from "./ContinueLanding"

class Main extends Component {
  render() {
    const {
      currentUser,
      setSaveAndExitData
    } = this.props

    return (
      <Switch>
        <Route
          exact
          path="/"
          component={RegisterForm} />

        <Route
          exact
          path="/complete-account"
          component={ContinueLanding} />

        <Route
          exact
          path="/login"
          component={LoginForm} />

        <Route
          exact
          path="/forgot_password"
          component={PasswordResetForm} />

        <Route
          exact
          path="/users/password/edit"
          component={ConfirmPasswordResetForm} />

        <ProtectedRoute
          exact
          currentUser={currentUser}
          path="/verification"
          redirectedRoute="/marketplace"
          component={() => <VerificationForms setSaveAndExitData={setSaveAndExitData} />} />

        <Route
          path="/users/confirmation"
          // path="/users/confirmation*"
          component={ContinueLanding} />
      </Switch>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: reducers.getCurrentUser(state)
})

export default withRouter(connect(mapStateToProps)(Main))
