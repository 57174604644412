import React, { useState } from "react"
import { connect } from "react-redux"

import { getBaseUrl } from "../../reducers"
import AddInvestorCertForm from "../InvestorProfile/AddInvestorCertForm"
import AddWholesaleCertForm from "../InvestorProfile/AddWholesaleCertForm"

const AddInvestorProfileModal = ({ currentEntity, baseUrl }) => {
  const [formType, setFormType] = useState("Accredited Investor")
  return (
    <div
      aria-labelledby="pendingCancelledPopup"
      className="modal fade"
      id="addInvestorProfileModal"
      role="dialog"
      tabIndex={-1}
      style={{ display: "none" }}
      aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header border-0 pb-0">
            <button aria-label="Close" className="close" data-dismiss="modal" type="button">
              <span aria-hidden="true"> ×</span>
            </button>
          </div>
          <div className="modal-body pt-0 px-lg-5">
            <h2>Add Investor Profile</h2>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group mt-4">
                  <label>Profile Type</label>
                  <select
                    className="form-control custom-select"
                    onChange={e => {
                      setFormType(e.target.value)
                    }}>
                    <option value="Accredited Investor">Accredited Investor</option>
                    <option value="Wholesale Certificate">Wholesale Certificate</option>
                  </select>
                </div>
              </div>
            </div>
            {formType === "Accredited Investor"
              ? <AddInvestorCertForm currentEntity={currentEntity} baseUrl={baseUrl} />
              : <AddWholesaleCertForm currentEntity={currentEntity} baseUrl={baseUrl} />}
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  baseUrl: getBaseUrl(state)
})

export default connect(mapStateToProps)(AddInvestorProfileModal)
