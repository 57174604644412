import React, { useState, useEffect } from "react"
import { withRouter } from "react-router"
import { connect } from "react-redux"
import { useToasts } from "react-toast-notifications"
import { onlyNumbers } from "components/CommonMethods"
import { bankingCountryList } from "components/countries"
import { getBaseUrl } from "../../../reducers"
import { addBankDetails } from "../../../requesters"

const COUNTRIES_WITHOUT_SWIFT = ["AU"]

const AddBanking = ({ offeringId, baseUrl, reloadOffering }) => {
  const [form, setForm] = useState({ country: "" })
  // const [countryArray, setCountryArray] = useState([])
  const [isCodeValid, setIsCodeValid] = useState(false)
  const { addToast } = useToasts()

  const storeAndValidateField = e => {
    const {
      value, name
    } = e.target
    setForm({ ...form, [name]: value })
  }

  const onSubmit = () => {
    addBankDetails(baseUrl, offeringId, { form }).then(data => {
      const res = data.response.json
      if (res.success) {
        addToast("Bank Account created successfully", { appearance: "success" })
        reloadOffering()
      } else {
        addToast("Something went wrong", { appearance: "error" })
      }
    })
  }

  const validateBsb = () => {
    setIsCodeValid(form.bsb_number?.length === 6)
  }

  const validateSwift = () => {
    setIsCodeValid(!!form.swift_code)
  }

  useEffect(() => {
    if (form.country !== "AU") {
    validateSwift()
    } else {
      validateBsb()
    }
  }, [form])

  return (
    <div aria-labelledby="pendingCancelledPopup" className="modal fade" id="addBanking" role="dialog" tabIndex={-1} style={{ display: "none" }} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header border-0 pb-0">
            <button aria-label="Close" className="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body pt-0 px-lg-5 pb-5">
            <h2>Add Bank Account</h2>
            <p>Investors will transfer funds to this Bank Account</p>
            <div>
              <div className="my-5">
                <div className="form-group row mb-2">
                  <div className="col-sm-6 col-form-label">Country</div>
                  <div className="col-sm-6">
                    <select
                      className="form-control custom-select mr-2"
                      name="country"
                      onChange={e => {
                        storeAndValidateField(e)
                      }}>
                      {bankingCountryList.map(c => (
                        <option
                          key={c.value}
                          value={c.value}>
                          {c.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="form-group row mb-2">
                  <div className="col-sm-6 col-form-label">Financial Institution</div>
                  <div className="col-sm-6">
                    <input
                      className="form-control"
                      type="text"
                      autoComplete="disableAll"
                      onChange={storeAndValidateField}
                      name="financial_institution" />
                  </div>
                </div>
                {!COUNTRIES_WITHOUT_SWIFT.includes(form?.country) && (
                <div className="form-group row mb-2">
                  <div className="col-sm-6 col-form-label">SWIFT Code</div>
                  <div className="col-sm-6">
                    <input
                      className="form-control"
                      type="text"
                      autoComplete="disableAll"
                      onChange={storeAndValidateField}
                      name="swift_code" />
                  </div>
                </div>
                )}
                {form?.country === "AU" && (
                <>
                  <div className="form-group row mb-2">
                    <div className="col-sm-6 col-form-label">BSB</div>
                    <div className="col-sm-6">
                      <input
                        className="form-control"
                        type="number"
                        onKeyDown={onlyNumbers}
                        autoComplete="disableAll"
                        onChange={storeAndValidateField}
                        name="bsb_number" />
                    </div>
                  </div>
                  {!isCodeValid && !!form.bsb_number && <small className="err-msg text-danger">Please input a valid BSB (6 digits)</small>}
                </>
              )}
                <div className="form-group row mb-2">
                  <div className="col-sm-6 col-form-label">Account Name</div>
                  <div className="col-sm-6">
                    <input
                      className="form-control"
                      type="text"
                      autoComplete="disableAll"
                      onChange={storeAndValidateField}
                      name="account_name" />
                  </div>
                </div>
                <div className="form-group row mb-2">
                  <div className="col-sm-6 col-form-label">Account No.</div>
                  <div className="col-sm-6">
                    <input
                      className="form-control"
                      type="number"
                      autoComplete="disableAll"
                      onKeyDown={onlyNumbers}
                      onChange={storeAndValidateField}
                      name="account_number" />
                  </div>
                </div>
                <p className="mt-5 small w-75">Triple check these details are right. If money is credited to the wrong account we can’t guarantee its recovery or accept liability for any loss.</p>
              </div>
            </div>
            <div className="modal-footer border-0 justify-content-center">
              <button
                className="btn btn-main"
                id="btnCloseModal"
                data-dismiss="modal"
                type="button"
                style={{ marginRight: "1rem" }}
                disabled={!form.account_number || !form.account_name || !form.country || !form.financial_institution || !isCodeValid}
                onClick={onSubmit}>
                Add Account
              </button>
              <button className="btn btn-outlined-main" id="btnCloseModal" data-dismiss="modal" type="button">
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  baseUrl: getBaseUrl(state)
})

export default withRouter(connect(mapStateToProps)(AddBanking))