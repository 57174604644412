import React, { useState } from "react"
import { Link } from "react-router-dom"
import TwoFactorModal from "./TwoFactorModal"
import { generateTwoFactor } from "../../requesters/UsersRequester"

const LoginForm = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [showScreen, setShowScreen] = useState(false)
  const [error, setError] = useState("")

  const handleLogin = event => {
    event.preventDefault()
    generateTwoFactor({ email: email.toLowerCase(), password }).then(response => {
      if (_.get(response, "response.json.success")) {
        setShowScreen(true)
      } else setError(_.get(response, "response.json.message"))
    })
  }
  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          {showScreen ? (
            <TwoFactorModal
              email={email}
              password={password} />
          ) : (
            <div className="col-lg-6 col-md-8">
              <h1 className="text-center">
                Log In
              </h1>
              <form onSubmit={e => handleLogin(e)}>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    className="form-control"
                    onChange={e => setEmail(e.currentTarget.value)} />
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    onChange={e => setPassword(e.currentTarget.value)} />
                </div>
                <div className="text-center mt-5 pb-2">
                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-main btn-lg"
                      onClick={handleLogin}
                      disabled={!email || !password}>
                      Log In
                    </button>
                  </div>
                  {error && <div className="err-msg text-danger">{error}</div>}
                  <Link to="/forgot_password">Forgot password?</Link>
                  <hr />
                </div>
                <div className="text-center my-4 pt-2">
                  Don&#39;t have an account?
                  <Link to="/" className="text-main">&nbsp;Join Aura Group</Link>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default LoginForm