import React, { useState, useEffect } from "react"

import { backIcon } from "components/Icons"
import IdentitySubmitted from "./IdentitySubmitted"
import IdentityWaiting from "./IdentityWaiting"
import ProceedToBronId from "./ProceedToBronId"


const BronId = ({
  status,
  handleFormChange,
  setSaveAndExitDataFromState,
  handleBronIdRedirect
}) => {
  const [readyToNext, setReadyToNext] = useState(undefined)
  useEffect(() => {
    window.scrollTo(0, 0)
    setReadyToNext(status === "submitted")
    setSaveAndExitDataFromState()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const StatusComponent = () => {
    switch (status) {
      case "waiting":
        return <IdentityWaiting />
      case "submitted":
        return <IdentitySubmitted />
      default:
        return <ProceedToBronId handleBronIdRedirect={handleBronIdRedirect} />
    }
  }
  return (
    <>
      <StatusComponent />
      <div className="d-flex justify-content-between form-nav align-items-center">
        <button
          type="submit"
          className="btn btn-lg btn-plain pl-0"
          onClick={() => handleFormChange("profile")}>
          <div className="d-flex align-items-center justify-content-start">
            <img className="mr-2" src={backIcon} />
            Back
          </div>
        </button>
        <button
          type="submit"
          className="btn btn-main btn-lg"
          disabled={!(readyToNext)}
          onClick={() => handleFormChange("investor")}>
          Next
        </button>
      </div>
    </>
  )
}

export default BronId
