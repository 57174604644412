import React from "react"
import Money from "components/Money"

const InvestmentRow = ({ investment }) => (
  <tr key={`entity-investments-${investment.id}`}>
    <td>{investment.offering_name}</td>
    <td className="text-right">
      <Money currency="$" amount={investment.amount} />
    </td>
    <td className="text-right">{`${investment.interest_rate || ""}%`}</td>
    <td>{`${investment.start_date}`}</td>
    <td className="text-right">
      <Money currency="$" amount={investment.totalReturn} />
    </td>
    <td className="text-right">
      <Money currency="$" amount={investment.totalPrincipal} />
    </td>
    <td>{`${investment.end_date}`}</td>
  </tr>
  )

export default InvestmentRow
